import { MEMBER, CLIENT, EMPLOYEE, CONSULTANT } from '@beautycounter/constants/userStates';
import { minimumMonths } from '@beautycounter/constants/membershipRenewal';
import { subMonths, addMonths, isAfter, isBefore, isWithinRange } from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';

export function isNowWithinTimeWindow(start = null, end = null) {
    if (!start || !end) return false;

    const now = new Date();
    const windowStart = new Date(start);
    const windowEnd = new Date(end);

    return isWithinRange(now, windowStart, windowEnd);
}

export function isEligibleForBoB(userType = null, memberExpirationDateUTC = null) {
    const now = new Date();

    // special case for lapsed members (userType 3 with memberExpirationDateUTC field)
    if (userType && userType === Number(CLIENT) && memberExpirationDateUTC) {
        const minimumDate = addMonths(new Date(memberExpirationDateUTC), minimumMonths);
        const isAfterMinimumDate = isAfter(now, minimumDate);

        return isAfterMinimumDate;
    }

    // return regular check if user does not fall into special case
    return (
        userType !== Number(MEMBER) &&
        userType !== Number(CONSULTANT) &&
        userType !== Number(EMPLOYEE)
    );
}

export function isEligibleForBobRenewal(userType = null, memberExpirationDateUTC = null) {
    if (
        userType &&
        (userType === Number(MEMBER) || userType === Number(CLIENT)) &&
        memberExpirationDateUTC
    ) {
        const orderTimestamp = new Date();
        const memberExpirationDate = new Date(memberExpirationDateUTC);

        if (userType === Number(MEMBER)) {
            const expirationCompareDate = renewalEndOfDayDate(memberExpirationDateUTC);
            const minimumDate = subMonths(memberExpirationDate, minimumMonths);
            const isAfterMinDate = isAfter(orderTimestamp, minimumDate);
            const isBeforeExpiration = isBefore(orderTimestamp, expirationCompareDate);

            const isWithinDateRange = isAfterMinDate && isBeforeExpiration;
            return isWithinDateRange;
        }
        const maxLapsedDate = addMonths(memberExpirationDate, minimumMonths);
        const isAfterLapsedDate = isAfter(orderTimestamp, maxLapsedDate);

        return !isAfterLapsedDate;
    }

    // only current or lapsed members should be able to add Renewal to cart
    return false;
}

export function renewalEndOfDayDate(memberExpirationDateUTC) {
    // convert memberExpirationDateUTC from UTC to PST
    let dateUTC = new Date(memberExpirationDateUTC);
    let formattedDate = formatToTimeZone(dateUTC, 'YYYY-MM-DDTHH:mm:ss.SSS', {
        timeZone: 'America/Los_Angeles',
    });
    const datePSTString = formattedDate + 'Z';

    // set memberExpirationDateUTC to start of the day (member exp date at 12 am PST)
    const datePST = new Date(datePSTString);
    const utcInt = Date.parse(memberExpirationDateUTC);
    const pstInt = Date.parse(datePSTString);
    const offset = Number(((utcInt - pstInt) / (1000 * 60 * 60)).toFixed(0));
    const midnight = datePST.setUTCHours(offset, 0, 0, 0);
    const midnightDate = new Date(midnight);
    const startOfDay = midnightDate.toISOString();

    /*
    Use startOfDay date to calculate the end of day (member exp date at 11:59 pm PST).
    The endOfDay value will be used to compare against user's memberExpirationDateUTC
    since members are not expired until the end of the day at midnight
    */
    let utcIntEnd = Date.parse(startOfDay);
    let nextDay = utcIntEnd + 86399999;
    let newDate = new Date(nextDay);
    const expirationCompareDate = newDate.toISOString();

    return expirationCompareDate;
}
