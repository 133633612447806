import { observable, computed, action, runInAction, toJS, set } from 'mobx';
import getContent from '@cs-admin/services/content';
import { getCatalog } from '@cs-admin/services/catalog';
import { keyBy, isEmpty, cloneDeep } from 'lodash';
import { updateLocaleURL, unlocalizeURL } from '@cs-admin/utils/url';
import { sortTypes, developerTools, flags, locales } from '@cs-admin/config';
import { getSafe } from '@cs-admin/utils/object';

class ContentStore {
    @observable content;
    @observable extensions;
    @observable utilities;

    constructor() {
        this.content = {};
        this.extensions = {};
        this.updateInProgress = 1;
        this.fetch = [];
        this.utilities = {};
        this.subscription;
        this.server = {};
        this.context = {};
    }

    @computed
    get configQuickOrder() {
        return getSafe(() => this.extensions.quickOrder.data.configQuickOrder[0]) || {};
    }

    @computed
    get configQuickOrderExtented() {
        return getSafe(() => this.extensions.quickOrder.data.configQuickOrderExtended[0]) || {};
    }

    @action
    loadCatalog = code => {
        this.content = this.content || {};
        const locale = locales.find(x => x.code === code);

        if (!locale) return;
        this.retrieveAllModels(locale).then(([coreContent, , , catalog]) => {
            this.categoryStore.load({
                catalogCategories: catalog.data.Categories,
                contentfulCategories: coreContent.category,
                navigation: getSafe(() => coreContent.header[0].categoryNavigation.fields.links),
            });
            this.productsStore.load(catalog.data.Products);
            this.categoryStore.appendFilters({
                subcategories: catalog.data.Categories,
                filters: catalog.data.Filters,
            });
        });
    };

    retrieveAllModels = (
        newLocale,
        page = false,
        loadCatalog = true,
        firstLoad = false,
        userType,
    ) => {
        /* Retrieve core models and page index from Contentful */
        const fetch = [
            getContent({
                locale: newLocale.code,
                type: 'coreComponents',
                drafts: this.flags('contentDrafts'),
                firstLoad,
                renderId: this.renderId,
            }),
            getContent({
                locale: newLocale.code,
                type: 'pageIndex',
                drafts: this.flags('contentDrafts'),
                firstLoad,
                renderId: this.renderId,
            }),
        ];

        /* Retrieve data for current page (if applicable) */
        if (page) {
            fetch.push(
                getContent({
                    locale: newLocale.code,
                    type: 'singlePage',
                    page,
                    drafts: this.flags('contentDrafts'),
                    firstLoad,
                    renderId: this.renderId,
                }),
            );
        } else fetch.push(null);

        /* Retrieve product catalog */
        if (loadCatalog) {
            const params = {
                locale: newLocale,
                useCache: this.flags('catalogCaching'),
                firstLoad,
                drafts: this.flags('contentDrafts'),
                /* Wait until Auth has been verified */
                userType,
            };

            fetch.push(getCatalog(params));
        } else fetch.push(null);

        /* Retrieve extension data e.g. containers with CMS dependences */
        if (Object.keys(this.extensions).length) {
            Object.keys(this.extensions).map(extension => {
                const params = this.extensions[extension].params;
                fetch.push(this.getOnce({ ...params, firstLoad }));
            });
        }

        return Promise.all(fetch);
    };

    @action
    get = async ({
        locale = this.portalOrderStore.locale,
        page = false,
        showProgress = true,
        loadCatalog = true,
        firstLoad = false,
        reload = false,
        userType = this.userStore.isLogged && this.portalOrderStore.userType,
    }) => {
        if (firstLoad) {
            this.content = {};
            this.extensions = {};
            this.fetch = [];
        }

        /* Create an immutable copy of the locale internally */
        const newLocale = JSON.parse(JSON.stringify(locale));

        const path = window.location.pathname;

        if (typeof this.content.page != 'undefined' && !isEmpty(this.content.page)) {
            for (const pageName in this.content.page) {
                if (pageName == path || `/${pageName}` == path) {
                    page = path;
                }
            }
            // TODO: Run pages that are not found through the router again
            // rather than defaulting
            if (!page) page = '/';
        }

        const response = await this.retrieveAllModels(
            newLocale,
            page,
            loadCatalog,
            firstLoad,
            userType,
        );

        const [coreContent, pageIndex, currentPage, catalog] = response;

        // Save for server side dehydration
        if (__SERVER__ && this.flags('contentDrafts')) this.catalogFetch = catalog;

        if (coreContent && coreContent && typeof coreContent.category !== 'undefined') {
            runInAction('updating content', () => {
                this.categoryStore.load({
                    catalogCategories: catalog.data.Categories,
                    contentfulCategories: coreContent.category,
                    navigation: getSafe(
                        () => coreContent.header[0].categoryNavigation.fields.links,
                    ),
                });

                if (loadCatalog) {
                    this.productsStore.load(catalog.data.Products);
                    this.categoryStore.appendFilters({
                        subcategories: catalog.data.Categories,
                        filters: catalog.data.Filters,
                    });
                }
                if (response.length > 3) {
                    const extensions = response.slice(4);
                    extensions.map(extension => {
                        if (
                            !getSafe(() => extension.params.route) ||
                            (getSafe(() => extension.params.route) &&
                                containsRoute(
                                    toJS(extension.params.route),
                                    this.navigationStore.unlocalizedBasePath(),
                                ))
                        ) {
                            this.extensions[extension.params.id].data = cloneDeep(extension);
                            this.extensions[extension.params.id].locale = newLocale.code;
                            extension.params.refreshCallback();
                        }
                    });
                }

                function containsRoute(routes, url) {
                    if (Array.isArray(routes))
                        return (
                            routes.filter(route => url.includes(route)).length ||
                            routes.includes(url)
                        );
                    return url.includes(routes) || routes.includes(url);
                }

                this.categoryStore.appendSortTypes({
                    sortTypes,
                    names: response[0].shop[0],
                });

                const pages = keyBy(pageIndex.page, 'url');

                if (page && currentPage) {
                    pages[page] = {
                        ...cloneDeep(currentPage),
                        activeLocale: newLocale,
                    };
                }

                this.content = {
                    ...coreContent,
                    page: { ...this.content.pages, ...pages },
                };
                this.extensions = { ...this.extensions };

                if (reload) this.navigationStore.to({ url: path + (location.search || '') });

                showProgress && this.interfaceStore.hideProgress(`content-get-${newLocale.code}`);
            });
        } else {
            console.error('App failed to update content. Check your API request.');
            showProgress && this.interfaceStore.hideProgress('content-get');
            return false;
        }
    };

    @action
    getPage = async ({ locale, page, firstLoad }) => {
        if (
            this.content.page[page] &&
            this.content.page[page].activeLocale &&
            this.localeStore.activeLocale.code === this.content.page[page].activeLocale &&
            !this.contentDrafts
        )
            return true;

        const pageContent = await getContent({
            locale,
            type: 'singlePage',
            page,
            drafts: this.flags('contentDrafts'),
            firstLoad,
            renderId: this.renderId,
        });

        runInAction('updating page', async () => {
            if (locale == this.localeStore.activeLocale.code)
                this.content.page[page] = {
                    ...cloneDeep(pageContent),
                    activeLocale: locale,
                };
        });
    };

    @action
    getOnce = async item => {
        const data = await getContent({
            locale: this.localeStore.activeLocale.code,
            type: item.type,
            drafts: this.flags('contentDrafts'),
            firstLoad: item.firstLoad || false,
            renderId: this.renderId,
        });

        return { ...data, locale: this.localeStore.activeLocale.code, params: item };
    };

    @action
    createExtension = async extension => {
        set(this.extensions, extension.id, {
            data: null,
            params: extension,
            locale: this.localeStore.activeLocale.code,
        });

        const data = await getContent({
            locale: this.localeStore.activeLocale.code,
            type: extension.type,
            drafts: this.flags('contentDrafts'),
            renderId: this.renderId,
        });

        runInAction('updating extension', async () => {
            this.extensions[extension.id].data = cloneDeep(data);
            extension.refreshCallback();
            return true;
        });
    };

    @action
    addExtensionSubscription(location, callback) {
        try {
            if (this.extensions[location])
                this.extensions[location].params.refreshCallback = callback;
        } catch (e) {
            console.log(e);
        }
    }

    @action
    fetchContentfulConfig = async ({ id, route, models, callback = () => null }) => {
        if (
            this.extensions[id] &&
            this.extensions[id].data &&
            this.localeStore.activeLocale.code === this.extensions[id].locale &&
            !this.contentDrafts
        )
            return true;

        const actions = [
            this.createExtension({
                route,
                id,
                type: {
                    'sys.contentType.sys.id[in]': models.toString(),
                },
                refreshCallback: callback,
            }),
        ];

        await Promise.all(actions);
        return true;
    };

    @action
    refresh = async () => {
        await this.get({ reload: true });
    };

    @action
    flags(key, alt) {
        /*
         * This method will be deprecated in favor of
         * flagStore.isFeatureEnabled. However, we will proxy requests for
         * backwards compatibility temporarily.
         */

        if (key === 'nogento') return true;
        if (
            this.utilities &&
            this.utilities[key] &&
            this.utilities[key].hasOwnProperty('enabled')
        ) {
            return typeof this.utilities[key].enabled === 'function'
                ? !!this.utilities[key].enabled()
                : !!this.utilities[key].enabled;
        }
        return this.flagStore.isFeatureEnabled[key] || flags[key];
    }
}

const contentStore = new ContentStore();

export default contentStore;
export { ContentStore };
