import { CartEngine } from '.';
import { GWP, cartGWP, gwpIds } from './promos/calculatePromos';

/**
 * A convenience adapter for the cart engine
 * that calculates promos for a single
 * SKU. Useful for product grids and detail pages.
 * @param {object} context
 * @param {array} rules
 */

export default function getPromoPricing(context, ruleset) {
    if (!context && !context.product && typeof context.product !== 'object')
        throw new Error('Could not get promo pricing. No product provided.');
    if (!ruleset && !Array.isArray(ruleset))
        throw new Error('Could not get promo pricing. No valid ruleset provided.');

    const internalContext = JSON.parse(JSON.stringify(context));
    if (internalContext.cart.products.some(p => p.sku == context.product.sku)) {
        let idx = internalContext.cart.products.findIndex(p => p.sku == context.product.sku);
        internalContext.cart.products[idx].quantity++;
        internalContext.cart.products[idx].totalPrice += Number(context.product.price);
    } else {
        internalContext.cart.products.push({
            sku: String(context.product.sku),
            price: Number(context.product.price),
            totalPrice: Number(context.product.price),
            quantity: Number(1),
        });
    }

    const engine = new CartEngine();
    engine.updateRules(ruleset, {
        noDrop: true,
    });
    const oldContext = engine.applyPromos(JSON.parse(JSON.stringify(context)));
    const newContext = engine.applyPromos(internalContext);

    //if rules have isTier flag, we need to check for the threshold case for each promotion
    const tieredPromos = ruleset.filter(({ isTier }) => isTier);
    if (tieredPromos.length) {
        tieredPromos.forEach(tieredPromo =>
            handleTierDiscounts(oldContext, newContext, tieredPromo),
        );
    }

    let discount = Number(getDiscount(newContext)) - Number(getDiscount(oldContext));

    if (discount < 0) {
        discount = 0;
    }

    return {
        sku: context.product.sku,
        price: context.product.price,
        totalPrice: Number(context.product.price - discount),
        appliedPromos: newContext.cart.appliedPromos.filter(
            ({ sku }) => sku && sku == context.product.sku,
        ),
    };
}

export function getGWPPromos(context) {
    if (context && context.cart && context.cart.appliedPromos && context.cart.appliedPromos.length)
        return context.cart.appliedPromos.filter(
            a => a.sku && a.sku == context.product.sku && gwpIds.some(id => a.id == id),
        );

    return [];
}

export function getDiscount(context) {
    const discount =
        context.cart.appliedPromos
            .filter(a => a.sku == context.product.sku)
            .reduce((b, c) => {
                return b + Number(c.discount);
            }, 0) || 0;
    return Number(discount);
}

export function handleTierDiscounts(oldContext, newContext, tieredPromo) {
    const oldAppliedPromos = oldContext.cart.appliedPromos;
    const newAppliedPromos = newContext.cart.appliedPromos;
    const hasThresholdCase =
        !oldAppliedPromos.some(({ name }) => name === tieredPromo.name) &&
        newAppliedPromos.some(({ name }) => name === tieredPromo.name);

    if (hasThresholdCase) {
        //sort to get all applied tiered rules by sku
        const rulesBySku = newAppliedPromos
            .filter(({ name }) => name === tieredPromo.name)
            .reduce((acc, promo) => {
                const { sku } = promo;
                acc[sku] ? acc[sku].push(promo) : (acc[sku] = [promo]);
                return acc;
            }, {});

        //remove one of each applied tier rule
        const edgeCasePromos = Object.values(rulesBySku).reduce((acc, ruleset) => {
            acc = [...acc, ...ruleset.slice(1)];
            return acc;
        }, []);

        //add tier promos to old context to get correct discount for specified product
        oldContext.cart.appliedPromos = [...oldAppliedPromos, ...edgeCasePromos];
    }
}
