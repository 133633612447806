import { observable, computed, action, toJS } from 'mobx';
import { cloneDeep, uniqBy, orderBy, isEmpty, find, unionBy } from 'lodash';
import { getAccountInfo, searchConsultantNames } from '@cs-admin/services/portalOrder';
import Field from '@cs-admin/stores/formStore/field';
import { getUserByPhoenixId } from '@cs-admin/services/customers';
import { getPersonalOrders } from '@cs-admin/services/orders';
import { searchForProducts } from '@cs-admin/services/search';
import { CONSULTANT, EMPLOYEE, GUEST, MEMBER } from '@cs-admin/constants/userStates';
import { locales, specialCategories, square } from '@cs-admin/config';
import { loadUserAllOpenCarts } from '@cs-admin/services/cart/multiCarts';
import throttle from '@cs-admin/utils/throttle';
import Autocomplete from '@cs-admin/services/maps/places/autocomplete';
import {
    getCustomerCards,
    getEstimate,
    submitPortalOrder,
    verifyOrderForEnrollment,
} from '@cs-admin/services/portalOrder/portalOrder';
import { getProductCredit } from '@cs-admin/services/productCredit/productCredit';
import { getOpenPopups } from '@cs-admin/services/popups';
import { getUserToken } from '@cs-admin/services/user';
import { SPLIT_PAYMENT } from '@beautycounter/constants/paymentMethods';
import { getSafe } from '@cs-admin/utils/object';
import { COUNTERBASE } from '../../constants/channels';
import { mapShippingMethod } from '@cs-admin/constants/shipping';
import { PORTAL, ENROLLMENT, REPLACEMENT } from '@cs-admin/constants/orderTypes';
import { NEXT_DAY } from '@cs-admin/constants/shippingMethods';
import { writeAddressData } from '@cs-admin/services/user/address';
import { ERROR_CODES, SQUARE_FIELDS } from './constants';
import { getISODateString } from '@cs-admin/utils/date';
import { isAddressMelissaVerificationExpired } from '@cs-admin/utils/address';

class PortalOrderStore {
    @observable account;
    @observable addressForm;
    @observable attribution;
    @observable popup;
    @observable openPopups;
    @observable suppressEmailConfirmation;
    @observable countryId;
    @observable isLoadingProducts;
    @observable orders;
    @observable isOrderLoading;
    @observable searchText;
    @observable searchResults;
    @observable shippingAddresses;
    @observable customerCards;
    @observable shippingMethod;
    @observable shippingRates;
    @observable splitPayments;
    @observable estimateId;
    @observable context;
    @observable newCart;
    @observable isLoading;
    @observable productCredit;
    @observable appliedPC;
    @observable applyPC;
    @observable applyPCSuccess;
    @observable pcAmount;
    @observable squareErrors;
    @observable paymentInfo;
    @observable readyToSubmit;
    @observable voidTax;
    @observable voidShipping;
    @observable defaultAddress;
    @observable orderType;
    @observable orderTypeErrors;
    @observable squareSDKCard;

    constructor() {
        this.resetStore();
    }

    ready() {
        if (__BROWSER__) {
            this.categoryStore.setCurrentCategory(specialCategories.bestSellers);
            this.loadProducts();
            this.resetStore();
        }
    }

    resetStore() {
        this.customerCards = [];
        this.autocomplete = null;
        this.account = null;
        this.addressForm = null;
        this.attribution = null;
        this.activeCarts = [];
        this.popup = null;
        this.openPopups = [];
        this.suppressEmailConfirmation = false;
        this.countryId = 1;
        this.isLoadingProducts = false;
        this.orders = [];
        this.isOrderLoading = false;
        this.shippingAddresses = [];
        this.defaultAddress = 0;
        this.splitPayments = [];
        this.estimateId = null;
        this.context = null;
        this.newCart = false;
        this.isLoading = false;
        this.shippingMethod = null;
        this.shippingRates = [];
        this.productCredit = {};
        this.userToken = null;
        this.appliedPC = 0;
        this.applyPC = false;
        this.applyPCSuccess = false;
        this.pcAmount = 0;
        this.squareErrors = {
            cardNumber: null,
            expirationDate: null,
            cvv: null,
            postalCode: null,
            cardBrand: null,
        };
        this.paymentInfo = {};
        this.readyToSubmit = null;
        this.voidTax = false;
        this.voidShipping = false;
        this.orderType = null;
        this.orderTypeErrors = [];
        this.squareSDKCard = {};
    }

    @computed
    get selectedAccount() {
        return this.account;
    }

    @computed
    get selectedAttribution() {
        return this.attribution;
    }

    @computed
    get selectedPopup() {
        return this.popup;
    }

    @computed
    get squareLocationId() {
        return (
            getSafe(() => this.context.payment.squareLocationId) ||
            square[this.context.country].locationId
        );
    }

    @computed
    get userType() {
        return (this.account && this.account.userType) || GUEST;
    }

    @computed
    get isMember() {
        return this.account && this.account.userType === MEMBER;
    }

    @computed
    get isConsultant() {
        return (this.account && this.account.userType === CONSULTANT) || null;
    }

    @computed
    get isEmployee() {
        return this.account && this.account.userType === EMPLOYEE;
    }

    @computed
    get countryString() {
        return this.countryId === 1 ? 'US' : 'CA';
    }

    @computed
    get locale() {
        const locale = locales.find(locale => locale.countryId === this.countryId);
        return locale;
    }

    @computed
    get productsBySearch() {
        return this.searchResults;
    }

    @computed
    get defaultShipping() {
        if (!this.shippingAddresses || !this.shippingAddresses.length) return {};

        const shipping =
            (this.shippingAddresses.length &&
                this.shippingAddresses.find(
                    address => address.isDefaultShipping === 1 || address.isDefaultShipping === 1,
                )) ||
            this.shippingAddresses[0];
        return {
            id: shipping.id,
            name: `${shipping.firstName} ${shipping.lastName}`,
            phone: shipping.telephone,
            ...shipping,
        };
    }

    @computed
    get shippingTotal() {
        if (this.context && this.shippingRates && this.shippingMethod) {
            const rate = this.shippingRates.find(rate => rate.method === this.shippingMethod);
            if (!this.stores.cartStore.overrides.overrideShipping && rate && rate.amount)
                return rate.amount;
        }
        return 0;
    }

    countryAbbreviation(name) {
        // if the country Abbreviation is US/CA anything else length should be 2
        // if it is just return, otherwise go and change
        if (name.length === 2) return name;

        let shortName;

        switch (name) {
            case 'United States':
                shortName = 'US';
                break;
            case 'Canada':
                shortName = 'CA';
                break;
            default:
                shortName = name;
                break;
        }

        return shortName;
    }

    get taxTotal() {
        if (this.context && this.context.tax) {
            return this.stores.cartStore.overrides.overrideTax
                ? 0
                : this.context.tax && this.context.tax.taxAmount;
        }
        return 0;
    }

    @computed
    get availablePC() {
        const country = this.countryString.toLowerCase();
        if (Object.keys(this.productCredit).length && this.productCredit[country]) {
            return this.productCredit[country].balance;
        }
        return 0;
    }

    @computed
    get getGeneralData() {
        return {
            useUrlCode: false,
            localcode: this.locale.code,
            timestamp: new Date().toISOString(),
            channel: COUNTERBASE,
            country: this.locale.countryShort,
            byConsultant: false,
            userOverride: false,
            estimateId: this.estimateId || null,
            newShippingRates: true,
            newOrderFlow: true,
            shadowOrder: false,
            ecomm: false,
            step: 2,
            suppressEmailConfirmation: this.suppressEmailConfirmation,
            sponsorId: this.attribution ? this.attribution.phoenixId : 1,
        };
    }

    get getUserData() {
        // locale needs to change in the future because this dictates subtotal pricing to the backend in the contexct sent
        const anonymousId = this.sessionStore.anonymousId || '';
        const token = this.userToken;
        return {
            user: {
                anonymousId,
                userType: this.userStore.userInfo.userType,
                email: this.userStore.userInfo.email || null,
                userRole: this.userStore.userInfo.roleName,
                token,
                locale: this.locale.code,
                regionId: 1,
                consultantId: 0,
                sponsorId: 1,
                sailthruBid: null,
                receiveSmsNotifications: false,
            },
        };
    }

    get getPaymentData() {
        if (this.orderTotal === 0) {
            return {
                payment: {
                    method: SPLIT_PAYMENT,
                    cards: toJS(this.customerCards),
                    squareId: null,
                    squareNonce: '',
                    paypal: null,
                    appliedCredit: Number(this.appliedPC),
                    firstName: this.paymentInfo.firstName || '',
                    lastName: this.paymentInfo.lastName || '',
                    saveCard: false,
                    cardId: false,
                    splitPayments: [
                        {
                            // ...this.paymentInfo,
                            amount: 0,
                        },
                    ],
                    currency: this.locale.currency.name,
                },
            };
        }

        return {
            payment: {
                method: SPLIT_PAYMENT,
                cards: toJS(this.customerCards),
                squareId: this.account.squareId,
                squareNonce: '',
                paypal: null,
                appliedCredit: Number(this.appliedPC),
                firstName: this.paymentInfo.firstName || '',
                lastName: this.paymentInfo.lastName || '',
                saveCard: false,
                cardId: this.paymentInfo.cardId || false,
                splitPayments: [
                    {
                        ...this.paymentInfo,
                        amount: this.orderTotal || 0,
                    },
                ],
                currency: this.locale.currency.name,
            },
        };
    }

    get getCustomerData() {
        return {
            customer: {
                uid: this.account.uid,
                sponsorId: this.account.sponsorId,
                bobTCs: toJS(this.account.bobTCs),
                email: this.account.email,
                tAndCAccepted: this.account.tAndCAccepted,
                enrollmentDateUTC: this.account.enrollmentDateUTC,
                id: this.account.uid,
                countryId: this.account.countryId || this.locale.countryId,
                lastName: this.account.lastName,
                userType: this.account.userType,
                squareId: this.account.squareId,
                phoenixId: this.account.phoenixId,
                firstName: this.account.firstName,
                memberExpirationDateUTC: this.account.memberExpirationDateUTC,
            },
            attribution: {
                attributedConsultantId: this.attribution ? this.attribution.phoenixId : 1,
                sponsorId: this.attribution ? this.attribution.phoenixId : 1,
            },
        };
    }
    @action
    initilize() {
        this.stores.cartStore.clearItems();
        this.removeAttribution();
    }

    buildPromoItems(promoItems) {
        if (!promoItems) return promoItems;

        return promoItems.map(
            ({
                sku,
                quantity,
                price,
                id,
                image,
                title,
                bundled,
                isSample = false,
                isIncluded = false,
            }) => ({
                sku,
                quantity,
                price,
                id,
                image,
                name: title,
                title,
                isSample,
                isIncluded,
                bundled: bundled && Object.values(bundled),
            }),
        );
    }

    buildProducts(products) {
        if (!products) return products;

        return products.map(
            ({
                sku,
                quantity,
                price,
                priceInCents,
                id,
                image,
                isFinalSaleConfig,
                title,
                bundled,
                swatchlabel,
                CV,
                PV,
                QV,
                doNotShipAlone,
            }) => ({
                sku,
                quantity,
                price,
                priceInCents,
                id,
                image,
                isFinalSaleConfig,
                name: title,
                title,
                swatchlabel,
                bundled: bundled && Object.values(bundled),
                CV,
                PV,
                QV,
                doNotShipAlone,
            }),
        );
    }

    get getAddress() {
        if (this.addressForm) {
            const {
                firstName,
                lastName,
                companyName,
                address,
                address2,
                city,
                region,
                postalCode,
                telephone,
                country,
                saveAddress,
            } = this.addressForm.fields;
            return {
                firstName: firstName.value,
                lastName: lastName.value,
                companyName: companyName.value,
                address: address.value,
                address2: address2.value,
                apt: address2.value, // added apt for proper mapping to order/invoice docs and phoenix
                city: city.value,
                state: region.value,
                postalCode: postalCode.value,
                telephone: telephone.value,
                country: this.countryAbbreviation(country.value),
                saveAddress: saveAddress.value,
            };
        }
        return {};
    }

    get getCartData() {
        // might need to change hostrewarditems and promotags to do Object.values()
        // i havent seen them used in CB to see what data type they come in, but I assume they are arrays for now
        const currency = this.locale.currency.name;
        const products = Object.values(this.cartStore.unpromofiedItems);
        const promoItems = Object.values(this.cartStore.promoItems).length
            ? Object.values(this.cartStore.promoItems)
            : [];
        const hostRewardItems = this.cartStore.hostRewardItems.length
            ? this.cartStore.hostRewardItems
            : [];
        const promoTags = this.cartStore.promoTags.length ? this.cartStore.promoTags : [];
        return {
            cart: {
                activeLocale: this.cartStore.activeLocale,
                orderType: this.cartStore.orderType,
                cartId: this.cartStore.cartId,
                subtotal: this.cartStore.cart.total,
                currency: this.locale.currency.name,
                products: this.buildProducts(products),
                promoItems: this.buildPromoItems(promoItems),
                hostRewardItems,
                promoTags,
                shipping: {
                    method: this.shippingMethod,
                    saveAddress: false,
                    address: this.getAddress,
                },
                giftMessage: this.cartStore.giftMessage,
                giftOption: this.cartStore.giftOption,
                outOfStock: [],
                socialId: (this.popup && this.popup.id) || null, // his socialId is for quick/social ordering to attach to social
                isWholesale: false,
            },
        };
    }
    @action
    buildContext = async () => {
        const overrides = toJS(this.cartStore.overrides);
        const userData = this.getUserData;
        const customerData = this.getCustomerData;
        const generalData = this.getGeneralData;
        const paymentData = this.getPaymentData;
        const addressData = this.getAddress;
        const cartData = this.getCartData;
        const { siftSessionId } = this.sessionStore;

        const newContext = {
            ...this.context,
            ...generalData,
            ...userData,
            ...paymentData,
            ...addressData,
            ...cartData,
            ...customerData,
            orderType: this.orderType,
            overrides,
            siftSessionId,
            squareV2: this.flagStore.isFeatureEnabled('useV2'),
        };

        // if (this.appliedCredit && newContext.memberOverride) {
        //     newContext.memberOverride.appliedCredit = this.appliedMemberCredit;
        //     newContext.customerProductCredit.appliedCredit = this.appliedMemberCredit;
        // }
        this.context = newContext;
        return newContext;
    };

    @computed
    get expiringPC() {
        const country = this.countryString.toLowerCase();
        const pc = Object.keys(this.productCredit).length ? this.productCredit[country] : null;

        if (pc) {
            const currentDate = new Date();

            if (pc.ledger) {
                let nextToExpire = null;
                pc.ledger.map(({ remainingValue, expirationDate, doesNotExpire }) => {
                    if (!doesNotExpire && remainingValue > 0) {
                        const expDate = new Date(expirationDate);
                        const nextToExpDate = nextToExpire
                            ? new Date(nextToExpire.expirationDate)
                            : null;

                        if (nextToExpire && expDate >= currentDate && nextToExpDate > expDate)
                            nextToExpire = { expirationDate, amount: remainingValue / 100 };
                        else if (!nextToExpire && expDate >= currentDate)
                            nextToExpire = { expirationDate, amount: remainingValue / 100 };
                    }
                });

                return nextToExpire;
            }
            return { amount: pc.balance, expirationDate: pc.expirationDate };
        }
        return null;
    }

    @computed
    get orderTotal() {
        return (
            this.cartStore.subtotalWithOverrides +
            this.shippingTotal +
            this.taxTotal -
            this.appliedPC
        );
    }

    @action
    async loadProducts() {
        this.isLoadingProducts = true;
        await this.contentStore.loadCatalog('en-US');
        this.isLoadingProducts = false;
    }

    @action
    resetSquareErrors() {
        this.squareErrors.cardNumber = null;
        this.squareErrors.expirationDate = null;
        this.squareErrors.cvv = null;
        this.squareErrors.postalCode = null;
        this.squareErrors.cardBrand = null;
    }

    @action
    applyProductCredit(pcAmount) {
        if (pcAmount <= this.availablePC) {
            this.appliedPC = pcAmount;
            return true;
        }
        return false;
    }

    @action
    handleGetEstimate = async (triggerLoading = true) => {
        if (triggerLoading) this.loading = true;
        this.token = await getUserToken();
        this.context = await this.buildContext();
        // We only need to send for estimate if there is an address defined
        if (this.context.cart.shipping.address.address != '') {
            this.readyToSubmit = true;
            const response = await getEstimate(this.context);
            response && (this.readyToSubmit = false);
            if (triggerLoading) this.loading = false;
            this.handleClientErrors(response);
            this.handleVoidShippingErrors(response);

            if (response.success) {
                this.updateContext(response.context);
                this.estimateId = response.context.estimateId;
                return true;
            }
        }
    };

    @action
    setDefaultAddress = addressId => {
        this.defaultAddress = addressId;
    };

    @action
    updateShippingAddress = async (uid, address) => {
        try {
            const {
                data: { addresses },
            } = await writeAddressData(uid, address);

            return find(addresses, { id: address.id });
        } catch (error) {
            console.error(error);
            return {};
        }
    };

    @action
    handleClientErrors = ({ context, success, contentfulCode, message, code }) => {
        console.log(success, context);
        if (!context || !success) {
            console.log('There was an error connecting to the server.', {
                type: 'error',
            });
        }
    };

    handleVoidShippingErrors = ({ success, message, code }) => {
        if (!success && code === ERROR_CODES.VOID_SHIPPING)
            this.interfaceStore.openAlert(true, message);
    };

    @action
    updateContext = context => {
        this.context = context;
        const { rates = [] } = context.cart.shipping;
        this.shippingRates = [];
        rates.forEach(rate => {
            this.shippingRates.push({
                ...rate,
                title: mapShippingMethod(rate.phoenixShippingMethodId),
            });
        });

        if (this.shippingMethod) {
            const rate = this.shippingRates.find(rate => rate.method === this.shippingMethod);
            if (!rate) this.shippingMethod = null;
        }
    };

    @action
    setCurrentCategory = category => {
        this.searchText = '';
        this.currentCategory = category;
        this.categoryStore.setCurrentCategory(category);
    };

    @action
    setSearchText = text => {
        this.setCurrentCategory(1);
        this.searchText = text;
        this.updateSearchResult(text);
    };

    @action
    addNewShippingAddress = address => {
        this.shippingAddresses = unionBy([address], this.shippingAddresses, 'id');
    };

    @action
    setShippingMethod(shippingMethod) {
        this.shippingMethod = shippingMethod;
    }

    @action
    selectShippingAsAgent() {
        if (!this.shippingMethod) return null;
        const rates = getSafe(() => this.context.cart.shipping.rates);
        const phoenixShippingMethod = this.getPhoenixShippingMethod(rates);
        if (phoenixShippingMethod && phoenixShippingMethod !== NEXT_DAY) return false;
        if (this.voidShipping) {
            this.voidShipping = false;
            this.stores.cartStore.overrides.overrideShipping = false;
        }
        return true;
    }

    @action
    async setOrderType(orderType) {
        const orderTypeInt = parseInt(orderType, 10);
        if (!isNaN(orderTypeInt)) {
            this.orderType = orderTypeInt;
            await this.validateOrderType();
        }
    }

    @action
    clearOrderType() {
        this.orderType = PORTAL;
    }

    @action
    resetOrderTypeErrors(orderType) {
        this.orderTypeErrors = [];
    }

    @action
    async setCountry(countryId) {
        this.countryId = countryId;
        this.isLoadingProducts = true;
        await this.stores.cartStore.emptyCart();
        await this.contentStore.get({ locale: this.locale });
        this.clearAndVoidTax();
        this.clearAndVoidShipping();
        this.clearProductCreditFields();
        if (this.suppressEmailConfirmation) this.handleSuppressEmailCheckbox();
        this.context = {};
        this.defaultAddress = 0;
        this.shippingRates = [];
        this.popup = null;
        this.isLoadingProducts = false;
        await this.contentStore.get({ locale: this.locale });
        // await this.stores.cartStore.clearItems();
        await this.validateOrderType();
    }

    @action
    async setAccount(uid) {
        if (
            this.stores.cartStore.customer &&
            this.stores.cartStore.customer.uid &&
            uid != this.stores.cartStore.customer.uid
        ) {
            this.initilize();
        }
        this.resetPortalOrder();
        this.account = cloneDeep(await getAccountInfo(uid));
        if (this.account) {
            await this.setCountry(this.account.countryId || 1);
            const savedCards = await getCustomerCards(this.locale.name, uid);
            this.customerCards = savedCards.cards;
            this.productCredit = await getProductCredit(uid);
            if (this.isConsultant) this.orderType = REPLACEMENT;
            if (this.account.shippingAddresses)
                this.setShippingAddresses(this.account.shippingAddresses);

            if (
                !this.isConsultant &&
                !this.isEmployee &&
                this.account.sponsorId &&
                this.account.sponsorId !== 1
            )
                await this.setAttribution(this.account.sponsorId);
        }
    }

    @action
    setShippingAddresses = shippingAddresses => {
        this.shippingAddresses = shippingAddresses;
    };

    @action
    clearAccount = () => {
        this.account = null;
        this.shippingAddresses = [];
    };

    @computed
    get memberTermsStatus() {
        const tcs = getSafe(() => this.account.bobTCs);
        if (!tcs) return tcs;

        const orders = [...tcs].sort((a, b) => {
            if (a.orderDate > b.orderDate) return -1;
            if (b.orderDate > a.orderDate) return 1;
            return 0;
        });

        const today = new Date().toISOString();
        const expired = today > getSafe(() => orders[0].orderExpiration);

        return {
            ...(orders?.[0] || []),
            expired,
        };
    }

    @computed
    get hasPendingMemberTerms() {
        const tcs = this.memberTermsStatus;
        return tcs && !tcs.signed && !tcs.expired && !tcs.declined;
    }

    @action
    async setAttribution(sponsorId) {
        this.attribution = cloneDeep(await getUserByPhoenixId(sponsorId));
        await this.getOpenPopups(this.attribution.uid);
        await this.validateOrderType();
    }

    @action
    async removeAttribution() {
        this.attribution = null;
        await this.validateOrderType();
    }

    @action
    async getOpenPopups(uid) {
        this.openPopups = cloneDeep(await getOpenPopups(this.attribution.uid));
    }

    @action
    setPopup(popupId) {
        this.popup = this.openPopups.find(popup => popup.id === popupId);
        this.stores.portalOrderStore.handleGetEstimate();
    }

    @action
    removePopup() {
        this.popup = null;
        this.stores.portalOrderStore.handleGetEstimate();
    }

    @action
    searchConsultants = async (entry, limit) => {
        const result = await searchConsultantNames(entry, limit, this.account.countryId || 1);

        if (result) {
            return {
                ...result,
                items: result.items ? uniqBy(result.items, 'accountID') : [],
            };
        }
    };

    @action
    handleSuppressEmailCheckbox() {
        this.suppressEmailConfirmation = !this.suppressEmailConfirmation;
    }

    @action
    loadRecentOrders = async () => {
        if (!this.account || !this.account.uid) {
            this.orders = [];
            return;
        }

        this.isOrderLoading = true;
        const orders = await getPersonalOrders(this.account.uid);

        if (orders && orders.length) {
            const mostRecent5Orders = orderBy(orders, o => o.dateCreatedUTC, ['desc'])
                .filter(x => x.cart.products && x.cart.products.length > 0)
                .slice(0, 5);
            this.removeBundledItems(mostRecent5Orders);
            this.orders = mostRecent5Orders;
        }
        this.isOrderLoading = false;
    };

    @action
    async validateOrderType() {
        if (this.orderType === ENROLLMENT) {
            await this.validateUserForEnrollment();
        }
    }

    @action
    async validateUserForEnrollment() {
        const context = await this.buildContext();
        const response = await verifyOrderForEnrollment(context);
        if (response.success) {
            const { ableToClose, errors } = response.data;

            if (ableToClose) {
                this.interfaceStore.openAlert(
                    false,
                    'Enrollment order started. Please start by adding an enrollment sku to the cart.',
                );
                this.removePopup();
            } else {
                this.orderTypeErrors = errors;
            }
        } else {
            this.interfaceStore.openAlert(true, `Unable to verify enrollment.  Please try again.`);
            this.clearOrderType();
        }
        return response;
    }

    @action
    async resetPortalOrder() {
        this.orderType = null;
        this.orderTypeErrors = [];
    }

    removeBundledItems(orders) {
        const { productBySKU } = this.productsStore;
        orders.forEach(order => {
            const { products } = order.cart;
            const orderProducts = products.map(item => {
                const product = productBySKU(item.sku);
                item.productId = product.productId;
                return product;
            });

            const bundledProducts = orderProducts.filter(y => y.bundled && y.bundled.length);
            if (bundledProducts && bundledProducts.length) {
                bundledProducts.forEach(product => {
                    const { bundleIds } = product;
                    if (bundleIds) {
                        bundleIds.forEach(id => {
                            const freeItem = products.find(nbp => nbp.productId === id);
                            if (freeItem) products.splice(products.indexOf(freeItem), 1);
                        });
                    }
                });
            }
        });
    }

    getPhoenixShippingMethod(rates) {
        if (isEmpty(rates)) return null;
        const phoenixShippingVariant = rates.find(rate => rate.method === this.shippingMethod);
        return phoenixShippingVariant.method;
    }

    clearAndVoidTax() {
        this.voidTax = false;
        this.stores.cartStore.overrides.overrideTax = false;
    }

    clearAndVoidShipping() {
        this.shippingMethod = null;
        this.voidShipping = false;
        this.stores.cartStore.overrides.overrideShipping = false;
    }

    clearProductCreditFields() {
        this.applyPC = false;
        this.applyPCSuccess = false;
        this.pcAmount = 0;
        this.applyProductCredit(0);
    }

    updateAddressFormValues = addressValues => {
        const {
            city,
            region,
            postalCode,
            address,
            address2,
            id,
            saveAddress,
            melissaVerificationDateUTC,
        } = this.addressForm.fields;

        address.updateValue(addressValues.address || '', false);
        address2.updateValue(addressValues.address2 || '', false);
        postalCode.updateValue(addressValues.postalCode || '', false);
        city.updateValue(addressValues.city || '', false);
        region.updateValue(addressValues.region || '', false);
        id.updateValue(addressValues.id || '', false);
        saveAddress.updateValue(addressValues.saveAddress || '', false);
        melissaVerificationDateUTC.updateValue(
            addressValues.melissaVerificationDateUTC || '',
            false,
        );
    };

    setupAddressForm = () => {
        if (!this.autocomplete) {
            this.autocomplete = new Autocomplete();
        }

        const createAction = () => {
            this.addressForm = this.formStore.create({
                key: 'addressForm',
                persist: false,
            });

            this.addressForm.add([
                {
                    key: 'firstName',
                    label: 'First Name',
                    value: this.account && this.account.firstName,
                    type: 'lengthExceededRequired',
                },
                {
                    key: 'lastName',
                    label: 'Last Name',
                    value: this.account && this.account.lastName,
                    type: 'lengthExceededRequired',
                },
                {
                    key: 'companyName',
                    label: 'Company Name',
                    type: 'lengthExceeded',
                },
                {
                    key: 'address',
                    label: 'Address',
                    type: 'address',
                },
                {
                    key: 'address2',
                    label: 'Apt/Floor/Suite',
                    type: 'lengthExceeded',
                },
                {
                    key: 'city',
                    label: 'City',
                    type: 'required',
                },
                {
                    key: 'postalCode',
                    label: this.countryId === 1 ? 'Zip Code' : 'Postal Code',
                    type: 'zip',
                },
                {
                    key: 'region',
                    label: this.countryId === 1 ? 'State' : 'Province',
                    type: 'required',
                },
                {
                    key: 'telephone',
                    label: 'Telephone',
                    type: 'phoneOptional',
                },
                {
                    key: 'country',
                    label: 'Country',
                    value: this.locale.country,
                },
                {
                    key: 'id',
                    label: 'Addresses Id',
                    type: 'optional',
                },
                {
                    key: 'saveAddress',
                    label: 'Save Address',
                    type: 'optional',
                },
                {
                    key: 'melissaVerificationDateUTC',
                    type: 'optional',
                },
            ]);
        };

        this.formStore.clearForm('addressForm');
        this.formStore.createReactiveForm('addressForm', 'forms', createAction);
    };

    autocompleteAddress = async place_id => {
        const place = await this.autocomplete.getPlace(place_id);
        const { city, postalCode, region, address } = place;
        place.countryId = this.countryId;
        const {
            address: fAddress,
            city: fCity,
            region: fState,
            postalCode: fPostalCode,
        } = this.addressForm.fields;
        [[fAddress, address], [fCity, city], [fPostalCode, postalCode], [fState, region]].forEach(
            tuple => {
                Field.hydrateValue(tuple[0], tuple[1] || '');
            },
        );
    };

    populateSelectedAddress = () => {
        const {
            firstName,
            lastName,
            address,
            address2,
            postalCode,
            city,
            region,
            melissaVerificationDateUTC,
        } = this.addressForm.fields;
        const { selectedAddress, isAddressSuggestedByMelissa } = this.melissaStore;

        if (!selectedAddress) return;

        firstName.updateValue(selectedAddress.firstName, true);
        lastName.updateValue(selectedAddress.lastName, true);
        address.updateValue(selectedAddress.firstAddress, true);
        address2.updateValue(selectedAddress.secondAddress, true);
        postalCode.updateValue(selectedAddress.postalCode, true);
        city.updateValue(selectedAddress.city, true);
        region.updateValue(selectedAddress.state, true);
        melissaVerificationDateUTC.updateValue(
            isAddressSuggestedByMelissa ? getISODateString() : '',
        );
    };

    getCurrentAddress = () => {
        const {
            firstName,
            lastName,
            companyName,
            address,
            address2,
            city,
            region,
            postalCode,
            country,
        } = this.addressForm.values;

        return {
            company: companyName,
            firstAddress: address,
            secondAddress: address2,
            state: region,
            city,
            firstName,
            lastName,
            country,
            postalCode,
        };
    };

    checkForAddressSuggestions = async () => {
        try {
            return await this.melissaStore.checkForSuggestions(this.getCurrentAddress());
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    validateSelectedAddressByMelissa = async () => {
        const {
            hasAddressError,
            hasSuggestions,
            resetSuggestions,
            isAddressSuggestedByMelissa,
        } = this.melissaStore;

        if (
            !hasSuggestions &&
            !hasAddressError &&
            isAddressMelissaVerificationExpired(this.addressForm.values)
        ) {
            const {
                hasAddressError: hasMelissaAddressError,
                hasSuggestions: hasMelissaSuggestions,
            } = await this.checkForAddressSuggestions();

            // Mark address as verified since it was passed to Melissa
            if (isAddressSuggestedByMelissa)
                this.addressForm.fields.melissaVerificationDateUTC.updateValue(getISODateString());

            if (hasMelissaSuggestions || hasMelissaAddressError) return false;
        }

        resetSuggestions();
        return true;
    };

    getLocalizedSquareId = () => {
        const { countryShort = 'US' } = this.locale;
        const squareIdField = SQUARE_FIELDS[countryShort];

        return this.account[squareIdField];
    };

    @action
    addCardData(selectedCardId) {
        const squareId = this.getLocalizedSquareId();

        toJS(this.customerCards).map(card => {
            if (card.id === selectedCardId)
                this.paymentInfo = {
                    squareId,
                    cardId: card.id,
                    savedCard: true,
                    saveCard: false,
                };
        });
        this.handleGetEstimate();
    }

    @action
    addNewCardData(cardData) {
        this.paymentInfo = {
            nonce: cardData.nonce,
            firstName: cardData.firstName,
            lastName: cardData.lastName,
            postalCode: cardData.postalCode,
            saveCard: false,
            savedCard: false,
        };
        this.handleGetEstimate();
    }

    @action
    submitOrder = async () => {
        /* TODO: Logic for portal order submission */
        const context = await this.buildContext();
        const response = await submitPortalOrder(context);
        if (response.success) {
            this.interfaceStore.openAlert(false, 'Your order was successfully submitted.');
        } else {
            this.interfaceStore.openAlert(
                true,
                `Your order was not successful. ${response.message}`,
            );
        }
        return response;
    };

    @action
    updateActiveCart = cart => {
        const cartToUpdate = this.activeCarts.find(c => c.cartId === cart.cartId);
        if (cartToUpdate) {
            cartToUpdate.activeLocale = cart.activeLocale;
            cartToUpdate.unpromofiedItems = cart.unpromofiedItems;
            cartToUpdate.promoItems = cart.promoItems;
        }
    };

    @action
    loadUserOpenCarts = async () => {
        const { email, userId } = this.userStore;
        const defaultLocaleCode = this.localeStore.activeLocale.code;
        const { personalOrder, clientOrders = [] } = await loadUserAllOpenCarts({
            email,
            userId,
            defaultLocaleCode,
        });

        if (!clientOrders.some(x => x.cartId === this.cartStore.cartId) && personalOrder) {
            this.cartStore.loadOpenCart(personalOrder);
        }

        this.activeCarts = clientOrders.map(cart => this.computeSubtotal(cart));
    };
    @throttle(300)
    updateSearchResult() {
        this.searchResults = this.searchText
            ? searchForProducts(this.productsStore.products, this.searchText, true)
            : [];
    }

    @action
    updateSquareSDKCard = newCard => {
        this.squareSDKCard = newCard;
    };
}

const portalOrderStore = new PortalOrderStore();

export default PortalOrderStore;
export { PortalOrderStore };
