import getContentful from '@cs-admin/middleware/api/contentful';
import wrapEntry from '@cs-admin/middleware/api/contentful/wrapEntry';
import { coreModels } from '@cs-admin/config';
import { getSafe } from '@cs-admin/utils/object';

const defaultLocale = 'en-US';

/**
 * Contentful query types
 * 1) singlePage: Only retrieve the payload for the current page
 * 2) pageIndex: Retrieve the index/reference list of all site pages
 * 3) coreComponents: Essential application data such as header/footer
 */

const singlePage = {
    content_type: 'page',
};
const pageIndex = {
    content_type: 'page',
    select: 'sys.contentType,fields.url',
};
const coreComponents = {
    'sys.contentType.sys.id[in]': coreModels.toString(),
};

/**
 * Creates a content object based on selected locale
 * The data is pulled from the Contentful API
 * @param {String} locale
 * @param {String} type
 * @param {String} page
 * @param {Boolean} drafts
 */

export default async function getContent({
    locale = defaultLocale,
    type,
    page = null,
    drafts = false,
    firstLoad,
    renderId,
}) {
    let content = {};
    const requestType = eval(type) || type;
    const pageRequest = page ? { 'fields.url[in]': page } : {};

    /**
     * Use dehydrated server state if available on first load
     * so that we can diff the prevDOM with the nextDOM
     * when using server side rendering
     */

    const id = JSON.stringify({ type, page, locale, drafts }).replace(/[^a-z0-9]/gi, '');
    const request = () =>
        makeRequest(
            {
                ...requestType,
                ...pageRequest,
                include: 8,
                locale,
                limit: 1000,
            },
            drafts,
        );
    const response = await manageResponseCache(request, id, firstLoad, drafts, renderId);

    // Resolve Contentful links
    const data = wrapEntry(response);

    // Check that we received actual data
    if (!data || typeof data.items == 'undefined') {
        console.error('Request to Contentful failed. You may have exceeded your rate limit.');
        return null;
    }

    // If the request is not for a specific page, restructure the models into a more
    // friendly object
    if (!page) {
        data.items.forEach((entry, key) => {
            if (typeof content[entry.sys.contentType.sys.id] === 'undefined')
                content[entry.sys.contentType.sys.id] = [];
            content[entry.sys.contentType.sys.id].push(entry.fields);
        });
    } else if (data.items[0]) content = data.items[0].fields;
    else return null;

    return content;
}

async function makeRequest(request, drafts) {
    const response = await getContentful(request, drafts);

    return response.json();
}

/**
 * Server-side: create a Node cache for API calls and dehydrate state for the client
 * Client-side: check for existence of a dehydrated server state and attempt to rehydrate
 * @param {Promise} request
 * @param {String} id
 * @param {Boolean} firstLoad
 */

async function manageResponseCache(request, id, firstLoad, drafts, renderId = 'default') {
    const hasDehydratedState = firstLoad && getSafe(() => window.STATE.fetch[id]);
    const hasServerCache = getSafe(() => global.contentfulCache[renderId][id]);
    let response;

    if (hasServerCache) {
        response = hasServerCache;
    } else {
        response = hasDehydratedState ? window.STATE.fetch[id] : await request();
    }

    if (typeof global !== 'undefined') {
        if (!getSafe(() => global.contentful[renderId])) global.contentful = { [renderId]: {} };
        if (!getSafe(() => global.contentfulCache[renderId]))
            global.contentfulCache = { [renderId]: {} };
        global.contentful[renderId][id] = response;
        global.contentfulCache[renderId][id] = response;
    }

    return response;
}
