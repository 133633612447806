import { observable, computed, action, autorun, toJS, set, remove, trace } from 'mobx';
import React from 'react';
import { values, isEmpty, keyBy } from 'lodash';
import { CartEngine } from '@beautycounter/bc-cart-engine';
import { COUNTERBASE } from '@cs-admin/constants/channels';
import { COUNTERBASE_FLAG } from '@cs-admin/constants/flags';
import { notificationConfig } from './cartNotificationConfig';
// 1.8.5 updating the cart-engine without a change in /src will not trigger the cloud build!
// import EnrollmentTray from '@cs-admin/components/Common/SharingModal/EnrollmentTray';
import {
    sortByDate,
    loadCartByUser,
    loadCartById,
    issueCartId,
    mergeCarts,
    deleteCart,
    subscribeToCartData,
} from '@cs-admin/services/cart';
import { getSafe } from '@cs-admin/utils/object';
import { PORTAL_ORDER } from '@beautycounter/constants/orderTypes';
import {
    nogento,
    specialProductSKUs,
    memberOnlySKUs,
    routes,
    locales,
    verbose,
} from '@cs-admin/config';
import { asyncLoadStore } from '@cs-admin/services/navigation';
import { MEMBERSHIP, MEMBERSHIP_RENEWAL } from '@beautycounter/constants/specialSkus';
import { MEMBER, CONSULTANT, EMPLOYEE, CLIENT } from '@beautycounter/constants/userStates';
import { REPLACEMENT, PORTAL } from '@cs-admin/constants/orderTypes';
import { productHasFlag } from '@cs-admin/utils/productHasFlag';
import { PROMO_ID } from '@beautycounter/bc-cart-engine/src/utils/constants/promoIDs';

const DROP_ITEM_PROMO_IDS = [
    PROMO_ID.REMOVE_ITEM_FROM_CART_WHEN_ADDRESS_IN_ZIP_OR_STATE,
    PROMO_ID.REMOVE_ITEM_FROM_CART_WHEN_NO_PARTICULAR_ITEM_IN_CART,
    19,
];
import { subMonths, addMonths, isBefore, isAfter } from 'date-fns';
import { minimumMonths } from '@beautycounter/constants/membershipRenewal';
import {
    isEligibleForBoB,
    isEligibleForBobRenewal,
} from '@beautycounter/bccom/src/services/cart/membership';

class CartStore {
    @observable cartId;
    @observable currency;
    @observable serverRequest;
    @observable itemLimit;
    @observable context;
    @observable unpromofiedItems;
    @observable promoItems;
    @observable cartEngine;
    @observable promoTags;
    @observable promoRulesFetched;
    @observable removedPromoItems;
    @observable starterSetsSKUs;
    @observable customer;
    @observable uid;
    @observable activeLocale;
    @observable socialId;
    @observable hostRewardItems;
    @observable lastSavedTime;
    @observable overrides;
    @observable overrideForm;
    @observable promoTool;
    @observable product;

    constructor() {
        this.cartEngine = new CartEngine();
        this.customer = {};
        this.cartId = this.generateCartId();
        (this.currency = {
            name: 'USD',
            format: '$0',
        }),
            (this.serverRequest = false),
            (this.itemLimit = 10);
        this.newCartItem = false;
        this.lastSaved = Date.now();
        this.context = {};
        this.unpromofiedItems = {};
        this.promoItems = {};
        this.promoTags = {};
        this.promoRulesFetched = false;
        this.hostRewardRulesFetched = false;
        this.removedPromoItems = [];
        this.starterSetsSKUs = [];
        this.activeLocale = null;
        this.uid = null;
        this.socialId = null;
        this.hostRewardItems = [];
        this.loadQueue = [];
        this.overrides = {
            overrideProducts: [],
            overrideTax: false,
            overrideShipping: false,
        };
        this.overrideForm = null;
        this.product = {};

        /* Temporary feature flag key    */
        this.isNew = true;
        this.promoTool = false;
    }

    ready() {
        this.fetchPromoRules();
        if (__BROWSER__) {
            autorun(() => {
                this.userStore.userType;
            });
            autorun(() => {
                this.promoRulesFetched;

                const newContext =
                    this.cartEngine && this.cartEngine.applyPromos(this.buildContext);
                this.context = newContext;
                this.addPromoItems();
                this.contextReaction();
            });
            autorun(() => {
                const promo =
                    this.context.cart &&
                    this.context.cart.appliedPromos &&
                    this.context.cart.appliedPromos.length &&
                    this.context.cart.appliedPromos.find(({ id }) =>
                        DROP_ITEM_PROMO_IDS.includes(id),
                    );
                if (promo) {
                    const { sku, quantity } = promo;
                    if (sku && quantity) {
                        this.changeQuantity(
                            {
                                product: {
                                    sku,
                                },
                                quantity: -quantity,
                            },
                            true,
                        );
                    }
                    this.context =
                        this.cartEngine && this.cartEngine.applyPromos(this.buildContext);
                }
            });
            autorun(() => {
                this.currency = 'en-US';
            });
            autorun(() => {
                if (this.userStore.isEnrolling) {
                    const enrollmentStore = asyncLoadStore('EnrollmentStore', this.stores);
                    enrollmentStore.then(store => (this.starterSetsSKUs = store.starterSetsSKUs));
                }
            });
            if (!this.activeLocale) this.activeLocale = this.portalOrderStore.locale;
        }
    }

    @computed
    get isShoppingOnBehalfOf() {
        return (
            this.hasCustomer &&
            this.portalOrderStore.account.email &&
            this.portalOrderStore.account.email !== this.userStore.email
        );
    }

    @computed
    get volumes() {
        const {
            cart: { products },
        } = this.context;
        const volumesObj = {};
        products.forEach(({ sku, volumes }) => (volumesObj[sku] = volumes));
        return volumesObj;
    }

    @computed
    get hasCustomer() {
        return !isEmpty(this.portalOrderStore.account) && this.portalOrderStore.account.email;
    }

    @computed
    get isCustomerEligibleForBob() {
        const customer = this.cartCustomer;
        const userType = Number(this.userType);
        const memberExpirationDateUTC = getSafe(() => customer.memberExpirationDateUTC) || null;

        return isEligibleForBoB(userType, memberExpirationDateUTC);
    }

    @computed
    get isCustomerEligibleForBobRenewal() {
        const customer = this.cartCustomer;
        const userType = Number(this.userType);
        const memberExpirationDateUTC = getSafe(() => customer.memberExpirationDateUTC);

        return isEligibleForBobRenewal(userType, memberExpirationDateUTC);
    }

    @computed
    get isConsultant() {
        return this.portalOrderStore?.isConsultant;
    }

    @computed
    get cartCustomer() {
        return this.portalOrderStore && this.portalOrderStore.account
            ? this.portalOrderStore.account
            : null;
    }

    @computed
    get notificationConfig() {
        return notificationConfig;
    }

    @computed
    get isStarterSetInCart() {
        return this.starterSetsSKUs.some(sku => !!this.productsBySku[sku]);
    }

    @computed
    get isEnrollmentKitInCart() {
        const { enrollmentKit } = specialProductSKUs;
        return getSafe(() => this.items.some(item => item.sku === enrollmentKit));
    }

    @computed
    get errorTable() {
        return getSafe(() => this.contentStore.content.cart[0].cartMessages) || {};
    }

    @action
    setPromos(promos) {
        this.promoRulesFetched = false;
        this.cartEngine.updateRules(promos);
        this.promoRulesFetched = true;
    }

    @action
    fetchPromoRules = async () => {
        // scheduled promos as SEO is less important for promos.
        // For now, we will only render promos client side
        // scheduled promos as SEO is less important for promos.
        if (!__BROWSER__) return false;

        try {
            const response = await fetch(`${nogento.base}/promorules`, {
                headers: {
                    Accept: 'application/json',
                },
            });
            const data = await response.json();
            if (data.success && data.rules && data.rules.length) {
                this.cartEngine.updateRules(data.rules);
                this.promoRulesFetched = true;
            }
        } catch (e) {
            console.error('Unable to fetch promo rules.', e);
        }
    };

    @action
    fetchHostRewardRules = async () => {
        if (!__BROWSER__) return false;
        try {
            const rules = null;
            if (rules && rules.length) {
                this.cartEngine.updateHostRewardRules(rules);
                this.hostRewardRulesFetched = true;
            }
        } catch (e) {
            console.error('Unable to fetch host reward rules.', e);
        }
    };

    @computed
    get isWholesale() {
        return !this.isShoppingOnBehalfOf && this.userStore.isConsultant;
    }

    @computed
    get userType() {
        if (this.promoTool) return Number(this.promoToolStore.usertype);
        return Number(
            this.isShoppingOnBehalfOf
                ? this.portalOrderStore.account.userType
                : this.userStore.userType,
        );
    }

    get channel() {
        if (this.promoTool) return this.promoToolStore.channel;
        return COUNTERBASE;
    }

    @computed
    get locale() {
        if (this.promoTool) return this.promoToolStore.locale.code;
        return this.portalOrderStore.locale.code;
    }

    @computed
    get isReadOnly() {
        return false;
    }

    @computed
    get buildContext() {
        const products = Object.values(toJS(this.unpromofiedItems));
        const promoItems = Object.values(toJS(this.promoItems));
        const promoTags = Object.values(toJS(this.promoTags));
        const hostRewardItems = Object.values(toJS(this.hostRewardItems || []));
        const locale = locales.find(x => x.code === this.locale);
        const overrides = Object.values(toJS(this.overrides));
        const counterbaseFlag = productHasFlag(this.product, COUNTERBASE_FLAG);

        return {
            localcode: this.locale,
            timestamp: Date.now(),
            socialId: this.socialId || null,
            channel: Number(this.channel),
            user: {
                userType: Number(this.userType),
                regionId: locale ? locale.countryId : null,
            },
            customer: this.portalOrderStore.account,
            cart: {
                products,
                promoItems,
                promoTags,
                hostRewardItems,
                isWholesale: this.isWholesale,
            },
            counterbaseFlag,
            overrides,
        };
    }

    @action
    handleVoidShipping() {
        this.overrides.overrideShipping = !this.overrides.overrideShipping;
    }

    @action
    handleVoidTax() {
        this.overrides.overrideTax = !this.overrides.overrideTax;
    }

    @action
    addPromoItems() {
        if (this.promoSelections.length) {
            this.promoSelections.forEach(promo => {
                if (
                    promo.selection &&
                    promo.selection.length === 1 &&
                    !this.removedPromoItems.includes(promo.selection[0])
                ) {
                    const product = this.productsStore.productBySKU(promo.selection[0]);
                    const { promoContent } = this;

                    /*
                     * Calls in content from Config - Promo Content (Contentful)
                     * then looks for the addProperty field under a particular
                     * promo for the current sku.
                     *
                     * addProperty contains any additional information that can
                     * be spread into a product. This is where certain flags
                     * can be set. Will not override isPromo or hideCart.
                     */

                    if (!product.selectedVariant)
                        return this.addProduct({
                            product,
                            isPromo: true,
                            hideCart: true,
                        });

                    const variant = product.variants.find(
                        variant => variant.id === product.selectedVariant,
                    );
                    this.addProduct({
                        product,
                        selectedVariant: product.selectedVariant,
                        variant,
                        isPromo: true,
                        hideCart: true,
                    });
                }
            });
        }
    }

    @action
    openCart(hideCart) {
        /* if (!hideCart) {
            this.interfaceStore.setRightPanel(<Cart />);
            this.interfaceStore.openRightPanel();
        } */
    }

    @action
    emptyCart() {
        this.unpromofiedItems = {};
        this.promoItems = {};
        this.formStore.clearForm('shipping');
        this.cartId = this.cartId || this.generateCartId();
        this.socialId = null;
        this.customer = {};
        this.context = {};
    }

    @action
    clearItems() {
        this.unpromofiedItems = {};
        this.promoItems = {};
        this.context = {};
        this.postCartEvents(true);
        this.overrides.overrideProducts = [];
    }

    @action
    buildVariant(product, variant) {
        const { QV, PV, CV } = variant;
        return {
            cartId: variant.id,
            id: variant.id,
            parentId: product.id,
            isFinalSaleConfig: product.isFinalSaleConfig,
            doNotShipAlone: product.doNotShipAlone,
            price: variant.price,
            priceInCents: variant.priceInCents,
            image: getSafe(() => variant.images[0].src),
            handle: product.handle,
            productId: variant.id,
            variant,
            variantId: variant.id,
            variantTitle: getSafe(() => variant.swatches.swatchlabel),
            QV,
            PV,
            CV,
        };
    }

    @action
    buildProduct({
        id,
        price,
        images,
        image,
        handle,
        QV,
        CV,
        PV,
        isFinalSaleConfig,
        priceInCents,
        doNotShipAlone,
    }) {
        return {
            cartId: id,
            id,
            parentId: id,
            price,
            priceInCents,
            image: getSafe(() => images[0].src) || image,
            handle,
            isFinalSaleConfig,
            doNotShipAlone,
            productId: id,
            variantId: id,
            variant: {
                id,
                price,
                priceInCents,
                productId: id,
                variantTitle: id,
            },
            QV,
            PV,
            CV,
        };
    }
    @computed
    get isSocialOrdering() {
        return !!this.socialId;
    }
    @computed
    get freeHostRewardCount() {
        if (!this.hostRewardItems) return 0;
        return this.hostRewardItems.filter(x => x.isFree).length;
    }

    @computed
    get halfOffHostRewardCount() {
        if (!this.hostRewardItems) return 0;
        return this.hostRewardItems.filter(x => !x.isFree).length;
    }

    @action
    setupOverrideForm(product, quantity, price, overrideView, orderType) {
        const { overrideProducts } = this.overrides;
        const finalPrice = product.totalPrice ? product.totalPrice : price;
        const isProductOverriden = overrideProducts.find(
            overridenProduct => overridenProduct.id === product.id,
        );
        let overridenPrice = isProductOverriden ? finalPrice : price;
        const isFloat = !Number.isInteger(Number(overridenPrice));
        // limit the amount of numbers after "." to "2", to exclude numbers like 33.599999999999994
        if (isFloat) overridenPrice = parseFloat(overridenPrice).toFixed(2);

        const overriddenCV = this.ibisPOFlag || orderType === REPLACEMENT ? '0' : product.CV;
        const overriddenPV =
            this.isConsultant && (orderType === PORTAL || orderType === REPLACEMENT)
                ? '0'
                : product.PV;
        const overriddenQV = this.isConsultant && orderType === REPLACEMENT ? '0' : product.QV;

        const createAction = () => {
            this.overrideForm = this.formStore.create({
                key: 'overrideForm',
                persist: false,
            });

            this.overrideForm.add([
                {
                    key: 'id',
                    label: '',
                    value: product.selectedVariant ? product.selectedVariant : product.id,
                    required: true,
                },
                {
                    key: 'sku',
                    label: '',
                    value: product.sku,
                    required: true,
                },
                {
                    key: 'overridePrice',
                    label: 'Override Price',
                    type: 'float',
                    value: overrideView ? overridenPrice.toString() : finalPrice.toString(),
                    required: true,
                },
                {
                    key: 'overridePV',
                    label: 'Override PV',
                    type: 'float',
                    value: overriddenPV,
                    required: true,
                },
                {
                    key: 'overrideQV',
                    label: 'Override QV',
                    type: 'float',
                    value: overriddenQV,
                    required: true,
                },
                {
                    key: 'overrideCV',
                    label: 'Override CV',
                    type: 'float',
                    value: overriddenCV,
                    required: true,
                },
                {
                    key: 'quantity',
                    label: 'Quantity',
                    type: 'number',
                    value: product.quantity || quantity,
                    required: true,
                },
            ]);
        };

        this.formStore.clearForm('overrideForm');
        this.formStore.createReactiveForm('overrideForm', 'forms', createAction);
    }

    @action
    removeOverrideForm() {
        this.overrideForm = null;
    }

    @action
    addProductOverride({
        product,
        selectedVariant,
        variant,
        isPromo = false,
        isReplacement = false,
        qty = '1',
    }) {
        // to set up Replacement Orders
        if (isReplacement && this.isConsultant)
            this.setupOverrideForm(product, qty, 0, true, REPLACEMENT);

        const {
            overridePrice,
            overridePV,
            overrideQV,
            overrideCV,
            quantity,
        } = this.overrideForm.fields;

        const { overrideProducts } = this.overrides;
        const { value } = quantity;
        let selectedProduct = product;

        if (selectedVariant) {
            selectedProduct = product.variants.find(x => x.id === selectedVariant);
        }
        const overridden = overrideProducts.findIndex(
            override => override.id == selectedProduct.id,
        );
        if (overridden < 0) {
            overrideProducts.push({
                id: selectedProduct.id,
                sku: selectedProduct.sku,
                overridePrice: overridePrice.value,
                overridePV: overridePV.value,
                overrideQV: overrideQV.value,
                overrideCV: overrideCV.value,
                quantity: value,
            });
            this.addProduct({
                product,
                quantity: value,
                selectedVariant,
                variant: selectedVariant ? selectedProduct : product,
                isPromo: product.isPromo,
            });
            this.changeQuantity({ product, quantity: value });
        } else {
            overrideProducts[overridden] = {
                id: selectedProduct.id,
                sku: selectedProduct.sku,
                overridePrice: overridePrice.value,
                overridePV: overridePV.value,
                overrideQV: overrideQV.value,
                overrideCV: overrideCV.value,
                quantity: value,
            };
            this.changeQuantity({ product, quantity: value });
        }
    }

    @action
    addProduct({
        product,
        quantity = 1,
        selectedVariant,
        variant,
        bundled = [],
        hideCart = false,
        callback = null,
        isPromo = false,
        showTray = false,
        isSample = false,
        isIncluded = false,
    }) {
        // Adds product to observable before auto run to
        // conditionally check for counterbase flag and add flagged SKUs to the cart.
        this.product = product;

        if (this.isReadOnly && !isSample) return false;
        if (product.sku === MEMBERSHIP) {
            if (!this.isCustomerEligibleForBob) {
                this.interfaceStore.openAlert(
                    true,
                    'This account is not eligible for purchasing membership sku',
                );
                return false;
            }
            if (this.stores.portalOrderStore.hasPendingMemberTerms) {
                this.interfaceStore.openAlert(
                    true,
                    'This account has an order with pending terms and is not eligible for purchasing membership sku',
                );
                return false;
            }
        }
        if (product.sku === MEMBERSHIP_RENEWAL) {
            if (!this.isCustomerEligibleForBobRenewal) {
                this.interfaceStore.openAlert(
                    true,
                    'This account is not eligible for purchasing renewal sku',
                );
                return false;
            }
        }

        if (Object.keys(bundled).length)
            bundled = this.productsStore.bundledProducts(product.sku, bundled);

        const isVariant = selectedVariant && Number(product.id) !== Number(selectedVariant);

        const sku = isVariant ? variant.sku : product.sku;
        const swatchlabel = getSafe(() => variant.swatches.swatchlabel) || '';

        if (!isPromo && this.unpromofiedItems[sku]) {
            this.changeQuantity({
                product: {
                    sku,
                },
                quantity,
            });
            return this.openCart(hideCart);
        }
        const { QV, CV, PV, hostValue, handle } = product;

        if (isPromo && this.promoItems[sku]) {
            quantity += this.promoItems[sku].quantity;
        }

        const { productVolume } = this.product;

        const baseObject = {
            sku,
            bundled,
            quantity,
            isPromo,
            isSample,
            isIncluded,
            productVolume,
            selectedVariant,
            // product,
            priceInCents: product.priceInCents,
            handle,
            isVariant,
            title: product.title,
            QV,
            CV,
            PV,
            hostValue,
            swatchlabel,
            dateAdded: Date.now(),
        };

        const productToAdd = Object.assign(
            baseObject,
            isVariant ? this.buildVariant(product, variant) : this.buildProduct(product),
        );

        const validatedProduct = this.validateProduct(productToAdd, quantity);
        if (!validatedProduct) return;

        if (isPromo) set(this.promoItems, productToAdd.sku, validatedProduct);
        else set(this.unpromofiedItems, productToAdd.sku, validatedProduct);

        this.openCart(hideCart);

        if (showTray) {
            if (product) {
                asyncLoadStore('EnrollmentStore', this.stores).then(
                    enrollmentStore => (enrollmentStore.kitAddedToCart = product.title),
                );
            }
            this.interfaceStore.openBottomPanel();
        }

        this.newCartItem = true;
        setTimeout(() => {
            this.newCartItem = false;
            if (callback) callback();
        }, 300);

        this.postCartEvents(true);
    }

    @computed
    get customerPhoenixId() {
        return this.customer && this.customer.email
            ? this.customer.phoenixId
            : this.userStore.phoenixId;
    }

    @action
    removeProduct = ({ product = {} }) => {
        if (!this.unpromofiedItems[product.sku]) return;

        if (this.starterSetsSKUs.includes(product.sku)) {
            const enrollmentStore = asyncLoadStore('EnrollmentStore', this.stores);
            enrollmentStore.then(store => {
                store.hideDetailsPage();
                store.setStarterSetInCart(false);
            });
        }

        const overrideIndex = this.overrides.overrideProducts.findIndex(
            override => override.id === product.id,
        );
        if (overrideIndex >= 0) {
            this.overrides.overrideProducts.splice(overrideIndex, 1);
        }

        remove(this.unpromofiedItems, product.sku);

        this.postCartEvents(true);
    };

    @action
    removeFreeProduct = ({ product }) => {
        if (this.promoItems[product.sku]) {
            this.removedPromoItems.push(product.sku);
            remove(this.promoItems, product.sku);
        } else if (this.unpromofiedItems[product.sku]) {
            if (this.unpromofiedItems[product.sku].quantity === 1) {
                remove(this.unpromofiedItems, product.sku);
            } else
                this.changeQuantity({
                    product,
                    quantity: -1,
                });
        }
    };

    @action
    removeHostRewardProduct = ({ quantity, sku, isFree }) => {
        let hostRewardItems = toJS(this.hostRewardItems);
        hostRewardItems = hostRewardItems.slice().reverse();
        for (let i = 0; i < quantity; i++) {
            const index = hostRewardItems.findIndex(x => x.sku === sku && x.isFree == isFree);
            if (index >= 0) hostRewardItems.splice(index, 1);
        }

        this.hostRewardItems = hostRewardItems.slice().reverse();
        this.postCartEvents(true);
    };

    @action
    changeHostRewardQuantity = ({ product, quantity }) => {
        const { sku, isFree } = product;
        let hostRewardItems = toJS(this.hostRewardItems);
        const isRemoving = quantity < 0;
        // add or remove
        hostRewardItems = hostRewardItems.slice().reverse();
        for (let i = 0, times = Math.abs(quantity); i < times; i++) {
            if (!isRemoving) hostRewardItems.splice(0, 0, { ...product, quantity: 1 });
            else {
                const index = hostRewardItems.findIndex(x => x.sku === sku && x.isFree == isFree);
                if (index >= 0) hostRewardItems.splice(index, 1);
            }
        }

        this.hostRewardItems = hostRewardItems.slice().reverse();
        this.postCartEvents(true);
    };

    @action
    changeQuantity = ({ product, quantity }, promoItem = false) => {
        if (this.isReadOnly) return false;
        const productList = promoItem ? this.promoItems : this.unpromofiedItems;
        if (!productList[product.sku]) return;
        const newQuantity = parseInt(quantity);
        const validatedProduct = this.validateProduct(productList[product.sku], newQuantity);
        if (!validatedProduct) return remove(productList, product.sku);
        set(productList, product.sku, validatedProduct);
        this.postCartEvents(true);
    };

    @action
    startNewCart = (account, socialId) => {
        this.sessionStore.clearAllCartInstances();
        this.formStore.clearForm('shipping');
        const {
            id,
            uid,
            firstName,
            lastName,
            userType,
            sponsorId,
            phoenixId,
            email,
            groupId,
            countryId,
        } = account || {};
        this.customer = {
            uid: id || uid || null,
            firstName,
            lastName,
            userType,
            sponsorId,
            phoenixId,
            email,
            groupId: groupId || null,
        };
        this.cartId = this.generateCartId();

        if (countryId) {
            const localeToSetTo = locales.find(x => x.countryId === countryId);
            if (localeToSetTo.code !== this.activeLocale) {
                this.localeStore.setActive(localeToSetTo, false, false);
                this.activeLocale = localeToSetTo.code;
            }
        } else {
            this.activeLocale = this.localeStore.activeLocale.code;
        }

        this.currency = this.localeStore.activeLocale.currency || {
            name: 'USD',
            format: '$0',
        };
        this.unpromofiedItems = {};
        this.hostRewardItems = [];
        this.removedPromoItems = [];
        this.uid = this.userStore.userInfo.uid;
        this.socialId = socialId || null;
        this.postCartEvents(true);
        return {
            activeLocale: this.activeLocale,
            cartId: this.cartId,
            currency: this.currency,
            customer: this.portalOrderStore.account,
            orderType: this.orderType,
            subtotal: !isEmpty(this.overrides.overrideProducts) ? this.subtotalWithOverrides : this.subtotal,
            uid: this.uid,
            socialId: this.socialId,
            unpromofiedItems: this.unpromofiedItems,
            volumes: this.volumes,
        };
    };

    @computed
    get orderType() {
        return PORTAL_ORDER;
    }

    @action
    create = data => {
        // Unused for now.
    };

    @action generateCartId = () => issueCartId();

    @action
    setActiveLocale = async code => {
        this.activeLocale = code;
        const localeToSetTo = locales.find(x => x.code === code);
        this.localeStore.setActive(localeToSetTo, false, false);
        const cart = this.sessionStore.getCart();
        this.switchLocalizedCart(cart);
        this.postCartEvents(true);
    };

    @action
    switchLocalizedCart(cart) {
        this.currency =
            cart && cart.currency
                ? cart.currency
                : {
                      name: 'USD',
                      format: '$0',
                  };
        this.unpromofiedItems = cart && cart.unpromofiedItems ? cart.unpromofiedItems : {};
        this.promoItems = {};
    }

    @action
    async loadOpenCart(cart) {
        this.sessionStore.clearAllCartInstances();
        await this.localeStore.setLocaleByCode(cart.activeLocale, false, false);

        this.cartId = cart.cartId || this.generateCartId();
        this.currency = cart.currency || this.localeStore.activeLocale.currency;
        this.unpromofiedItems = cart.unpromofiedItems || {};
        this.hostRewardItems = cart.hostRewardItems || [];
        this.removedPromoItems = [];
        this.customer = this.portalOrderStore.account || {};
        this.uid = cart.uid;
        this.context = {};
        this.socialId = cart.socialId || null;

        if (cart.activeLocale !== this.activeLocale) this.activeLocale = cart.activeLocale;

        this.postCartEvents(true);
    }

    @action
    load(data) {
        verbose && console.info('Repopulated cartStore: ', data);
        if (data) {
            this.cartId = data.cartId || this.generateCartId();
            this.currency = data.currency || {
                name: 'USD',
                format: '$0',
            };
            this.unpromofiedItems = data.unpromofiedItems || {};
            this.customer = data.customer || this.portalOrderStore.account || {};
            this.activeLocale = data.activeLocale || this.localeStore.activeLocale.code;
            this.formStore.clearForm('shipping');
            this.uid = data.uid || this.userStore.userId || null;
            this.promoItems = data.promoItems || {};
            this.context = data.context || {};
            this.promoTags = data.promoTags || {};
            this.removedPromoItems = data.removedPromoItems || [];
            this.socialId = data.socialId || null;
            this.hostRewardItems = data.hostRewardItems || [];
            // this.subscribeToCartChange(this.cartId);
        }

        /* Do not save cart on first load (cart is either empty or restored from localStorage and should not be saved again) */
        // this.postCartEvents(false);

        // if (!this.cartLoaded) this.cartLoaded = true;
    }

    subscribeToCartChange(cartId) {
        if (this.unsubscriber) {
            if (this.unsubscriber.cartId !== cartId) {
                this.unsubscriber.unsubscribe();
                this.unsubscriber = subscribeToCartData();
            }
        } else {
            this.unsubscriber = subscribeToCartData();
        }
    }

    @action
    loadUserCart = async ({ fromLogin = false } = {}) => {
        // attribution change triggers userdata callback which call addLoginState which call this function
        // skip reload if    it is same user

        if ((fromLogin && this.uid === this.userStore.uid) || this.userStore.isConsultant) return;

        const urlParamSocialId = this.navigationStore.path.split('socialId=')[1];
        if (urlParamSocialId) return;
        const urlParamCartId = this.navigationStore.path.split('cartId=')[1];

        const load = this.applyLoadingState;
        const byId = () => loadCartById(urlParamCartId);
        const byUser = () => loadCartByUser(this.userStore.userId, this.cartId);

        const record = urlParamCartId ? await load(byId) : await load(byUser);

        if (record && record.cartInstances) {
            try {
                const parsed = record.cartInstances;
                this.cartId = urlParamCartId ? this.generateCartId() : record.cartId;
                this.activeLocale = this.localeStore.activeLocale.code;
                this.socialId = record.socialId;

                Object.keys(parsed).map(key => {
                    const cartInstance = parsed[key];
                    if (cartInstance) {
                        const existingCart = this.sessionStore.getKey(`cart-${key}`);
                        const { uid: localCartUid } = existingCart || {};
                        const { uid } = cartInstance || {};
                        const isSameUser = localCartUid == uid;
                        /* Merge guest cart with existing user cart */
                        const merged = mergeCarts(
                            existingCart && isSameUser ? existingCart.unpromofiedItems : {},
                            cartInstance ? cartInstance.unpromofiedItems : {},
                        );

                        if (merged) {
                            if (key === this.localeStore.activeLocale.code) {
                                this.unpromofiedItems = merged;
                                if (cartInstance.hostRewardItems) {
                                    this.hostRewardItems = cartInstance.hostRewardItems;
                                }
                            } else {
                                this.sessionStore.saveKey(`cart-${key}`, {
                                    cartId: record.cartId,
                                    currency: this.currency,
                                    unpromofiedItems: merged,
                                });
                            }
                        }
                    }
                });
            } catch (e) {
                console.info(e);
            }
        }
        this.postCartEvents(true);
    };

    @action
    clear = async (save = false) => {
        this.userStore.startOrderBootstrapping();
        try {
            this.sessionStore.clearAllCartInstances();
            const cartId = this.cartId;
            if (save) await deleteCart(this.cartId);
            this.portalOrderStore && this.portalOrderStore.removeCart(this.cartId);
            this.customer = this.portalOrderStore.account;
            return this.portalOrderStore.loadPersonalCart();

            if (!save) this.cartId = this.generateCartId();
            this.unpromofiedItems = {};
            this.currency = 'USD';
            this.promoItems = {};
            this.promoTags = {};
            this.removedPromoItems = [];
            this.customer = {};
            this.socialId = null;
            this.hostRewardItems = [];
            this.context = {};
            if (!save) this.uid = null;
        } catch (error) {
            console.log(error);
        }

        this.userStore.stopOrderBootstrapping();
        this.postCartEvents(save);
    };

    @action
    applyLoadingState = async (event, name = 'cart-fetch') => {
        this.loadQueue.push(name);
        /*
         * May be worth following up with a 10 second timeout
         * wrapper to handle possible network failures.
         */
        const output = await event();
        this.loadQueue.length && this.loadQueue.splice(-1);
        return output;
    };

    @action
    contextReaction() {
        /*
         * Having a very difficult time preventing side
         * effects with this (i.e. save race conditons, locale switching,
         * locked cart, broken persistence).
         * Leaving under feature flag for now.
         */
        if (!this.isLoading()) {
            this.saveCart();
        }
    }

    isLoading() {
        return this.loadQueue.length;
    }

    @computed
    get cartData() {
        return {
            cartId: this.cartId,
            promoItems: this.promoItems,
            unpromofiedItems: this.unpromofiedItems,
            promoTags: this.promoTags,
            customer: this.portalOrderStore.account,
            activeLocale: this.activeLocale,
        };
    }

    @action
    saveCart = () => {
        this.postCartEvents(true);
    };

    postCartEvents = (save = true) => {
        this.lastSaved = Date.now();

        const cart = {
            // cartEngine: this.cartEngine,
            cartId: this.cartId,
            lastSave: this.lastSaved,
            promoItems: this.promoItems,
            unpromofiedItems: this.unpromofiedItems,
            context: this.context,
            promoTags: this.promoTags,
            removedPromoItems: this.removedPromoItems,
            customer: this.portalOrderStore.account,
            uid: this.uid || this.userStore.userInfo.uid || null,
            activeLocale: this.activeLocale,
            socialId: this.socialId || null,
            hostRewardItems: this.hostRewardItems || [],
            currency: this.currency || null,
        };

        if (save) {
            if (this.isLoading())
                verbose && console.info('Save cart event blocked. Still loading.');
            else {
                verbose && console.info('Saving cart.', this.cartId);
                this.sessionStore.saveCart(cart).then(() => {
                    this.lastSavedTime = Date.now();
                });

                if (this.portalOrderStore) {
                    this.portalOrderStore.updateActiveCart(cart);
                }
            }
        }
        if (this.stores.portalOrderStore.account) {
            this.stores.portalOrderStore.handleGetEstimate();
        }
    };

    @action
    validateProduct(product, quantity) {
        if (quantity < 1) return;
        product = {
            ...product,
        };
        const catalogProduct = this.productsStore.variantById(product.id);
        if (!(catalogProduct && catalogProduct.inStock)) return;
        if (quantity > 0) product.quantity = Math.min(quantity, catalogProduct.maxQuantity);
        if (catalogProduct.hasOwnProperty('specialPrice')) {
            product.price = catalogProduct.compareAtPrice;
        }
        product.finalSale = false;
        product.totalPrice = product.price * product.quantity;
        return product;
    }

    @action getLatestProductAttributes = id => getSafe(() => this.productsStore.variantById(id));

    @action
    getItemMaxQuantity(id) {
        const maxQuantity = getSafe(() => this.getLatestProductAttributes(id).maxQuantity);
        const quantityInCart = this.productsById[id] ? this.productsById[id].quantity : 0;

        return maxQuantity - quantityInCart;
    }

    @action
    prepareCheckout = (ignoreCount = false) => {
        if (this.count < 1 && !ignoreCount) {
            this.navigationStore.to({
                url: '/',
            });
            this.messagingStore.addMessage('You cannot checkout if your cart is empty!', {
                type: 'error',
            });
            return;
        }
        this.interfaceStore.fadeOutAll(this.startNewCheckout);
    };

    startNewCheckout = () => {
        const redirect = this.navigationStore.localizeURL(routes.CHECKOUT);
        this.userStore.clearGuest();
        this.sessionStore.clearScopedField({
            name: 'checkout-attribution-selection',
            key: this.userStore.email,
        });

        this.navigationStore.to({
            url: redirect,
            replace: true,
        });
    };

    @action
    completeOrder = context => {
        __BROWSER__ &&
            requestAnimationFrame(() => {
                this.clear();
            });
    };

    @action getCheckoutItems = () => this.items;

    @action
    handleCheckoutError(error) {
        this.interfaceStore.hideProgress('checkout');
        this.messagingStore.addMessage(error || 'Sorry, there was an error. Try again?', {
            type: 'error',
        });
        this.serverRequest = false;
    }

    @computed
    get products() {
        return sortByDate(values(JSON.parse(JSON.stringify(this.productsById))));
    }

    @computed
    get count() {
        return this.items.reduce((prev, curr) => prev + curr.quantity, 0);
    }

    @computed
    get totalCost() {
        return getSafe(() => this.context.cart.subtotal) || 0;
    }

    @computed
    get total() {
        return getSafe(() => this.context.cart.subtotal) || 0;
    }

    @computed
    get subtotal() {
        return getSafe(() => this.context.cart.subtotal) || 0;
    }

    @computed
    get prePromoSubtotal() {
        return getSafe(() => this.context.cart.prePromoSubtotal) || 0;
    }

    @computed
    get hasMemberOnlyItems() {
        let has = 0;
        Object.keys(this.productsById).map(key => {
            const sku = getSafe(() => this.productsById[key].sku);
            if (memberOnlySKUs.includes(sku)) has++;
        });
        return has >= 1;
    }

    @computed
    get cartIsEmpty() {
        return this.items.length === 0;
    }

    @computed
    get items() {
        return getSafe(() => this.context.cart.products) || [];
    }

    @computed
    get productsById() {
        return keyBy(toJS(this.items), 'id') || {};
    }

    @computed
    get productsBySku() {
        return keyBy(toJS(this.items), 'sku') || {};
    }

    @computed
    get promos() {
        return getSafe(() => this.context.cart.appliedPromos) || [];
    }

    @computed
    get promoSelections() {
        return getSafe(() => this.context.cart.selections) || [];
    }

    @action
    addPromoCode(code, fromCheckout = false) {
        const existingFromCheckout = Object.values(this.promoTags).find(tag => tag.fromCheckout);
        if (existingFromCheckout) remove(this.promoTags, existingFromCheckout.code);
        set(this.promoTags, code, {
            code,
            success: false,
            serverValidated: false,
            fromCheckout,
        });
    }

    @action
    removePromoCode(code) {
        remove(this.promoTags, code);
    }

    @action
    removeCheckoutPromoCodes = () => {
        Object.values(this.promoTags).forEach(tag => {
            if (tag.fromCheckout) remove(this.promoTags, tag.code);
        });
    };

    @computed
    get cart() {
        const currency = this.currency.name ? this.currency.name : this.currency;
        return toJS({
            cartId: this.cartId || this.generateCartId(),
            total: !isEmpty(this.overrides.overrideProducts) ? this.subtotalWithOverrides : this.subtotal,
            currency,
        });
    }

    @computed
    get cartItems() {
        return Object.keys(this.productsById).map(id => ({
            ...this.productsStore.segmentObject[id],
            quantity: this.productsById[id].quantity,
        }));
    }

    @computed
    get cartHasItems() {
        return !!this.items.length;
    }

    @computed
    get ibisPOFlag() {
        return (
            this.flagStore?.isFeatureEnabled('ibisConsultantPortalOrders') &&
            this.portalOrderStore.isConsultant
        );
    }

    @computed
    get itemsWithOverrides() {
        const { overrideProducts } = this.overrides;

        if (this.isConsultant && this.portalOrderStore.orderType === REPLACEMENT) {
            return this.items.map(item =>
                // This is when user selects "Add". Adding "hasOverride" to prevent promo from changing price
                ({
                    ...item,
                    totalPrice: '0',
                    price: '0',
                    CV: 0,
                    PV: 0,
                    QV: 0,
                    hasOverride: true,
                }),
            );
        }

        if (overrideProducts.length) {
            return this.items.map(item => {
                const override =
                    overrideProducts.length &&
                    overrideProducts.find(product => product.id === item.id);

                // This is when user selects "Add Override" to manually override the volumes and price
                if (override)
                    return {
                        ...item,
                        totalPrice: override.overridePrice,
                        price: override.overridePrice,
                        hasOverride: true,
                        PV: override.overridePV,
                        QV: override.overrideQV,
                        CV: override.overrideCV,
                    };

                // This is portal orders that are not overridden
                return item;
            });
        }

        return this.items;
    }

    // TODO: possibly remove these methods if estimate will calculate subtotal/CV from overrides???
    @computed
    get totalCVWithOverrides() {
        const { products } = this.context.cart;
        const { orderType } = this.portalOrderStore;
        let totalCV = 0;

        products &&
            products.map(product => {
                const { overrideProducts } = this.overrides;
                const override = overrideProducts.find(override => override.id === product.id);

                if (override) totalCV += override.overrideCV * product.quantity;
                else
                    totalCV +=
                        (this.ibisPOFlag || orderType === REPLACEMENT ? 0 : product.CV) *
                        product.quantity;
            });

        return totalCV;
    }

    @computed
    get subtotalWithOverrides() {
        const { products } = this.context.cart || null;

        const { overrideProducts } = this.overrides;
        const copyOfProductsFromCart = [...products];
        let productTotal = 0;
        let subtotal = 0;

        overrideProducts.forEach(overrideProduct => {
            copyOfProductsFromCart.forEach((cartProduct, i) => {
                if (
                    overrideProduct.id === cartProduct.id ||
                    overrideProduct.id === cartProduct.variantId
                ) {
                    copyOfProductsFromCart.splice(i, 1);
                }
            });
        });

        const productsWithoutOverrides = [...copyOfProductsFromCart];

        if (this.ibisPOFlag) {
            productsWithoutOverrides.forEach(prod => {
                productTotal += prod.totalPrice;
            });

            products &&
                products.map(product => {
                    const override =
                        overrideProducts.length &&
                        overrideProducts.find(
                            override =>
                                override.id === product.id || override.id === product.variantId,
                        );
                    if (override) subtotal += override.overridePrice * product.quantity;
                });
            return subtotal + productTotal;
        }

        if (overrideProducts.length) {
            productsWithoutOverrides.forEach(prod => {
                if (this.portalOrderStore.orderType === REPLACEMENT) {
                    productTotal += 0;
                } else productTotal += prod.totalPrice;
            });

            products &&
                products.map(product => {
                    const override = overrideProducts.find(
                        override => override.id === product.id || override.id === product.variantId,
                    );
                    if (override) subtotal += override.overridePrice * product.quantity;
                });
            return subtotal + productTotal;
        }
        return this.context.cart.subtotal || 0;
    }
}

const cartStore = new CartStore();

export default cartStore;
export { CartStore };
