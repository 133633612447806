import firebase from '@cs-admin/services/firebase/init';
import { getOrderStatus } from '@cs-admin/services/orders';
import { orderStates, CANCELLED } from '@cs-admin/constants/orderStates';
import { CLOSED } from '@cs-admin/constants/popupStatuses';
import { getUserToken } from '@cs-admin/services/user';
import { nogento } from '@cs-admin/config';

export async function getPopupsByUID(uid) {
    try {
        const database = await firebase.firestore();
        const popups = [];

        await database
            .collection('socials')
            .where('uid', '==', uid)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    popups.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
            });

        return popups;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function getPopupByID(id) {
    try {
        const database = await firebase.firestore();
        const popup = await database
            .collection('socials')
            .doc(id)
            .get()
            .then(popup => ({
                id,
                ...popup.data(),
            }));
        return popup;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function getOrdersForPopup(id) {
    try {
        const database = await firebase.firestore();
        const orders = [];

        await database
            .collection('invoices')
            .where('socialId', '==', id)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    const data = doc.data();
                    orders.push({
                        docId: doc.id,
                        status: getOrderStatus(data),
                        ...data,
                    });
                });
            });

        await database
            .collection('orders')
            .where('socialId', '==', id)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    const data = doc.data();
                    let status = 'Pending Terms';
                    if (data.state === CANCELLED) status = orderStates[data.state].name;
                    if (!orders.find(order => order.orderId === data.orderId))
                        orders.push({
                            docId: doc.id,
                            status,
                            ...data,
                        });
                });
            });

        await database
            .collection('ship-to-socials')
            .where('socialId', '==', id)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    const data = doc.data();
                    let status = 'Pending Pop-up';
                    if (data.state === CANCELLED) status = orderStates[data.state].name;
                    if (!orders.find(order => order.orderId === data.orderId))
                        orders.push({
                            docId: doc.id,
                            status,
                            ...data,
                        });
                });
            });

        return orders;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function getOpenPopups(consultantUid) {
    try {
        const database = await firebase.firestore();
        const popups = [];

        await database
            .collection('socials')
            .where('uid', '==', consultantUid)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    if (data.status !== CLOSED) {
                        popups.push({
                            id: doc.id,
                            ...doc.data(),
                        });
                    }
                });
            });

        return popups;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function updatePopup(docId, collection, popupId) {
    try {
        const database = await firebase.firestore();

        if (collection === 'invoices') {
            const invoice = database.collection('invoices').doc(docId);
            const order = database.collection('orders').doc(docId);
            const orderRef = await order.get();
            const orderExists = orderRef.exists;
            let oldPopupId;

            return database
                .runTransaction(transaction => {
                    transaction.get(invoice).then(doc => {
                        oldPopupId = doc.data().socialId;
                    });

                    transaction.update(invoice, { socialId: popupId });
                    if (orderExists) transaction.update(order, { socialId: popupId });

                    return Promise.resolve();
                })
                .then(async () => recalculatePopup(oldPopupId, popupId))
                .catch(error => {
                    console.log(error);
                    return {
                        success: false,
                        error,
                    };
                });
        }
        const order = database.collection(collection).doc(docId);
        let oldPopupId;

        return database
            .runTransaction(transaction => {
                transaction.get(order).then(doc => {
                    oldPopupId = doc.data().popupId;
                });

                transaction.update(order, { socialId: popupId });
                return Promise.resolve();
            })
            .then(async () => recalculatePopup(oldPopupId, popupId))
            .catch(error => {
                console.log(error);
                return {
                    success: false,
                    error,
                };
            });
    } catch (error) {
        console.log(error);
        return {
            success: false,
            error,
        };
    }
}

async function recalculatePopup(oldPopupId, popupId) {
    const token = await getUserToken();

    if (oldPopupId !== 0) {
        await fetch(`${nogento.counterbase}/cs/recalculateSocial`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${token}` },
            body: JSON.stringify({ id: oldPopupId }),
        });
    }

    if (popupId !== 0) {
        await fetch(`${nogento.counterbase}/cs/recalculateSocial`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${token}` },
            body: JSON.stringify({ id: popupId }),
        });
    }

    return { success: true };
}
