import { nogento } from '@cs-admin/config';
import fetch from '@beautycounter/utils/fetch';
import { getUserToken } from '@cs-admin/services/user';
import {
    getMilestonesQuery,
    mutateMilestoneQuery,
    enrollerPayoutsQuery,
    availablePeriodsQuery,
    mutatePayoutsQuery,
} from './gqlQuery';

const prepareGqlParam = param => JSON.stringify(param).replaceAll('"', '');

export async function mutateMilestone({ requirements = [], rewards = [] } = {}) {
    const query = mutateMilestoneQuery({
        requirements: prepareGqlParam(requirements),
        rewards: prepareGqlParam(rewards),
    });
    return handleCall(query);
}

export async function mutateEnrollerPayouts({
    startDate,
    endDate,
    selectionId,
    excludes = [],
    selection = [],
}) {
    const query = mutatePayoutsQuery({
        startDate,
        endDate,
        excludes,
        selection,
        selectionId,
    });
    return handleCall(query);
}

export async function getMilestones() {
    return handleCall(getMilestonesQuery);
}

export async function getAvailablePeriods() {
    return handleCall(availablePeriodsQuery);
}

export async function getEnrollerPayouts({ isExport = false, ...requestOptions }) {
    const query = enrollerPayoutsQuery(requestOptions);
    return handleCall(query, isExport);
}

export async function handleCall(payload, isExport = false) {
    try {
        const result = await query(payload, isExport);
        if (!result) throw Error('No data');
        if (isExport) return result;
        const { errors, data } = result;
        if (errors) throw Error(errors[0].message);
        return data;
    } catch (e) {
        console.error('Milestones GQL error: ', e.message);
        return { error: e.message };
    }
}

const getQueryOptions = ({ authToken, payload }) => ({
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ query: payload }),
});

const START_COUNTING_QUERY_ENDPOINT = `${nogento.base}/startCounting/query`;
const START_COUNTING_EXPORT_ENDPOINT = `${nogento.base}/startCounting/export`;

export async function query(payload, isExport = false) {
    const token = await getUserToken();
    const url = isExport ? START_COUNTING_EXPORT_ENDPOINT : START_COUNTING_QUERY_ENDPOINT;
    const result = await fetch(url, getQueryOptions({ authToken: token, payload, isExport }));
    if (isExport) return result.text();
    return result.json();
}
