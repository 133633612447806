import { InterfaceStore } from './interfaceStore/interfaceStore';
import { UserStore } from './userStore/userStore';
import { ProductsStore } from './productsStore/productsStore';
import { CategoryStore } from './categoryStore/categoryStore';
import { NavigationStore } from './navigationStore/navigationStore';
import { CustomerStore } from './customerStore/customerStore';
import { OrderStore } from './orderStore/orderStore';
import { CartStore } from './cartStore/cartStore';
import { CartApiStore } from './cartApiStore/cartApiStore';
import { SessionStore } from './sessionStore/sessionStore';
import { FormStore } from './formStore/formStore';
import { FlagStore } from './flagStore/flagStore';
import { ReturnStore } from './returnStore/returnStore';
import { PortalOrderStore } from './portalOrderStore/portalOrderStore';
import { ContentStore } from './contentStore/contentStore';
import { PromoStore } from './promoStore/promoStore';
import { PopupStore } from './popupStore/popupStore';
import { RefundStore } from './refundStore/refundStore';
import { PromoToolStore } from './promoToolStore/promoToolStore';
import { LocaleStore } from './localeStore/localeStore';
import { PromoToolBusinessStore } from './promoToolBusinessStore/promoToolBusinessStore';
import { StartCountingStore } from './startCountingStore/startCountingStore';
import { AddressAutocompleteStore } from './addressAutocompleteStore/addressAutocompleteStore';
import { MelissaStore } from './melissaStore/melissaStore';

const storeClasses = {
    NavigationStore,
    InterfaceStore,
    UserStore,
    CustomerStore,
    OrderStore,
    FormStore,
    FlagStore,
    ReturnStore,
    ProductsStore,
    CategoryStore,
    CartStore,
    CartApiStore,
    ContentStore,
    SessionStore,
    PortalOrderStore,
    PromoStore,
    PopupStore,
    RefundStore,
    PromoToolStore,
    StartCountingStore,
    LocaleStore,
    PromoToolBusinessStore,
    AddressAutocompleteStore,
    MelissaStore,
};

/**
 * Inititalizes base stores with access to other store instances.
 * Should only fire on app init (client).
 */

export function setupStores(inserts = {}) {
    const stores = {};
    stores.stores = stores;
    Object.keys(storeClasses).forEach(key => {
        const ReferenceClass = storeClasses[key];
        if (ReferenceClass)
            stores[key.charAt(0).toLowerCase() + key.slice(1)] = new ReferenceClass();
    });
    Object.keys(stores).forEach(key => {
        Object.keys(stores).forEach(key2 => {
            if (key !== key2) {
                const base = stores[key.charAt(0).toLowerCase() + key.slice(1)];
                base[key2] = stores[key2];
                base.stores = stores;
            }
        });
        if (__SERVER__ && inserts.server && stores[key].server) stores[key].server = inserts.server;
        if (stores[key].ready) stores[key].ready();
    });
    return stores;
}

const defaults = setupStores();
export default defaults;
