export const CLIENT = 3,
    CONSULTANT = 1,
    MEMBER = 2,
    GUEST = 7,
    PROSPECT = 5,
    EMPLOYEE = 4;

export const userTypes = {
    1: 'Consultant',
    2: 'Member',
    3: 'Client',
    4: 'Employee',
    5: 'Prospect',
    7: 'Guest',
};
