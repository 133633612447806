import firebase from '@cs-admin/services/firebase/init';
import returnTypes from '@cs-admin/constants/returnTypes';
import fetch from '@cs-admin/services/fetch';
import { nogento } from '@cs-admin/config';
import { getUserToken } from '@cs-admin/services/user';
import {
    LEADER,
    AGENT,
    DEVELOPER,
    FRAUD_AGENT,
    SALES_OPERATOR_MANAGER,
} from '@cs-admin/constants/roleTypes';

/**
 * Returns product credit associated with id
 * @param {string} id
 */
export async function getProductCredit(id) {
    try {
        const database = await firebase.firestore();
        const doc = database.collection('productCredit').doc(id);
        const credit = await doc.get().then(credit => {
            if (credit.exists) {
                return credit.data();
            }
            return {
                us: {
                    balance: 0,
                },
                ca: {
                    balance: 0,
                },
            };
        });

        // commented instead of deleted because PC used to be stored as a positive integer
        // but now its being stored as a negative integer
        // doing this shows the USED PC in the ledger
        const ledger = [];
        await doc
            .collection('us-ledger')
            // .where('value', '>=', 0)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    ledger.push(doc.data());
                });
            });

        if (credit.us) credit.us.ledger = ledger;

        return credit;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Updates the product credit balance
 * @param {object} data
 */
export async function updateCreditBalance(data) {
    const token = await getUserToken();
    const neverExpire = returnTypes[14] && data.type == returnTypes[14].title ? 1 : 0;
    const updateCreditUrl = `${nogento.counterbase}/cs/updateBalance/${
        data.uid
    }?neverExpire=${neverExpire}`;

    return fetch(updateCreditUrl, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
}

export function returnType(id) {
    return { ...defaultReturnType, ...returnTypes[id] };
}

export const maxAmount = role => {
    let maxVal;
    if (role === LEADER || role === DEVELOPER || role === SALES_OPERATOR_MANAGER) {
        maxVal = 150.0;
    }
    if (role === AGENT || role === FRAUD_AGENT) {
        maxVal = 51.0;
    }
    return maxVal;
};

const defaultReturnType = {
    amount: 1.0,
    maxAmount,
    isStatic: false,
};
