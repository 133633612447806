import { observable, computed, action, toJS } from 'mobx';
import { keyBy, flatten, last, uniqBy } from 'lodash';
import { sortFilters, getCategoryLinks } from '@cs-admin/services/categories';
import { removeStartSlash, removeFilterQueries } from '@cs-admin/utils/url';
import { sorting } from '@cs-admin/utils/sorting';

class CategoryStore {
    @observable categories;
    @observable categoriesById;
    @observable categoriesBySlug;
    @observable currentCategory;
    @observable categoryFilters;
    @observable categoryLinks;
    @observable categoryLinksById;
    @observable categoryHistory;
    @observable filters;
    @observable quicklook;
    @observable sortTypes;

    constructor() {
        this.categories = [];
        this.categoriesById = {};
        this.categoriesBySlug = {};
        this.currentCategory = null;
        this.categoryFilters = {};
        this.categoryLinks = [];
        this.categoryLinksById = {};
        this.uniqueCategoryLinksById = {};
        this.categoryHistory = [];
        this.filters = {};
        this.filterHistory = [];
        this.quicklook = {};
        this.sortTypes = [];
    }

    @action
    load = ({ contentfulCategories, catalogCategories, navigation }) => {
        const categories = this.filterCategory(contentfulCategories, catalogCategories);

        categories.map(category => {
            category.slug = removeStartSlash(category.slug || Math.random().toString(32));
        });

        this.categories = categories;
        this.categoriesById = keyBy(categories, 'magentoId');
        this.categoriesBySlug = keyBy(categories, 'slug');

        this.navigationStore.load(navigation);
    };

    filterCategory = (contentfulCategories, categories) =>
        uniqBy(
            contentfulCategories.filter(contentfulCategory =>
                categories.some(({ categoryId }) => categoryId === contentfulCategory.magentoId),
            ),
            'magentoId',
        );

    @action
    appendFilters = ({ subcategories, filters }) => {
        this.categoryFilters = sortFilters({
            categories: subcategories,
            definedFilters: filters,
            content: this.categories,
        });

        const categoryLinks = getCategoryLinks(this.categoryFilters, this.categoriesById);
        this.categoryLinks = categoryLinks;
        this.categoryLinksById = keyBy(categoryLinks, 'deepId');
        this.uniqueCategoryLinksById = keyBy(uniqBy(categoryLinks, d => d.name), 'id');
    };

    @action
    toggleFilter = (data, urlUpdate = true) => {
        if (typeof this.filters[data.parent] == 'undefined') this.filters[data.parent] = {};

        data.value
            ? (this.filters[data.parent][data.id] = data)
            : delete this.filters[data.parent][data.id];

        this.filters = JSON.parse(JSON.stringify(this.filters));
        data.value && this.filterHistory.push(data.id);

        const keys = [];

        Object.keys(this.filters).forEach(section => {
            Object.keys(this.filters[section]).forEach(i => {
                const filter = this.filters[section][i];
                keys.push(filter.query);
            });
        });

        urlUpdate &&
            this.navigationStore.addQuery({
                queries: keys,
                isBackPoint: true,
            });

        if (!keys.length) {
            removeFilterQueries();
            this.filters = {};
            this.filterHistory.push(null);
        }

        // close quicklook
        this.quicklook = {};
    };

    @computed
    get flattenedFilters() {
        const filters = this.filters;
        return flatten(
            Object.keys(filters).map(type =>
                Object.keys(filters[type]).map(filter => filters[type][filter]),
            ),
        );
    }

    @computed
    get filterCount() {
        const flattenedFilters = this.flattenedFilters || [];
        return flattenedFilters.length;
    }

    @action
    resetFilters = () => {
        this.quicklook = {};
        this.filters = {};
        this.filterHistory.push(null);
        removeFilterQueries();
    };

    @action
    toggleQuicklook = ({ product, row, variant }) => {
        if (!this.quicklook[product] || (this.quicklook[product] != true && !setValue)) {
            const quicklook = {};
            quicklook[product] = true;
            quicklook[row] = true;
            quicklook.variant = variant;
            this.quicklook = JSON.parse(JSON.stringify(quicklook));
        }
    };

    @action
    setCurrentCategory = params => {
        this.currentCategory = params;
        this.filters = {};
        params && this.categoryHistory.push(params);
        params && this.filterHistory.push(null);
    };

    @computed
    get categoryIds() {
        return toJS(this.categoriesById);
    }

    @computed
    get categorySlugs() {
        return toJS(this.categoriesBySlug);
    }

    @computed
    get lastCategory() {
        return last(this.categoryHistory) || null;
    }

    @computed
    get lastFilter() {
        return last(this.filterHistory) || null;
    }

    @action
    appendSortTypes = ({ sortTypes, names }) => {
        sortTypes.forEach((type, key) => {
            sortTypes[key].name = names[type.id];
            sortTypes[key].callback = sorting[type.method];
        });
        this.sortTypes = sortTypes;
    };
}

const categoryStore = new CategoryStore();

export default categoryStore;
export { CategoryStore };
