import { observable, observe, computed, action } from 'mobx';
import { cloneDeep, isEmpty, orderBy, uniqBy, find, omitBy, isNil } from 'lodash';
import { getSafe } from '@cs-admin/utils/object';
import { isValidMembershipChange, getMemberServiceMethod } from '@cs-admin/utils/membership';
import { bobTCs, bccomBase, newBase, devUrl } from '@cs-admin/config';
import { phone } from '@cs-admin/utils/masks';
import { updateCreditBalance, getProductCredit } from '@cs-admin/services/productCredit';
import { getOrdersById } from '@cs-admin/services/orders';
import { searchConsultantNames } from '@cs-admin/services/portalOrder';
import { getPopupsByUID } from '@cs-admin/services/popups';
import { checkPWSPersistence } from '@cs-admin/services/attribution/pws';
import {
    getCustomerInfo,
    getUserByPhoenixId,
    updateCustomerEmail,
    updateCustomerInfo,
    resendTcsAcceptanceEmail,
    getCustomerAuthToken,
    updateAccountStatus,
    updatePhoenixSponsorId,
    updateMemberExpirationDate,
    triggerForgotPassword,
    updateMembershipStatus,
    updateCustomerEnrollerId,
    customersSearch,
    checkValidClientsAndMembers,
    restoreClientsAndMembers,
    checkValidDownline,
    restoreDownline,
    getAccountTypeHistory,
    restoreMemberToConsultant,
    changeCountry,
    lapseMember,
} from '@cs-admin/services/customers';
import { getEmployeeAccounts, offboardEmployeeAccount } from '@cs-admin/services/employees';
import { writeAddressData } from '@cs-admin/services/user/address';
import { CONSULTANT, MEMBER, EMPLOYEE, userTypes } from '@cs-admin/constants/userStates';
import { OPEN, CLOSED } from '@cs-admin/constants/popupStatuses';
import { CUSTOMERS_CHUNK_SIZE } from '@cs-admin/constants/customerSearchConstants';
import { DISTRIBUTOR } from '@cs-admin/constants/customerTypes';
import {
    DISTRIBUTOR_DATE_OF_BIRTH,
    REGULAR_USER_DATE_OF_BIRTH,
} from '@cs-admin/constants/validation';
import memberStatuses from '@cs-admin/constants/memberStatuses';
import { isPwsAvailable, updatePws } from '@cs-admin/services/pws';
import { updateCustomerTaxNumber } from '@cs-admin/services/taxNumbers';

class CustomerStore {
    @observable customers;
    @observable activeCustomer;
    @observable preferences;

    // Customer Detail Forms
    @observable customerDetailForm;
    @observable addressForm;
    @observable consultantInformationForm;
    @observable employeeAccounts;

    @observable popups;
    @observable newPrimaryConsultant;
    @observable memberExpirationDate;
    @observable pws;
    @observable lastQuery;
    @observable lastCustomer;

    // Is used for pagination with new nogento endpoint to fetch customers
    @observable page;

    @observable lazyLoadSearchResults;
    @observable searchedByFirstOrLastName;
    @observable moreSearchResultsAvailable;
    @observable customersSearched;
    @observable customersLoading;
    @observable isRestoreClientsAndMembers;
    @observable isRestoreDownline;

    constructor() {
        this.customers = [];
        this.activeCustomer = null;
        this.preferences = [];

        this.consultantInformationForm = null;
        this.customerDetailForm = null;
        this.addressForm = null;

        this.popups = [];
        this.newPrimaryConsultant = null;
        this.memberExpirationDate = null;
        this.pws = {};
        this.lastQuery = null;
        this.lastCustomer = null;
        this.page = 1;
        this.lazyLoadSearchResults = false;
        this.searchedByFirstOrLastName = false;
        this.moreSearchResultsAvailable = false;
        this.customersSearched = false;
        this.customersLoading = false;
        this.employeeAccounts = {};
        this.isRestoreClientsAndMembers = false;
        this.isRestoreDownline = false;
    }

    ready() {
        if (__BROWSER__) {
            this.mountReactions();
        }
    }

    mountReactions() {
        observe(this.userStore, 'isLogged', async isLogged => {
            if (isLogged) await this.searchCustomers();
        });
    }

    resetStore() {
        this.customers = [];
        this.activeCustomer = null;
        this.preferences = [];
        this.customerDetailForm = null;
        this.addressForm = null;
        this.popups = [];
        this.newPrimaryConsultant = null;
        this.memberExpirationDate = null;
        this.pws = {};
        this.lastQuery = null;
        this.lastCustomer = null;
        this.page = 1;
        this.lazyLoadSearchResults = false;
        this.searchedByFirstOrLastName = false;
        this.moreSearchResultsAvailable = false;
        this.customersSearched = false;
        this.customersLoading = false;
        this.employeeAccounts = {};
        this.isRestoreClientsAndMembers = false;
        this.isRestoreDownline = false;
    }

    @computed
    get isConsultant() {
        return this.activeCustomer && this.activeCustomer.userType === CONSULTANT;
    }

    @computed
    get isMember() {
        return this.activeCustomer && this.activeCustomer.userType === MEMBER;
    }

    @action
    resetCustomers = () => {
        this.lastCustomer = null;
        this.customers = [];
        this.lastQuery = null;
        this.page = 1;
    };

    @action
    async setActiveCustomer(id) {
        this.interfaceStore.showProgress();
        const customer = await getCustomerInfo(id);
        // remove this once we find out where Address.address object is coming from
        // also insert stackdriver logger logic in here as well.
        if (customer && customer.shippingAddresses && customer.shippingAddresses.length) {
            const { shippingAddresses } = customer;
            this.pws = {};
            for (const address of shippingAddresses) {
                if (address.address === null || address.address === undefined) address.address = '';

                if (typeof address.address !== 'string') {
                    // add stackdriver logging logic within this condtion.

                    // ~~~~~RIGHT HERE~~~~

                    // if the expected address field is not a string
                    // check if there is a nested address object which is commonplace for the bug we have been seeing as of 2/21/20
                    // reassing it as the correct address if it is a string type value
                    if (address.address.address && typeof address.address.address === 'string') {
                        const nestedAddressField = address.address.address;
                        address.address = nestedAddressField;

                        // if it is not a string type value, assign it a value of empty string because render is expecting str type
                    } else {
                        address.address = '';
                    }
                }
            }
        }

        if (customer.userType === EMPLOYEE) {
            const retrievedAccounts = await getEmployeeAccounts(id);
            this.employeeAccounts = retrievedAccounts.success
                ? retrievedAccounts.employeeAccounts
                : retrievedAccounts.error;
        }

        if (customer.success !== undefined && customer.success === false) {
            this.activeCustomer = null;
        } else {
            this.memberExpirationDate = customer.memberExpirationDateUTC;
            this.activeCustomer = cloneDeep(customer);
            this.getConsultantPreferences();

            if (this.isConsultant) {
                this.popups = await getPopupsByUID(id);
                this.checkToRestoreClientsAndMembers();
                this.checkToRestoreDownline();
            }
        }
        this.interfaceStore.hideProgress();
    }

    @action
    grabCarts() {
        const { uid } = this.activeCustomer;
        return Promise.all([
            this.cartApiStore.getPersonalCarts(uid),
            this.isConsultant && this.cartApiStore.getClientCarts(uid),
            this.isConsultant && this.cartApiStore.getPopupCarts(uid),
        ]);
    }

    @action
    clearActiveCustomer = () => {
        this.activeCustomer = null;
        this.preferences = [];
        this.popups = [];
        this.customerDetailForm = null;
        this.addressForm = null;
        this.newPrimaryConsultant = null;
        this.memberExpirationDate = null;
        this.employeeAccounts = {};
    };

    @action
    searchConsultants = async (entry, limit) => {
        // the way the pheonix endpoint for searching consultant names is that it filters by countryId
        // so if its a us customer, you probably won't be able to find a canadian consultant to change attribution
        const result = await searchConsultantNames(
            entry,
            limit,
            this.activeCustomer.countryId || 1,
        );
        if (result) {
            return {
                ...result,
                items: result.items ? uniqBy(result.items, 'accountID') : [],
            };
        }
    };

    /* TODO: Remove after full move to orders collection
     * Figure out why we need this second check for a race condition
     */
    @action
    updateOrders = async () => {
        if (this.activeCustomer) {
            const orders = await getOrdersById(this.activeCustomer.uid);
            if (this.activeCustomer)
                this.activeCustomer.orders = orderBy(orders, o => o.timestamp, ['desc']);
        }
    };

    @computed
    get sortedPopups() {
        const currentDate = new Date();
        let openPopups = [];
        let futurePopups = [];
        let closedPopups = [];

        openPopups = this.popups
            .filter(popup => popup.status === OPEN && new Date(popup.openingDate) <= currentDate)
            .sort((a, b) => new Date(a.openingDate) - new Date(b.openingDate));
        futurePopups = this.popups
            .filter(popup => popup.status === OPEN && new Date(popup.openingDate) > currentDate)
            .sort((a, b) => new Date(a.openingDate) - new Date(b.openingDate));
        closedPopups = this.popups
            .filter(popup => popup.status === CLOSED)
            .sort((a, b) => new Date(a.openingDate) - new Date(b.openingDate));

        return [...openPopups, ...futurePopups, ...closedPopups];
    }

    @computed
    get memberTermsStatus() {
        const bobTCsUrl = bobTCs;
        const tcs = this.activeCustomer && this.activeCustomer.bobTCs;
        if (!tcs) return tcs;

        const orders = [...tcs].sort((a, b) => {
            if (a.orderDate > b.orderDate) return -1;
            if (b.orderDate > a.orderDate) return 1;
            return 0;
        });

        const today = new Date().toISOString();
        const expired = today > getSafe(() => orders[0].orderExpiration);

        return {
            ...(orders?.[0] || []),
            expired,
            bobTCsUrl,
        };
    }

    @computed
    get memberStatus() {
        const { PENDING_TERMS, MEMBER, EXPIRED, DECLINED } = memberStatuses;

        if (this.activeCustomer) {
            const tcs = this.memberTermsStatus;
            const { memberExpirationDateUTC } = this.activeCustomer;

            if (tcs || memberExpirationDateUTC) {
                if (this.isPending) return PENDING_TERMS;
                if ((tcs && tcs.signed) || memberExpirationDateUTC) return MEMBER;
                if (tcs.expired) return EXPIRED;
                if (tcs.declined) return DECLINED;
            }
        }

        return 'N/A';
    }

    @computed
    get isActiveMember() {
        const { MEMBER } = memberStatuses;
        const today = new Date().toISOString();
        const { memberExpirationDateUTC } = this.activeCustomer;

        return this.memberStatus === MEMBER && memberExpirationDateUTC > today;
    }

    @computed
    get isPending() {
        const tcs = this.memberTermsStatus;
        return tcs && !tcs.signed && !tcs.expired && !tcs.declined;
    }

    @computed
    get isInPhoenix() {
        return this.activeCustomer && this.activeCustomer.phoenixId;
    }

    @computed
    get defaultShipping() {
        if (
            !this.activeCustomer ||
            !this.activeCustomer.shippingAddresses ||
            !this.activeCustomer.shippingAddresses.length
        )
            return {};

        const shipping =
            (this.activeCustomer.shippingAddresses.length &&
                this.activeCustomer.shippingAddresses.find(
                    address =>
                        address.isDefaultShipping === 1 || address.isDefaultShipping === true,
                )) ||
            this.activeCustomer.shippingAddresses[0];

        return {
            id: shipping.id,
            name: `${shipping.firstName} ${shipping.lastName}`,
            phone: shipping.telephone,
            ...shipping,
        };
    }

    @action
    setupConsultantInformationForm = () => {
        this.consultantInformationForm = this.formStore.create({
            key: 'consultantInformationForm',
            persist: false,
        });

        const {
            consultant = {},
            enroller = {},
            enrollmentDateUTC,
            nextRenewalDateUTC,
        } = this.activeCustomer;

        this.consultantInformationForm.add([
            {
                key: 'mentor',
                label: 'mentor',
                value: consultant.fullName,
            },

            {
                key: 'enroller',
                label: 'enroller',
                value: enroller.fullName,
            },
            {
                key: 'enrollmentDate',
                label: 'enrollment date',
                type: 'date',
                value: enrollmentDateUTC,
            },

            {
                key: 'renewalDate',
                label: 'next Renewal Date',
                type: 'date',
                value: nextRenewalDateUTC,
            },
        ]);

        const clearConsultantInformationForm = () => {
            this.formStore.clearForm('consultantInformationForm');
        };

        return clearConsultantInformationForm;
    };

    @action
    setupAddressForm = () => {
        const createAction = () => {
            this.addressForm = this.formStore.create({
                key: 'addressForm',
                persist: false,
            });

            this.addressForm.add([
                {
                    key: 'firstName',
                    label: 'First Name',
                    type: 'required',
                    value: this.defaultShipping.firstName,
                },
                {
                    key: 'lastName',
                    label: 'Last Name',
                    type: 'required',
                    value: this.defaultShipping.lastName,
                },
                {
                    key: 'companyName',
                    label: 'Company Name',
                    value: this.defaultShipping.companyName,
                },
                {
                    key: 'address1',
                    label: 'Address',
                    type: 'address',
                    value: this.defaultShipping.address,
                },
                {
                    key: 'address2',
                    label: 'Apt/Floor/Suite',
                    value: this.defaultShipping.address2,
                },
                {
                    key: 'city',
                    label: 'City',
                    type: 'required',
                    value: this.defaultShipping.city,
                },
                {
                    key: 'postalCode',
                    label: 'Zip Code',
                    type: 'zip',
                    value: this.defaultShipping.postalCode,
                },
                {
                    key: 'state',
                    label: 'State',
                    type: 'required',
                    value: this.defaultShipping.region,
                },
                {
                    key: 'telephone',
                    label: 'Telephone',
                    type: 'phoneOptional',
                    value: getSafe(() => phone(this.defaultShipping.telephone)),
                },
                {
                    key: 'country',
                    label: 'Country',
                    value: this.defaultShipping.country,
                },
            ]);
        };

        this.formStore.clearForm('addressForm');
        this.formStore.createReactiveForm('addressForm', 'forms', createAction);
    };

    @action
    checkToRestoreClientsAndMembers = async () => {
        const { phoenixId = null } = this.activeCustomer;
        const { success, data } = await checkValidClientsAndMembers(phoenixId);
        const { hasClientsAndMembersToBeRestored = false } = data;
        this.isRestoreClientsAndMembers = success
            ? Boolean(hasClientsAndMembersToBeRestored)
            : false;
    };

    @action
    restoreClientsAndMembers = async accountId => {
        try {
            const { success } = await restoreClientsAndMembers(accountId);
            if (success) {
                this.interfaceStore.openAlert(
                    false,
                    'Clients and Members has been successfully restored to consultant.',
                );
                return success;
            }
            this.interfaceStore.openAlert(
                true,
                'Failed to restore clients and members to consultant.',
            );
        } catch (error) {
            this.interfaceStore.openAlert(
                true,
                'Failed to restore clients and members to consultant.',
            );
        }
    };

    @action
    checkToRestoreDownline = async () => {
        const { phoenixId = null } = this.activeCustomer;
        const { success, data: hasDownlineToBeRestored = false } = await checkValidDownline(
            phoenixId,
        );
        this.isRestoreDownline = success ? Boolean(hasDownlineToBeRestored) : false;
    };

    @action
    restoreDownline = async accountId => {
        try {
            const { success } = await restoreDownline(accountId);
            if (success) {
                this.interfaceStore.openAlert(
                    false,
                    'Downline has been successfully restored to consultant.',
                );
                return success;
            }
            this.interfaceStore.openAlert(true, 'Failed to restore downline to consultant.');
        } catch (error) {
            this.interfaceStore.openAlert(true, 'Failed to restore downline to consultant.');
        }
    };

    @action
    searchCustomers = async (query = {}) => {
        this.customersLoading = true;
        await this.getCustomers(query);
        this.customersLoading = false;
    };

    @action
    getCustomers = async (query = {}) => {
        const { customers = [] } = await customersSearch(query);
        this.customers = [...this.customers, ...customers];
        this.lastQuery = query;
        this.moreSearchResultsAvailable = customers.length === CUSTOMERS_CHUNK_SIZE;
        this.customersSearched = !isEmpty(query);
    };

    @action
    async loadMoreSearchResults() {
        this.page = this.page + 1;
        this.interfaceStore.showProgress();
        await this.getCustomers({ ...this.lastQuery, page: this.page });
        this.interfaceStore.hideProgress();
        this.customersSearched = true;
    }

    @action
    async getConsultantPreferences() {
        try {
            const { consultantPreferences } = this.activeCustomer;
            if (!isEmpty(consultantPreferences) && this.preferences.length === 0) {
                Object.keys(consultantPreferences).forEach(async consultant => {
                    const sponsorId = await getUserByPhoenixId(Number(consultant));

                    this.preferences.push({
                        id: Number(consultant),
                        name: sponsorId.fullName,
                        creditAccess: consultantPreferences[consultant].creditAccess ? 'Yes' : 'No',
                        paymentAccess: consultantPreferences[consultant].paymentAccess
                            ? 'Yes'
                            : 'No',
                    });
                });
            }
            if (!this.isConsultant) {
                const pwsId = await checkPWSPersistence(this.activeCustomer.uid);
                if (pwsId) {
                    const pws = await getUserByPhoenixId(pwsId);
                    if (pws) this.pws = pws;
                }
            }
            if (!this.pws.uid) this.pws = {};

            return this.preferences || [];
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    // Need to refactor comments component
    // to get rid of comments obj being passed back
    @action
    saveNewComment = async comment => {
        if (this.activeCustomer) {
            const { uid } = this.activeCustomer;
            const comments = this.activeCustomer.comments || [];
            comments.unshift(comment);

            const response = await updateCustomerInfo(uid, { comments });

            if (response.success) {
                this.interfaceStore.openAlert(false, 'Successfully submitted');
                await this.setActiveCustomer(uid);
            } else {
                this.interfaceStore.openAlert(true, 'Failed to add comment.');
            }

            return { ...response, comments };
        }

        return {
            success: false,
        };
    };

    @action
    updateMentor = async (consultant = {}) => {
        const { accountID: consultantAccountId, fullName } = consultant;
        const { consultantPreferences, phoenixId, uid } = this.activeCustomer;
        const isNewConsultantForCustomer = !find(this.preferences, { id: consultantAccountId });
        const customerData = {
            sponsorId: consultantAccountId,
        };

        if (isNewConsultantForCustomer) {
            this.preferences.unshift({
                id: consultantAccountId,
                name: fullName,
                creditAccess: 'Yes',
                paymentAccess: 'No',
            });

            customerData.consultantPreferences = {
                ...consultantPreferences,
                [consultantAccountId]: {
                    creditAccess: true,
                    paymentAccess: false,
                },
            };
        }

        const updateConsultantResponse = await updatePhoenixSponsorId(
            phoenixId,
            consultantAccountId,
        );

        if (updateConsultantResponse.status !== 200) {
            return { success: false };
        }

        return updateCustomerInfo(uid, customerData);
    };

    @action
    updateConsultantInformation = async updateData => {
        const form = this.consultantInformationForm;

        form.validate();

        if (!form.valid || !this.activeCustomer) {
            return {
                success: false,
            };
        }

        const {
            uid,
            phoenixId,
            firstName,
            lastName,
            email,
            userType,
            sponsorId,
        } = this.activeCustomer;
        const { enrollerId, mentor } = updateData;
        const isTheSameConsultant = sponsorId === mentor?.accountID;

        if (mentor && !isTheSameConsultant) {
            const mentorUpdateResponse = await this.updateMentor(mentor);
            if (!mentorUpdateResponse.success) {
                this.onUpdateError('Unable to update Mentor information');
                return {
                    success: false,
                };
            }
        }

        if (enrollerId) {
            const updateEnrollerIdResponse = await updateCustomerEnrollerId({
                accountId: phoenixId,
                enrollerId,
            });

            if (!updateEnrollerIdResponse.status) {
                this.onUpdateError('Unable to update enrollerId');
                return {
                    success: false,
                };
            }
        }

        const {
            nextRenewalDateUTC,
            enrollmentDateUTC,
            pws,
            taxNumber,
            entityName,
            isEntity,
        } = updateData;
        const isPwsEdited = pws !== getSafe(() => this.activeCustomer.pws.data.pwsKey);

        if (pws && isPwsEdited) {
            const title = userTypes[userType];

            const pwsAvailable = await isPwsAvailable(pws);

            if (!pwsAvailable) {
                this.onUpdateError('This PWS is unavailable');
                return { success: false };
            }

            const updatePwsResponse = await updatePws({
                phoenixId,
                firstName,
                lastName,
                email,
                pwsKey: pws,
                title,
            });

            if (!updatePwsResponse.success) {
                this.onUpdateError('Unable to update PWS');
                return { success: false };
            }
        }

        if (nextRenewalDateUTC || enrollmentDateUTC) {
            const { success: isCustomerInfoUpdated } = await updateCustomerInfo(
                uid,
                omitBy(
                    {
                        nextRenewalDateUTC,
                        enrollmentDateUTC,
                    },
                    isNil,
                ),
            );

            if (!isCustomerInfoUpdated) {
                this.onUpdateError();
                return { success: false };
            }
        }

        if (taxNumber) {
            const { status: isTaxNumberUpdated, message } = await updateCustomerTaxNumber({
                phoenixId,
                taxNumber,
                entityName,
                isEntity,
            });

            if (!isTaxNumberUpdated) {
                this.onUpdateError(message);
                return { success: false };
            }
        }

        await this.setActiveCustomer(uid);
        this.onUpdateSuccess();
        return { success: true };
    };

    @action
    updateBasicCustomerDetails = async () => {
        const form = this.customerDetailForm;
        const customerFormData = form.values;
        form.validate();

        if (!form.valid || !this.activeCustomer) {
            return {
                success: false,
            };
        }

        const { uid, userType, phoenixId, countryId, email = '' } = this.activeCustomer;
        customerFormData.userType = parseInt(customerFormData.userType, 10);

        if (customerFormData.email.toLowerCase() !== email.toLowerCase()) {
            const updateCustomerResponse = await updateCustomerEmail(customerFormData.email, uid);

            if (updateCustomerResponse.status !== 200) {
                this.onUpdateError('Failed to update email and customer info.');
                return updateCustomerResponse;
            }
        }

        if (customerFormData.countryId !== countryId) {
            const response = await changeCountry({
                countryId: customerFormData.countryId,
                accountId: phoenixId,
            });

            if (!response.success) {
                this.onUpdateError('Failed to update country.');
                return { success: false };
            }
        }

        const membershipManualUpgrade = this.flagStore.isFeatureEnabled('membershipManualUpgrade');
        if (
            membershipManualUpgrade &&
            isValidMembershipChange(userType, customerFormData.userType)
        ) {
            const membershipServiceMethod = getMemberServiceMethod(
                userType,
                customerFormData.userType,
            );
            if (!membershipServiceMethod) {
                this.onUpdateError(
                    'An error occurred when processing membership: Invalid membership status change',
                );
                return { success: false };
            }
            /*
                delete incoming userType, allowing updateMembershipStatus to handle membership related
                userType changes and preventing updateCustomerInfo from mutating userType
            */
            delete customerFormData.userType;

            const { success, message } = await updateMembershipStatus(uid, {
                reason: membershipServiceMethod,
            });
            if (!success) {
                this.onUpdateError(`An error occurred when processing membership: ${message}`);
                return { success };
            }
        }

        const response = await updateCustomerInfo(uid, customerFormData);

        if (response.success) {
            this.onUpdateSuccess();
            await this.setActiveCustomer(uid);
        } else {
            this.onUpdateError('Failed to update customer info.');
        }

        return response;
    };

    @action
    updatePrimaryConsultant = async () => {
        const { accountID: consultantAccountId, alreadyExists } = this.newPrimaryConsultant;
        const { consultantPreferences, phoenixId, uid } = this.activeCustomer;
        const customerData = {
            sponsorId: consultantAccountId,
        };

        if (!alreadyExists) {
            customerData.consultantPreferences = {
                ...consultantPreferences,
                [consultantAccountId]: {
                    creditAccess: true,
                    paymentAccess: false,
                },
            };
        }

        this.newPrimaryConsultant = null;

        const updateConsultantResponse = await updatePhoenixSponsorId(
            phoenixId,
            consultantAccountId,
        );

        if (updateConsultantResponse.status !== 200) {
            this.onUpdateError('Invalid sponsorId or PhoenixId to update Phoenix account.');
            return { success: false };
        }

        const updateCustomerResponse = await updateCustomerInfo(uid, customerData);
        if (updateCustomerResponse.success) {
            this.onUpdateSuccess();
            await this.setActiveCustomer(uid);
        } else {
            this.onUpdateError('Failed to update customer info.');
        }

        return updateCustomerResponse;
    };

    onUpdateSuccess = (message = 'Successfully submitted') =>
        this.interfaceStore.openAlert(false, message);

    onUpdateError = (message = 'Failed to update.') => this.interfaceStore.openAlert(true, message);

    @action
    updateDefaultAddress = async () => {
        const form = this.addressForm;
        form.validate();
        if (form.valid && this.activeCustomer) {
            const uid = this.activeCustomer.uid;

            const shippingAddresses = this.activeCustomer.shippingAddresses;

            let defaultShippingAddressFound = false;
            for (const address of shippingAddresses) {
                if (address.isDefaultShipping === 1 || address.isDefaultShipping === true) {
                    address.firstName = form.fields.firstName.value;
                    address.lastName = form.fields.lastName.value;
                    address.companyName = form.fields.companyName.value;
                    address.telephone = form.fields.telephone.value.replace(/[()-\s]/g, '');
                    address.address = form.fields.address1.value;
                    address.address2 = form.fields.address2.value;
                    address.city = form.fields.city.value;
                    address.region = form.fields.state.value;
                    address.postalCode = form.fields.postalCode.value;
                    address.country = form.fields.country.value;
                    address.countryId = this.activeCustomer.countryId;
                    address.isDefaultShipping = true;
                    (address.phoenixId = this.activeCustomer.phoenixId),
                        (address.dateLastModified = new Date());
                    defaultShippingAddressFound = true;
                    break;
                } else if (
                    address.address === form.fields.address1.value &&
                    address.postalCode === form.fields.postalCode.value
                ) {
                    address.firstName = form.fields.firstName.value;
                    address.lastName = form.fields.lastName.value;
                    address.companyName = form.fields.companyName.value;
                    address.telephone = form.fields.telephone.value.replace(/[()-\s]/g, '');
                    address.address = form.fields.address1.value;
                    address.address2 = form.fields.address2.value;
                    address.city = form.fields.city.value;
                    address.region = form.fields.state.value;
                    address.postalCode = form.fields.postalCode.value;
                    address.country = form.fields.country.value;
                    address.countryId = this.activeCustomer.countryId;
                    address.isDefaultShipping = true;
                    (address.phoenixId = this.activeCustomer.phoenixId),
                        (address.dateLastModified = new Date());
                    defaultShippingAddressFound = true;
                    break;
                }
            }

            let response;
            if (!defaultShippingAddressFound) {
                const newAddress = {
                    address: form.fields.address1.value,
                    address2: form.fields.address2.value,
                    city: form.fields.city.value,
                    companyName: form.fields.companyName.value,
                    country: form.fields.country.value,
                    countryId: this.activeCustomer.countryId,
                    firstName: form.fields.firstName.value,
                    isDefaultShipping: true,
                    lastName: form.fields.lastName.value,
                    postalCode: form.fields.postalCode.value,
                    region: form.fields.state.value,
                    telephone: form.fields.telephone.value.replace(/[()-\s]/g, ''),
                    dateLastModified: new Date(),
                    phoenixId: this.activeCustomer.phoenixId,
                };
                response = await writeAddressData(uid, newAddress);
            } else {
                response = await updateCustomerInfo(uid, {
                    shippingAddresses,
                });
            }
            if (response.success) {
                this.onUpdateSuccess();
                await this.setActiveCustomer(uid);
            } else {
                this.onUpdateError(response.error.message);
            }

            return response;
        }

        return {
            success: false,
        };
    };

    @action
    updateProductCredit = async data => {
        const response = await updateCreditBalance(data);
        this.activeCustomer.productCredit = await getProductCredit(this.activeCustomer.uid);
        return response;
    };

    @action
    setupCustomerDetailForm = () => {
        const createAction = () => {
            this.customerDetailForm = this.formStore.create({
                key: 'customerDetail',
                persist: false,
            });

            const {
                firstName,
                lastName,
                email,
                userType,
                telephone,
                genderId,
                defaultLanguageId,
                dateOfBirth,
                countryId,
            } = this.activeCustomer;

            const dateOfBirthType =
                userType === DISTRIBUTOR ? DISTRIBUTOR_DATE_OF_BIRTH : REGULAR_USER_DATE_OF_BIRTH;

            this.customerDetailForm.add([
                {
                    key: 'firstName',
                    label: 'First Name',
                    type: 'required',
                    value: firstName,
                },
                {
                    key: 'lastName',
                    label: 'Last Name',
                    type: 'required',
                    value: lastName,
                },
                {
                    key: 'email',
                    label: 'Email',
                    type: 'email',
                    value: email,
                },
                {
                    key: 'userType',
                    label: 'Account Type',
                    type: 'required',
                    value: userType,
                },
                {
                    key: 'telephone',
                    label: 'Phone Number',
                    value: telephone,
                },
                {
                    key: 'genderId',
                    label: 'Gender',
                    value: genderId,
                },
                {
                    key: 'defaultLanguageId',
                    label: 'Default Language',
                    value: defaultLanguageId,
                },
                {
                    key: 'dateOfBirth',
                    label: 'Date of birth',
                    type: dateOfBirthType,
                    value: dateOfBirth,
                },
                {
                    key: 'countryId',
                    label: 'Country',
                    value: countryId,
                },
            ]);
        };

        this.formStore.clearForm('customerDetail');
        this.formStore.createReactiveForm('customerDetail', 'forms', createAction);
    };

    @action
    resendTcsAcceptanceEmail = async () => {
        if (this.activeCustomer && this.memberTermsStatus) {
            const response = await resendTcsAcceptanceEmail(
                this.activeCustomer.email,
                this.memberTermsStatus.orderId,
            );

            if (response.success) {
                this.interfaceStore.openAlert(
                    false,
                    'Successfully sent terms and conditions acceptance email.',
                );
            } else {
                this.interfaceStore.openAlert(true, `Could not resend email. ${response.err}`);
            }

            return response;
        }

        return { success: false };
    };

    @action
    triggerForgotPassword = async () => {
        if (this.activeCustomer) {
            const response = await triggerForgotPassword(this.activeCustomer.email);

            if (response.success) {
                this.interfaceStore.openAlert(false, 'Reset password email sent');
            } else {
                this.interfaceStore.openAlert(
                    true,
                    'Reset password email was not sent, please try again later',
                );
            }

            return response;
        }

        return { success: false };
    };

    @action
    loginAs = async (uid, option = null) => {
        const result = await getCustomerAuthToken(uid);
        const { success, data } = result;
        if (!success) {
            return this.interfaceStore.openAlert(true, 'Failed to generate user token');
        }
        const path = option === 'localhost' ? devUrl : newBase;
        window.open(`${path}/btc?cstoken=${data.token}`, '_blank');
    };

    @action
    updateStatus = async status => {
        const { uid } = this.activeCustomer;

        const { success, message } = await updateAccountStatus(uid, status);

        if (success) {
            this.interfaceStore.openAlert(false, 'Updated account status successfully.');
            await this.setActiveCustomer(uid);
        } else {
            this.interfaceStore.openAlert(
                true,
                `An error occurred when updating account status: ${message}`,
            );
        }
    };

    updateMemberExpiration = date => {
        this.memberExpirationDate = getSafe(() => new Date(date).toISOString()) || null;
    };

    updateMemberExpirationDate = async () => {
        const { uid, userType, memberExpirationDateUTC } = this.activeCustomer;
        if (!this.memberExpirationDate) {
            this.interfaceStore.openAlert(true, 'Expiration date should not be empty');
            return;
        }

        if (userType === MEMBER && memberExpirationDateUTC !== this.memberExpirationDate) {
            const { success, message } = await updateMemberExpirationDate(
                uid,
                this.memberExpirationDate,
            );
            const updateStatusMessage = success
                ? `The member expiration date has been successfully updated.`
                : `An error occurred when updating expiration date: ${message}`;
            this.interfaceStore.openAlert(!success, updateStatusMessage);
        }
    };

    lapseMember = async () => {
        const { uid, userType } = this.activeCustomer;

        if (userType === MEMBER) {
            const { success, message } = await lapseMember(uid);
            const updateStatusMessage = success
                ? `The member is lapsed now.`
                : `An error occurred when trying to convert to a lapsed member: ${message}`;
            this.interfaceStore.openAlert(!success, updateStatusMessage);
        }
    };

    checkToRestoreMemberToConsultant = async () => {
        const { phoenixId = null } = this.activeCustomer;
        const { success, data } = await getAccountTypeHistory(phoenixId);

        if (!success) return false;
        const wasConsultant = data.some(type => type.AccountTypeID === 1);

        return wasConsultant && this.isMember;
    };

    restoreConsultant = async () => {
        try {
            const { uid } = this.activeCustomer;
            const { success } = await restoreMemberToConsultant(uid);

            if (success) {
                await this.setActiveCustomer(uid);
                this.interfaceStore.openAlert(
                    false,
                    'Member has been successfully restored to a consultant',
                );
                return success;
            }
            this.interfaceStore.openAlert(true, 'Failed to restore member to consultant');
        } catch (error) {
            this.interfaceStore.openAlert(true, 'Failed to restore member to consultant');
        }
    };

    offboardAccount = async (service, accountInfo) => {
        const result = await offboardEmployeeAccount(service, accountInfo);
        this.employeeAccounts[service] = result;
    };
}

const customerStore = new CustomerStore();

export default CustomerStore;
export { CustomerStore };
