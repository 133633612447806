import { groupBy, map, findIndex, omit, isEmpty, has, get } from 'lodash';
import { routes, devUrl, baseUrl } from '@cs-admin/config';
import queryString from 'query-string';

export function getAbsoluteBaseUrl() {
    if (typeof window !== 'undefined') {
        return `${window.location.protocol}//${window.location.host}`;
    }
    return devUrl;
}

export function createFilterUrl(selected) {
    const grouped = groupBy(selected, 'type'),
        newItems = map(grouped, section => map(section, item => item.slug).join(':')),
        currentPathname = window.location.pathname,
        pathnameItems = currentPathname.split('/'),
        productsIndex = findIndex(pathnameItems, a => a === 'products'),
        newPathnameItems = [...pathnameItems.slice(0, productsIndex + 2), ...newItems];

    return newPathnameItems.join('/');
}

export function getNavigationItemUrl(slug) {
    if (slug.substring(0, 1) != '/') slug = `/${slug}`;

    return `${routes.PRODUCTS}${slug}`;
}

export function removeURLQueries(push = false, custom = false) {
    const loc = custom || location.href;

    if (loc.includes('?')) {
        const newurl = loc.split('?')[0];
        if (push) window.history.pushState({ path: newurl }, '', newurl);
        else return newurl;
    }
    return loc;
}

export function setURLQuery(filters) {
    if (typeof location !== 'undefined') {
        const base = removeURLQueries(false);
        const newQuery = queryString.parse(location.search);

        newQuery.filters = [];

        filters.map((filter, key) => {
            newQuery.filters.push(filter);
        });

        const stringified = queryString.stringify(newQuery);
        const newurl = base + (stringified ? `?${stringified}` : '');

        return newurl;
    }
}

export function removeParam(param) {
    if (typeof location === 'undefined') return false;
    const query = queryString.parse(location.search);
    delete query[param];
    const base = removeURLQueries(false);
    const stringified = queryString.stringify(query);
    const newurl = base + (stringified ? `?${stringified}` : '');
    window.history.replaceState({ path: newurl }, '', newurl);
}

export function removeFilterQueries() {
    const base = removeURLQueries(false);

    const newQuery = queryString.parse(location.search);
    newQuery.filters = [];
    const stringified = queryString.stringify(newQuery);
    const newurl = base + (stringified ? `?${stringified}` : '');

    window.history.pushState({ path: newurl }, '', newurl);
}

/**
 *
 * @description takes a full url address string and return the hostname part only.
 * @param {*} url
 * @param {*} glamorous
 */
export function addressStrip(url, glamorous = false) {
    const address = url.match(/https?:\/\/([^\/]+)/);
    return glamorous ? address[1] : address[0];
}

/**
 * @description takes a full url address and returns the params only with no hostname.
 * @param {*} url
 */
export function hostnameStrip(url) {
    // Create a regex to match protocol, domain, and host
    const matchProtocolDomainHost = /^.*\/\/[^\/]+:?[0-9]?\//i;
    return url.replace(matchProtocolDomainHost, '');
}

export function updateURL(color) {
    const newurl = `${removeVariantArgs(false)}/variant-${color}`;
    window.history.pushState({ path: newurl }, '', newurl);
}

export function removeVariantArgs(push = false) {
    const loc = location.href || '';
    if (loc.includes('variant-')) {
        const newurl = loc.split('variant-')[0];
        if (push) window.history.pushState({ path: newurl }, '', newurl);
        else return newurl.replace(/\/$/, '');
    }
    return loc.replace(/\/$/, '');
}

export function parseContentURL(url) {
    if (url.substring(0, 1) != '/') url = `/${url}`;

    return url;
}

export function removeStartSlash(path) {
    if (path.substring(0, 1) === '/') return path.substr(1);
    return path;
}

export function removeEndSlash(path, removeOnEmpty) {
    const clipEmptySlash = removeOnEmpty ? true : path !== '/';
    if (path.slice(-1) === '/' && clipEmptySlash && path != '') return path.slice(0, -1);
    return path;
}

export function getPageUrl(route) {
    return typeof window != 'undefined' ? window.location : '';
}

export function initQueryString(str = '') {
    return ['?', '&', undefined].includes(str[0]) ? str : `?${str}`;
}

/**
 * Flatten queryString with nested `?`
 */
export function flattenQueryString(query) {
    return query.replace(/\?/g, '&').replace(/\&/, '?');
}

/**
 * Does the samething as flattenQueryString,
 * but can take multiple Strings.
 * @param {String} query
 * @param {String} queries
 */
export function joinQueryStrings(query, ...queries) {
    const joined = [query, ...queries].map(query => initQueryString(query)).join('');

    return flattenQueryString(joined);
}

export function omitQueryParam(string, key) {
    const parsedQuery = queryString.parse(string);
    return queryString.stringify(omit(parsedQuery, key));
}

export function queryParamExists(query, key) {
    return has(queryString.parse(query), key);
}

export function getQueryParamWithKey(query, key) {
    return get(queryString.parse(query), key, '');
}

export function safelyUnnestQueryString(initialQuery) {
    const [ignore, ...nestedParams] = initialQuery.split(/\?/g);
    const newParams = !isEmpty(null || nestedParams)
        ? nestedParams
              .map(_ => queryString.parse(`?${_}`))
              .reduce((a = {}, b = {}) => ({ ...a, ...b }))
        : {};

    return newParams;
}

export function getNativeProductUrl(slug, variant = '') {
    const variantUrl = variant ? `/variant-${variant}` : variant;
    return `${routes.PRODUCT}/${slug}${variantUrl}`;
}

function splitAndMarryString(str, char) {
    const encodedChar = encodeURI(char),
        splitStr = str.split(encodedChar),
        marriedStr = splitStr.join(char);

    return marriedStr;
}

export function generateQueryString(queryObject, encode = true, char = false) {
    const encodedString = queryString.stringify(queryObject, { encode: false });

    const mainString = char ? splitAndMarryString(encodedString, char) : encodedString;

    return encode ? mainString : decodeURIComponent(mainString);
}

export function createValidPath(path, length) {
    if (typeof path !== 'string') return '';

    const sanitized = path
        .substr(0, length || path.length)
        .replace(/[^A-Za-z0-9_-]/g, '-')
        .toLowerCase();

    return sanitized.substr(-1) === '-' ? sanitized.substr(0, sanitized.length - 1) : sanitized;
}

export function updateLocaleURL(selectedLocaleCode) {
    if (typeof window !== 'undefined') {
        const newurl = getLocaleURL(selectedLocaleCode) + location.search;

        if (window.location.pathname !== newurl) {
            window.history.pushState({ path: newurl }, newurl, newurl);
            return true;
        }
    }
}

export function unlocalizeURL(path = '') {
    let newurl = null;

    for (let i = 0; i < locales.length; i++) {
        if (path.includes(`${locales[i].code.toLowerCase()}/`)) {
            if (!locales[i].default) {
                path = path.replace(`${locales[i].code.toLowerCase()}/`, '');
                newurl = path;
            }
        }
    }
    if (!newurl) {
        newurl = path;
        if (newurl.charAt(0) != '/') newurl = `/${newurl}`;
    }

    // Remove slashes at the end except for homepage
    newurl = removeEndSlash(newurl);

    return newurl;
}
