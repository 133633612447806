import { nogento, phoenix } from '@cs-admin/config';
import firebase from '@cs-admin/services/firebase/init';
import { getUserToken } from '@cs-admin/services/user';
import { fetchWithTimeout } from '@cs-admin/services/fetch';
// fetch does not exist as a function in @cs-admin/services/fetch that is why i am importing it from the package
import fetch from 'node-fetch';

const CONSULTANT_SEARCH_CHUNK_SIZE = 10;

// Describes account id pattern. E.g.: 2333213, M123 (<- old version)
const ACCOUNT_ID_PATTERN = /^\d+$|^M\d+$/;

export async function getAccountInfo(uid) {
    try {
        const database = await firebase.firestore();

        return await database
            .collection('users')
            .doc(uid)
            .get()
            .then(doc => {
                const data = doc.data();
                return {
                    uid: doc.id,
                    ...data,
                };
            });
    } catch (error) {
        console.log(error);
        return null;
    }
}

/**
 * Searches consultant first and last names
 * and returns an array of results
 * @param {string} entry
 * @param {number} limit
 */

export async function searchConsultantNames(
    entry,
    limit = CONSULTANT_SEARCH_CHUNK_SIZE,
    country = 1,
) {
    const endpoint = `${phoenix.search}/Account/Open/QuickNameSearchConsultants`;
    const searchParam = ACCOUNT_ID_PATTERN.test(entry) ? 'account' : 'q';
    const searchUrl = `${endpoint}?${searchParam}=${entry}&take=${limit}&countryid=${country}`;

    try {
        const response = await fetch(searchUrl);
        const json = await response.json();
        if (json.success) return json.data;
    } catch (e) {
        console.info(e);
        return false;
    }
}

export async function getCustomerCards(country, uid) {
    const token = await getUserToken();
    const response = await fetch(`${nogento.counterbase}/cs/customerCards/${uid}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ country }),
    });
    return response.json();
}

async function fetchEstimate(context) {
    const token = await getUserToken();
    const options = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(context),
    };

    const response = await fetchWithTimeout(
        `${nogento.checkout}/checkout/estimate`,
        options,
        15000,
    );

    const data = await response.json();

    return data;
}

export async function getEstimate(context) {
    try {
        let data;
        // We only run step 3 if there is a shipping method
        if (
            context.cart.shipping.method &&
            (context.payment.splitPayments[0].nonce != '' ||
                context.payment.splitPayments[0].cardId != '')
        ) {
            context.step = 3;
        }
        data = await fetchEstimate(context);
        console.info('Estimate information:', context);

        return data;
    } catch (error) {
        console.log(error);
        return { success: false };
    }
}

export async function submitPortalOrder(context) {
    try {
        const token = await getUserToken();
        const response = await fetch(`${nogento.counterbase}/cs/purchase`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(context),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.info(error);
        return { success: false };
    }
}

export async function verifyOrderForEnrollment(context) {
    try {
        const token = await getUserToken();
        const response = await fetch(`${nogento.counterbase}/cs/enrollments/verify`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(context),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        return { success: false };
    }
}
