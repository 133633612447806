import firebase from '@cs-admin/services/firebase/init';
import { nogento, flags } from '@cs-admin/config';
import fetch from '@cs-admin/services/fetch';

/**
 * Returns a one time snapshot of the user's
 * informtion from the user table
 * @param {string} uid
 */

export async function getUserDataSnapshot(uid, meta = {}) {
    try {
        const database = await firebase.firestore();

        const snapshot = await database
            .collection('cs-users')
            .doc(uid)
            .get();

        const data = snapshot.data();

        return { ...data, ...meta, uid };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Invokes a callback with the user's data
 * each time it is modified in realtime
 * @param {function} callback
 */

export async function subscribeToUserData(callback) {
    const database = await firebase.firestore();
    const auth = await firebase.auth();

    if (auth && auth.currentUser) {
        const uid = auth.currentUser.uid;

        database
            .collection('cs-users')
            .doc(uid)
            .onSnapshot(data => callback(() => ({ ...data.data(), uid })), e => null);
    } else callback();
}

/**
 * Writes specified data to user entry
 * @param {object} data
 * @param {object} options
 */

export async function writeUserData(data, options = {}) {
    const database = await firebase.firestore();
    const auth = await firebase.auth();

    if (auth.currentUser && auth.currentUser.uid) {
        const profileChange = await checkProfileChange(data);
        if (!profileChange.success) return profileChange;

        try {
            await database
                .collection('cs-users')
                .doc(auth.currentUser.uid)
                [options.set ? 'set' : 'update'](data);

            return {
                success: true,
            };
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    }
}

/**
 * Logs user into Firebase Auth.
 * If it fails, we will check Phoenix for a login
 * as a backup.
 * @param {string} email
 * @param {string} password
 */

export async function login(email, password) {
    const auth = await firebase.auth();
    const { cleanLogin } = flags;
    let session, meta;

    try {
        const url = `${nogento.base}/auth/login`;
        const token = await getUserToken();
        const options = {
            method: 'POST',
            body: JSON.stringify({
                email,
                password,
            }),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        };

        if (cleanLogin) {
            const request = await fetch(url, options);
            const response = await request.json();
            const { success } = response;
            if (!success) return response;
        }

        session = await auth.signInWithEmailAndPassword(email, password);
        if (!session.user) throw session;

        return getUserDataSnapshot(session.user.uid, meta);
    } catch (e) {
        console.error(e);
        return {
            success: false,
            e,
        };
    }
}

/**
 * Logs user out and then invokes specified
 * callback on success
 * @param {function} callback
 */

export async function logout(callback = () => null) {
    const auth = await firebase.auth();
    return auth.signOut().then(() => callback());
}

/**
 * Checks whether user is logged in and invokes
 * callback if true
 * @param {function} callback
 */

export async function subscribeIsLogged(callback) {
    const auth = await firebase.auth();

    auth.onAuthStateChanged(session => {
        if (session && session.uid) {
            callback(() => getUserDataSnapshot(session.uid));
            subscribeToUserData(callback);
        } else callback(() => false);
    });
}

/**
 * Checks whether email exists in the Auth table
 * @param {string} input
 */

export async function checkEmailExists(input = '') {
    try {
        const request = await fetch(`${nogento.base}/auth/checkEmailExists?email=${input}`);
        return request.json();
    } catch (e) {
        return {
            success: false,
            message: e,
        };
    }
}

/**
 * Applies email address change to Firebase Auth
 * if it is different
 * @param {object} data
 */

export async function checkProfileChange(data) {
    const auth = await firebase.auth();

    const session = auth.currentUser;
    if (data.email && data.email !== session.email) {
        try {
            await session.updateEmail(data.email);
            return {
                success: true,
            };
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    } else
        return {
            success: true,
        };
}

export async function resetPassword(data) {
    const auth = await firebase.auth();

    try {
        await auth.sendPasswordResetEmail(data.email);

        return {
            success: true,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function forgotPassword(data) {
    try {
        const response = await fetch(`${nogento.base}/accounts/forgotPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        return response.json();
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function confirmPasswordReset(query = {}) {
    const auth = await firebase.auth();

    try {
        await auth.confirmPasswordReset(query.oobCode, query.password);
        return {
            success: true,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function getUserToken() {
    const auth = await firebase.auth();

    return auth.currentUser && auth.currentUser.getIdToken();
}

export async function getEmail() {
    const auth = await firebase.auth();

    return auth.currentUser && auth.currentUser.email;
}

export async function getUid() {
    const auth = await firebase.auth();

    return auth.currentUser && auth.currentUser.uid;
}

export async function getDisplayName() {
    const auth = await firebase.auth();

    return auth.currentUser && auth.currentUser.displayName;
}
