const defaultCurrency = {
    name: 'USD',
    format: '$0',
    rounding: true,
};

export function formatPrice(price, currency = defaultCurrency) {
    let formattedPrice = parseFloat(price);
    if ((typeof currency.rounding != 'undefined' && !currency.rounding) || formattedPrice % 1 != 0)
        formattedPrice = formattedPrice.toFixed(2);

    let output = JSON.parse(JSON.stringify(currency.format));

    // format negatives
    if (price < 0) {
        output = `-${output}`;
        formattedPrice *= -1;
    }

    return output.replace('0', formattedPrice.toString());
}

export function formatPriceFull(price = 0, currency = defaultCurrency) {
    const negativePrice = price < 0;

    const priceMultiplier = negativePrice ? -1 : 1;
    const formattedPrice = parseFloat(price * priceMultiplier).toFixed(2);

    const negativeSymbol = negativePrice ? '-' : '';
    const priceWithCurrency = `${negativeSymbol}${currency.format.replace(
        '0',
        formattedPrice.toString(),
    )}`;
    return priceWithCurrency;
}

export function getCurrencySymbol(abbreviation) {
    return CURRENCY_SIGN[abbreviation];
}

export function calculatePrice(initialPrice, discount) {
    return initialPrice * (1 - discount);
}
