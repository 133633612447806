export const CUSTOMER = 16,
    PERSONAL = 2,
    SOCIAL = 3,
    PORTAL = 4,
    RETURN = 8,
    REPLACEMENT = 10,
    ENROLLMENT = 11,
    EMPLOYEE = 12,
    BCCOM = 17,
    RETAIL = 18,
    ADJUSTMENT = 20;

export const ORDER_TYPES = {
    2: 'Personal Order',
    3: 'Social Order',
    4: 'Portal Order',
    6: 'Auto-replenishment',
    8: 'Return Order',
    10: 'Replacement Order',
    11: 'Enrollment Order',
    12: 'Employee Order',
    16: 'Customer Order',
    17: 'BCCOM Order',
    18: 'Retail Order',
    20: 'Adjustment Order',
};
