/**
 * Synonym mappings.
 *
 * These should be moved into Contentful and loaded
 * externally to prevent payload size from becoming too
 * large, and also to support localization.
 */

export const synonymList = {
    facial: ['face'],
    face: ['facial'],
    tinted: ['tint', 'tinting'],
    moisturizer: ['moisturize', 'moisturizing', 'moisturizers'],
    moisturizing: [
        'moisturize',
        'moisturizer',
        'moisturizers',
        'moisturise',
        'moisturiser',
        'moisturisers',
    ],
    lotion: ['lotions'],
    serum: ['serums'],
    scrub: ['scrubs'],
    wash: ['washes', 'hand soap', 'soaps', 'soap'],
    cleansing: ['cleanser', 'cleansers'],
    cleanser: ['cleansing'],
    plumbing: ['plumper'],
    lip: ['lips'],
    mattifying: ['mattifying'],
    brow: ['eyebrow'],
    mask: ['masks'],
    brush: ['brushes'],
    oil: ['oils', 'oily'],
    concealer: ['concealers'],
    brightening: ['lightening', 'lightener', 'brightener'],
    shampoo: ['shampoos'],
    conditioner: ['conditioners'],
    protect: ['protective'],
    bronzer: ['bronzers', 'powder', 'powders'],
    volumizing: ['volumizer'],
    volume: ['volumizer', 'volumizing'],
    smooth: ['smoothening'],
    repair: ['repairing'],
    sunscreen: ['sunblocker', 'sunscren'],
    foundation: ['foundations'],
    flat: ['foundations', 'foundation'],
    dew: ['foundations', 'foundation'],
    hydrating: ['moisturizing', 'moisturizer', 'moisturize'],
    cloths: ['wipes'],
    baby: ['babies'],
    collection: ['collections'],
    bag: ['bags'],
    côte: ['cote'],
    mascara: ['mascaras'],
    lipstick: ['lipsticks'],
    blush: ['blushes', 'powder', 'powders'],
    eyeliner: ['liner', 'liquid eyeliner', 'liquid eyeliners', 'liners', 'eyeliners'],
    pencil: ['liner', 'liners', 'eyeliner', 'eyeliners'],
    eyeshadow: ['eyeshadows', 'powder', 'powders'],
    gloss: ['lipgloss'],
};
