import { contentful } from '@cs-admin/config';
import fetch from '@cs-admin/middleware/api/fetch';
import queryString from 'query-string';

/**
 * Fetches Contentful schema
 * @param {Object} options
 */
function getContentful(options, drafts) {
    const space = contentful.space;
    const accessToken = drafts ? contentful.previewAccessToken : contentful.accessToken;
    const host = drafts ? 'https://preview.contentful.com' : 'https://cdn.contentful.com';

    options.access_token = accessToken;
    const query = `${host}/spaces/${space}/entries?${queryString.stringify(options)}`;

    return fetch(query);
}

export default getContentful;
