export function validByUser(data, userType) {
    if (data.userTypes && !userType) return;
    const now = Date.now();

    // If the product has a counterbase flag, we want to include the product regardless of user type.
    // If the flag is not present, we want to follow the rest of the user type checks.
    const counterbaseFlag = data && data.flags && data.flags.includes('counterbase');
    if (counterbaseFlag) return true;

    if (
        data.userTypes &&
        Array.isArray(data.userTypes) &&
        data.userTypes.length &&
        !data.userTypes.some(({ userType: userTypeAllowed, visibleDateRange }) => {
            if (userTypeAllowed === String(userType) || userTypeAllowed === Number(userType)) {
                if (!visibleDateRange) return true;
                const { fromDate, toDate } = visibleDateRange;

                const from = createDateObject(fromDate);
                const to = createDateObject(toDate);
                if ((!from || now > from) && (!to || now < to)) return true;
            }
        })
    )
        return;
    return true;
}

export function createDateObject(date) {
    if (!date) return null;
    const split = date.split('-');
    const dateObject = new Date(split[0], Number(split[1]) - 1, split[2]);
    return dateObject;
}
