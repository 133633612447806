import { observable, computed, action, toJS } from 'mobx';
import { getSafe } from '@cs-admin/utils/object';
import { isEmpty } from 'lodash';
import { getPromoPricing, getRulesetStatus } from '@beautycounter/bc-cart-engine';
import { CONSULTANT } from '@beautycounter/constants/userStates';

class PromoStore {
    @observable promoGraph;
    @observable promosByID;
    @observable promosByPromoId;
    @observable currentGWP;
    @observable activatedPromos;
    @observable currentGWPChoices;
    @observable promoCartProductsByID;
    @observable promoCartTotal;
    @observable discountTotal;

    constructor() {
        this.promoGraph = {};
        this.promosByID = {};
        this.promosByPromoId = {};
        this.currentGWPChoices = [];
        this.activatedPromos = {};
        this.promoCartProductsByID = null;
        this.unpromofiedCartItemsById = {};
        this.previousCartTotal = 0;
        this.recentlyUpdatedId = null;
        this.promoCartTotal = 0;
        this.discountTotal = 0;
    }

    @computed
    get isGWPActive() {
        return false;
    }

    // called by pdp add button to determine where the promo page is located
    @action navigationForProductID = id => `/${getSafe(() => toJS(this.promosByID[id]).url)}`;

    @action isItemPromoEligible = () => false;

    @action
    getPromoPrice = (product = {}) => {
        const rules = this.cartStore.cartEngine.ruleset;

        const context = getSafe(() => this.stores.cartStore.buildContext);
        context.product = product;
        return getSafe(() => getPromoPricing(context, rules)) || null;
    };

    @action
    getHostHalfOffPrice = (product = {}) => {
        const rules = [
            {
                id: 0,
                active: true,
                discountValue: [0.5],
                exclusionItems: [],
                name: 'HostHalfOff',
                priority: 100,
                stop: true,
            },
        ];

        const context = getSafe(() => this.stores.cartStore.buildContext);
        context.product = product;
        return getSafe(() => getPromoPricing(context, rules)) || null;
    };

    @action
    getPromoInfo = (product = {}) => {
        const {
            appliedPromos = [],
            price = product.price,
            sku = product.sku,
            totalPrice = product.totalPrice,
        } = getSafe(() => this.getPromoPrice(product)) || {};
        const content = getSafe(() => this.contentStore.content.cart[0].promoContentData) || {};

        return (
            {
                appliedPromos,
                price,
                sku,
                totalPrice,
                content,
            } || {}
        );
    };

    @action
    getPromoContentFromSelection = (selection = {}) => {
        const content = getSafe(() => this.contentStore.content.cart[0].promoContentData) || {};
        return getSafe(() => content[selection.name]);
    };

    @computed
    get observePromoStateChanges() {
        return [this.portalOrderStore.locale, this.cartStore.userType, this.cartStore.cartEngine];
    }

    @computed
    get promoRulesetStatus() {
        const { buildContext, cartEngine } = this.cartStore;
        return getSafe(() => getRulesetStatus(buildContext, cartEngine.ruleset)) || [];
    }

    promoTypeHasBehavior = () => false;

    getStatusOfPromosByName(names) {
        return this.promoRulesetStatus.filter(promoRule => names.includes(promoRule.name));
    }

    /**
     * Helper function check all statuses of promos in CartEngine
     * @param {Array} names
     */
    isPromoValidByNames(names) {
        return (
            Array.isArray(names) && this.getStatusOfPromosByName(names).some(promo => promo.isValid)
        );
    }
}

const promoStore = new PromoStore();

export default promoStore;
export { PromoStore };
