import firebase from '@cs-admin/services/firebase/init';
import { orderBy, isEqual } from 'lodash';
import { getSafe } from '@beautycounter/utils/object';
import { getProductCredit } from '@cs-admin/services/productCredit';
import customerTypes from '@cs-admin/constants/customerTypes';
import fetch from '@cs-admin/services/fetch';
import { nogento } from '@cs-admin/config';
import { ssnDigitError, ssnTemplate } from '@cs-admin/constants/ssnValidation';
import { getUid, getUserToken } from '@cs-admin/services/user';
import { COUNTERBASE } from '@beautycounter/constants/channels';
import { getUserAccountInfo } from '@cs-admin/services/customer/graphql';
import { titlesQuery } from '@cs-admin/services/customer/query';
import accountStatuses from '@cs-admin/constants/accountStatuses';
import { getUserPwsInfo } from '@cs-admin/services/pws';
import { getCustomerTaxNumber } from '@cs-admin/services/taxNumbers';
import { generateQueryString } from '@cs-admin/utils/url';
import { BEAUTYCOUNTER_HQ } from '@beautycounter/constants/beautycounterHQ';

export async function checkValidClientsAndMembers(accountId) {
    try {
        const url = `${
            nogento.counterbase
        }/cs/account/${accountId}/checkValidClientsAndMembersToBeRestored`;
        const token = await getUserToken();
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            const json = await response.json();
            return json;
        }
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function restoreClientsAndMembers(accountId) {
    try {
        const url = `${nogento.counterbase}/cs/account/${accountId}/restoreClientsAndMembersFromHQ`;
        const token = await getUserToken();
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            const json = await response.json();
            return json;
        }
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function checkValidDownline(accountId) {
    try {
        const url = `${
            nogento.counterbase
        }/cs/consultant/${accountId}/isRestoreDownlineConsultantsAvailable`;
        const token = await getUserToken();
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            const json = await response.json();
            return json;
        }
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function restoreDownline(accountId) {
    try {
        const url = `${nogento.counterbase}/cs/consultant/${accountId}/restoreDownlineConsultants`;
        const token = await getUserToken();
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            const json = await response.json();
            return json;
        }
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function customersSearch(searchParams) {
    try {
        const searchQueryParams = generateQueryString(searchParams);
        const searchCustomersURL = `${nogento.counterbase}/cs/customers?${searchQueryParams}`;
        const token = await getUserToken();
        const searchCustomersResponse = await fetch(searchCustomersURL, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return searchCustomersResponse.json();
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Returns all information of customer including
 * product credit, consultant, and order info
 * @param {string} id
 */
export async function getCustomerInfo(id) {
    try {
        const database = await firebase.firestore();
        const customer = await database
            .collection('users')
            .doc(id)
            .get()
            .then(snapshot => snapshot.data());
        const customerPhoenixId = customer.phoenixId;
        const productCredit = await getProductCredit(id);
        const phoenixInfo = customerPhoenixId && (await phoenixAccountInfo(customerPhoenixId));
        const countryIdInPhoenix = phoenixInfo && phoenixInfo.countryId;
        if (phoenixInfo && !phoenixInfo.sponsorId) {
            phoenixInfo.sponsorId = BEAUTYCOUNTER_HQ;
        }
        const consultant = phoenixInfo && (await getUserByPhoenixId(phoenixInfo.sponsorId));
        const { titles: customerTitles = {} } =
            customer && (await getUserAccountInfo(customerPhoenixId, titlesQuery));
        if (phoenixInfo && !phoenixInfo.enrollerId) {
            phoenixInfo.enrollerId = BEAUTYCOUNTER_HQ;
        }
        const enroller = phoenixInfo && (await getUserByPhoenixId(phoenixInfo.enrollerId));
        const ssnError = await ssnValidation(id);
        const comments = customer.comments ? customer.comments : [];

        if (ssnError && !hasSsnComment(comments, ssnError)) {
            comments.unshift({
                uid: 'SYSTEM',
                date: new Date().toLocaleString(),
                description: ssnError,
            });

            await updateCustomerInfo(id, comments);
            customer.comments = comments;
        }

        const customerPhone = getSafe(() => phoenixInfo.phones[0].phoneNumber) || null;
        const {
            genderId,
            accountStatusId,
            enrollerId,
            enrollmentDateUTC: phoenixEnrollmentDateUTC,
            nextRenewalDateUTC: phoenixRenewalDateUTC,
        } = phoenixInfo || {};

        const { enrollmentDateUTC, nextRenewalDateUTC } = customer || {};

        const pws = (await getUserPwsInfo(customerPhoenixId)) || {};
        const {
            ssn = '',
            sin = '',
            taxId = '',
            businessNumber = '',
            entityName = '',
        } = await getCustomerTaxNumber({
            phoenixId: customerPhoenixId,
        });

        return {
            ...customer,
            uid: id,
            productCredit,
            consultant,
            customerTitles,
            enrollerId,
            customerPhone,
            enroller,
            enrollmentDateUTC: enrollmentDateUTC || phoenixEnrollmentDateUTC,
            nextRenewalDateUTC: nextRenewalDateUTC || phoenixRenewalDateUTC,
            accountStatus: accountStatusId,
            countryIdInPhoenix,
            genderId,
            pws,
            ssn,
            sin,
            taxId,
            businessNumber,
            entityName,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Returns the string translation of a
 * customer's accountStatus
 * @param {number} accountStatus
 */

export function getAccountStatus(accountStatus) {
    return accountStatus ? accountStatuses[accountStatus].name : 'N/A';
}

/**
 * Returns the string translation of a
 * customer's userType
 * @param {string} customerType
 */
export function customerType(customerType) {
    switch (customerType) {
        case 6:
            return 'Partner';
        case 7:
            return 'Not Logged In';
        default:
            return customerTypes[customerType] ? customerTypes[customerType].name : 'Unavailable';
    }
}

/**
 * Returns the item of the customer's
 * address if it exists
 * @param {object} customer
 * @param {string} item
 */
export function customerAddressInfo(customer, item) {
    return customer.shippingAddresses && customer.shippingAddresses.length
        ? customer.shippingAddresses.map(address => {
              if (address.isDefaultShipping === 1) {
                  return address[item];
              }
          })
        : 'N/A';
}

export function getOperatorName(id, admins) {
    let name = id;

    admins.map(admin => {
        if (admin.id == id) {
            name = `${admin.firstName} ${admin.lastName}`;
        }
    });

    return name;
}

/**
 * Returns list of admin users
 */
export async function getAdministration() {
    try {
        const admins = [];
        const database = await firebase.firestore();
        const adminData = await database
            .collection('cs-users')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    admins.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
            });

        return admins;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Returns string translation of payment type
 * @param {string} paymentType
 */
export function paymentType(paymentType) {
    return PaymentTypes[paymentType].name;
}

/**
 * Gets the user by phoenixId
 * Returns a string of their fullname and uid in firebase
 * @param {string} phoenixId
 */
export async function getUserByPhoenixId(phoenixId) {
    try {
        let consultant = {};
        const database = await firebase.firestore();
        const consultantData = await database
            .collection('users')
            .where('phoenixId', '==', phoenixId)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    const data = doc.data();
                    consultant = {
                        phoenixId: data.phoenixId,
                        fullName: `${data.firstName} ${data.lastName}`,
                        uid: doc.id,
                    };
                });
            });
        return consultant;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Updates email in firebase auth table
 * @param {string} email
 * @param {string} uid
 */
export async function updateCustomerEmail(email, uid) {
    const updateEmailUrl = `${nogento.base}/auth/updateEmail`;
    const updateToken = await getUserToken();

    return fetch(updateEmailUrl, {
        method: 'POST',
        body: JSON.stringify({
            email,
            uid,
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${updateToken}`,
        },
    });
}

export async function updateCustomerEnrollerId({ accountId, enrollerId }) {
    try {
        const updateEnrollerUrl = `${nogento.counterbase}/cs/enrollerId/?accountId=${accountId}`;
        const token = await getUserToken();
        const updateEnrollerIdResponse = await fetch(updateEnrollerUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ enrollerId }),
        });
        return updateEnrollerIdResponse.json();
    } catch (error) {
        console.error(error);
        return { success: false };
    }
}

/**
 * Updates customer info with new data
 * @param {string} uid
 * @param {object} data
 */

export async function updateCustomerInfo(customerId, data) {
    try {
        const database = await firebase.firestore();
        return await database
            .collection('users')
            .doc(customerId)
            .update({
                ...data,
                lastUpdatedUID: await getUid(),
            })
            .then(() => ({
                success: true,
            }));
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function updateMembershipStatus(uid, upgradeParams) {
    try {
        const token = await getUserToken();

        const url = `${nogento.counterbase}/cs/membership/status/${uid}`;
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(upgradeParams),
        };
        const response = await fetch(url, options);
        return await response.json();
    } catch (error) {
        return { success: false };
    }
}

async function ssnValidation(uid) {
    const token = await getUserToken();

    const response = await fetch(`${nogento.counterbase}/cs/invalid-tax-number/attempts/${uid}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status == 200) {
        const json = await response.json();
        const attempts = json.data.attempts ? json.data.attempts : [];

        if (attempts.length && !isEqual(attempts[attempts.length - 1].message, ssnDigitError)) {
            return `${ssnTemplate} ${attempts[attempts.length - 1].message}`;
        }
    }
}

function hasSsnComment(comments, error) {
    let hasError = false;

    comments.map(comment => {
        if (isEqual(comment.description, error)) {
            hasError = true;
        }
    });

    return hasError;
}

async function phoenixAccountInfo(id) {
    const token = await getUserToken();
    const response = await fetch(`${nogento.base}/accounts/getAccountInfo/${id}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
        const json = await response.json();
        return json.data;
    }

    return {};
}

export async function resendTcsAcceptanceEmail(email, orderId) {
    const token = await getUserToken();

    const response = await fetch(`${nogento.counterbase}/cs/resendTcsAcceptanceEmail/${orderId}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
    });

    const json = await response.json();

    return { ...json };
}

export async function getCustomerAuthToken(uid) {
    const token = await getUserToken();

    const response = await fetch(`${nogento.counterbase}/cs/account/${uid}/custom-token`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    return response.json();
}

export async function updateAccountStatus(uid, status) {
    const token = await getUserToken();

    const response = await fetch(`${nogento.counterbase}/cs/updateStatus/${uid}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
    });

    return response.json();
}

export async function getAccountLogs(uid) {
    try {
        const accountLogs = [];
        const database = await firebase.firestore();
        await database
            .collection('account-log')
            .doc(uid)
            .collection('events')
            .get()
            .then(snapshot => {
                snapshot.forEach(logs => {
                    accountLogs.push({
                        id: logs.id,
                        ...logs.data(),
                    });
                });
            });
        const sortedLogs = orderBy(accountLogs, ['dateChangedUTC'], ['desc']);

        const formattedLogs = [];
        // this loops through the differences array property and makes each change a seperate
        // object consisting of the change and the root elements (changedBy, Collection, date)
        // so for each difference, it will create and object of { changedby, collection, date, difference }
        // and push into formattedLogs array
        for (const entry of sortedLogs) {
            const { id, changedBy, collection, dateChangedUTC, differences } = entry;

            // kind is action, lhs is old, rhs is new, path column name
            for (const difference of differences) {
                // my temp object used as a template to be pushed into [formattedLogs]
                const objectTemplate = {
                    id,
                    changedBy,
                    collection,
                    dateChangedUTC,
                };

                const { kind, lhs, rhs, path } = difference;
                objectTemplate.action = kind;
                objectTemplate.oldValue = lhs;
                objectTemplate.newValue = rhs;
                objectTemplate.columnName = path;

                formattedLogs.push(objectTemplate);
            }
        }
        return {
            status: 'success',
            data: formattedLogs,
        };
    } catch (error) {
        console.log('error', error);
        return {
            status: 'error',
            data: null,
        };
    }
}

export async function getUserDataByUniqueId(uid) {
    try {
        let user = {};
        const database = await firebase.firestore();
        const userData = await database
            .collection('users')
            .doc(uid)
            .get()
            .then(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    user = {
                        id: uid,
                        fullName: `${data.firstName} ${data.lastName}`,
                        // ...data
                    };
                }
            });
        return user;
    } catch (error) {
        console.log('error', error);
        return null;
    }
}

export async function updatePhoenixSponsorId(phoenixId, sponsorId) {
    const token = await getUserToken();

    const response = await fetch(`${nogento.counterbase}/cs/account/${phoenixId}/${sponsorId}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    return response;
}

export async function updateMemberExpirationDate(uid, expirationDate) {
    const token = await getUserToken();

    const response = await fetch(`${nogento.counterbase}/cs/membership/expiration/${uid}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ expirationDate }),
    });

    return response.json();
}

export async function lapseMember(uid) {
    const token = await getUserToken();

    const response = await fetch(`${nogento.counterbase}/cs/membership/lapseMember/${uid}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    return response.json();
}

/**
 * Trigger forgot password email to send
 * @param {string} email
 */
export async function triggerForgotPassword(email) {
    const forgotPassword = `${nogento.base}/accounts/forgotPassword`;
    const updateToken = await getUserToken();

    const response = await fetch(forgotPassword, {
        method: 'POST',
        body: JSON.stringify({
            email,
            channel: COUNTERBASE,
            localeCode: 'en-US',
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${updateToken}`,
        },
    });

    const json = await response.json();

    return { ...json };
}

export async function getAccountTypeHistory(phoenixId) {
    try {
        const token = await getUserToken();

        const response = await fetch(
            `${nogento.counterbase}/cs/account/accountTypeHistory/${phoenixId}`,
            {
                method: 'GET',
                headers: { Authorization: `Bearer ${token}` },
            },
        );
        const json = await response.json();
        return json;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function restoreMemberToConsultant(uid) {
    try {
        const token = await getUserToken();
        const response = await fetch(
            `${nogento.counterbase}/cs/account/restoreMemberToConsultant/${uid}`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        if (response.status === 200) {
            const json = await response.json();
            return json;
        }
    } catch (error) {
        return {
            success: false,
            error,
            message: 'message',
        };
    }
}

export async function compressConsultant(accountId) {
    try {
        const token = await getUserToken();
        const response = await fetch(`${nogento.counterbase}/cs/commissions/compressAccountById`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ accountId: accountId.toString() }),
        });
        const json = await response.json();
        return json;
    } catch (error) {
        return {
            success: false,
            error,
            message: `Internal Error. ${error}`,
        };
    }
}

export async function changeCountry({ countryId, accountId }) {
    const token = await getUserToken();
    const response = await fetch(`${nogento.counterbase}/cs/commissions/changeCountry`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accountId: accountId.toString(), countryId }),
    });
    const json = await response.json();
    return json;
}

export async function compressDownline(accountId) {
    try {
        const token = await getUserToken();
        const response = await fetch(`${nogento.counterbase}/cs/compressDownline`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ accountId: accountId.toString() }),
        });

        const json =
            response.status === 403
                ? { success: false, code: 'INSUFFICIENT_PERMISSIONS' }
                : await response.json();

        return json;
    } catch (error) {
        return {
            success: false,
            error,
            message: `Internal Error. ${error}`,
            code: 'SPONSOR_COMPRESSION_ERROR',
        };
    }
}
