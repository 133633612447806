import queryString from 'query-string';
import uuidv4 from 'uuid/v4';
import { throttle } from 'lodash';
import { computed, action, observable } from 'mobx';

import { nogento } from '@cs-admin/config';
import fetch from '@cs-admin/middleware/api/fetch';

import { getCurrentPosition } from './geolocation';

const bySelectedCountry = country => place => {
    const placeCountry = place.description.split(' ').pop();
    return placeCountry === country;
};

export default class Autocomplete {
    static query = throttle(query, 100);
    static getPlace = throttle(find, 100);

    @observable lastQuery = [];
    @observable selectedPlace = {};
    @observable mostRecentPosition = {};
    sessiontoken = '';

    pendingSearch = null;

    constructor() {
        this.initNewSession();
        this.updateGeolocation();
    }

    clearSearch() {
        this.initNewSession();
        this.pendingSearch = null;
        this.lastQuery = [];
        this.mostRecentPosition = {};
        this.selectedPlace = {};
    }

    @computed
    get queryResults() {
        return this.lastQuery || [];
    }

    @action
    async updateGeolocation() {
        this.geolocation = await getCurrentPosition();
        return this.geolocation;
    }

    @action
    initNewSession() {
        // TODO: cache this token at the application level.
        this.sessiontoken = uuidv4();
    }

    /**
     * Search place API for array of places matching string.
     * @param {string} value
     */
    @action
    search = async (value = '', country) => {
        if (value) {
            // const { latitude, longitude, accuracy } = (await this.updateGeolocation()).coords;
            this.pendingSearch = Autocomplete.query({
                q: value,
                // lat: latitude,
                // lng: longitude,
                // radius: accuracy,
                sessiontoken: this.sessiontoken,
            });

            const autocompletedPlaces = (await this.pendingSearch).data || [];
            const selectedCountry = country === 'United States' ? 'USA' : country;
            this.lastQuery = autocompletedPlaces.filter(bySelectedCountry(selectedCountry));
        }
    };

    /**
     * Set selectedPlace to promise that will
     * resolve to the place that matches the provided ID.
     * @param {string} place_id
     */
    @action
    getPlace = async place_id => {
        if (place_id && place_id !== this.selectedPlace.place_id) {
            this.selectedPlace = await Autocomplete.getPlace({
                place_id,
                sessiontoken: this.sessiontoken,
            }).then(res => {
                this.initNewSession();
                return res.data;
            });
        }

        return this.selectedPlace;
    };
}

/**
 * Queries maps/autocomplete
 */
export async function query({ sessiontoken, q, lat, lng }) {
    try {
        const queryS = queryString.stringify({
            q,
            lat,
            lng,
            sessiontoken,
        });
        const endpoint = `${nogento.base}/maps/search?${queryString.stringify({
            q,
            lat,
            lng,
            sessiontoken,
        })}`;

        const res = await fetch(endpoint, {
            method: 'GET',
        });

        return await res.json();
    } catch (e) {
        return {
            success: false,
            data: [],
        };
    }
}

export async function find({ sessiontoken, place_id }) {
    try {
        const endpoint = `${nogento.base}/maps/find?${queryString.stringify({
            sessiontoken,
            place_id,
        })}`;

        const res = await fetch(endpoint, {
            method: 'GET',
        });

        return res.json();
    } catch (e) {
        return {
            success: false,
            data: {},
        };
    }
}
