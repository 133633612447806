import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './FlagController.scss';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Text from '@cs-admin/components/Common/Typography';
import IconCancel from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

@withStyles(s)
class FlagController extends Component {
    render() {
        const { flags } = this.props.flagStore;

        return (
            <Dialog
                fullWidth
                open={this.props.open}
                TransitionComponent={Transition}
                onClose={() => this.props.closeFlags()}
                aria-label="Flags"
            >
                <DialogTitle className={s.label}>Flags</DialogTitle>
                <DialogContent className={s.content}>
                    {Object.keys(flags).map(key => {
                        const hasEnabled = !!flags[key];
                        return (
                            <div key={key} className={s.flag}>
                                <Checkbox
                                    checked={hasEnabled}
                                    onClick={() => {
                                        this.props.flagStore.forceFlag(key, !hasEnabled);
                                        this.forceUpdate();
                                    }}
                                />
                                <p>{key}</p>
                            </div>
                        );
                    })}
                </DialogContent>
            </Dialog>
        );
    }
}

export default FlagController;
