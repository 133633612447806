exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1ics_,.MG3Pd{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex}.MG3Pd{min-height:100vh;width:100%;height:100%;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column}._3LYnR button{background-color:#000;color:#fff;position:fixed;bottom:2%;right:2%}._3MrfX{position:fixed;top:50%;left:50%;right:50%;bottom:50%;z-index:1}._24PLB{background-color:#08b96a!important}._16_xP{background-color:#d13131!important}", ""]);

// exports
exports.locals = {
	"root": "_1ics_",
	"content": "MG3Pd",
	"fab": "_3LYnR",
	"progress": "_3MrfX",
	"success": "_24PLB",
	"error": "_16_xP"
};