export const GROUND = 1,
    SECOND_DAY = 2,
    NEXT_DAY = 3,
    PRIORITY = 4,
    EXPEDITED = 5,
    SHIP_TO_SOCIAL = 6,
    DO_NOT_SHIP = 7;

export const SHIPPING_METHODS = {
    1: 'Ground',
    2: 'Second Day',
    3: 'Next Day',
    4: 'Priority',
    5: 'Expedited',
    6: 'Ship to Social',
    7: 'Do Not Ship',
};
