import { getUserToken } from '@cs-admin/services/user';
import fetch from '@cs-admin/services/fetch';
import { nogento } from '@cs-admin/config';
import firebase from '@cs-admin/services/firebase/init';

export async function fetchEstimate(refund) {
    try {
        const token = await getUserToken();
        const response = await fetch(`${nogento.counterbase}/cs/refund/estimate`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(refund),
        });

        return await response.json();
    } catch (error) {
        console.error(error);
        return {};
    }
}

export async function fetchProcessedRefunds(orderId) {
    try {
        const refunds = [];
        const database = await firebase.firestore();
        await database
            .collection('refunds')
            .where('orderId', '==', parseInt(orderId))
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    const refund = doc.data();

                    if (refund.newRefundFlow) {
                        refunds.push({
                            ...refund,
                            id: doc.id,
                        });
                    } else {
                        // Need to backsupport old refunds
                        let refundInfo = {
                            refundId: doc.id,
                            orderId: refund.orderId,
                            reason: refund.reason || '',
                            timestamp: new Date(refund.timestamp.seconds * 1000),
                            tax: refund.order.tax.taxAmount,
                            phoenixReturnItems: refund.phoenixResponse.body
                                ? refund.phoenixResponse.body[0].returnOrderItems
                                : [],
                            returnItems: refund.returnItems,
                            shippingTotal: refund.shippingTotal,
                            refundedAmount: refund.amount,
                            pcAmount: refund.pcAmount,
                            shippingTotal: refund.shippingTotal,
                            adminUid: refund.adminUid,
                            clawback: refund.clawback,
                        };

                        if (refund.refunds || refund.cards) {
                            const cardRefunds = refund.refunds || refund.cards;
                            refundInfo = {
                                ...refundInfo,
                                cardRefunds,
                            };
                        } else {
                            refundInfo = {
                                ...refundInfo,
                                cardBrand: refund.order.newOrderFlow
                                    ? refund.order.payment.cardData.card_brand
                                    : refund.order.cardData.card_brand,
                            };
                        }
                        refunds.push({
                            ...refundInfo,
                        });
                    }
                });
            });

        return {
            success: true,
            refunds,
        };
    } catch (error) {
        console.error(error);
        return {
            success: false,
            error,
        };
    }
}

export async function processRefund(refund) {
    try {
        const token = await getUserToken();
        const response = await fetch(`${nogento.counterbase}/cs/refund`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(refund),
        });

        return await response.json();
    } catch (error) {
        console.error(error);
        return { success: false };
    }
}

export async function retryRefund(refund) {
    const token = await getUserToken();
    const response = await fetch(`${nogento.counterbase}/cs/retryRefund`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(refund),
    });

    return await response.json();
}
