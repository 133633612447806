const shippingMethods = {
    3: { name: 'Standard Ground' },
    5: { name: '2nd Day' },
    6: { name: 'Next Day' },
    9: { name: 'Canada Standard' },
    10: { name: 'Canada Expedited' },
    11: { name: 'Express (3-5 business days)' },
    1011: { name: 'Store Pickup' },
    1015: { name: 'Express' },
    1016: { name: 'Standard' },
    1018: { name: 'Do Not Ship' },
};

const shippingCarriers = {
    1: { name: 'FedEx' },
    2: { name: 'USPS' },
    3: { name: 'Canada Post' },
    4: { name: 'Purolator' },
    6: { name: 'DHL' },
    7: { name: 'Canpar' },
    8: { name: 'DHL Ground' },
    9: { name: 'DHL' },
};

export const mapShippingMethod = id => shippingMethods[id].name;
export const mapShippingCarrier = id => shippingCarriers[id].name;
