import { locales, firebaseApiBase } from '@cs-admin/config';
import firebase from '@cs-admin/services/firebase/init';

/**
 * Requests the catalog from Firestore
 * @param {string} code
 */

export default async function fetchCatalog(code = locales[0].code) {
    const products = [];
    const categories = [];
    const filters = [];
    const db = await firebase.firestore();

    try {
        const [productsData, categoryData, filterData] = await Promise.all([
            db.collection(`products-${code.toLowerCase()}`).get(),
            db.collection(`categories-${code.toLowerCase()}`).get(),
            db.collection(`filters-${code.toLowerCase()}`).get(),
        ]);
        productsData.docs.forEach(doc => products.push(doc.data()));
        categoryData.docs.forEach(doc => categories.push(doc.data()));
        filterData.docs.forEach(doc => filters.push(doc.data()));
    } catch (e) {
        console.error(e);
    }

    return {
        Products: products,
        Categories: categories,
        Filters: filters,
    };
}

export async function fetchProductCatalog(code = locales[0].code, userType) {
    const options = {
        method: 'GET',
    };

    let endpoint = `${firebaseApiBase}/fetchCatalog?localeCode=${code}`;
    if (userType) endpoint += `&userType=${userType}`;
    const request = await fetch(endpoint, options);
    const response = await request.json();

    const { products, categories, filters } = response.catalog;
    return {
        data: {
            Products: products,
            Categories: categories,
            Filters: filters,
        },
    };
}
