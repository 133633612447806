import firestoreRecordSearch from './firestoreRecordSearch';
import { BCCOM, CB } from '@cs-admin/constants/employeeAccountServices';
import { employeeAccountErrorMessages } from '@cs-admin/constants/errorMessages';

export default async function accountSearch(service, accountInfo) {
    try {
        switch (service) {
            case BCCOM:
                return await firestoreRecordSearch(service, accountInfo);
            case CB:
                return await firestoreRecordSearch(service, accountInfo);
            default:
                throw new Error(
                    employeeAccountErrorMessages.serviceNotSupported('Account Lookup', service),
                );
        }
    } catch (error) {
        console.error(error);
        return {
            success: false,
            error,
        };
    }
}
