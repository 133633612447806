/**
 * Container declarations
 *
 * Use dynamic import() chunk for container that should be
 * loaded on demand and/or in parallel.
 * https://webpack.js.org/guides/code-splitting/
 */

const containers = {
    DashboardPage: () =>
        import(/* webpackChunkName: "DashboardPage" */
        '@cs-admin/containers/PageContainer/DashboardPage/DashboardPage.jsx'),
    LoginPage: () =>
        import(/* webpackChunkName: "LoginPage" */
        '@cs-admin/containers/PageContainer/LoginPage/LoginPage.jsx'),
    ForgotPasswordPage: () =>
        import(/* webpackChunkName: "ForgotPasswordPage" */
        '@cs-admin/containers/PageContainer/ForgotPasswordPage/ForgotPasswordPage.jsx'),
    CustomersContainer: () =>
        import(/* webpackChunkName: "CustomersContainer" */
        '@cs-admin/containers/CustomersContainer/CustomersContainer.jsx'),
    CustomerDetailContainer: () =>
        import(/* webpackChunkName: "CustomerDetailContainer" */
        '@cs-admin/containers/CustomerDetailContainer/CustomerDetailContainer.jsx'),
    OrdersContainer: () =>
        import(/* webpackChunkName: "OrdersContainer" */
        '@cs-admin/containers/OrdersContainer/OrdersContainer.jsx'),
    OrderDetailContainer: () =>
        import(/* webpackChunkName: "OrderDetailContainer" */
        '@cs-admin/containers/OrderDetailContainer/OrderDetailContainer.jsx'),
    BatchOrdersContainer: () =>
        import(/* webpackChunkName: "BatchOrderContainer" */
        '@cs-admin/containers/BatchOrdersContainer/BatchOrdersContainer.jsx'),
    BatchOrderDetailsContainer: () =>
        import(/* webpackChunkName: "BatchOrderDetailsContainer" */
        '@cs-admin/containers/BatchOrderDetailsContainer/BatchOrderDetailsContainer.jsx'),
    BulkLedgersContainer: () =>
        import(/* webpackChunkName: "BulkLedgersContainer" */
        '@cs-admin/containers/BulkLedgersContainer/BulkLedgersContainer.jsx'),
    ReturnsContainer: () =>
        import(/* webpackChunkName: "ReturnsContainer" */
        '@cs-admin/containers/ReturnsContainer/ReturnsContainer.jsx'),
    AdminContainer: () =>
        import(/* webpackChunkName: "AdminContainer" */
        '@cs-admin/containers/AdminContainer/AdminContainer.jsx'),
    AdminCreateContainer: () =>
        import(/* webpackChunkName: "AdminCreateContainer" */
        '@cs-admin/containers/AdminCreateContainer/AdminCreateContainer.jsx'),
    PortalOrderContainer: () =>
        import(/* webpackChunkName: "PortalOrderContainer" */
        '@cs-admin/containers/PortalOrderContainer/PortalOrderContainer.jsx'),
    PopupDetailContainer: () =>
        import(/* webpackChunkName: "PopupDetailContainer" */
        '@cs-admin/containers/PopupDetailContainer/PopupDetailContainer.jsx'),
    CartDetailContainer: () =>
        import(/* webpackChunkName: "CartDetailContainer" */
        '@cs-admin/containers/CartDetailContainer/CartDetailContainer.jsx'),
    PromoContainer: () =>
        import(/* webpackChunkName: "PromoContainer" */
        '@cs-admin/containers/PromoContainer/PromoContainer.jsx'),
    IncentivesContainer: () =>
        import(/* webpackChunkName: "IncentivesContainer" */
        '@cs-admin/containers/IncentivesContainer/IncentivesContainer.jsx'),
    PromoCreateContainer: () =>
        import(/* webpackChunkName: "PromoCreateContainer" */
        '@cs-admin/containers/PromoContainer/PromoCreateContainer/PromoCreateContainer.jsx'),
};

export { containers };
