import { observable, computed, action } from 'mobx';
import { throttle } from 'lodash';
import { MEDIA_PHONE, MEDIA_LAPTOP } from '@cs-admin/constants/media';
import throttleBreakpoints from '@cs-admin/utils/media';

class InterfaceStore {
    @observable progress;
    @observable viewportMode;
    @observable viewportWidth;
    @observable alert;
    @observable appIsMounted;

    constructor() {
        this.progress = false;
        this.viewportWidth = window.innerWidth;
        this.viewportMode = this.viewportWidth;
        this.appIsMounted = false;
        this.alert = {
            open: false,
            error: false,
            message: 'Test message',
        };
        this.addResizeListener();
    }

    addMountState = () => {
        if (__BROWSER__) this.appIsMounted = true;
    };

    @computed
    get inProgress() {
        return this.progress === true;
    }

    @action
    showProgress = () => {
        this.progress = true;
    };

    @action
    hideProgress = () => {
        this.progress = false;
    };

    @computed
    get alertOpen() {
        return this.alert.open === true;
    }

    @computed
    get alertError() {
        return this.alert.error === true;
    }

    get alertMessage() {
        return this.alert.message;
    }

    @action
    openAlert = (error, message) => {
        this.alert = {
            open: true,
            error,
            message,
        };
    };

    @action
    closeAlert = () => {
        this.alert = {
            open: false,
            error: false,
            message: '',
        };
    };

    @computed
    get isPhone() {
        return this.viewportMode <= MEDIA_PHONE;
    }

    @computed
    get isMobile() {
        return this.viewportMode < MEDIA_LAPTOP;
    }

    @computed
    get isTabletPortrait() {
        return this.viewportMode < MEDIA_LAPTOP && this.viewportMode > MEDIA_PHONE;
    }

    @computed
    get isComputer() {
        return this.viewportMode >= MEDIA_LAPTOP;
    }

    @action
    updateViewport = () => {
        const prevWidth = this.viewportWidth;
        this.viewportWidth = window.innerWidth;

        const newSize = throttleBreakpoints(window.innerWidth, prevWidth);
        if (newSize) this.viewportMode = newSize;
    };

    addResizeListener = () => {
        const resizeListener = throttle(this.updateViewport, 100);
        window.addEventListener('resize', resizeListener);
        this.updateViewport();
    };
}

const interfaceStore = new InterfaceStore();

export default interfaceStore;
export { InterfaceStore };
