export const INITIAL = 'INITIAL',
    NEW = 'NEW',
    RENEWAL = 'RENEWAL',
    NEW_RENEWAL = 'NEW_RENEWAL',
    DOWNGRADE = 'DOWNGRADE',
    MANUAL_UPGRADE = 'MANUAL_UPGRADE',
    MANUAL_DOWNGRADE = 'MANUAL_DOWNGRADE',
    PORTAL_NEW = 'PORTAL_NEW',
    PORTAL_RENEWAL = 'PORTAL_RENEWAL',
    ENROLLMENT_UPGRADE = 'ENROLLMENT_UPGRADE',
    DEFAULT = 'DEFAULT';
