import { observable, observe, action, toJS } from 'mobx';
import { cloneDeep, orderBy } from 'lodash';
import {
    getOrderInfo,
    submitComment,
    processRefund,
    getRefunds,
    resendOrderConfirmationEmail,
    resendShippingConfirmationEmail,
    setFlagStore,
    getFailedOrders,
    failedOrderSearch,
    retryRefund,
    cancelOrder,
    updateFraudStatus,
    updateFraudStatusAtSiftDecision,
    getOrdersById,
    getProcessedOrders,
} from '@cs-admin/services/orders';
import { getDisplayName } from '@cs-admin/services/user';
import { updatePopup } from '@cs-admin/services/popups';
import { GUEST } from '@cs-admin/constants/userStates';
import { getCustomerInfo, resendTcsAcceptanceEmail } from '@cs-admin/services/customers';
import { CANCELLED, CAPTURED } from '@cs-admin/constants/orderStates';
import { SHIP_TO_SOCIAL } from '@beautycounter/constants/shippingMethods';
import { ORDERS_CHUNK_START_PAGE, ORDERS_CHUNK_LIMIT } from '@cs-admin/services/orders/constants';

class OrderStore {
    @observable orders;
    @observable activeOrder;
    @observable editable;
    @observable customerInfo;
    @observable failedOrders;
    @observable ordersLoading;
    @observable batchOrders;
    @observable ordersSearched;
    @observable page;

    constructor() {
        this.orders = [];
        this.activeOrder = null;
        this.editable = true;
        this.failedOrders = [];
        this.ordersLoading = false;
        this.processedOrdersSearchForm = null;
        this.invoicesLazyLoadOptions = null;
        this.ordersLazyLoadOptions = null;
        this.shipToSocialsLazyLoadOptions = null;
        this.ordersSearched = false;
        this.page = ORDERS_CHUNK_START_PAGE;
    }

    ready() {
        if (__BROWSER__) {
            this.mountReactions();
        }
    }

    mountReactions() {
        /* TODO: Remove setFlagStore when full move to orders */
        setFlagStore(this.flagStore);
        observe(this.userStore, 'isLogged', async isLogged => {
            if (isLogged) await this.getFailedOrders();
        });
    }

    @action
    async getFailedOrders() {
        this.ordersLoading = true;
        const orders = cloneDeep(await getFailedOrders());
        this.failedOrders = orders.sort((a, b) => {
            if (a?.context?.dateCreatedUTC && b?.context?.dateCreatedUTC)
                return new Date(b.context.dateCreatedUTC) - new Date(a.context.dateCreatedUTC);
            if (a?.context?.dateCreatedUTC && !b?.context?.dateCreatedUTC) return -1;
            if (!a?.context?.dateCreatedUTC && b?.context?.dateCreatedUTC) return 1;
            return 0;
        });
        this.ordersLoading = false;
    }

    @action
    async setActiveOrder(id) {
        let order = null;
        this.editable = true;
        order = await getOrderInfo(id, 'Completed');
        if (!order || order == null) {
            this.editable = false;
            order = await getOrderInfo(id, 'Pending');
        }

        if (order) {
            if (order.state === CANCELLED) this.editable = false;

            this.activeOrder = cloneDeep(order);
            this.customerInfo =
                this.activeOrder &&
                (this.activeOrder.customer || (await getCustomerInfo(this.activeOrder.uid)));
        }
    }

    @action
    clearActiveOrder = () => {
        this.activeOrder = null;
        this.customerInfo = null;
    };

    @action
    refreshOrder = id => {
        this.clearActiveOrder();
        this.setActiveOrder(id);
    };

    @action
    resetOrders = () => {
        this.orders = [];
        this.page = ORDERS_CHUNK_START_PAGE;
    };

    @action
    searchOrders = async () => {
        this.ordersLoading = true;
        await this.getOrders();
        this.ordersLoading = false;
    };

    @action getOrders = async () => {
        const { values: searchValues } = this.processedOrdersSearchForm;

        const processedOrders = await getProcessedOrders({
            searchValues,
            page: this.page,
            limit: ORDERS_CHUNK_LIMIT,
        });
        this.orders = [...this.orders, ...processedOrders];
        this.ordersSearched = processedOrders.length < ORDERS_CHUNK_LIMIT;
    };

    @action
    loadMoreSearchResults = async () => {
        if (!this.ordersSearched) {
            this.page = this.page + 1;

            this.interfaceStore.showProgress();
            await this.getOrders();
            this.interfaceStore.hideProgress();
        }
    };

    @action
    async searchFailedOrders(query) {
        this.ordersLoading = true;
        this.failedOrders = cloneDeep(await failedOrderSearch(query.orderId));
        this.ordersLoading = false;
    }

    setupOrderSearchForm() {
        const createAction = () => {
            this.processedOrdersSearchForm = this.formStore.create({
                key: 'processedOrdersSearchForm',
                persist: false,
            });

            this.processedOrdersSearchForm.add([
                {
                    key: 'firstName',
                    label: 'FIRST NAME',
                },
                {
                    key: 'lastName',
                    label: 'LAST NAME',
                },
                {
                    key: 'orderType',
                    label: 'ORDER TYPE',
                },
                {
                    key: 'orderId',
                    label: 'FIREBASE ORDER ID',
                },
                {
                    key: 'phoenixOrderId',
                    label: 'PHOENIX ORDER ID',
                },
                {
                    key: 'country',
                    label: 'LOCATION',
                },
                {
                    key: 'dateFrom',
                    label: 'FROM',
                },
                {
                    key: 'dateTo',
                    label: 'TO',
                },
            ]);
        };
        this.formStore.clearForm('processedOrdersSearchForm');
        this.formStore.createReactiveForm('processedOrdersSearchForm', 'forms', createAction);
    }

    get customerName() {
        if (this.customerInfo) {
            if (this.customerInfo.userType == GUEST) {
                const { address } = this.activeOrder.cart.shipping;
                return `${address.firstName} ${address.lastName}`;
            }

            return `${this.customerInfo.firstName} ${this.customerInfo.lastName}`;
        }
    }

    get customerUid() {
        return this.customerInfo && (this.customerInfo.id || this.customerInfo.uid);
    }

    get customerInfo() {
        return this.customerInfo || null;
    }

    get isFailedOrder() {
        return (
            this.activeOrder &&
            this.failedOrders.find(failed => failed?.context?.orderId === this.activeOrder?.orderId)
        );
    }

    get isCancelled() {
        return (
            this.activeOrder &&
            (this.activeOrder.cancellation || this.activeOrder.state === CANCELLED)
        );
    }

    // TODO: Update to use refundStore instead
    get fullyRefunded() {
        if (!this.activeOrder) return false;

        let refundAmount = 0;
        const refunds = toJS(this.refundStore.refunds);
        const { grandTotal } = this.activeOrder;

        refunds &&
            refunds.map(refund => {
                refundAmount += parseFloat(refund.amount);
            });

        return grandTotal == refundAmount.toFixed(2);
    }

    get isInPhoenix() {
        return this.activeOrder && this.activeOrder.phoenixOrderId !== 'N/A';
    }

    get canCancel() {
        if (this.activeOrder) {
            if (this.isCancelled) return false;

            const {
                isMembership,
                isRenewal,
                hostOrder,
                cart: {
                    shipping: { method },
                },
                dhlHoldStatus,
            } = this.activeOrder;

            const { isDeveloper, isLeader, isFraudAgent } = this.userStore;

            if (!this.isInPhoenix) {
                return (
                    !hostOrder &&
                    (isMembership || isRenewal || method === SHIP_TO_SOCIAL) &&
                    (isLeader || isFraudAgent || isDeveloper)
                );
            }
            return dhlHoldStatus && (isFraudAgent || isDeveloper);
        }
        return false;
    }

    @action
    resendOrderConfirmation = async () => {
        if (this.activeOrder) {
            const response = await resendOrderConfirmationEmail(this.activeOrder.orderId);

            if (response.success) {
                this.interfaceStore.openAlert(false, 'Successfully sent order confirmation email.');
            } else {
                this.interfaceStore.openAlert(true, `Could not resend email. ${response.err}`);
            }

            return response;
        }
        return {
            success: false,
        };
    };

    @action
    resendShippingConfirmation = async () => {
        if (this.activeOrder && this.activeOrder.orderId) {
            const response = await resendShippingConfirmationEmail(this.activeOrder.orderId);

            if (response.success) {
                this.interfaceStore.openAlert(
                    false,
                    'Successfully sent shipping confirmation email.',
                );
            } else {
                this.interfaceStore.openAlert(true, `Could not resend email. ${response.err}`);
            }

            return response;
        }
        return {
            success: false,
        };
    };

    @action
    resendTcsAcceptanceEmail = async () => {
        const { customer: { email } = {} } = this.activeOrder;

        if (this.activeOrder && email && this.activeOrder.orderId) {
            const response = await resendTcsAcceptanceEmail(email, this.activeOrder.orderId);

            if (response.success) {
                this.interfaceStore.openAlert(
                    false,
                    'Successfully sent terms and conditions acceptance email.',
                );
            } else {
                this.interfaceStore.openAlert(true, `Could not resend email. ${response.err}`);
            }

            return response;
        }
        this.interfaceStore.openAlert(true, `Could not resend email.`);
        return { success: false };
    };

    @action
    updatePopup = async popupId => {
        if (this.activeOrder) {
            const id = this.activeOrder.id;
            const response = await updatePopup(id, this.activeOrder.collection, popupId);

            if (response.success) {
                this.interfaceStore.openAlert(false, 'Successfully submitted');
                await this.setActiveOrder(id);
            } else {
                this.interfaceStore.openAlert(true, response.error.message);
            }

            return response;
        }

        return {
            success: false,
        };
    };

    @action
    saveNewComment = async comment => {
        if (this.activeOrder) {
            const { id, collection } = this.activeOrder;

            const response = await submitComment(id, comment, collection);

            if (response.success) {
                this.setActiveOrder(id, 'Completed');
            }

            return response;
        }

        return {
            success: false,
        };
    };

    // TODO: Remove once on new refund flow
    @action
    submitRefund = async refundInfo => {
        if (this.activeOrder) {
            const {
                formData,
                order,
                returnItems,
                adminUid,
                idempotencyKey,
                transactionId,
                uid,
            } = refundInfo;
            const { amount, pcAmount, country, shippingTotal, subtotal } = formData;
            const { orderId, cart, payment, squareV2 } = order;
            const reason = formData.reason ? formData.reason : undefined;
            delete order.refunds;

            let refund = {
                reason,
                amount,
                returnItems,
                shippingTotal,
                subtotal,
                orderId,
                transactionId,
                uid,
                currency: cart.currency,
                tenderId: payment.tenderId,
                adminUid,
                order,
                pcAmount,
                country,
                idempotencyKey,
                squareV2,
            };

            if (formData.payment.splitPayments && formData.payment.splitPayments.length) {
                const cards = formData.payment.splitPayments.filter(
                    card => card.refundAmount && card.refundAmount > 0,
                );

                refund = {
                    ...refund,
                    cards,
                };
            }

            const response = await processRefund(refund);
            this.activeOrder.refunds = await getRefunds(this.activeOrder.orderId);
            return response;
        }

        return {
            success: false,
        };
    };

    // TODO: Remove once on new refund flow
    @action
    retryRefund = async refund => {
        this.interfaceStore.showProgress();
        const response = await retryRefund(refund);
        this.activeOrder.refunds = await getRefunds(this.activeOrder.orderId);
        this.interfaceStore.hideProgress();

        if (response.success) {
            this.interfaceStore.openAlert(false, 'Processed refund successfully.');
        }

        this.interfaceStore.openAlert(true, 'Retry failed.');
    };

    @action
    cancelOrder = async ({ ticket, comment }) => {
        const { id, collection, orderId, newOrderFlow, state, payment } = this.activeOrder;
        const { uid } = this.userStore.userInfo;
        const orderCaptured = (newOrderFlow && state === CAPTURED) || payment.state === 'CAPTURED';
        const needsToRefund = !this.userStore.isFraudAgent && !this.userStore.isDeveloper;

        if (orderCaptured && !this.fullyRefunded && needsToRefund) {
            this.interfaceStore.openAlert(
                true,
                'Order needs to be fully refunded before cancelling.',
            );
            return;
        }

        const { success, message } = await cancelOrder(orderId, uid, { ticket, comment });

        if (success) {
            const description = ticket ? `${ticket}: ${comment}` : comment;
            const newComment = {
                description,
                name: (await getDisplayName()) || uid,
                date: new Date().toLocaleString(),
                status: 'Cancelled',
            };
            await submitComment(id, newComment, collection);
            this.interfaceStore.openAlert(false, `Sucessfully cancelled order ${orderId}`);
            this.interfaceStore.showProgress();
            this.refreshOrder(id);
            this.interfaceStore.hideProgress();
        } else {
            this.interfaceStore.openAlert(true, message);
        }
    };

    @action
    updateFraudStatus = async isFraud => {
        const {
            isFraudAgent,
            isDeveloper,
            userInfo: { email: analystEmail } = {},
        } = this.userStore;
        if (!this.activeOrder || !(isFraudAgent || isDeveloper)) {
            return {
                success: false,
            };
        }

        const { id, collection, orderId, uid } = this.activeOrder;
        const response = await updateFraudStatus(id, isFraud, collection);
        if (response.success) {
            await this.setActiveOrder(id);
            await updateFraudStatusAtSiftDecision({ isFraud, uid, analystEmail, orderId });
        }
        return response;
    };

    @action
    updateOrders = async id => {
        const getOrders = orderBy(await getOrdersById(id), o => o.timestamp, ['desc']);
        this.orders = getOrders;
    };
}

const orderStore = new OrderStore();

export default OrderStore;
export { OrderStore };
