import { getFirestoreDocId, getFirestoreUserRecord } from './utils';
import { employeeAccountErrorMessages } from '@cs-admin/constants/errorMessages';

export default async function firestoreRecordSearch(service, accountInfo) {
    try {
        const docId = getFirestoreDocId(service, accountInfo);
        const account = await getFirestoreUserRecord(service, docId);
        if (!account.exists) {
            throw new Error(employeeAccountErrorMessages.accountNotFound(docId, service));
        }
        return { success: true, accountInfo, service, account };
    } catch (error) {
        console.error(error);
        return {
            success: false,
            accountInfo: accountInfo,
            service,
            error,
        };
    }
}
