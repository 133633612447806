import { specialCategories, specialProductSKUs } from '@cs-admin/config';
import { processSrc } from '@cs-admin/services/products';
import { validByUser } from '@cs-admin/services/catalog/filters';
import { productFields, parentFields } from './allowedProductFields';

/**
 * Processes the API product data by
 * injecting/renaming fields to match the internal
 * front end schema. Uses (controlled) mutation,
 * due to the performance intensity.
 * @param {array} data
 */

export default function processProductData(data, drafts, userType) {
    try {
        for (let i = data.length - 1; i >= 0; i--) {
            try {
                if (
                    (data[i].draft && !drafts) ||
                    data[i].mobileOnly ||
                    !data[i].productId ||
                    !validByUser(data[i], userType) ||
                    invalidFlag(data[i].flags)
                )
                    data.splice(i, 1);
                else {
                    const product = sanitizeFields(data[i], parentFields);
                    data[i] = product;
                    product.tags = [];
                    product.id = product.productId;

                    processCategories(product);
                    processImages(product);
                    processPrice(product);
                    product.inStock = parseStock(product.inStock);

                    if (product.positions) {
                        try {
                            const positions = product.positions.reduce(
                                (value, accumulator) => `${accumulator},${value}`,
                            );

                            product.positions = JSON.parse(`{${positions}}`);
                        } catch (e) {
                            product.positions = {};
                        }
                    } else product.positions = {};

                    createFallbackTags(product);
                    // product.preOrderCTA = true;

                    // Special SKUs
                    // Temp hardcode workaround until the data is added in the CMS
                    const specialSKUs = [
                        ...Object.keys(specialProductSKUs).map(key => specialProductSKUs[key]),
                        '16000',
                        '16001',
                        '16002',
                        '9078', // consultant enrollment kit
                    ];

                    if (specialSKUs.includes(product.sku)) {
                        if (product.maxQuantity !== 1) product.maxQuantity = 1;
                        product.type = 'bundle';
                    }
                    if (!product.maxQuantity) product.maxQuantity = 10;
                    if (!product.bundleIds) product.bundleIds = [];

                    // Fallback for bundledOptions titles
                    if (product.bundledOptions) {
                        product.bundledOptions.forEach((option, key) => {
                            if (!option.storeViewTitle)
                                product.bundledOptions[key].storeViewTitle =
                                    option.defaultTitle || '';
                            if (option.defaultTitle)
                                delete product.bundledOptions[key].defaultTitle;
                        });
                    }

                    if (product.ingredients && !product.ingredients.fullIngredients)
                        product.ingredients = null;

                    if (product.variants && Array.isArray(product.variants)) {
                        for (let x = product.variants.length - 1; x >= 0; x--) {
                            if (
                                typeof product.variants[x] === 'object' &&
                                product.variants[x].productId
                            ) {
                                if (product.variants[x].draft && !drafts)
                                    product.variants.splice(x, 1);
                                else {
                                    const variant = sanitizeFields(
                                        product.variants[x],
                                        productFields,
                                    );
                                    product.variants[x] = variant;

                                    variant.parentId = product.id;
                                    variant.tags = [];
                                    variant.inStock = parseStock(variant.inStock);
                                    variant.variantTitle = product.productId;
                                    variant.id = variant.productId;

                                    processImages(variant, product);
                                    processPrice(variant);
                                }
                            } else {
                                product.variants = placeholderVariant(product);
                            }
                        }

                        try {
                            product.variants.sort(
                                (a, b) => a.swatches.position - b.swatches.position,
                            );
                        } catch (e) {}
                    } else {
                        product.variants = placeholderVariant(product);
                    }
                }
            } catch (e) {
                console.info(e);
                return data[i];
            }
        }
        return { data };
    } catch (e) {
        console.error(e);
    }
    return false;
}

function processImages(product, parent = product, parentGallery = []) {
    const productGallery = [];
    const lowestSortNumber = (a, b) => a.sortOrder - b.sortOrder;
    parentGallery = parentGallery.sort(lowestSortNumber);

    if (!product.video) product.video = [];

    const variantId = parent
        ? product.id
        : product.variants && product.variants.length > 1
        ? product.variants[0].productId
        : 0;

    /**
     * The bundledSkuInterruptImageUrl & bundledSkuInterruptImageAltText should only be used
     * on the Flawless in Five wizard and not in the gallery or in other places on the site.
     */
    if (product.image && product.image.bundledSkuInterruptImageUrl) {
        const imageAltText = product.image.bundledSkuInterruptImageAltText || null;
        product.fifInterruptorImage = product.image.bundledSkuInterruptImageUrl
            ? {
                  src: processSrc(product.image.bundledSkuInterruptImageUrl),
                  altText: imageAltText,
              }
            : {};
    }

    if (product.image && product.image.imageUrl) {
        const image = product.image.imageUrl || null;
        const imageAltText = product.image.altText || null;
        product.images = image
            ? [
                  {
                      src: processSrc(image),
                      variantId,
                      altText: imageAltText,
                  },
              ]
            : [];

        if (product.image.gallery && Array.isArray(product.image.gallery)) {
            product.image.gallery.forEach(item => {
                !item.disabled &&
                    productGallery.push({
                        src: processSrc(item.url),
                        variantId,
                        sortOrder: item.sortOrder,
                        altText: item.altText || null,
                    });
            });
        }
        if (product.altVideo) {
            productGallery.push({
                src: product.altVideo,
                variantId: product.id,
                video: true,
                sortOrder: 99,
            });
        }
        product.images.push(...productGallery.sort(lowestSortNumber), ...parentGallery);
    }
    delete product.image;
    if (!product.images || !product.images.length) product.images = [{ src: '' }];

    return product;
}

export function parseStock(inStock) {
    return typeof inStock === 'string' ? Boolean(parseInt(inStock)) : Boolean(inStock);
}

function placeholderVariant(product) {
    return [
        {
            productId: product.productId,
            id: product.productId,
            variantTitle: product.productId,
            price: parseFloat(product.price),
        },
    ];
}

function processPrice(product) {
    if (product.price) {
        product.price = parseFloat(product.price);
        if (product.specialPrice) {
            product.compareAtPrice = product.price;
            product.price = parseFloat(product.specialPrice);
        }
    } else product.price = 0;
}

function processCategories(product) {
    if (!product.parentCategoryIds || !Array.isArray(product.parentCategoryIds))
        product.parentCategoryIds = [];
    if (!product.categories || !Array.isArray(product.categories)) product.categories = [];
    product.categoryList = [...product.categories, ...product.parentCategoryIds];
}

function createFallbackTags(product) {
    /**
     * Inject "virtual" new and best seller categories
     */

    let isNew = false;
    if (product.newTo && product.newFrom) isNew = dateCheck(product.newFrom, product.newTo);

    if (isNew || (product.promoName && product.promoName.toUpperCase() == 'NEW')) {
        product.categoryList.push(specialCategories.newProducts);
        if (!product.parentCategoryIds) product.parentCategoryIds = [];
        product.tags.push('tagNew');
    }
    if (
        product.bestSeller ||
        (product.promoName && product.promoName.toUpperCase() == 'BEST SELLER')
    ) {
        product.categoryList.push(specialCategories.bestSellers);
        product.tags.push('tagBestSeller');
    }
    if (product.draft) {
        product.tags.push('tagDraft');
    }
}

function invalidFlag(flags) {
    const valid = ['web', 'enrollment', 'counterbase'];
    if (flags && Array.isArray(flags)) {
        return !flags.filter(flag => valid.includes(flag)).length;
    }
}

function sanitizeFields(data, allowedFields) {
    const sanitized = {};
    allowedFields.forEach(key => {
        if (data[key] || data[key] === 0) sanitized[key] = data[key];
    });
    return sanitized;
}

function dateCheck(from, to) {
    const fDate = Date.parse(from);
    const lDate = Date.parse(to);
    const cDate = new Date();

    if (cDate <= lDate && cDate >= fDate) {
        return true;
    }
    return false;
}
