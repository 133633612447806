import { DROP_IDS } from '../utils/constants/promoIDs';
/**
 * If a do not stack rule is present handle accordingly.
 * If a deeper discount is applied to the same sku, drop the do not stack rule.
 * If there is not a deeper discount, only apply the do not stack rule for that sku.
 */
export default function handleDoNotStackRules(appliedPromos) {
    let newAppliedPromos = [...appliedPromos];
    const doNotStackDiscounts = appliedPromos.filter(({ doNotStack }) => doNotStack);

    doNotStackDiscounts.forEach(doNotStackDiscount => {
        let hasBiggerDiscount = false;
        let hasOtherDiscounts = false;
        const { discount, sku: currentSku, name } = doNotStackDiscount;

        //determine if there is a larger discount being applied to the same sku
        appliedPromos.forEach(promo => {
            const sameSku = promo.sku === currentSku;
            const notSamePromo = promo.name !== name;
            //compare discounts, discounts are $ off a products price
            const biggerDiscount = promo.discount > discount;

            if (sameSku && notSamePromo) {
                hasOtherDiscounts = true;

                if (biggerDiscount) {
                    hasBiggerDiscount = true;
                }
            }
        });

        //if there are not other discounts on the current sku,
        //we need no changes
        if (!hasOtherDiscounts) {
            return;
        }

        //filter rules based off bigger discount results
        newAppliedPromos = newAppliedPromos.reduce((promos, promo) => {
            const notSameSku = promo.sku !== currentSku;
            const isDropRule = DROP_IDS.find(dropId => dropId == promo.id);

            //keep all drop rules and promos applied to a different sku
            if (notSameSku || isDropRule) {
                promos.push(promo);
            } else {
                const isDoNotStackDiscount = promo.name === doNotStackDiscount.name;
                const keepAllOtherDiscounts = hasBiggerDiscount && !isDoNotStackDiscount;
                const keepOnlyDoNotStackDiscount = !hasBiggerDiscount && isDoNotStackDiscount;

                //If there was a bigger discount,
                //keep all other discounts on the current sku
                if (keepAllOtherDiscounts) {
                    promos.push(promo);
                }

                //If there was not a bigger discount,
                //keep only do not stack discount(s) on the current sku
                if (keepOnlyDoNotStackDiscount) {
                    promos.push(promo);
                }
            }

            return promos;
        }, []);
    });

    return newAppliedPromos;
}
