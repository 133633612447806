export const getMutatePayoutsOptions = ({
    allAccountsSelected,
    selectedAccounts,
    excludedAccounts,
    selectedPeriod,
    textSearch,
}) => {
    const { startDate, endDate } = selectedPeriod;
    const options = {
        startDate,
        endDate,
    };
    if (textSearch || textSearch === 0) {
        options.selectionId = textSearch;
    }
    if (!allAccountsSelected) {
        if (excludedAccounts.length) {
            options.excludes = excludedAccounts;
            return options;
        }
        if (selectedAccounts.length) {
            options.selection = selectedAccounts;
        }
    }
    return options;
};

export const isOnlyExcludedAccount = ({ rewardPayoutId, excludedAccounts }) =>
    excludedAccounts.length === 1 && excludedAccounts.includes(rewardPayoutId);

export const getSelectedAccoutsStoreKey = excludedAccounts =>
    excludedAccounts.length ? 'excludedAccounts' : 'selectedAccounts';
