export const getMilestonesQuery = `
query {
  milestoneValues {
    name
    participantRequirements {
        requirementId
        requirementTypeId
        name
        value
    }

    participantRewards {
        rewardId
        rewardTypeId
        name
        value
    }
    enrollerRewards {
        rewardId
        rewardTypeId
        name
        value
    }
  }
}
`;

// requirements: [{ id: 1, value: 100 }]
// rewards: [{ id: 2, value: 200 }]
export const mutateMilestoneQuery = ({ requirements, rewards }) => `
mutation {
    milestoneValuesUpdate(
        requirements: ${requirements}
        rewards: ${rewards}
    ) {
        success
    }
}
`;

export const enrollerPayoutsQuery = ({
    startDate,
    endDate,
    pageSize,
    pageNumber,
    textSearch,
    pagination = false,
}) => `
    query {
        enrollerPayouts(
            startDate: "${startDate}"
            endDate: "${endDate}"
            pagination: ${pagination}
            ${pageSize ? `pageSize: ${pageSize}` : ''}
            ${pageNumber ? `pageNumber: ${pageNumber}` : ''}
            ${textSearch || textSearch === 0 ? `textSearch: ${textSearch}` : ''}
        ) { 
            pageNumber
            totalPaidAccounts
            totalUnpaidAccounts
            enrollerPayoutsData {
                rewardPayoutId
                participantAccountId
                enrollerAccountId
                milestoneName
                startDate
                endDate
                finished
                succeeded
                paidDate
                paid
            }
        }
    }
    `;

export const availablePeriodsQuery = `
query {
    periods {
        periodId
        startDate
        endDate
    }
}
`;

export const mutatePayoutsQuery = ({
    startDate,
    endDate,
    selectionId,
    excludes = [],
    selection = [],
}) => `
mutation {
    enrollerIssuePayouts(
        startDate: "${startDate}"
        endDate: "${endDate}"
        excludes: [${excludes}]
        selection: [${selection}]
        ${selectionId || selectionId === 0 ? `selectionId: ${selectionId}` : ''}
    ) {
        success
    }
}
`;
