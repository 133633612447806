import errorMessages from '@cs-admin/constants/errorMessages';

// Matches string which includes: Expire, expir, Expired, expiration. Case insensitive.
const REASON_FIELD_REGEX = /\b(\w*expir\w*)\b/i;

export function validate(title, form) {
    var error = false;
    if (form.validations) {
        form.validations.map(v => {
            const valid = v(title, form.value);

            if (valid !== true) {
                error = valid;
            }
        });

        return {
            ...form,
            error: error !== false,
            message: error,
        };
    }

    return form;
}

export const validators = {
    required: (title, value) =>
        value.length > 0 ? true : errorMessages.required.replace('${label}', title),
    validEmail: (title, value) => (validEmail(value) ? true : errorMessages.validEmail),
    validUsZipcode: (title, value) => (validUsZipcode(value) ? true : errorMessages.validUsZipcode),
    validCaZipcode: (title, value) => (validCaZipcode(value) ? true : errorMessages.validCaZipcode),
    validReason: (title, value) => (validReason(value) ? true : errorMessages.validReason),
};

export function isLessThan(key, item) {
    const valid =
        item.value <= item[key] ? true : `Value must be less than or equal to $${item[key]}`;

    return {
        ...item,
        error: valid !== true,
        message: valid,
    };
}

export function validEmail(email) {
    const re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

    // Return false if email domain extention is less than 2 characters long. (eg. test@gmail.c)
    const emailEndingExt = email.split('.');
    return emailEndingExt[emailEndingExt.length - 1].length <= 1
        ? false
        : re.test(email.toLowerCase());
}

export function validUsZipcode(zipcode) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipcode);
}

export function validCaZipcode(zipcode) {
    return /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/.test(zipcode);
}

export function validPhoneNumber(phone) {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phone);
}

export function validDate(date) {
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) return false;

    const parts = date.split('/');
    const day = parseInt(parts[1], 10);
    const month = parseInt(parts[0], 10);
    const year = parseInt(parts[2], 10);
    const dt = new Date();
    const currentYear = dt.getFullYear();

    if (year < 1900 || year > currentYear || month == 0 || month > 12) return false;

    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;

    return day > 0 && day <= monthLength[month - 1];
}

export function isOver18(date) {
    const dateUTC = new Date(date);
    const ageTimeStamp = Date.now() - dateUTC.getTime();
    const ageUTC = new Date(ageTimeStamp);
    const age = Math.abs(ageUTC.getUTCFullYear() - 1970);
    return age >= 18;
}

export function validUsSsn(ssn) {
    return /^\d|\*{3}-\d|\*{2}-\d|\*{4}$/.test(ssn);
}

export function validCaSin(sin) {
    return /^\d|\*{3}-\d|\*{3}-\d|\*{3}$/.test(sin);
}

export function isCompanyEmail(value) {
    return value.includes('beautycounter.com');
}

export function validReason(value) {
    return !REASON_FIELD_REGEX.test(value);
}

export default validators;
