import { MEDIA_PHONE, MEDIA_MOBILE, MEDIA_LAPTOP, MEDIA_DESKTOP } from '@cs-admin/constants/media';

/**
 * Updates viewport state of component only once per breakpoint change
 * @param {number} width
 * @param {number} state
 */

export default function throttleBreakpoints(width, state) {
    if (width >= MEDIA_DESKTOP) {
        if (state < MEDIA_DESKTOP) {
            return MEDIA_DESKTOP;
        }
    } else if (width >= MEDIA_MOBILE && width < MEDIA_DESKTOP) {
        if (state < MEDIA_MOBILE || state >= MEDIA_DESKTOP) {
            return MEDIA_LAPTOP;
        }
    } else if (width > MEDIA_PHONE && width <= MEDIA_MOBILE) {
        if (state <= MEDIA_PHONE || state > MEDIA_MOBILE) {
            return MEDIA_MOBILE;
        }
    } else if (state > MEDIA_PHONE) {
        return MEDIA_PHONE;
    }
    return false;
}
