import offboardAuth from './offboardAuth';
import { nogento } from '@cs-admin/config';
import { employeeAccountErrorMessages } from '@cs-admin/constants/errorMessages';

export default async function offboardCB(uid) {
    const actions = [];
    try {
        const response = await offboardAuth(`${nogento.base}/auth/removeCSUser`, uid);

        actions.push({
            success: response.success,
            message: response.success
                ? `Deleted from cs-users user with uid ${uid}`
                : employeeAccountErrorMessages.csUserCouldNotBeDeleted(uid, response.error),
        });

        return {
            success: true,
            actions,
            accountInfo: uid,
        };
    } catch (error) {
        console.error(error);
        return {
            success: false,
            error,
            actions,
            accountInfo: uid,
        };
    }
}
