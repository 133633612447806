import { validateAnniversary } from './anniversary.js';
import { bobValidation, renewalValidation } from './bobValidation.js';
import { subscriptionValidation } from './subscriptionValidation.js';
import { channelValidation, channelsValidation } from './channel.js';
import { COUNTERBASE } from '@beautycounter/constants/channels';
// TODO: configure more helpful messages
const validate = (ruleset, failureMessage = '') => validator => context => {
    const isValid = !ruleset || validator(ruleset, context);
    return {
        isValid,
        message: !isValid ? failureMessage : '',
    };
};

/**
 * Reduce validate returns into single message returnable statement
 */
const toSingleResponse = ruleset => (a, b) => ({
    name: ruleset.name,
    isValid: b.isValid && a.isValid,
    message: b.message || a.message,
});

/**
 * Static validation Methods
 * Constructing a new instace will run all validations in optimal order
 */
export default class Validation {
    static anniversary(anniversaryRule, context) {
        return validateAnniversary(anniversaryRule, context);
    }

    static validateBoB(validateBoB, context) {
        return bobValidation(validateBoB, context);
    }

    static validateRenewal(validateRenewal, context) {
        return renewalValidation(validateRenewal, context);
    }

    static userType(userTypes, context = {}) {
        //Counterbase depends on the customer type for portal orders not user type. User is set to the counterbase user logged in.
        const customerUserType = context.customer && context.customer.userType;
        const { userType } = context.user || {};

        if (context.channel == COUNTERBASE) {
            return !userTypes || userTypes.includes(customerUserType);
        }
        return !userTypes || userTypes.includes(userType);
    }

    static regionId(regionId, context) {
        return !regionId || regionId.includes(context.user.regionId);
    }

    static timestamps(timestamps, context) {
        const timestamp =
            (typeof context.timestamp === 'number'
                ? context.timestamp
                : Date.parse(context.timestamp)) / 1000;
        return (
            (!timestamps.start || timestamp >= timestamps.start._seconds) &&
            (!timestamps.end || timestamp <= timestamps.end._seconds)
        );
    }

    static disqualificationItems(disqualificationItems, context) {
        return !disqualificationItems.some(r => context.cart.products.some(p => p.sku == r));
    }

    static channel(channel, context) {
        return channelValidation(channel, context);
    }

    static code(code, context) {
        return context.cart.promoTags.some(t =>
            code.some(r => r.toUpperCase() == t.code.toUpperCase()),
        );
    }

    static milestone(milestone, context) {
        const { timestamp } = context;
        const currentTime = typeof timestamp === 'string' ? timestamp : new Date().toISOString();
        const { consultantMilestones = [] } = context.user || {};
        return consultantMilestones
            .filter(({ expirationDate }) => !expirationDate || expirationDate > currentTime)
            .some(({ milestoneId }) => milestoneId === milestone);
    }

    static newUsersOnly(newUsersOnly, context) {
        if (newUsersOnly && context.byConsultant)
            return (
                context.customer.userType === 5 ||
                (context.customer.userType === 3 && !context.customer.sponsorId)
            );
        return newUsersOnly && !context.user.hasCheckedOutBefore ? true : false;
    }

    static hasInfluencer(hasInfluencer, context) {
        return hasInfluencer && context.attribution && context.attribution.influencer
            ? true
            : false;
    }

    static hasSubscription(subscriptionBased, context) {
        return subscriptionValidation(subscriptionBased, context);
    }

    static hasAttribution(hasAttribution, context) {
        return hasAttribution && context.sponsorId != 1;
    }

    static channels(channels, context) {
        return channelsValidation(channels, context);
    }

    /**
     * Shorthand to avoid having to construct with new keyword
     */
    static all(rule, context) {
        return new Validation(rule, context);
    }

    /**
     * Validate all validators in optimal order.
     * Returns an object with isValid, message, and the name of rule
     * @param {*} rule
     * @param {*} context
     */
    constructor(rule, context) {
        return [
            validate(!rule.active)(() => false),
            validate(rule.userTypes)(Validation.userType),
            validate(rule.regionId)(Validation.regionId),
            validate(rule.timestamps)(Validation.timestamps),
            validate(rule.disqualificationItems)(Validation.disqualificationItems),
            validate(rule.channel)(Validation.channel),
            validate(rule.code)(Validation.code),
            validate(rule.milestone)(Validation.milestone),
            validate(rule.newUsersOnly)(Validation.newUsersOnly),
            validate(rule.anniversary)(Validation.anniversary),
            validate(rule.hasInfluencer)(Validation.hasInfluencer),
            validate(rule.validateBoB)(Validation.validateBoB),
            validate(rule.validateRenewal)(Validation.validateRenewal),
            validate(rule.subscriptionBased)(Validation.hasSubscription),
            validate(rule.channels)(Validation.channels),
        ]
            .map(validateWith => validateWith(context))
            .reduce(toSingleResponse(rule));
    }
}
