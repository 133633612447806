import { action, observable } from 'mobx';
import { downloadCsvFile } from '@cs-admin/utils/export';
import {
    getMilestones,
    mutateMilestone,
    getEnrollerPayouts,
    getAvailablePeriods,
    mutateEnrollerPayouts,
} from '../../services/milestones/graphql';
import { find } from 'lodash';
import { US } from '@cs-admin/constants/incentivesPromoRuleNames';
import {
    getMutatePayoutsOptions,
    isOnlyExcludedAccount,
    getSelectedAccoutsStoreKey,
} from './utils';

const DEFAULT_PAGE = 1;

class StartCountingStore {
    @observable milestones;
    @observable errorMessage;
    @observable selectedPromoRuleCountry;
    @observable currentPromoRule;
    @observable enrollerPayouts;
    @observable availablePeriods;
    @observable selectedPeriod;
    @observable requestPending;
    @observable selectedAccounts;
    @observable excludedAccounts;
    @observable allAccountsSelected;
    @observable enrollersSearchTerm;

    constructor() {
        this.milestones = [];
        this.enrollerPayouts = [];
        this.availablePeriods = [];
        this.selectedAccounts = [];
        this.excludedAccounts = [];
        this.errorMessage = '';
        this.selectedPromoRuleCountry = US;
        this.currentPromoRule = {};
        this.enrollersSearchTerm = '';
        this.requestPending = false;
        this.allAccountsSelected = false;
        this.selectedPeriod = null;
    }

    @action
    async enrollerIssuePayout({
        allAccountsSelected,
        selectedAccounts,
        excludedAccounts,
        selectedPeriod,
        pageNumber = DEFAULT_PAGE,
        textSearch = '',
    }) {
        const options = getMutatePayoutsOptions({
            allAccountsSelected,
            selectedAccounts,
            excludedAccounts,
            selectedPeriod,
            textSearch,
        });

        const { enrollerIssuePayouts: { success } = {} } = await mutateEnrollerPayouts(options);

        if (success) {
            const { startDate, endDate } = selectedPeriod;
            await this.getEnrollerPayouts({
                startDate,
                endDate,
                pageNumber,
                textSearch,
            });
            this.interfaceStore.openAlert(false, 'Selected accounts were successfully paid.');
        } else {
            this.interfaceStore.openAlert(true, 'Error occurred while accounts paid.');
        }
        this.clearSelections();
    }

    @action
    async exportEnrollerPayouts({ startDate, endDate, periodId }) {
        const data = await getEnrollerPayouts({
            startDate,
            endDate,
            pagination: false,
            isExport: true,
        });
        downloadCsvFile({ data, fileName: `enrollerPayouts-period-${periodId}.csv` });
    }

    @action
    async getMilestones() {
        const milestones = await getMilestones();

        if (milestones.error) {
            this.errorMessage = milestones.error;
        } else {
            this.milestones = milestones.milestoneValues;
        }
    }

    @action
    async getAvailablePeriods() {
        this.requestPending = true;
        const availablePeriods = await getAvailablePeriods();

        if (availablePeriods.error) {
            this.errorMessage = availablePeriods.error;
        } else {
            this.availablePeriods = availablePeriods.periods;
        }
        this.requestPending = false;
        this.enrollersSearchTerm = '';
        this.selectedPeriod = null;
        this.enrollerPayouts = [];
        this.clearSelections();
    }

    @action
    async getEnrollerPayouts({
        startDate,
        endDate,
        pageNumber = DEFAULT_PAGE,
        textSearch = '',
        isExport = false,
    }) {
        this.requestPending = true;
        const payouts = await getEnrollerPayouts({
            startDate,
            endDate,
            pagination: true,
            pageNumber,
            textSearch,
            isExport,
        });

        if (payouts.error) {
            this.errorMessage = payouts.error;
        } else {
            this.enrollerPayouts = payouts.enrollerPayouts;
        }
        this.requestPending = false;
    }

    @action
    async updateMilestone({ requirements, rewards }) {
        const milestoneUpdateResponse = await mutateMilestone({ requirements, rewards });
        if (!milestoneUpdateResponse || milestoneUpdateResponse.errors) return;

        if (milestoneUpdateResponse.milestoneValuesUpdate.success) {
            await this.getMilestones();
        }
    }

    @action
    setPromoRuleCountry(country = US) {
        this.selectedPromoRuleCountry = country;
    }

    @action
    setPromoRule(rule) {
        this.currentPromoRule = rule;
    }

    getMilestoneByName(name) {
        return find(this.milestones, { name }) || {};
    }

    @action
    selectPeriod(period) {
        if (period) {
            this.selectedPeriod = period;
            this.enrollersSearchTerm = '';
            this.clearSelections();
        }
    }

    @action
    toggleAccountSelection = ({ rewardPayoutId }) => {
        if (this.allAccountsSelected) {
            this.excludedAccounts = [rewardPayoutId];
            this.allAccountsSelected = false;
        } else {
            this.allAccountsSelected = isOnlyExcludedAccount({
                excludedAccounts: this.excludedAccounts,
                rewardPayoutId,
            });
            const selectedAccoutsStoreKey = getSelectedAccoutsStoreKey(this.excludedAccounts);
            this[selectedAccoutsStoreKey] = this[selectedAccoutsStoreKey].includes(rewardPayoutId)
                ? this[selectedAccoutsStoreKey].filter(payoutId => payoutId !== rewardPayoutId)
                : [...this[selectedAccoutsStoreKey], rewardPayoutId];
        }
    };

    @action
    toggleAllAccounts = () => {
        this.excludedAccounts = [];
        this.selectedAccounts = [];
        this.allAccountsSelected = !this.allAccountsSelected;
    };

    @action
    setSearchTerm = searchTerm => {
        this.enrollersSearchTerm = searchTerm;
    };

    @action
    clearSelections = () => {
        this.excludedAccounts = [];
        this.selectedAccounts = [];
        this.allAccountsSelected = false;
    };
}

const startCountingStore = new StartCountingStore();

export default startCountingStore;
export { StartCountingStore };
