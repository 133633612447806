import fetch from '@cs-admin/services/fetch';
import { nogento } from '@cs-admin/config';
import { getUserToken } from '@cs-admin/services/user';

export async function fetchPendingPromos() {
    if (__TEST__) return [];

    const token = await getUserToken();
    const response = await fetch(`${nogento.counterbase}/cs/promo/pendingRules`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    const { success, data } = await response.json();
    if (success) return data;
    return [];
}

export async function fetchActivePromos() {
    try {
        const token = await getUserToken();
        const response = await fetch(`${nogento.counterbase}/cs/promo/rules?prod=true`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        const responseData = await response.json();
        if (
            responseData.success &&
            responseData.data['promo-rules-prod'] &&
            responseData.data['promo-rules-prod'].length
        ) {
            return responseData.data['promo-rules-prod'];
        }
    } catch (e) {
        console.error('Unable to fetch promo rules.', e);
    }
}

export async function fetchShippingRates(context) {
    const token = await getUserToken();
    const response = await fetch(`${nogento.counterbase}/cs/promo/shippingRates`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(context),
    });

    const { success, data } = await response.json();
    if (success) return data;
}

export async function savePromoRule(body) {
    const token = await getUserToken();
    const response = await fetch(`${nogento.counterbase}/cs/promo/editRule`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    const result = await response.json();
    return result;
}

export async function deployPromoRule(body) {
    const token = await getUserToken();
    const response = await fetch(`${nogento.counterbase}/cs/promo/deployRule`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    const result = await response.json();
    return result;
}

export async function createPromoRule(data) {
    const token = await getUserToken();
    const response = await fetch(`${nogento.counterbase}/cs/promos/create`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
}

export async function deployPromoRuleGenerated(name = '') {
    const token = await getUserToken();
    const response = await fetch(`${nogento.counterbase}/cs/promos/${name}/deploy`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
    const result = await response.json();
    return result;
}

export async function fetchPromoTags() {
    try {
        const token = await getUserToken();
        const response = await fetch(`${nogento.counterbase}/cs/promos/tags`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        const { promoTagsList } = await response.json();
        return promoTagsList;
    } catch (e) {
        console.error('Unable to fetch promo tags.', e);
    }
}

export async function bulkUpdatePromos(data) {
    try {
        const token = await getUserToken();
        const response = await fetch(`${nogento.counterbase}/cs/promos/bulkUpdate`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (e) {
        console.error('Unable to bulk update', e);
        return { success: false };
    }
}
