import { CONSULTANT, MEMBER, CLIENT, PROSPECT } from '@cs-admin/constants/userStates';
import { MANUAL_UPGRADE, MANUAL_DOWNGRADE } from '@cs-admin/constants/membershipServiceMethods';

export function isValidMembershipChange(userType, changedUserType) {
    return (
        (userType !== MEMBER && changedUserType === MEMBER) ||
        (userType === MEMBER && changedUserType !== MEMBER)
    );
}

export function getMemberServiceMethod(userType, changedUserType) {
    const canUpgrade = userType === CLIENT || userType === PROSPECT;
    const changedTypeIsMember = changedUserType === MEMBER;
    const isMember = userType === MEMBER;
    const changedTypeCanDowngradeFromMember = changedUserType === CLIENT;
    const isConsultant = userType === CONSULTANT;
    const changedTypeCanDowngradeFromConsultant = changedUserType === MEMBER;

    if (canUpgrade && changedTypeIsMember) {
        return MANUAL_UPGRADE;
    }

    if (isMember && changedTypeCanDowngradeFromMember) {
        return MANUAL_DOWNGRADE;
    }

    if (isConsultant && changedTypeCanDowngradeFromConsultant) {
        return MANUAL_DOWNGRADE;
    }

    return null;
}
