// https://countertech.atlassian.net/wiki/spaces/BPT/pages/707002369/Volumes+and+Orders+and+Stuff

import { ENROLLMENT, STARTER_SETS, ANNIVERSARY_SETS } from '../utils/constants/specialSkus';
import { toTwoDecimal, toDecimal } from '../utils/numbers';
import { CONSULTANT_DISCOUNT_CALC, CONSULTANT_DISCOUNT_PROMO } from '../utils/constants/volumes';

const dateObj = new Date();
const currentYear = dateObj.getUTCFullYear();
const currentMonth = dateObj.getUTCMonth() + 1;
const currentDay = dateObj.getUTCDate();

export function calculateVolumes(context, isWholesale = false) {
    const volumes = { CV: 0, PV: 0, QV: 0, NV: 0, SV: 0 };

    const shouldHavePV = isWholesale ? shouldGeneratePV(context) : true;
    const shouldHaveNV = shouldGenerateNV(context);

    context.cart.products.forEach(product => {
        const consultantDiscounts = getConsultantDiscounts(context, product);
        product.volumes = calculateProductVolumes(
            product,
            shouldHavePV,
            shouldHaveNV,
            consultantDiscounts,
        );
    });

    context.cart.products.forEach(
        ({
            volumes: {
                originalCV,
                originalPV,
                originalQV,
                originalNV,
                originalSV,
                CV,
                PV,
                QV,
                NV,
                SV,
            },
        }) => {
            volumes.originalCV = calculateTotal(volumes.originalCV, originalCV);
            volumes.originalPV = calculateTotal(volumes.originalPV, originalPV);
            volumes.originalQV = calculateTotal(volumes.originalQV, originalQV);
            volumes.originalNV = calculateTotal(volumes.originalNV, originalNV);
            volumes.originalSV = calculateTotal(volumes.originalSV, originalSV);
            volumes.CV = calculateTotal(volumes.CV, CV);
            volumes.PV = calculateTotal(volumes.PV, PV);
            volumes.QV = calculateTotal(volumes.QV, QV);
            volumes.NV = calculateTotal(volumes.NV, NV);
            volumes.SV = calculateTotal(volumes.SV, SV);
        },
    );

    context.cart.volumes = volumes;

    removeProductCreditFromVolumes(context, isWholesale);

    return context;
}

export function removeProductCreditFromVolumes(context, isWholesale = false) {
    const { payment, cart } = context;
    const { products, volumes: cartVolumes } = cart;

    if (!payment || !hasProductCreditApplied(payment)) return context;
    const { appliedCredit } = payment;
    const { PV, CV, SV } = cartVolumes;
    const denominator = isWholesale ? CV : PV;
    const multiplier = Math.max(1 - appliedCredit / denominator, 0);

    cartVolumes.CV = toTwoDecimal(cartVolumes.CV * multiplier);
    cartVolumes.PV = toTwoDecimal(cartVolumes.PV * multiplier);
    cartVolumes.QV = toTwoDecimal(cartVolumes.QV * multiplier);
    cartVolumes.NV = toTwoDecimal(cartVolumes.NV * multiplier);
    cartVolumes.SV = toTwoDecimal(cartVolumes.SV * multiplier);

    products.forEach(({ volumes }) => {
        volumes.CV = toTwoDecimal(volumes.CV * multiplier);
        volumes.PV = toTwoDecimal(volumes.PV * multiplier);
        volumes.QV = toTwoDecimal(volumes.QV * multiplier);
        volumes.NV = toTwoDecimal(volumes.NV * multiplier);
        volumes.SV = toTwoDecimal(volumes.SV * multiplier);
    });

    return context;
}

function hasProductCreditApplied(payment) {
    return payment && payment.appliedCredit && payment.appliedCredit > 0;
}

function calculateProductVolumes(product, shouldHavePV, shouldHaveNV, consultantDiscounts) {
    const { CV, PV, QV, SV, price, quantity, totalPrice, isHostReward, sku } = product;
    const isAnniversarySet = ANNIVERSARY_SETS.some(x => x === sku);
    const originalCV = CV ? toTwoDecimal(Number(CV) * quantity) : null;
    const originalPV = PV ? toTwoDecimal(Number(PV) * quantity) : null;
    const originalQV = QV ? toTwoDecimal(Number(QV) * quantity) : null;
    const originalSV = SV ? toTwoDecimal(Number(SV) * quantity) : null;
    if (product.isHostReward)
        return {
            CV: 0,
            PV: 0,
            QV: 0,
            NV: 0,
            SV: 0,
            originalCV: CV,
            originalPV: PV,
            originalQV: QV,
            originalNV: 0,
            originalSV: SV,
        };

    const discounts = product.discounts || [];

    const duplicateDiscountQty = getDuplicateDiscounQty(discounts);

    const isConsultantDiscountNotCombined = !(
        discounts.some(({ name }) => name === CONSULTANT_DISCOUNT_PROMO) &&
        discounts.length - duplicateDiscountQty > 1
    );

    let discountPercent = totalPrice / (price * quantity);

    if (consultantDiscounts && isConsultantDiscountNotCombined) {
        discountPercent = totalPrice / (CONSULTANT_DISCOUNT_CALC * price * quantity);
    }

    const finalCV = originalCV && toTwoDecimal(originalCV * discountPercent);
    const finalPV = originalPV && toTwoDecimal(originalPV * discountPercent);
    const finalQV = originalQV && toTwoDecimal(originalQV * discountPercent);
    const finalSV = originalSV && toTwoDecimal(originalSV * discountPercent);

    const volumes = {
        originalCV,
        originalPV: shouldHavePV || isAnniversarySet ? originalPV : 0,
        originalQV,
        originalNV: shouldHaveNV ? originalQV : 0,
        originalSV: shouldHavePV || isAnniversarySet ? originalSV : 0,
        CV: finalCV,
        PV: shouldHavePV || isAnniversarySet ? finalPV : 0,
        QV: finalQV,
        NV: shouldHaveNV ? finalQV : 0,
        SV: shouldHavePV || isAnniversarySet ? finalSV : 0,
    };

    return volumes;
}

function shouldGeneratePV(context) {
    if (isEnrolling(context)) return true;

    const hasStarterSet = STARTER_SETS.some(sku =>
        context.cart.products.find(product => product.sku === sku),
    );
    if (!hasStarterSet) return false;

    const { month, day } = parseEnrollmentDate(context);
    if (!(month && day)) return false;
    return month === currentMonth && day === currentDay;
}

function shouldGenerateNV(context) {
    if (isEnrolling(context)) return true;
    const { year, month } = parseEnrollmentDate(context);
    if (!(month && year)) return false;
    return year === currentYear && month === currentMonth;
}

function calculateTotal(currentTotal, volume) {
    return toTwoDecimal((currentTotal || 0) + (volume || 0));
}

function isEnrolling(context) {
    const isEnrolling = context.cart.products.find(product => product.sku === ENROLLMENT);
    return isEnrolling;
}

function parseEnrollmentDate(context) {
    const enrollmentDateUTC = context.user.enrollmentDateUTC;
    if (!enrollmentDateUTC) return { year: null, month: null, day: null };
    const date = enrollmentDateUTC.split('T')[0].split('-');
    const year = Number(date[0]);
    const month = Number(date[1]);
    const day = Number(date[2]);
    return { year, month, day };
}

function getConsultantDiscounts(context, { sku }) {
    if (!(context.cart.appliedPromos && context.cart.appliedPromos.length)) return;
    const consultantDiscounts = context.cart.appliedPromos.filter(
        ({ name, sku: promoSku }) => promoSku === sku && name === CONSULTANT_DISCOUNT_PROMO,
    );
    return consultantDiscounts.length;
}

function getDuplicateDiscounQty(discounts) {
    const { count } = discounts.reduce(
        (acc, discount) => {
            const { name } = discount;
            if (!acc[name]) acc[name] = discount;
            else acc.count += 1;
            return acc;
        },
        { count: 0 },
    );
    return count;
}
