import { getVariantKey } from '@cs-admin/services/products';
import { isEmpty, mergeWith } from 'lodash';
import { getSafe } from '@cs-admin/utils/object';
import { generateFirebaseId } from '@cs-admin/utils/id';
import firebase from '@cs-admin/services/firebase/init';
import { PROSPECT } from '@beautycounter/constants/userStates';

/**
 * Save cart data to server
 * @param {Object} cart
 * @param {Number} cartId
 * @param {Number} uid
 * @param {String} userEmail
 */

export async function saveUserCart({
    cart,
    cartId,
    uid,
    userEmail,
    customer,
    activeLocale,
    legacy = false,
    socialId,
    canceled = false,
}) {
    try {
        const database = await firebase.firestore();

        const data = {
            userEmail,
            uid,
            cartLegacy: legacy && cart,
            cartInstances: !legacy && cart,
            cartId,
            activeLocale,
            customer: mapCustomer(customer),
            socialId,
            canceled,
            lastSaved: Date.now(),
        };
        return database
            .collection('carts')
            .doc(cartId.toString())
            .set(data);
    } catch (err) {
        console.info(err);
        return err;
    }
}

function mapCustomer(customer) {
    if (!customer.uid) return {};
    // remove large properties to trim down the cart persisted
    const {
        attributionLog,
        consultantPreferences,
        mainAddress,
        shippingAddresses,
        socials,
        consultant,
        ...customerToSave
    } = customer;
    removeUndefinedProps(customerToSave);
    return customerToSave;
}

function removeUndefinedProps(obj) {
    Object.keys(obj).forEach(key => {
        if (typeof obj[key] === 'undefined') {
            delete obj[key];
        }
    });
}

export async function cancelCart(cartId) {
    return deleteCart(cartId);
}

export async function deleteCart(cartId) {
    try {
        if (!cartId) return false;

        const database = await firebase.firestore();

        return database
            .collection('carts')
            .doc(cartId)
            .delete();
    } catch (err) {
        console.info(err);
        return err;
    }
}

/**
 * Load cart by id
 * @param {String} cid
 */

export async function loadCartById(cid) {
    try {
        if (!cid) return false;

        const database = await firebase.firestore();

        const snapshot = await database
            .collection('carts')
            .where('cartId', '==', cid)
            .get();

        const data = snapshot.docs.map(doc => doc.data());
        return data[0] || false;
    } catch (err) {
        console.info(err);
        return err;
    }
}

/**
 * Load all active carts of a User
 */
export async function loadCartsByUser(uid, legacy = false) {
    try {
        if (!uid) return false;

        const database = await firebase.firestore();

        const snapshot = await database
            .collection('carts')
            .where('uid', '==', uid)
            .where('cartLegacy', '==', legacy)
            .where('canceled', '==', false)
            .get();

        if (snapshot.empty) return [];
        const carts = snapshot.docs.map(doc => doc.data()).filter(x => !x.canceled && !x.socialId);

        return carts;
    } catch (err) {
        console.info(err);
        return err;
    }
}

/**
 * Get first cart of the user
 * Load cart by user id
 * @param {String} uid
 */

export async function loadCartByUser(uid, currentCartId) {
    let result = await loadCartsByUser(uid);
    if (Array.isArray(result)) {
        const currentCart = result.find(x => x.cartId === currentCartId);
        if (currentCart) result = currentCart;
        else
            [result] = result
                .sort(sortByLastSaved)
                .filter(x => !x.customer || x.customer.uid === uid);
    }

    return result;
}

function sortByLastSaved(a, b) {
    return a.lastSaved < b.lastSaved ? 1 : a.lastSaved > b.lastSaved ? -1 : 0;
}

export async function subscribeToCartData(cartId, callback) {
    const database = await firebase.firestore();

    const unsubscribe = database
        .collection('carts')
        .doc(cartId)
        .onSnapshot(data => callback(() => data.data()), e => console.log(e));

    return { unsubscribe, cartId };
}
/**
 * Sorts the product list by date (newest first)
 * @param {Array} items
 */

export function sortByDate(items) {
    return items.sort((a, b) => new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime());
}

/**
 * Merges two cart objects
 * Existing cart wins (current session cart)
 * @param {Object} existingCart
 * @param {Object} newCart
 */

export function mergeCarts(existingCart = {}, newCart = {}) {
    if (isEmpty(newCart) && isEmpty(existingCart)) return {};
    if (isEmpty(newCart)) return existingCart;
    if (isEmpty(existingCart)) return newCart;

    function safeItem(item) {
        if (item && item.quantity && !item.isPromo) return item;
    }

    function filterItem(oldValue, newValue) {
        return safeItem(oldValue);
    }

    return mergeWith(existingCart, {}, filterItem);
}

export function issueCartId() {
    return generateFirebaseId();
}
