export const promoIdAndType = {
    7: 'Buy X Get Y',
};
export const UNITEDSTATES = 1;
export const CANADA = 2;

export const countries = [
    {
        name: 'United States',
        catalog: 'en-US',
        regionCode: UNITEDSTATES,
    },
    {
        name: 'Canada',
        catalog: 'en-CA',
        regionCode: CANADA,
    },
];

export const userTypes = [
    'Consultant',
    'Enrolling Consultant',
    'Client',
    'Member',
    'Enrolling Member',
    'Host',
    'Guest',
    'Prospect',
    'Employee',
];

export const cards = ['condition', 'action'];

export const conditionText = {
    card: 'condition',
    title: 'Condition',
    subtitle: 'A condition is an item needed in the cart to qualify for a discount',
};

export const actionText = {
    card: 'action',
    title: 'Action',
    subtitle: 'An action is an item the customer will receive when they meet the condition',
};

export const durationText = {
    title: 'Promo Duration',
    subtitle:
        'All start dates will auto start at 12:00a PST/PDT and all end dates will auto end at 11:59p PST/PDT depending on time of year',
};
export const noSearchResultsText = `No matches found. Please enter your search again.`;

export const NO_DURATION = 'no-duration';

export const EDITING_DURATION = 'editing-duration';

export const DURATION_SAVED = 'duration-saved';
