import { actionTemplate } from './action';

export const routeListGenerator = (routeConfig, parentUrl = '') => {
    let list = [];
    if (parentUrl === '/') parentUrl = '';
    for (let i = 0; i < routeConfig.length; i += 1) {
        const config = routeConfig[i];
        const { redirectTo, component, to, children } = config;
        if (Array.isArray(to) && (component || redirectTo)) {
            list = [
                ...list,
                ...config.to.map(to => {
                    const configPart = config;
                    configPart.to = `${parentUrl}${to}`;
                    return actionTemplate(configPart);
                }),
            ];
        } else if (component || redirectTo) {
            config.to = `${parentUrl}${to}`;
            list.push(actionTemplate(config));
        }
        if (children) {
            list.unshift(...routeListGenerator(children, `${parentUrl}${to}`));
        }
    }

    return list;
};
