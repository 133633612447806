import { observable, computed, action } from 'mobx';
import { flags } from '@cs-admin/config';

class FlagStore {
    @observable flags;

    constructor() {
        this.flags = flags;
    }

    @computed
    get isFeatureEnabled() {
        return flag => {
            return this.flags[flag];
        };
    }

    @action
    clearForced() {
        this.flags = flags;
    }

    @action
    forceFlag(flag, toggle) {
        this.flags[flag] = toggle;
    }
}

const flagStore = new FlagStore();

export default flagStore;
export { FlagStore };
