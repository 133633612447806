import { observable, observe, computed, action, autorun } from 'mobx';
import { cloneDeep } from 'lodash';
import { getReturns, updateReturnInfo, returnSearch } from '@cs-admin/services/returns';

class ReturnStore {
    @observable returns;

    constructor() {
        this.returns = [];
    }

    ready() {
        if (__BROWSER__) {
            this.mountReactions();
        }
    }

    mountReactions() {
        observe(this.userStore, 'isLogged', async isLogged => {
            if (isLogged) await this.getReturns();
        });
    }

    @action
    async getReturns() {
        this.returns = cloneDeep(await getReturns());
        this.interfaceStore.hideProgress();
    }

    @action
    updateReturnStatus = async data => {
        /**
         * TODO: Add form validation.
         */
        const id = data.docId;
        const returnData = {
            returnStatus: data.returnStatus,
        };
        const response = await updateReturnInfo(id, returnData);

        if (response.success) {
            this.interfaceStore.openAlert(false, 'Successfully submitted');
            this.getReturns();
        } else {
            this.interfaceStore.openAlert(true, response.error.message);
        }

        return response;
    };

    @action
    async searchReturns(query = {}) {
        this.returns = cloneDeep(await returnSearch(query));
    }
}

const returnStore = new ReturnStore();

export default ReturnStore;
export { ReturnStore };
