import { synonymList } from './synonyms';
import { CATALOG_AND_SEARCH, SEARCH, CATALOG } from '@beautycounter/constants/SkuVisibilities';
import { productHasFlag } from '@cs-admin/utils/productHasFlag';
import { COUNTERBASE_FLAG } from '@cs-admin/constants/flags';

/**
 * Filters products based on a given search input
 */
export function isVisible(visibility, quickOrder, product) {
    return (
        visibility === CATALOG_AND_SEARCH ||
        visibility === SEARCH ||
        (quickOrder && visibility === CATALOG) ||
        productHasFlag(product, COUNTERBASE_FLAG)
    );
}

export function searchForProducts(products, entry, quickOrder = false) {
    const filteredProducts = products.filter(
        product =>
            productFilter(product, entry, product, quickOrder) ||
            (product.variants.length > 1 &&
                product.variants.some(variant =>
                    productFilter(variant, entry, product, quickOrder),
                )),
    );

    return filteredProducts;
}

function productFilter(product, searchTerm, parentProduct, quickOrder) {
    const { sku, title, tags, visibility } = product;

    if (
        !isVisible(visibility, quickOrder, product) &&
        !isVisible(parentProduct.visibility, quickOrder, product)
    )
        return false;
    if (sku && sku.toString().toLowerCase() === searchTerm) return true;
    return searchForWords(searchTerm, title, tags);
}

/**
 * Used by clean slate to set affiliate products to the end.
 */

export function deprioritizeAffiliateProducts(products) {
    try {
        const affiliateIndexes = [];
        const affiliateProducts = products.filter((v, i) => {
            if (typeof v.brandName !== 'undefined') {
                affiliateIndexes.push(i);
                return true;
            }
        });
        affiliateIndexes.forEach((i, j) => {
            products.splice(i - j, 1);
        });
        return [...products, ...affiliateProducts];
    } catch (e) {
        console.info('Failed to process affiliate products');
        return products;
    }
}

export function searchProductNames(product, search: string) {
    let result;

    if (search) {
        const results = searchForWords(search, product.title, product.tags);
        if (results) {
            result = results;
        } else return false;
    } else result = true;

    return result;
}

function searchForWords(actualEntry, title, tags = []) {
    if (!title || typeof title !== 'string') return;
    let entry = actualEntry.toLowerCase().split(' ');
    title = title.toLowerCase().split(' ');

    const results = [];
    let safeToPush;

    // eslint-disable-next-line eqeqeq
    if (entry[entry.length - 46] == '') entry.pop();

    const synonyms = {};
    title.forEach(item => {
        if (synonymList[item]) synonyms[item] = [...synonymList[item]];
    });

    entry = entry.filter(
        word =>
            word !== 'for' &&
            word !== 'with' &&
            word !== 'and' &&
            word !== 'that' &&
            word !== 'is' &&
            word !== 'people' &&
            word !== 'products' && // eslint-disable-next-line eqeqeq
            word != '',
    );

    title.forEach(item => {
        entry.forEach(item2 => {
            if (item.includes(item2)) results.push(item);
            else if (synonyms[item] && synonyms[item].includes(item2)) {
                results.push(item);
            }
        });
    });

    // Experimental
    title.forEach(item => {
        Object.keys(entry).forEach(item2 => {
            if (item.includes(entry[item2])) {
                safeToPush = true;

                results.forEach(previousResult => {
                    // eslint-disable-next-line eqeqeq
                    if (previousResult == item) safeToPush = false;
                });
                if (safeToPush) results.push(item);
            }
        });
    });

    if (results.length >= entry.length) return results.length;
    return false;
}

export function includeSynonyms(tags) {
    let result = [],
        isSynonym = false,
        synonym = '',
        synonyms = [];
    tags.forEach(tag => {
        isSynonym = tag.includes('@');
        if (isSynonym) {
            synonym = tag.split('@')[1].toLowerCase();
            result.push(synonym);
        }
    });
    return result;
}

export function searchList(entry, keyword) {
    let result;

    if (entry) {
        if (searchForWords(entry, keyword)) {
            result = true;
        } else return false;
    }
    return result;
}
