import React from 'react';
import { Provider } from 'mobx-react';
import { containers } from '@cs-admin/containers';
import { routes } from '@cs-admin/config';

const actionTemplate = config => ({
    path: config.to,
    async action({ next }) {
        return await next();
    },
    children: [
        ...(() =>
            (config.variables &&
                config.variables.map(path => ({
                    path,
                    action: context => context.route.parent.page(context),
                }))) ||
            [])(),
        {
            path: `*`,
            action: context => context.route.parent.page(context),
        },
    ],
    page: async (context, params) => {
        // definitions
        const { stores, query, path } = context;

        const pagePath = context.path
            .replace(/\\/g, '')
            .replace(/\//g, '')
            .toLowerCase(); // REMOVE: ?

        let {
            dependencies,
            to,
            children,
            component,
            key,
            preRenderCallback,
            preLoadCallBack,
            props,
            variables,
        } = config;

        if (config.requireAuth && !stores.userStore.isLogged) {
            const isLogged = await stores.userStore.verifyLoggedIn();
            if (!isLogged) {
                stores.navigationStore.to({
                    url: routes.LOGIN,
                    reload: true,
                });
            }
        }

        const storeDeps = (dependencies && dependencies.stores) || [];

        if (preLoadCallBack) await preLoadCallBack(context, params, config);

        // perform async calls
        const loadedStores = (await Promise.all([...storeDeps.map(s => containers[s]())]))
            .map((s, i) => ({ [storeDeps[i].replace(/^./, str => str.toLowerCase())]: s.default }))
            .reduce((acc, x) => {
                for (const key in x) acc[key] = x[key];
                return acc;
            }, {});

        // optional route specific pre rendering.
        if (preRenderCallback) preRenderCallback(context, params);

        // creates an object of the route variables keys to received params
        let variablesFormatted = {};
        variables &&
            variables.forEach((variable, i) => {
                try {
                    variablesFormatted = {
                        ...variablesFormatted,
                        [variable]: context.params[variable],
                    };
                } catch (e) {
                    console.error(e);
                }
            });

        if (config.redirectTo) stores.navigationStore.to({ url: config.redirectTo, reload: true });
        else {
            if (!props) props = () => ({});

            // load page wrapper
            const Component = component && (await containers[component]()).default;

            const propsForComponent = {
                route: context.path,
                path: pagePath,
                query,
                variables: variablesFormatted,
                ...props(context, params),
            };

            return {
                chunks: [storeDeps, Component],
                component: (
                    <Provider {...loadedStores}>
                        <Component {...propsForComponent} />
                    </Provider>
                ),
            };
        }
    },
});

export { actionTemplate };
