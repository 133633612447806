// Temp order statuses that get assigned on client side.
// After work on order statuses in BACK-1274 is complete,
// these might be removed.
export const COMPLETED = 'Completed';
export const PENDING_TERMS = 'Pending Terms';
export const PENDING_POPUP = 'Pending Pop-up';
export const NOT_AVAILABLE = 'N/A';

export const UNATTRIBUTED_SPONSOR_NAME = 'Unattributed';

export const customerSearchRegex = {
    // finds all white spaces
    orderIdRegex: /\s+/g,
    // finds all white spaces and searches for initial F/X letters of phoenixId
    phoenixOrderIdRegex: /(\s+|F|X)/g,
};

export const ORDERS_CHUNK_START_PAGE = 1;
export const ORDERS_CHUNK_LIMIT = 20;
