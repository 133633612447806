import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';

const viewBox = {
    x: 0,
    y: 0,
    width: 155.139,
    height: 155.139,
};

class BulkledgerMenuIcon extends Component {
    render() {
        return (
            <Icon {...this.props} altText="BulkledgerMenuIcon" viewBox={viewBox}>
                <svg
                    width="24"
                    height="20"
                    viewBox="0 0 24 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M23 5.71429V16.8571C23 17.4255 22.7771 17.9705 22.3804 18.3724C21.9837 18.7742 21.4456 19 20.8846 19M20.8846 19C20.3236 19 19.7855 18.7742 19.3888 18.3724C18.9921 17.9705 18.7692 17.4255 18.7692 16.8571V1.85714C18.7692 1.62981 18.6801 1.4118 18.5214 1.25105C18.3627 1.09031 18.1475 1 17.9231 1H1.84615C1.62174 1 1.40652 1.09031 1.24783 1.25105C1.08915 1.4118 1 1.62981 1 1.85714V17.2857C1 17.7404 1.1783 18.1764 1.49567 18.4979C1.81303 18.8194 2.24348 19 2.69231 19H20.8846Z"
                        stroke="#707172"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M13.0997 12.0653C13.1062 11.1838 12.7599 10.4866 12.0693 9.99424C11.5358 9.61515 10.8885 9.41741 10.2624 9.22739C8.95811 8.82945 8.57976 8.61984 8.57976 7.9921C8.57976 7.29287 9.48265 7.04408 10.2556 7.04408C10.8185 7.04408 11.4661 7.22023 11.8683 7.48128L12.6657 6.23842C12.1401 5.89547 11.4206 5.66154 10.7102 5.58893V4.42834H9.23934V5.67031C7.93183 5.95979 7.10817 6.81985 7.10817 7.9921C7.10817 8.81308 7.44714 9.46415 8.11374 9.92337C8.62074 10.274 9.23908 10.4622 9.83638 10.6445C11.1157 11.0336 11.6352 11.2708 11.6293 12.0562L11.6293 12.0619C11.6293 12.7211 10.7613 12.9555 10.0177 12.9555C9.31524 12.9555 8.55137 12.6456 8.11838 12.185L7.0498 13.2022C7.59815 13.7859 8.39484 14.1986 9.23931 14.3604V15.5712H10.7102V14.3864C12.1631 14.1771 13.0983 13.2968 13.0997 12.0653Z"
                        fill="#707172"
                    />
                </svg>
            </Icon>
        );
    }
}

export default BulkledgerMenuIcon;
