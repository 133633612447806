import { nogento } from '@cs-admin/config';
import { getSafe } from '@cs-admin/utils/object';
import fetch from '@beautycounter/utils/fetch';
import { cartFields } from './fields';
import { getUserToken } from '@cs-admin/services/user';

/**
 * Default to WEB instead of COUNTERBASE,
 * because the cart calculations are different
 * with the CB channel and we are dealing with end user
 * carts rather than portal order carts.
 */
const channel = 'WEB';

export async function getCart(modifiers = {}) {
    const payload = `
        query {
            cart(${buildParams(modifiers)}) {
                ${cartFields}
            }
        }
    `;
    return handleCall(payload, 'cart');
}

export function mutateCart(action = 'cart', modifiers = {}) {
    const payload = `
            mutation {
                ${action}(${buildParams(modifiers)}, channel: ${channel}) {
                    ${cartFields}
                }
            }
        `;
    return handleCall(payload, action);
}

export async function getCarts(name, modifiers = {}) {
    try {
        const payload = `
                query {
                    ${name}(${buildParams(modifiers)}) {
                        carts { ${cartFields} }
                    }
                }
            `;

        const result = await query(payload);
        if (result && result.data) {
            const cart = getSafe(() => result.data[name].carts);
            return cart;
        }
        throw new Error('no data');
    } catch (error) {
        console.log('Error cart: ', error);
    }
}

export async function handleCall(payload, name, handleError) {
    try {
        const result = await query(payload);
        if (result && result.errors) return { error: result.errors[0].message };
        if (result && result.data) {
            const cart = getSafe(() => result.data[name]);
            return cart;
        }
        throw new Error('no data');
    } catch (error) {
        console.log('Error cart: ', error);
    }
}

export async function query(payload) {
    const token = await getUserToken();
    const result = await fetch(`${nogento.ecomm}/query`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token} Request-Admin`,
        },
        body: JSON.stringify({ query: payload }),
    });
    return result.json();
}

const transcode = type => {
    if (typeof type === 'string') return `"${type}"`;
    if (Array.isArray(type)) return `[${type.map(t => transcode(t)).join(',')}]`;
    if (Object.keys(type).length)
        return `{${Object.keys(type)
            .map(t => `${t}:${transcode(type[t])}`)
            .join(',')}}`;
    return type;
};
const param = (r, p) => (p ? `${r}: ${transcode(p)}` : '');
const buildParams = obj => {
    const arr = [];
    Object.keys(obj).forEach(key => {
        obj[key] && arr.push(`${param(key, obj[key])}`);
    });
    return arr.join(', ');
};
