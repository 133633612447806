import { observable, computed, action, runInAction } from 'mobx';
import { find, uniqBy } from 'lodash';
import { locales as localeDefinitions } from '@cs-admin/config';
import { formatPrice, formatPriceFull } from '@cs-admin/utils/money';
import { getSessionItem } from '@cs-admin/services/session';
import { statesProvinces } from '@beautycounter/constants/geographies';

class LocaleStore {
    @observable locales;
    @observable activeLocale;

    constructor() {
        this.locales = [];
        this.activeLocale = localeDefinitions[0];
        this.autoDetected = false;
    }

    @computed
    get countries() {
        return uniqBy(this.locales, 'countryId');
    }

    /**
     * Map into options prop for Select
     */
    @computed
    get stateOptions() {
        return this.regionsForLocale(this.activeLocale);
    }

    /**
     * Takes a locale and returns all regions associated
     * @param {*} param0
     */
    regionsForLocale({ name } = {}) {
        return statesProvinces
            .filter(region => region.country === name)
            .map(({ short, name, country }) => ({
                short,
                name,
                country,
                selectable: true,
            }));
    }

    @action
    autoDetect = () => {
        /**
         * Temporary client side locale restoration. Executes only on the second
         * render pass to avoid hydration issues after server side render (which is agnostic).
         *
         * This should be moved soon to the edge for a more seamless locale bucketing
         * experience so that the pre-render is already matched to the locale.
         */

        if (typeof navigator !== 'undefined') {
            const lang = navigator.language || navigator.userLanguage;
            const saved = this.sessionStore.getKey('autoLocale');
            if (lang && !saved) {
                const found =
                    find(localeDefinitions, { code: lang.toString() }) ||
                    find(localeDefinitions, { lang });
                if (
                    found &&
                    found.code &&
                    found.code != this.activeLocale.code &&
                    found.code != localeDefinitions[0].code
                ) {
                    setTimeout(() => requestAnimationFrame(() => this.setActive(found)), 1500);
                    this.sessionStore.saveKey('autoLocale', found.code);
                }
            } else if (lang && saved && saved !== this.activeLocale.code) {
                const found = find(localeDefinitions, { code: saved.toString() });
                found && setTimeout(() => requestAnimationFrame(() => this.setActive(found)), 1500);
            }
        }
    };

    @action
    load = params => {
        this.locales = params;
    };

    @action
    localeCountry = code => {
        const [locale] = this.locales.filter(x => x.code === code);
        return locale ? locale.countryId : null;
    };

    @action
    getCountryCurrency = countryId => {
        const [locale] = this.locales.filter(x => x.countryId === countryId);
        return locale
            ? locale.currency
            : {
                  name: 'USD',
                  format: '$0',
              };
    };

    getCountryLocale = countryId => {
        const [locale] = localeDefinitions.filter(
            x => x.countryId === countryId && x.languageId === 1,
        );
        return locale ? locale.code : 'en-US';
    };

    getLocaleById = id => localeDefinitions.find(locale => locale.countryId === id);

    getLocaleByCode = code => localeDefinitions.find(locale => locale.code === code);

    @action
    setLocaleByCode = async (code, firstLoad = false, restoreSession = true) => {
        if (this.activeLocale.code !== code) {
            const locale = find(localeDefinitions, { code });
            await this.setActive(locale, firstLoad, restoreSession);
        }
    };

    @action
    setActive = async (params, firstLoad = false, restoreSession = true) => {
        /* Fetch a copy of the content data for the selected locale */
        this.activeLocale = JSON.parse(JSON.stringify(params));
        this.contentStore.context.flushTags();
        await this.contentStore.get({
            locale: params,
            firstLoad,
        });
        this.sessionStore.getKey('autoLocale') &&
            this.sessionStore.saveKey('autoLocale', params.code);

        this.userStore.saveSignature();

        if (!restoreSession) return;
        runInAction('setting active locale', async () => {
            const user = getSessionItem('user') || {};
            this.sessionStore.restoreSessions(params);
        });
    };

    @action
    modifyActive = async params => {
        if (this.activeLocale.code !== params.code)
            this.activeLocale = JSON.parse(JSON.stringify(params));
    };

    @action
    formatDate = (date, args = {}) => {
        const active = new Date(date || Date.now());

        // See MDN docs for toLocaleString() arguments: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString

        return active.toLocaleDateString(this.activeLocale.code, args);
    };

    @action formatPrice = price => formatPrice(price, this.activeLocale.currency);

    @action formatPriceFull = price => formatPriceFull(price, this.activeLocale.currency);
}

const localeStore = new LocaleStore();

export default localeStore;
export { LocaleStore };
