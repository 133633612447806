import { routes } from '@cs-admin/config';
import { forEach } from 'lodash';
import { getNavigationItemUrl } from '@cs-admin/utils/url';
import { getSafe } from '@cs-admin/utils/object';
import { containers } from '@cs-admin/containers';
import { camelCase } from 'lodash';

const BACK_POINT_PATHS = [routes.PRODUCTS, routes.CATEGORY];

/**
 * Returns whether we can use the path URL
 * as a back point
 * @param {String} path
 */

export function isBackPointPath(path) {
    let isBackPoint = false;

    forEach(BACK_POINT_PATHS, subpath => {
        if (path.includes(subpath)) {
            isBackPoint = true;
        }
    });

    return isBackPoint;
}

/**
 * Refactor raw navigation JSON into
 * the eader navigation
 * @param {Array} data
 */

export function parseNavigation(data) {
    const navigation =
        data &&
        data.map(link => {
            // Category link
            if (getSafe(() => link.fields.magentoId)) {
                const {
                    svgDisplayLocations,
                    svgIcon: svgIconRef,
                    navigationLinkColor,
                } = link.fields;
                let svgIcon = null;
                getSafe(() => {
                    svgIcon = svgIconRef.fields.file.url;
                });
                return {
                    name: link.fields.name,
                    to: getNavigationItemUrl(link.fields.slug),
                    id: link.fields.magentoId,
                    isSpecial: link.fields.special && true,
                    type: 'category',
                    svgDisplayLocations,
                    svgIcon,
                    color: navigationLinkColor,
                };
            }
            // Standard page link
            if (getSafe(() => link.fields.name))
                return {
                    name: link.fields.name,
                    to: link.fields.url,
                    type: 'link',
                };
            return null;
        });

    return navigation;
}

export async function loadContainer(name) {
    const container = await containers[name]();
    return container.default;
}

export async function asyncLoadStore(name, stores) {
    const instance = camelCase(name);
    if (stores[instance]) return stores[instance];
    const container = await containers[name]();
    const module = container.default;
    stores[instance] = new module();
    injectStores(stores, stores[instance], instance);

    return stores[instance];
}
