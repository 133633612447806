import Validation from './validators';
import { subscriptionItemsOnlyDiscountPromo } from './subscriptions/subscriptionItemsOnlyDiscountPromo.js';
import { subscriptionItemsOnlyPercentPromo } from './subscriptions/subscriptionItemsOnlyPercentPromo.js';
import exclusionItemsAddressFormMatch from '../utils/exclusionItemsAddressFormMatch.js';
import handleDoNotStackRules from './handleDoNotStackRules';
import { DROP_IDS } from '../utils/constants/promoIDs';

export const GWP = 7;
export const cartGWP = 13;
export const gwpIds = [GWP, cartGWP];

const ENTERING_CHECKOUT = 1;

export function calculatePromos(context, ruleset, hostRewardProducts) {
    if (!validateInput(context, ruleset)) return context;

    let newContext = JSON.parse(JSON.stringify(context));
    if (!newContext.user.regionId && newContext.user.countryId)
        newContext.user.regionId = newContext.user.countryId;

    if (!newContext.user.regionId && newContext.user.region)
        newContext.user.regionId = newContext.user.region;

    const newCart = JSON.parse(JSON.stringify(newContext.cart));
    newContext.cart.appliedPromos = [];
    newContext.cart.cartCounterPromos = [];
    const ccPromosMap = {};
    // Logic added to use bundled sku child items as a condition item
    const { bundledSkus } = getBundledSkus(newCart.products);
    const intCart = newCart.products.filter(x => !x.isHostReward).map(c => Array(1).fill(c.sku));
    let expCart = [].concat.apply([], intCart);
    let matchedRules = [];
    let matches = 0;

    // match all conditions
    const validatedRules = ruleset.filter(rule => Validation.all(rule, newContext).isValid);

    for (const rule of validatedRules) {
        let conditionMatches = 0;

        // Initialize Cart Counter Promos
        if (rule.cartCounter) {
            const ccRule = mapPromoRuleToCCRule(rule);
            ccPromosMap[ccRule.name] = ccRule;

            const validatedRulesCopy = [...validatedRules];
            const sortedValidatedRules = validatedRulesCopy.sort((a, b) => a.priority - b.priority);

            // Add flag 'stopped: true' if cc promo is stopped by higher priority rule
            if (sortedValidatedRules.findIndex(v => v.stop) > -1) {
                const stoppedRules = sortedValidatedRules.slice(
                    matchedRules.findIndex(v => v.stop) + 1,
                );
                stoppedRules.forEach(rule => {
                    if (ccPromosMap[rule.name]) {
                        const ccPromo = ccPromosMap[rule.name];
                        ccPromo.stopped = true;
                        ccPromosMap[rule.name] = ccPromo;
                    }
                });
            }
            /*
                Add flag 'competingRuleId: true'
                if promo with same id and higher priority
                will be applied first, preventing cc promo application
            */
            sortedValidatedRules.forEach(svRule => {
                if (svRule.id === rule.id && svRule.priority < rule.priority) {
                    const ccPromo = ccPromosMap[rule.name];
                    ccPromo.competingRuleId = true;
                    ccPromosMap[rule.name] = ccPromo;
                }
            });
        }

        if (noConditionGWP(rule)) {
            conditionMatches = 1;
        } else if (rule.conditionItems != null && !DROP_IDS.some(d => d == rule.id)) {
            const matchArray = rule.conditionItems;
            if (rule.bundledSkus) {
                expCart = expCart.concat(bundledSkus);
            }

            [expCart, matches] = pairUp(expCart, matchArray);
            conditionMatches += matches;
        } else {
            // this applies to everything
            conditionMatches = 1;
        }
        if (conditionMatches > 0) {
            matchedRules.push({
                id: rule.id,
                name: rule.name,
                count: conditionMatches,
                priority: rule.priority,
                stop: rule.stop,
            });
        }
    }
    newContext.cart.cartCounterPromos = Object.values(ccPromosMap);

    // sort by priority
    matchedRules = matchedRules.sort((a, b) => a.priority - b.priority);

    // remove any rule after the first 'stop' rule, check for and update stopped cart counter promos
    if (matchedRules.findIndex(v => v.stop) > -1) {
        matchedRules = matchedRules.slice(0, matchedRules.findIndex(v => v.stop) + 1);
    }

    // apply all actions

    for (const match of matchedRules) {
        let rule = ruleset.find(v => v.name == match.name);

        // apply the rule as many times as we are allowed
        for (
            let i = 0;
            i < match.count &&
            (rule.actionQuantity == null ||
                rule.actionQuantity.max == null ||
                i < rule.actionQuantity.max);
            i++
        ) {
            // Host reward items will only go towards the free shipping for Members promo
            const freeShipping = 15;
            if (rule.id === freeShipping) {
                rule = { ...rule, rewardProducts: hostRewardProducts || [] };
            }
            let temp;

            try {
                temp = applyRule(newContext, rule);
            } catch (e) {
                // temp is empty array, as
                // all rule calculations return an Array
                // no actions in the following if
                // statement will be taken
                temp = [];
                console.error(e, rule.name);
            }

            if (temp != '{}') {
                const { newAppliedPromos, newCartCounterPromos } = sortPromos(temp);

                if (newAppliedPromos) {
                    newContext.cart.appliedPromos = newContext.cart.appliedPromos.concat(
                        newAppliedPromos,
                    );
                }

                if (newCartCounterPromos) {
                    newCartCounterPromos.forEach(promo => {
                        const { name, qualifyingSubtotal } = promo;
                        if (ccPromosMap[name]) {
                            const ccPromo = { ...ccPromosMap[name] };
                            ccPromo.qualifyingSubtotal = qualifyingSubtotal;
                            ccPromosMap[name] = ccPromo;
                        }
                    });
                    newContext.cart.cartCounterPromos = Object.values(ccPromosMap);
                }

                if (rule.code)
                    newContext.cart.promoTags.forEach(t => {
                        if (
                            rule.code.some(r => r.toUpperCase() == t.code.toUpperCase()) &&
                            newContext.cart.appliedPromos.some(p => p.name == rule.name)
                        )
                            newContext.cart.appliedPromos.push({
                                id: 20,
                                code: t.code,
                                name: rule.name,
                                discount: 0,
                            });
                    });

                newContext.cart.appliedPromos = handleDoNotStackRules(
                    newContext.cart.appliedPromos,
                );
            }
        }
    }
    resolveGWP(newContext);
    newContext = handleDoubleDiscounts(newContext);
    dropPromoItems(newContext);
    return newContext;
}

function noConditionGWP(rule) {
    return rule.conditionItems != null && rule.conditionItems.length === 0 && rule.id === GWP;
}

function validateInput(context, ruleset) {
    return (
        context &&
        context.cart &&
        context.cart.products &&
        Array.isArray(context.cart.products) &&
        context.cart.products.length >= 0 &&
        (!context.shipping || (context.shipping && context.shipping.method)) &&
        ruleset &&
        ruleset.length &&
        ruleset.length > 0
    );
}

function dropPromoItems(context) {
    if (context.cart.promoItems) {
        const promoMap = {};
        context.cart.appliedPromos
            .filter(promo => promo.sku)
            .forEach(({ sku }) => {
                if (!sku) return;
                if (promoMap[sku]) promoMap[sku]++;
                else promoMap[sku] = 1;
            });
        context.cart.promoItems.forEach(({ sku, quantity, price }) => {
            const toRemove = quantity - (promoMap[sku] || 0);
            if (toRemove > 0) {
                context.cart.appliedPromos = [
                    {
                        id: 19,
                        name: 'DropItem',
                        sku,
                        quantity: toRemove,
                        amount: price * toRemove,
                    },
                    ...context.cart.appliedPromos,
                ];
            }
        });
    }
}

export function resolveGWP(context) {
    const { cart } = { ...context };
    const { products, promoItems } = cart || {};
    const gwpPromos = cart.appliedPromos.filter(
        ({ sku, id, redemptionKey }) =>
            sku && ((id == GWP && !redemptionKey) || (id == cartGWP && !redemptionKey)),
    );
    const skus = [];
    gwpPromos.forEach(({ sku }) => {
        if (!skus.some(s => s == sku)) skus.push(sku);
    });

    skus.forEach(s => {
        let qty = getProductQuantity(products, s);
        qty += getProductQuantity(promoItems, s);

        const tgwpPromos = gwpPromos.filter(({ sku }) => sku == s);
        if (tgwpPromos.length >= qty) {
            for (let i = 0; i < tgwpPromos.length - qty; i++) {
                context.cart.appliedPromos.push({
                    id: 7,
                    name: 'GWPResolution',
                    selection: [s],
                    discount: 0,
                });
            }
        }
    });
}

function getProductQuantity(products, s) {
    const { quantity: productQuantity } = products.find(({ sku }) => sku == s) || {};
    return productQuantity || 0;
}

export function handleDoubleDiscounts(context) {
    const newContext = JSON.parse(JSON.stringify(context));
    const { cart } = newContext;
    const gwpPromos = cart.appliedPromos.filter(
        ({ sku, id, redemptionKey }) => sku && (id == GWP || id == cartGWP) && !redemptionKey,
    );
    const skus = [];
    gwpPromos.forEach(({ sku }) => {
        if (!skus.some(s => s == sku)) skus.push(sku);
    });

    skus.forEach(s => {
        const appliedPromos = cart.appliedPromos.filter(
            ({ id, sku }) => !(!DROP_IDS.some(d => d == id) && sku && sku == s),
        );
        let skuAppliedPromos = cart.appliedPromos.filter(
            ({ id, sku }) => !DROP_IDS.some(d => d == id) && sku && sku == s,
        );

        const promos = {};
        const names = [];

        skuAppliedPromos = skuAppliedPromos.filter(({ id }) => id != GWP && id != cartGWP);

        skuAppliedPromos.forEach(promo => {
            const { name } = promo;
            if (promos[name]) {
                promos[name].push(promo);
            } else {
                names.push(name);
                promos[name] = [promo];
            }
        });

        let qty =
            (cart.products.find(({ sku }) => sku == s) &&
                cart.products.find(({ sku }) => sku == s).quantity) ||
            0;
        qty +=
            (cart.promoItems.find(({ sku }) => sku == s) &&
                cart.promoItems.find(({ sku }) => sku == s).quantity) ||
            0;

        const skuGWPPromos = gwpPromos.filter(({ sku }) => sku == s);

        if (skuGWPPromos.length >= qty) {
            skuGWPPromos.reverse();
            for (; qty > 0; qty--) {
                appliedPromos.push(skuGWPPromos.pop());
            }
        } else {
            qty -= skuGWPPromos.length;
            skuGWPPromos.forEach(p => {
                appliedPromos.push(p);
            });
            names.forEach(n => {
                for (let i = 0; i < qty; i++) {
                    appliedPromos.push(promos[n][i]);
                }
            });
        }
        newContext.cart.appliedPromos = appliedPromos;
    });
    const { appliedPromos } = newContext.cart;
    newContext.cart.appliedPromos = appliedPromos.filter(
        ({ id, redemptionKey }) => (id !== GWP && !redemptionKey) || id === GWP,
    );
    return newContext;
}

export function noConditionPromo(context, rule) {
    const { cart } = context;
    const { products } = cart || {};
    const { conditionQuantity = {}, exclusionItems = [] } = rule;
    let ret = [];

    const { min = 0 } = conditionQuantity;

    let promos = products
        .filter(i => (!rule.exclusionItems ? i : !rule.exclusionItems.some(r => r === i.sku)))
        .map(i => ({
            quantity: i.quantity,
            id: rule.id,
            sku: i.sku,
            name: rule.name,
            discount: (i.price * rule.discountValue[0]).toFixed(4),
            redemptionKey: i.redemptionKey,
            subscription: i.subscription,
        }));

    const productsThatApply = products.filter(({ sku }) => !exclusionItems.includes(sku)) || [];
    const combinedQuantity = productsThatApply.reduce((accum, product) => {
        accum += product.quantity;
        return accum;
    }, 0);

    if (min > 0) {
        if (combinedQuantity >= min) {
            promos = productsThatApply.map(i => ({
                quantity: i.quantity,
                id: rule.id,
                sku: i.sku,
                name: rule.name,
                discount: (i.price * rule.discountValue[0]).toFixed(4),
                redemptionKey: i.redemptionKey,
                subscription: i.subscription,
            }));
        } else {
            promos = [];
        }
    }

    promos.forEach(i => {
        const temp = new Array(i.quantity).fill(
            JSON.stringify({
                id: i.id,
                final_sale: rule.final_sale || false,
                do_not_ship_alone: rule.do_not_ship_alone || false,
                sku: i.sku,
                name: i.name,
                discount: Number(i.discount),
                limitOnePerEmail: rule.limitOnePerEmail || false,
                redemptionKey: i.redemptionKey || null,
                subscription: i.subscription,
                doNotStack: rule.doNotStack || false,
            }),
        );
        ret = [...ret, ...temp];
    });

    return ret;
}

export function byDiscountPromo(context, rule) {
    const ret = [];
    let max = 1;
    // keeping backwards compatibility cause promos are the wild wild west
    const noSelection = rule.noSelection || false;
    if (rule.actionQuantity && rule.actionQuantity.max) max = rule.actionQuantity.max;
    let skip = 1;
    if (rule.conditionQuantity && rule.conditionQuantity.skip) skip = rule.conditionQuantity.skip;
    let count = 0;
    for (let i = 0; i < context.cart.products.length; i++) {
        if (
            rule.conditionItems.includes(context.cart.products[i].sku) &&
            context.cart.products[i].quantity >= rule.conditionQuantity.min
        ) {
            for (let k = 0; k < rule.actionItems.length; k++) {
                const productsFindResult = context.cart.products.find(
                    c => c.sku == rule.actionItems[k],
                );
                const promoItemsFindResult = context.cart.promoItems.find(
                    d => d.sku == rule.actionItems[k],
                );
                if (
                    count < max &&
                    (productsFindResult != undefined ||
                        (context.cart.promoItems && promoItemsFindResult != undefined))
                ) {
                    let run = 0;
                    let qty = 0;
                    if (!rule.autoAdd) {
                        productsFindResult ? (qty += productsFindResult.quantity) : (qty += 0);
                    }
                    promoItemsFindResult ? (qty += promoItemsFindResult.quantity) : (qty += 0);
                    qty = ~~(qty / skip);
                    if (!(rule.actionQuantity && rule.actionQuantity.max)) max = qty;
                    count + qty < max ? (run = qty) : (run = max - count);
                    count += run;
                    for (let r = 0; r < run; r++) {
                        ret.push(
                            JSON.stringify({
                                final_sale: rule.final_sale || false,
                                do_not_ship_alone: rule.do_not_ship_alone || false,
                                sku: rule.actionItems[k],
                                id: rule.id,
                                name: rule.name,
                                autoAdd: rule.autoAdd,
                                discount: rule.discountValue[context.user.regionId - 1] || 0,
                                limitOnePerEmail: rule.limitOnePerEmail || false,
                            }),
                        );
                    }
                }
            }
            /**
             * For some reason this code would drop items into the cart. I wish I was better person
             * than this, but !noSelection works for now.
             *
             */

            if (count < max) {
                for (let j = count; j < max; j++) {
                    ret.push(
                        JSON.stringify({
                            id: rule.id,
                            name: rule.name,
                            selection: !noSelection ? rule.actionItems : [],
                            discount: 0,
                            autoAdd: rule.autoAdd,
                            limitOnePerEmail: rule.limitOnePerEmail || false,
                        }),
                    );
                }
            }
            break;
        }
    }
    return ret;
}

export function byPercentPromo(context, rule) {
    const {
        id,
        name,
        actionItems = [],
        actionQuantity = {},
        conditionQuantity = {},
        conditionItems = [],
        discountValue = [],
        autoAdd,
        oneForOne,
        limitOnePerEmail,
        final_sale,
        do_not_ship_alone,
        // new field for tracking promo in SAP
        tag,
    } = rule;
    const { min } = conditionQuantity;
    const noSelection = rule.noSelection || false;
    const { cart } = context;
    const { products, promoItems } = cart || {};

    const byPercentAppliedPromos = [];
    let max = 0;

    if (actionQuantity && actionQuantity.perConditionItem) {
        conditionItems.forEach(s => {
            max += getProductQuantity(products, s);
            max += getProductQuantity(promoItems, s);
        });
        max *= actionQuantity.perConditionItem;
    } else max = 1;

    if (actionQuantity && actionQuantity.max) max = actionQuantity.max;
    let skip = 1;
    if (conditionQuantity && conditionQuantity.skip) skip = conditionQuantity.skip;
    let count = 0;

    // Logic added to allow combination of conditionItems in cart to contribute to max threshold
    if (isValidCombineConditionItemsRule(rule)) {
        const combinedQuantity = products.reduce((accum, product) => {
            if (conditionItems.includes(product.sku)) accum += product.quantity;
            return accum;
        }, 0);

        if (combinedQuantity >= min) {
            for (let i = 0; i < cart.products.length; i++) {
                if (
                    (noConditionGWP(rule) || conditionItems.includes(cart.products[i].sku)) &&
                    (!conditionQuantity || !conditionQuantity.min || combinedQuantity >= min)
                ) {
                    for (let k = 0; k < actionItems.length; k++) {
                        const productsFindResult = cart.products.find(
                            ({ sku }) => sku == actionItems[k],
                        );
                        const promoItemsFindResult = cart.promoItems.find(
                            ({ sku }) => sku == actionItems[k],
                        );
                        if (
                            count < max &&
                            (productsFindResult != undefined ||
                                (cart.promoItems && promoItemsFindResult != undefined))
                        ) {
                            let run = 0;
                            let qty = 0;
                            if (!autoAdd) {
                                productsFindResult
                                    ? (qty += productsFindResult.quantity)
                                    : (qty += 0);
                            }
                            promoItemsFindResult
                                ? (qty += promoItemsFindResult.quantity)
                                : (qty += 0);
                            qty = ~~(qty / skip);
                            if (
                                !(
                                    actionQuantity &&
                                    (actionQuantity.max || actionQuantity.perConditionItem)
                                )
                            )
                                max = qty;
                            count + qty < max ? (run = qty) : (run = max - count);
                            count += run;
                            if (oneForOne) {
                                for (let m = 0; m < actionItems.length; m++) {
                                    for (let r = 0; r < run; r++) {
                                        byPercentAppliedPromos.push(
                                            JSON.stringify({
                                                final_sale: final_sale || false,
                                                do_not_ship_alone: do_not_ship_alone || false,
                                                sku: actionItems[m],
                                                id,
                                                autoAdd,
                                                name,
                                                discount:
                                                    (
                                                        cart.promoItems.find(
                                                            d => d.sku == rule.actionItems[m],
                                                        ) || {}
                                                    ).price * rule.discountValue[0],
                                                limitOnePerEmail: limitOnePerEmail || false,
                                            }),
                                        );
                                    }
                                }
                            } else {
                                for (let r = 0; r < run; r++) {
                                    byPercentAppliedPromos.push(
                                        JSON.stringify({
                                            final_sale: final_sale || false,
                                            do_not_ship_alone: do_not_ship_alone || false,
                                            sku: actionItems[k],
                                            id,
                                            name,
                                            autoAdd,
                                            discount:
                                                productsFindResult != undefined
                                                    ? productsFindResult.price *
                                                      rule.discountValue[0]
                                                    : promoItemsFindResult.price *
                                                      rule.discountValue[0],
                                            limitOnePerEmail: limitOnePerEmail || false,
                                        }),
                                    );
                                }
                            }
                        }
                    }
                    /**
                     * For some reason this code would drop items into the cart. I too wish I was better person
                     * than this, but it was good enough for Abby and !noSelection works for now.
                     *
                     */

                    if (count < max) {
                        if (oneForOne) {
                            for (let m = 0; m < actionItems.length; m++) {
                                for (let j = count; j < max; j++) {
                                    byPercentAppliedPromos.push(
                                        JSON.stringify({
                                            id,
                                            name,
                                            selection: [actionItems[m]],
                                            discount: discountValue[0],
                                            autoAdd,
                                            limitOnePerEmail: limitOnePerEmail || false,
                                        }),
                                    );
                                }
                            }
                        } else {
                            for (let j = count; j < max; j++) {
                                byPercentAppliedPromos.push(
                                    JSON.stringify({
                                        id,
                                        name,
                                        selection: !noSelection ? actionItems : [],
                                        discount: 0,
                                        autoAdd,
                                        limitOnePerEmail: limitOnePerEmail || false,
                                    }),
                                );
                            }
                        }
                    }
                    break;
                }
            }
        }
    } else {
        /*
            Enables Start Counting Milestones, which have no conditionItems
            Allows rule to be eligible with no items in cart
        */
        if (!cart.products.length && (!conditionItems || !conditionItems.length)) {
            applyAction();
        } else {
            for (let i = 0; i < cart.products.length; i++) {
                if (
                    (noConditionGWP(rule) || conditionItems.includes(cart.products[i].sku)) &&
                    (!conditionQuantity ||
                        !conditionQuantity.min ||
                        cart.products[i].quantity >= conditionQuantity.min)
                ) {
                    applyAction();
                }
            }
        }

        function applyAction() {
            const { redemptionType } = rule.conditions || {};

            for (let k = 0; k < actionItems.length; k++) {
                const productsFindResult = cart.products.find(({ sku, redemptionKey }) => {
                    const skuMatchesActionItem = sku == rule.actionItems[k];
                    const skusMatchAndNoRedemptionType = skuMatchesActionItem && !redemptionType;
                    const skusMatchAndRedemptionTypeKeyMatch =
                        skuMatchesActionItem && redemptionKey === redemptionType;
                    return skusMatchAndNoRedemptionType || skusMatchAndRedemptionTypeKeyMatch;
                });
                const promoItemsFindResult = cart.promoItems.find(
                    ({ sku }) => sku == actionItems[k],
                );
                if (
                    count < max &&
                    (productsFindResult != undefined ||
                        (cart.promoItems && promoItemsFindResult != undefined))
                ) {
                    let run = 0;
                    let qty = 0;
                    if (!autoAdd) {
                        productsFindResult ? (qty += productsFindResult.quantity) : (qty += 0);
                    }
                    promoItemsFindResult ? (qty += promoItemsFindResult.quantity) : (qty += 0);
                    qty = ~~(qty / skip);
                    if (
                        !(actionQuantity && (actionQuantity.max || actionQuantity.perConditionItem))
                    )
                        max = qty;
                    count + qty < max ? (run = qty) : (run = max - count);
                    count += run;
                    if (oneForOne) {
                        for (let m = 0; m < actionItems.length; m++) {
                            for (let r = 0; r < run; r++) {
                                byPercentAppliedPromos.push(
                                    JSON.stringify({
                                        final_sale: final_sale || false,
                                        do_not_ship_alone: do_not_ship_alone || false,
                                        sku: actionItems[m],
                                        id,
                                        autoAdd,
                                        name,
                                        discount:
                                            (
                                                cart.promoItems.find(
                                                    d => d.sku == rule.actionItems[m],
                                                ) || {}
                                            ).price * rule.discountValue[0],
                                        limitOnePerEmail: limitOnePerEmail || false,
                                        redemptionKey: redemptionType || null,
                                        tag: tag || null,
                                    }),
                                );
                            }
                        }
                    } else {
                        for (let r = 0; r < run; r++) {
                            byPercentAppliedPromos.push(
                                JSON.stringify({
                                    final_sale: final_sale || false,
                                    do_not_ship_alone: do_not_ship_alone || false,
                                    sku: actionItems[k],
                                    id,
                                    name,
                                    autoAdd,
                                    discount:
                                        productsFindResult != undefined
                                            ? productsFindResult.price * rule.discountValue[0]
                                            : promoItemsFindResult.price * rule.discountValue[0],
                                    limitOnePerEmail: limitOnePerEmail || false,
                                    redemptionKey: redemptionType || null,
                                    tag: tag || null,
                                }),
                            );
                        }
                    }
                }
            }
            /**
             * For some reason this code would drop items into the cart. I too wish I was better person
             * than this, but it was good enough for Abby and !noSelection works for now.
             *
             */
            if (count < max) {
                if (oneForOne) {
                    for (let m = 0; m < actionItems.length; m++) {
                        for (let j = count; j < max; j++) {
                            byPercentAppliedPromos.push(
                                JSON.stringify({
                                    id,
                                    name,
                                    selection: [actionItems[m]],
                                    discount: discountValue[0],
                                    autoAdd,
                                    limitOnePerEmail: limitOnePerEmail || false,
                                    redemptionKey: redemptionType || null,
                                    tag: tag || null,
                                }),
                            );
                        }
                    }
                } else {
                    for (let j = count; j < max; j++) {
                        byPercentAppliedPromos.push(
                            JSON.stringify({
                                id,
                                name,
                                selection: !noSelection ? actionItems : [],
                                discount: 0,
                                autoAdd,
                                limitOnePerEmail: limitOnePerEmail || false,
                                redemptionKey: redemptionType || null,
                                tag: tag || null,
                            }),
                        );
                    }
                }
            }
        }
    }

    return byPercentAppliedPromos.slice(0, actionQuantity.max || byPercentAppliedPromos.length);
}

export function cartDiscountPromo(context, rule) {
    let subtotal = context.cart.products.reduce((acc, val) => acc + val.price * val.quantity, 0);

    subtotal += context.cart.promoItems
        .filter(
            p =>
                context.cart.appliedPromos.some(a => a.sku && a.sku == p.sku) &&
                (!rule.exclusionItems || !rule.exclusionItems.some(e => e == p.sku)),
        )
        .reduce((acc, val) => acc + val.price * val.quantity, 0);

    if (rule.exclusionItems) {
        subtotal -= context.cart.products
            .filter(i => rule.exclusionItems.includes(i.sku))
            .reduce((a, e) => a + e.price * e.quantity, 0);
    }

    subtotal -= context.cart.appliedPromos
        .filter(a => !a.sku || !rule.exclusionItems.some(e => e == a.sku))
        .reduce((a, p) => a + p.discount, 0);

    if (
        subtotal > rule.conditionQuantity.min &&
        (!rule.conditionItems ||
            rule.conditionItems.some(c => context.cart.products.some(i => i.sku == c)))
    ) {
        return JSON.stringify({
            name: rule.name,
            id: rule.id,
            discount: rule.discountValue[0],
            limitOnePerEmail: rule.limitOnePerEmail || false,
        });
    }
    return '{}';
}

export function cartPercentPromo(context, rule) {
    const { cart } = context;
    const {
        id,
        name,
        exclusionItems,
        excludeFromSubtotal = [],
        conditionItems,
        conditionQuantity,
        final_sale = false,
        do_not_ship_alone = false,
        discountValue = [],
        limitOnePerEmail = false,
    } = rule;

    let ret = [];

    let subtotal = cart.products.reduce((acc, { price, quantity }) => acc + price * quantity, 0);

    subtotal += cart.promoItems
        .filter(
            p =>
                cart.appliedPromos.some(({ sku }) => sku && sku == p.sku) &&
                (!exclusionItems || !exclusionItems.some(e => e == p.sku)),
        )
        .reduce((acc, { price, quantity }) => acc + (price || 0) * (quantity || 0), 0);

    if (exclusionItems && !excludeFromSubtotal.length) {
        subtotal -= cart.products
            .filter(i => exclusionItems.includes(i.sku))
            .reduce((a, { price, quantity }) => a + price * quantity, 0);
    }

    if (excludeFromSubtotal.length) {
        subtotal -= cart.products
            .filter(({ sku }) => excludeFromSubtotal.includes(sku))
            .reduce((a, { price, quantity }) => a + price * quantity, 0);
    }

    // added logic to support FF-2021 tiered promo. this can be refined with proper tiered promo support work
    subtotal -= !excludeFromSubtotal.length
        ? cart.appliedPromos
              .filter(({ sku }) => !exclusionItems.some(e => e == sku))
              .reduce((a, { discount }) => a + discount, 0)
        : cart.appliedPromos.reduce((a, { discount }) => a + discount, 0);

    if (
        subtotal > conditionQuantity.min &&
        (!conditionItems || conditionItems.some(c => cart.products.some(({ sku }) => sku == c)))
    ) {
        const promos = cart.products
            .filter(i => (!exclusionItems ? i : !exclusionItems.some(r => r == i.sku)))
            .map(({ quantity, sku, price }) => ({
                quantity,
                id,
                sku,
                name,
                discount: (price * discountValue[0]).toFixed(4),
            }));
        promos.forEach(({ quantity, id, sku, name, discount }) => {
            const temp = new Array(quantity).fill(
                JSON.stringify({
                    id,
                    final_sale,
                    do_not_ship_alone,
                    sku,
                    name,
                    discount: Number(discount),
                    limitOnePerEmail,
                }),
            );
            ret = [...ret, ...temp];
        });
    }
    return ret;
}

export function cartItemDiscountPromo(context, rule) {
    const ret = [];
    let max = 1;
    if (rule.actionQuantity && rule.actionQuantity.max) max = rule.actionQuantity.max;
    let skip = 1;
    if (rule.conditionQuantity && rule.conditionQuantity.skip) skip = rule.conditionQuantity.skip;
    let count = 0;

    let subtotal = context.cart.products.reduce((acc, val) => acc + val.price * val.quantity, 0);
    subtotal += context.cart.promoItems
        .filter(
            p =>
                context.cart.appliedPromos.some(a => a.sku && a.sku == p.sku) &&
                (!rule.exclusionItems || !rule.exclusionItems.some(e => e == p.sku)),
        )
        .reduce((acc, val) => acc + val.price * val.quantity, 0);

    if (rule.exclusionItems) {
        subtotal -= context.cart.products
            .filter(i => rule.exclusionItems.includes(i.sku))
            .reduce((a, e) => a + e.price * e.quantity, 0);
    }

    subtotal -= context.cart.appliedPromos
        .filter(a => !a.sku || !rule.exclusionItems.some(e => e == a.sku))
        .reduce((a, p) => a + p.discount, 0);

    if (
        subtotal > rule.conditionQuantity.min &&
        (!rule.conditionItems ||
            rule.conditionItems.some(c => context.cart.products.some(i => i.sku == c)))
    ) {
        for (let k = 0; k < rule.actionItems.length; k++) {
            const productsFindResult = context.cart.products.find(
                c => c.sku == rule.actionItems[k],
            );
            const promoItemsFindResult = context.cart.promoItems.find(
                d => d.sku == rule.actionItems[k],
            );
            if (
                count < max &&
                (productsFindResult != undefined ||
                    (context.cart.promoItems && promoItemsFindResult != undefined))
            ) {
                let run = 0;
                let qty = 0;
                if (!rule.autoAdd) {
                    productsFindResult ? (qty += productsFindResult.quantity) : (qty += 0);
                }
                promoItemsFindResult ? (qty += promoItemsFindResult.quantity) : (qty += 0);
                qty = ~~(qty / skip);
                if (!(rule.actionQuantity && rule.actionQuantity.max)) max = qty;
                count + qty < max ? (run = qty) : (run = max - count);
                count += run;
                for (let r = 0; r < run; r++) {
                    ret.push(
                        JSON.stringify({
                            final_sale: rule.final_sale || false,
                            do_not_ship_alone: rule.do_not_ship_alone || false,
                            sku: rule.actionItems[k],
                            id: rule.id,
                            name: rule.name,
                            discount: rule.discountValue[context.user.regionId - 1] || 0,
                            limitOnePerEmail: rule.limitOnePerEmail || false,
                        }),
                    );
                }
            }
        }
        if (count < max) {
            for (let j = count; j < max; j++) {
                ret.push(
                    JSON.stringify({
                        name: rule.name,
                        id: rule.id,
                        selection: rule.actionItems,
                        discount: 0,
                        limitOnePerEmail: rule.limitOnePerEmail || false,
                    }),
                );
            }
        }
    }
    return ret;
}

export function cartItemPercentPromo(context, rule) {
    const ret = [];
    let max = 1;
    if (rule.actionQuantity && rule.actionQuantity.perConditionItem) {
        rule.conditionItems.forEach(s => {
            max =
                (context.cart.products.find(p => p.sku == s) &&
                    context.cart.products.find(p => p.sku == s).quantity) ||
                0;
            max +=
                (context.cart.promoItems.find(p => p.sku == s) &&
                    context.cart.promoItems.find(p => p.sku == s).quantity) ||
                0;
        });
        max *= rule.actionQuantity.perConditionItem;
    }
    if (rule.actionQuantity && rule.actionQuantity.max) max = rule.actionQuantity.max;
    let skip = 1;
    if (rule.conditionQuantity && rule.conditionQuantity.skip) skip = rule.conditionQuantity.skip;
    let count = 0;

    let subtotal = context.cart.products.reduce((acc, val) => acc + val.price * val.quantity, 0);

    subtotal += context.cart.promoItems
        .filter(
            p =>
                context.cart.appliedPromos.some(a => a.sku && a.sku == p.sku) &&
                (!rule.exclusionItems || !rule.exclusionItems.some(e => e == p.sku)),
        )
        .reduce((acc, val) => acc + val.price * val.quantity, 0);

    if (rule.exclusionItems) {
        subtotal -= context.cart.products
            .filter(i => rule.exclusionItems.includes(i.sku))
            .reduce((a, e) => a + e.price * e.quantity, 0);
    }

    if (rule.excludeFromSubtotal && rule.excludeFromSubtotal.length) {
        subtotal -= context.cart.products
            .filter(i => rule.excludeFromSubtotal.includes(i.sku))
            .reduce((a, e) => a + e.price * e.quantity, 0);
    }

    subtotal -= context.cart.appliedPromos
        .filter(a => !a.sku || !rule.exclusionItems.some(e => e == a.sku))
        .reduce((a, p) => a + p.discount, 0);

    // Logic added to use bundled sku child items to be used as a condition item
    const { products } = context.cart;
    const { bundledSkus, bundledProducts } = getBundledSkus(products);
    const regularAndBundledProducts = products.concat(bundledProducts);

    if (
        subtotal > rule.conditionQuantity.min &&
        (!rule.conditionItems ||
            rule.conditionItems.some(c => context.cart.products.some(i => i.sku == c)) ||
            (rule.bundledSkus &&
                rule.conditionItems.some(c => regularAndBundledProducts.some(i => i.sku == c))))
    ) {
        for (let k = 0; k < rule.actionItems.length; k++) {
            const productsFindResult = context.cart.products.find(
                c => c.sku == rule.actionItems[k],
            );
            const promoItemsFindResult = context.cart.promoItems.find(
                d => d.sku == rule.actionItems[k],
            );
            if (
                count < max &&
                (productsFindResult != undefined ||
                    (context.cart.promoItems && promoItemsFindResult != undefined))
            ) {
                let run = 0;
                let qty = 0;
                if (!rule.autoAdd) {
                    productsFindResult ? (qty += productsFindResult.quantity) : (qty += 0);
                }
                promoItemsFindResult ? (qty += promoItemsFindResult.quantity) : (qty += 0);
                qty = ~~(qty / skip);
                if (
                    !(
                        rule.actionQuantity &&
                        (rule.actionQuantity.max || rule.actionQuantity.perConditionItem)
                    )
                )
                    max = qty;
                count + qty < max ? (run = qty) : (run = max - count);
                count += run;
                ret.push(
                    JSON.stringify({
                        final_sale: rule.final_sale || false,
                        do_not_ship_alone: rule.do_not_ship_alone || false,
                        sku: rule.actionItems[k],
                        id: rule.id,
                        name: rule.name,
                        autoAdd: rule.autoAdd,
                        discount:
                            productsFindResult != undefined
                                ? productsFindResult.price * rule.discountValue[0] * run
                                : (promoItemsFindResult || {}).price * rule.discountValue[0] * run,
                        limitOnePerEmail: rule.limitOnePerEmail || false,
                    }),
                );
            }
        }
        if (count < max) {
            for (let j = count; j < max; j++) {
                ret.push(
                    JSON.stringify({
                        name: rule.name,
                        id: rule.id,
                        selection: rule.actionItems,
                        discount: 0,
                        autoAdd: rule.autoAdd,
                        limitOnePerEmail: rule.limitOnePerEmail || false,
                    }),
                );
            }
        }
    }

    return ret;
}

export function freeShipItems(context, rule) {
    const t = {};
    for (let i = 0; i < context.cart.products.length; i++) {
        if (
            rule.conditionItems.includes(context.cart.products[i].sku) &&
            context.cart.products[i].quantity >= rule.conditionQuantity.min
        ) {
            // context.cart.shipping.rates.map(r => {return rule.actionItems.includes(r.method)? r.amount = 0 : r ;});

            return JSON.stringify({
                name: rule.name,
                id: rule.id,
                methods: rule.actionItems,
                discount: 0,
                limitOnePerEmail: rule.limitOnePerEmail || false,
            });
        }
    }
    return '{}';
}

export function freeShipCart(context, rule) {
    let subtotal = context.cart.products.reduce((acc, val) => acc + val.price * val.quantity, 0);

    const valueOfPromoItems = context.cart.promoItems
        .filter(
            p =>
                context.cart.appliedPromos.some(a => a.sku && a.sku == p.sku) &&
                (!rule.exclusionItems || !rule.exclusionItems.some(e => e == p.sku)),
        )
        .reduce((acc, val) => acc + val.price * val.quantity, 0);
    subtotal += valueOfPromoItems;

    if (rule.rewardProducts) {
        const valueOfRewardProducts = rule.rewardProducts
            .filter(p => !rule.exclusionItems || !rule.exclusionItems.some(e => e === p.sku))
            .reduce((acc, val) => acc + val.totalPrice, 0);
        subtotal += valueOfRewardProducts;
    }

    if (rule.exclusionItems) {
        const valueOfExcludedSkus = context.cart.products
            .filter(i => rule.exclusionItems.includes(i.sku))
            .reduce((a, e) => a + e.price * e.quantity, 0);
        subtotal -= valueOfExcludedSkus;
    }

    const valueOfAppliedPromosExcludedSkus = context.cart.appliedPromos
        .filter(a => !a.sku || !rule.exclusionItems.some(e => e == a.sku))
        .reduce((a, p) => a + p.discount, 0);
    subtotal -= valueOfAppliedPromosExcludedSkus;

    const ruleMeetsRequirements =
        subtotal > rule.conditionQuantity.min &&
        (!rule.conditionItems ||
            rule.conditionItems.some(c => context.cart.products.some(i => i.sku == c)));

    const sameRuleIdNotApplied = !context.cart.appliedPromos.some(v => v.id == rule.id);

    if (ruleMeetsRequirements && sameRuleIdNotApplied) {
        return JSON.stringify({
            id: rule.id,
            name: rule.name,
            methods: rule.actionItems,
            discount: 0,
            qualifyingSubtotal: subtotal,
            limitOnePerEmail: rule.limitOnePerEmail || false,
            cartCounter: rule.cartCounter || null,
        });
    }

    return JSON.stringify({
        name: rule.name,
        qualifyingSubtotal: subtotal,
        cartCounter: rule.cartCounter || null,
        aspiringPromo: true,
    });
}

export function discountShipItems(context, rule) {
    const t = {};
    for (let i = 0; i < context.cart.products.length; i++) {
        if (
            rule.conditionItems.includes(context.cart.products[i].sku) &&
            context.cart.products[i].quantity >= rule.conditionQuantity.min
        ) {
            // context.cart.shipping.rates.map(r => {return rule.actionItems.includes(r.method)? r.amount = 0 : r ;});

            return JSON.stringify({
                name: rule.name,
                id: rule.id,
                methods: rule.actionItems,
                discount: 0,
                limitOnePerEmail: rule.limitOnePerEmail || false,
            });
        }
    }
    return '{}';
}

export function discountShipCart(context, rule) {
    if (!context.cart.appliedPromos.some(v => v.id == rule.id)) {
        let subtotal = context.cart.products.reduce(
            (acc, val) => acc + val.price * val.quantity,
            0,
        );

        subtotal += context.cart.promoItems
            .filter(
                p =>
                    context.cart.appliedPromos.some(a => a.sku && a.sku == p.sku) &&
                    (!rule.exclusionItems || !rule.exclusionItems.some(e => e == p.sku)),
            )
            .reduce((acc, val) => acc + val.price * val.quantity, 0);

        if (rule.exclusionItems) {
            subtotal -= context.cart.products
                .filter(i => rule.exclusionItems.includes(i.sku))
                .reduce((a, e) => a + e.price * e.quantity, 0);
        }

        subtotal -= context.cart.appliedPromos
            .filter(a => !a.sku || !rule.exclusionItems.some(e => e == a.sku))
            .reduce((a, p) => a + p.discount, 0);

        if (
            subtotal > rule.conditionQuantity.min &&
            (!rule.conditionItems ||
                rule.conditionItems.some(c => context.cart.products.some(i => i.sku == c)))
        ) {
            // context.cart.shipping.rates.map(r => {return rule.actionItems.includes(r.method)? r.amount = 0 : r ;});

            return JSON.stringify({
                id: rule.id,
                name: rule.name,
                methods: rule.actionItems,
                discount: 0,
                limitOnePerEmail: rule.limitOnePerEmail || false,
            });
        }
    }
    return '{}';
}

function restrictShippingCart(context, rule) {
    for (let i = 0; i < context.cart.products.length; i++) {
        if (!rule.conditionItems || rule.conditionItems.includes(context.cart.products[i].sku)) {
            // context.cart.shipping.rates = context.cart.shipping.rates.filter(r => {return !rule.actionItems.includes(r.method)});

            return JSON.stringify({
                id: rule.id,
                name: rule.name,
                methods: rule.actionItems,
                discount: 0,
            });
        }
    }

    for (let i = 0; i < context.cart.promoItems.length; i++) {
        if (!rule.conditionItems || rule.conditionItems.includes(context.cart.promoItems[i].sku)) {
            // context.cart.shipping.rates = context.cart.shipping.rates.filter(r => {return !rule.actionItems.includes(r.method)});

            return JSON.stringify({
                id: rule.id,
                name: rule.name,
                methods: rule.actionItems,
                discount: 0,
            });
        }
    }
    return '{}';
}

export function noShippingDrop(context, rule) {
    const { cart, step } = context;
    const { products, promoItems } = cart;
    const { name, id, exclusionItems, conditionItems, limitOnePerEmail = false } = rule;
    if (
        (exclusionItemsAddressFormMatch(exclusionItems, cart, 'postalCode') ||
            exclusionItemsAddressFormMatch(exclusionItems, cart, 'state') ||
            exclusionItemsAddressFormMatch(exclusionItems, cart, 'address')) &&
        step !== ENTERING_CHECKOUT
    ) {
        /* for (let i = 0; i < context.cart.products.length; i++) {
            if ((rule.conditionItems.includes(context.cart.products[i].sku))) {
                context.cart.products.splice(i, 1);
            }
        } */
        const skus = [];
        products.forEach(({ sku }) => conditionItems.includes(sku) && skus.push(sku));
        promoItems.forEach(({ sku }) => conditionItems.includes(sku) && skus.push(sku));

        return skus.map(sku =>
            JSON.stringify({
                id,
                sku,
                name,
                discount: 0,
                limitOnePerEmail,
            }),
        );
    }
    return '{}';
}

export function restrictShippingAddress(context, rule) {
    const { cart } = context;
    const { name, id, exclusionItems, actionItems } = rule;
    if (
        exclusionItemsAddressFormMatch(exclusionItems, cart, 'state') ||
        exclusionItemsAddressFormMatch(exclusionItems, cart, 'city') ||
        exclusionItemsAddressFormMatch(exclusionItems, cart, 'address')
    ) {
        // context.cart.shipping.rates = context.cart.shipping.rates.filter(r => {return !rule.actionItems.includes(r.method)});
        return JSON.stringify({
            name,
            id,
            methods: actionItems,
            discount: 0,
        });
    }
    return '{}';
}

export function dropProducts(context, rule) {
    let ret = [];

    // Prevents product from being dropped if counterbase
    // flag is added to SKU
    if (context && context.counterbaseFlag) return ret;

    if (rule.dropGWPs) {
        ret = [
            ...ret,
            ...rule.actionItems
                .filter(
                    a =>
                        !(
                            context.cart.appliedPromos &&
                            context.cart.appliedPromos.some(c => c.sku == a)
                        ),
                )
                .map(r =>
                    JSON.stringify({
                        id: rule.id,
                        name: rule.name,
                        sku: r,
                        discount: 0,
                    }),
                ),
        ];
    } else if (
        !rule.conditionItems.some(item =>
            context.cart.products.some(
                c =>
                    c.sku == item &&
                    (!rule.conditionQuantity ||
                        !rule.conditionQuantity.min ||
                        c.quantity >= rule.conditionQuantity.min),
            ),
        ) &&
        !rule.conditionItems.every(item => context.cart.promoItems.some(c => c.sku == item))
    ) {
        ret = [
            ...ret,
            ...rule.actionItems
                .filter(
                    a =>
                        context.cart.products.some(c => c.sku == a) ||
                        context.cart.promoItems.some(c => c.sku == a),
                )
                .map(r =>
                    JSON.stringify({
                        id: rule.id,
                        name: rule.name,
                        sku: r,
                        discount: 0,
                    }),
                ),
        ];
    }

    return ret;
}

// Checks how many items are in both a and b, and returns the number of matches and the list of items
// It's used to make sure that if you have rules with condition items, that you only count rules whose condition items are in the context.cart.
function pairUp(a, b) {
    let matches = 0;
    const ta = JSON.parse(JSON.stringify(a));

    /*
    matches = a.reduce((acc,val) => {
        return b.includes(val.sku) ? matches : matches++;
    }, 0);
    */

    while (b.some(v => a.includes(v))) {
        for (let x = 0; x < b.length; x++) {
            const idx = a.indexOf(b[x]);
            if (idx > -1) {
                a.splice(idx, 1);
            }
        }
        matches++;
    }
    return [ta, matches];
}

function mapPromoRuleToCCRule(rule) {
    const {
        id,
        name,
        conditionQuantity,
        conditionItems,
        exclusionItems,
        priority,
        cartCounter,
    } = rule;
    return {
        id,
        name,
        qualifyingSubtotal: null,
        conditionQuantity: conditionQuantity || null,
        conditionItems: conditionItems || null,
        exclusionItems: exclusionItems || null,
        priority: priority || null,
        cartCounter,
    };
}

export function sortPromos(promos) {
    let newAppliedPromos = [];
    let newCartCounterPromos = [];

    const promoArray = typeof promos === 'object' ? promos : [promos];
    promoArray.forEach(p => {
        const pParsed = JSON.parse(p);
        const { cartCounter, aspiringPromo, name, qualifyingSubtotal } = pParsed;
        if (!aspiringPromo) newAppliedPromos.push(pParsed);

        if (cartCounter) {
            newCartCounterPromos.push({
                name,
                qualifyingSubtotal: qualifyingSubtotal || 0,
            });
        }
    });

    newAppliedPromos = newAppliedPromos.length ? newAppliedPromos : null;
    newCartCounterPromos = newCartCounterPromos.length ? newCartCounterPromos : null;

    return { newAppliedPromos, newCartCounterPromos };
}

function isValidCombineConditionItemsRule(rule) {
    const { conditionQuantity, actionItems } = rule;
    const { combineConditionItems, min } = conditionQuantity || {};
    return combineConditionItems && min && min > 1 && actionItems && actionItems.length;
}

export function getBundledSkus(cartProducts) {
    const bundledObjects = [];

    const bundledSkusOnly = cartProducts.reduce((filtered, product) => {
        if (product.bundled && product.bundled.length) {
            const { bundled, sku, quantity } = product;
            const parentSku = sku;

            bundled.forEach(b => {
                b.parentSku = parentSku;
                b.quantity = quantity;

                // create list of bundled sku field only
                filtered.push(b.sku);
                bundledObjects.push(b);
            });
        }
        return filtered;
    }, []);

    // flatten array of bundled skus
    const bundledArray = [].concat.apply([], bundledSkusOnly);
    const bundledSkus = [].concat.apply([], bundledArray);
    // flatten array of bundled objects
    const bundledObjectsArray = [].concat.apply([], bundledObjects);
    const bundledProducts = [].concat.apply([], bundledObjectsArray);

    return { bundledSkus, bundledProducts };
}

export function applyRule(context, rule) {
    switch (rule.id) {
        case 0:
            return noConditionPromo(context, rule);
        case 1:
            return byDiscountPromo(context, rule);
        case 2:
            return byPercentPromo(context, rule);
        case 3:
            return byDiscountPromo(context, rule);
        case 4:
            return byPercentPromo(context, rule);
        case 5:
            return byDiscountPromo(context, rule);
        case 6:
            return byPercentPromo(context, rule);
        case 7:
            return byPercentPromo(context, rule);
        case 8:
            return cartDiscountPromo(context, rule);
        case 9:
            return cartPercentPromo(context, rule);
        case 10:
            return cartItemDiscountPromo(context, rule);
        case 11:
            return cartItemPercentPromo(context, rule);
        case 12:
            return cartItemDiscountPromo(context, rule);
        case 13:
            return cartItemPercentPromo(context, rule);
        case 14:
            return freeShipItems(context, rule);
        case 15:
            return freeShipCart(context, rule);
        case 16:
            return restrictShippingCart(context, rule);
        case 17:
            return noShippingDrop(context, rule);
        case 18:
            return restrictShippingAddress(context, rule);
        case 21:
            return dropProducts(context, rule);
        case 22:
            return discountShipItems(context, rule);
        case 23:
            return discountShipCart(context, rule);
        case 24:
            return subscriptionItemsOnlyDiscountPromo(context, rule);
        case 25:
            return subscriptionItemsOnlyPercentPromo(context, rule);
        default:
            return context;
    }
}
