import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import UserIcon from '@material-ui/icons/People';
import ViewListIcon from '@material-ui/icons/ViewList';
import InputIcon from '@material-ui/icons/Input';
import Build from '@material-ui/icons/Build';
import Money from '@material-ui/icons/AttachMoney';
import LocalActivityOutlined from '@material-ui/icons/LocalActivityOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import { routes } from '@cs-admin/config';
import BulkledgerMenuIcon from '../BulkLedgers/Icons/BulkledgerMenuIcon.jsx';

export const PROD_MENU_ITEMS = [
    {
        resource: 'Dashboard',
        icon: <DashboardIcon />,
        route: routes.DASHBOARD,
    },
    {
        resource: 'Customers',
        icon: <UserIcon />,
        route: routes.CUSTOMERS,
    },
    {
        resource: 'Orders',
        icon: <ViewListIcon />,
        route: routes.ORDERS,
    },
    {
        resource: 'Batch Orders',
        icon: <BusinessIcon />,
        route: routes.BATCHORDERS,
    },
    {
        resource: 'Bulk Ledgers',
        icon: <BulkledgerMenuIcon />,
        route: routes.BULKLEDGERS,
    },
    {
        resource: 'Returns',
        icon: <InputIcon />,
        route: routes.RETURNS,
    },
    {
        resource: 'Administrators',
        icon: <Build />,
        route: routes.ADMINISTRATORS,
    },
    // TODO: Update to resolve to PROMOS when more than one tab
    {
        resource: 'Promo Tool',
        icon: <Money />,
        route: routes.PROMO_TOOL,
    },
    {
        resource: 'Incentives',
        icon: <LocalActivityOutlined />,
        route: routes.INCENTIVES,
    },
];

export const DEV_MENU_ITEMS = [
    {
        resource: 'Dashboard',
        icon: <DashboardIcon />,
        route: routes.DASHBOARD,
    },
    {
        resource: 'Customers',
        icon: <UserIcon />,
        route: routes.CUSTOMERS,
    },
    {
        resource: 'Orders',
        icon: <ViewListIcon />,
        route: routes.ORDERS,
    },
    {
        resource: 'Batch Orders',
        icon: <BusinessIcon />,
        route: routes.BATCHORDERS,
    },
    {
        resource: 'Bulk Ledgers',
        icon: <BulkledgerMenuIcon />,
        route: routes.BULKLEDGERS,
    },
    {
        resource: 'Returns',
        icon: <InputIcon />,
        route: routes.RETURNS,
    },
    {
        resource: 'Administrators',
        icon: <Build />,
        route: routes.ADMINISTRATORS,
    },
    // TODO: Update to resolve to PROMOS when more than one tab
    {
        resource: 'Promo Tool',
        icon: <Money />,
        route: routes.PROMO_LIST,
    },
    {
        resource: 'Incentives',
        icon: <LocalActivityOutlined />,
        route: routes.INCENTIVES_MILESTONE_1,
    },
];
