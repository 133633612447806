import { observable, action, computed, autorun, toJS } from 'mobx';
import { searchForProducts } from '@cs-admin/services/search';
import throttle from '@cs-admin/utils/throttle';
import {
    promoIdAndType,
    countries,
    cards,
    userTypes as userTypeArray,
    UNITEDSTATES,
    CANADA,
    NO_DURATION,
    DURATION_SAVED,
} from '@cs-admin/constants/promoFormConfig';
import { addDays } from 'date-fns';
import { createPromoRule, deployPromoRuleGenerated } from '@cs-admin/services/promos';
import { PROMO_RULES_PROD, PENDING_PROMO_RULES } from '@cs-admin/constants/promoCollections';

class PromoToolBusinessStore {
    @observable promoForm;
    @observable name;
    @observable promoType;
    @observable id;
    @observable regionId;
    @observable catalogRegion;
    @observable userTypes;
    @observable isLoadingProducts;
    @observable condition;
    @observable action;
    @observable durationState;
    @observable durationRange;

    constructor() {
        this.resetStore();
    }

    @computed
    get formComplete() {
        // this is where we would check the mobx store
        // for items that are necessary for completion
        return (
            !!this.promoForm?.fields?.name?.value.length &&
            !!this.promoType.length &&
            !!this.id &&
            !!this.regionId.length &&
            !!this.userTypes.length
        );
    }

    @computed
    get hasPublishAction() {
        // this computed variable will also eventually
        // contain logic for when to display which
        // publish action based on what collection the rule
        // resides in.
        // currently, it only reflects user permissions.
        const { junoAccess, promoAccess } = this.userStore;
        return {
            pending: promoAccess || junoAccess,
            drafts: promoAccess || junoAccess,
            prod: junoAccess,
        };
    }

    @computed
    get showDetailedPromoForm() {
        return this.promoForm?.fields?.name?.value.length > 0 && this.promoType.length > 0;
    }

    @computed
    get durationDisplay() {
        const start = new Date(this.durationRange[0].startDate).toDateString();
        const end = new Date(this.durationRange[0].endDate).toDateString();
        return `${start} - ${end}`;
    }

    @computed
    get countriesApplied() {
        return this.regionId.map(id => this.getCountryByCode(id));
    }

    getCountryByCode = regionCode => countries.find(country => country.regionCode === regionCode);

    resetStore() {
        this.promoForm = null;
        this.name = '';
        this.promoType = '';
        this.id = null;
        this.regionId = [UNITEDSTATES]; // us by default
        this.catalogRegion = UNITEDSTATES; // yay ethnocentrism
        this.userTypes = [];
        this.isLoadingProducts = false;
        this.condition = { searchText: '', searchResults: [], selectedProducts: [] };
        this.action = { searchText: '', searchResults: [], selectedProducts: [] };
        this.durationState = NO_DURATION;
        this.durationRange = [
            {
                startDate: new Date(),
                endDate: addDays(new Date(), 7),
                key: 'selection',
            },
        ];
    }

    setupPromoForm = () => {
        const createAction = () => {
            this.promoForm = this.formStore.create({
                key: 'promoForm',
                persist: false,
            });

            this.promoForm.add([
                {
                    key: 'name',
                    label: 'ENTER PROMO NAME',
                    value: this.name,
                    type: 'required',
                },
            ]);
        };
        this.formStore.clearForm('promoForm');
        this.formStore.createReactiveForm('promoForm', 'forms', createAction);
    };

    handleSelectPromoType = event => {
        const { name, value = '' } = event.target;
        this[name] = value.trim();

        Object.entries(promoIdAndType).forEach(([id, promoType]) => {
            if (value === promoType) this.id = Number(id);
        });
    };

    handleSelectCountry = async (value, passback) => {
        if (!value) {
            // remove region from array, also removes from available catalogs
            this.regionId = this.regionId.filter(country => country !== passback);
            // unload region catalog if is current catalog
            if (this.regionId.indexOf(this.catalogRegion) < 0) {
                if (this.regionId.peek().length > 0) this.handleCountryCatalogSet(this.regionId[0]);
                else this.handleCountryCatalogSet(UNITEDSTATES);
                // always defaulting this to US catalog if no catalog country
                // technically selected, so as to ensure no search issues.
                // could be resolved with disabling search when no catalog country
                // selected, but that should be product decision
            }
            // remove region products if they exist
            this.removeRegionProducts(passback);
        } else {
            // add region to array if not already in array
            if (this.regionId.indexOf(passback) < 0) this.regionId = [...this.regionId, passback];
        }
    };

    handleCountryCatalogSet = async (value = 1) => {
        this.catalogRegion = this.getCountryByCode(value);
        await this.loadProducts(this.catalogRegion);
    };

    handleSelectUserType = (value, passback) => {
        if (!value) this.userTypes = this.userTypes.filter(type => type !== passback);
        else this.userTypes = [...this.userTypes, passback];
    };

    handleDurationStateChange = state => {
        this.durationState = state;
    };

    handleDurationRangeChange = ranges => {
        const { selection } = ranges;
        selection.endDate = selection.endDate;
        this.durationRange = [selection];
    };

    handleDurationRangeSet = () => {
        if (this.durationState === NO_DURATION) {
            this.durationRange = [
                {
                    startDate: new Date(),
                    endDate: addDays(new Date(), 7),
                    key: 'selection',
                },
            ];
        }
    };

    setSearchText = (event, card) => {
        const value = (event?.target?.value || '').trim();
        this[card].searchText = value;
        this.updateSearchResult(card);
    };

    @action
    async loadProducts(country) {
        this.isLoadingProducts = true;
        await this.contentStore.loadCatalog(country.catalog);
        this.isLoadingProducts = false;
    }

    @throttle(300)
    updateSearchResult(card) {
        this[card].searchResults = this[card].searchText
            ? searchForProducts(this.productsStore.products, this[card].searchText)
            : [];
    }

    @action
    addProduct(product, card) {
        const itemSku = product.sku;
        const { selectedProducts } = this[card];
        const itemAlreadySelected = selectedProducts.find(({ sku }) => itemSku == sku);

        if (itemAlreadySelected) return;

        const item = this[card].searchResults.find(({ sku }) => itemSku == sku);
        const { sku, variants } = item;
        const variantsSkus = variants.length > 1 ? variants.map(({ sku }) => sku) : [];

        this[card].selectedProducts = [
            {
                sku,
                variants: variantsSkus,
                product: item,
                catalogRegion: this.catalogRegion,
            },
            ...selectedProducts,
        ];
    }

    @action
    removeProduct(product, card) {
        const itemSku = product.sku;
        this[card].selectedProducts = this[card].selectedProducts.filter(
            ({ sku }) => itemSku != sku,
        );
    }

    @action
    removeRegionProducts(region) {
        cards.forEach(card => {
            this[card].selectedProducts = this[card].selectedProducts.filter(
                ({ catalogRegion }) => catalogRegion != region,
            );
        });
    }

    @action
    getParentAndVariantSkus(selectedProducts) {
        return selectedProducts.reduce((acc, { sku, variants }) => {
            acc = [...acc, sku, ...variants];
            return acc;
        }, []);
    }

    @computed
    get ruleContext() {
        const name = this.promoForm.value('name');
        const conditionItems = this.getParentAndVariantSkus(this.condition.selectedProducts);
        const actionItems = this.getParentAndVariantSkus(this.action.selectedProducts);

        const context = {
            id: this.id,
            name,
            conditionItems,
            actionItems,
            regionId: this.regionId,
            userTypes: this.userTypes,
        };

        if (this.durationState !== NO_DURATION) {
            const duration = this.durationRange[0];
            const startTimestamp = duration.startDate.toISOString();
            const endTimestamp = duration.endDate.toISOString();

            context.startDate = startTimestamp;
            context.endDate = endTimestamp;
        }

        return context;
    }

    @action
    async handlePublishToPending() {
        const { success, message, data } = await createPromoRule({
            data: this.ruleContext,
            collection: PENDING_PROMO_RULES,
        });

        if (!success) {
            this.interfaceStore.openAlert(true, message);
            return;
        }

        this.interfaceStore.openAlert(
            false,
            `Promo rule ${data.name} successfully created and saved to pending rules.`,
        );
    }

    @action
    async handlePublishToDrafts() {
        const { success, message, data } = await createPromoRule({
            data: this.ruleContext,
            collection: PROMO_RULES_PROD,
        });

        if (!success) {
            this.interfaceStore.openAlert(true, message);
            return;
        }

        this.interfaceStore.openAlert(
            false,
            `Promo rule ${data.name} successfully created and saved to drafts.`,
        );
    }

    @action
    async handlePublishToProd() {
        const { name } = this.ruleContext;
        const { success, message, data } = await deployPromoRuleGenerated(name);

        if (!success) {
            this.interfaceStore.openAlert(true, message);
            return { success };
        }

        this.interfaceStore.openAlert(
            false,
            `Promo rule ${data.name} successfully deployed to production.`,
        );
        return { success };
    }
}

const promoToolBusinessStore = new PromoToolBusinessStore();

export default promoToolBusinessStore;
export { PromoToolBusinessStore };
