export const masks = {
    phone,
    name,
    zip,
    ssn,
    maskedSsn,
    sin,
    maskedSin,
    date,
    taxId,
    companyName,
    bn,
};

export function phone(value) {
    let x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
    return x;
}

export function name(value) {
    return value.replace(/[0-9~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi, '');
}

export function zip(value) {
    return value.replace(/['`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi, '');
}

// Matches 123-45-6789
const SSN_REG_EXP = /(\d{0,3})(\d{0,2})(\d{0,4})/;

// US Social Security Number
export function ssn(value) {
    let x = value.replace(/\D/g, '').match(SSN_REG_EXP);
    x = !x[2] ? x[1] : `${x[1]}-${x[2]}${x[3] ? `-${x[3]}` : ''}`;
    return x;
}

export function maskedSsn(value) {
    let x = value.replace(/\D/g, '').match(SSN_REG_EXP);
    x = !x[2] ? x[1] : `xxx-xx${x[3] ? `-${x[3]}` : ''}`;
    return x;
}

// US Social Security Number formatter
export function formatMaskedSsn(value) {
    const str = value.substring(value.length - 9);
    return `${str.substring(0, 3)}-${str.substring(3, 5)}-${str.substring(5, 9)}`;

    // return value.substring(0, 3) + '-' + value.substring(3, 5) + '-' + value.substring(5);
}

// Matches 12-3456789
const TAX_ID_REGEXP = /(\d{0,2})(\d{0,7})/;

export function taxId(value) {
    const match = value.replace(/\D/g, '').match(TAX_ID_REGEXP);
    return !match[2] ? match[1] : `${match[1]}-${match[2]}`;
}

// Matches all non lowercase, digits or ampersand
const COMPANY_NAME_REG_EXP = /[^a-z0-9&]/g;

export function companyName(value) {
    return value.replace(COMPANY_NAME_REG_EXP, '');
}

// Canada Social Insurance Number
export function sin(value) {
    let x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})/);
    x = !x[2] ? x[1] : `${x[1]}-${x[2]}${x[3] ? `-${x[3]}` : ''}`;
    return x;
}

// Matches any number sequence. Breaks it by groups of 3 digit
const SIN_NUMBERS_GROUPS_REG_EXP = /(\d{0,3})(\d{0,3})(\d{0,3})/;

// Canada Social Insurance Number
export function maskedSin(value) {
    const match = value.replace(/\D/g, '').match(SIN_NUMBERS_GROUPS_REG_EXP);
    return !match[2] ? match[1] : `xxx-xxx${match[3] ? `-${match[3]}` : ''}`;
}

// E.g. "123456789AB3456". Breaks by groups of numbers-letters-numbers
const BUSINESS_NUMBER_REG_EXP = /(\d{0,9})([a-zA-Z]{0,2})(\d{0,4})/;

// Canada Business Number
export function bn(value) {
    const match = value.replace(/[^a-zA-Z0-9]/g, '').match(BUSINESS_NUMBER_REG_EXP);
    return !match[2] ? match[1] : `${match[1]}${match[2]}${match[3] ? `${match[3]}` : ''}`;
}

// CA Social Insurance Number formatter
export function formatMaskedSin(value) {
    const str = value.substring(value.length - 9);
    return `${str.substring(0, 3)}-${str.substring(3, 6)}-${str.substring(6)}`;
}

export function date(value) {
    let x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
    x = !x[2] ? x[1] : `${x[1]}/${x[2]}${x[3] ? `/${x[3]}` : ''}`;
    return x;
}

export default masks;
