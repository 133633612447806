import { dateInMs } from './date';

const SIX_MONTHS_IN_MS = 6 * 30 * 24 * 60 * 60 * 1000;

export const isAddressMelissaVerificationExpired = (address = {}) => {
    const { melissaVerificationDateUTC } = address;

    if (!melissaVerificationDateUTC) return true;

    const verificationDateInMs = dateInMs(new Date(melissaVerificationDateUTC));

    const currentDateInMs = dateInMs();

    return currentDateInMs - verificationDateInMs > SIX_MONTHS_IN_MS;
};
