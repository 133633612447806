import { BCCOM, CB } from '@cs-admin/constants/employeeAccountServices';
import { employeeAccountErrorMessages } from '@cs-admin/constants/errorMessages';
import offboardBCCOM from './bccom';
import offboardCB from './cb';

export default async function offboardAccount(service, accountInfo) {
    try {
        switch (service) {
            case BCCOM:
                return await offboardBCCOM(accountInfo);
            case CB:
                return await offboardCB(accountInfo);
            default:
                throw new Error(
                    employeeAccountErrorMessages.serviceNotSupported(
                        'Account Offboarding',
                        service,
                    ),
                );
        }
    } catch (error) {
        console.error(error);
        return {
            success: false,
            error,
            accountInfo,
        };
    }
}
