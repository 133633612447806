import { observable, action } from 'mobx';
import { getCart, getCarts, mutateCart } from '@cs-admin/services/cart/api/graphql';
import { isEmpty, uniqBy } from 'lodash';
import { locales } from '@cs-admin/config';

class CartApiStore {
    @observable cart;
    @observable loadingCart;
    @observable personalCarts;
    @observable clientCarts;
    @observable popupCarts;

    constructor() {
        this.cart = {};
        this.personalCarts = [];
        this.clientCarts = [];
        this.popupCarts = [];
        this.loadingCart = true;
        this.pollingConfig = {};
        this.pollCart();
    }

    @action
    async setActiveCart(query) {
        return this.getCart(this.parseQuery(query));
    }

    @action
    async addProduct(sku, quantity, cart) {
        if (quantity <= 0) {
            return this.interfaceStore.openAlert(
                true,
                'Please add a positive quantity. Got to keep things upbeat.',
            );
        }
        return this.mutateCart(
            'addProduct',
            {
                ...this.populateDefaults(cart),
                sku,
                quantity: Number(quantity),
            },
            `Successfully added ${quantity} of SKU ${sku} to cart`,
        );
    }

    @action
    async removeProduct(lineItemId, cart) {
        return this.mutateCart('removeProduct', {
            ...this.populateDefaults(cart),
            lineItemId,
        });
    }

    @action
    async incrementProduct(lineItemId, cart) {
        return this.mutateCart('incrementProductQuantity', {
            ...this.populateDefaults(cart),
            lineItemId,
            by: 1,
        });
    }

    @action
    async decrementProduct(lineItemId, cart) {
        return this.mutateCart('decrementProductQuantity', {
            ...this.populateDefaults(cart),
            lineItemId,
            by: 1,
        });
    }

    @action
    async emptyCart(cart) {
        return this.mutateCart('emptyCart', {
            ...this.populateDefaults(cart),
        });
    }

    populateDefaults(cart) {
        return {
            uid: cart.uid,
            clientUid: cart.clientUid,
            socialId: cart.socialId,
            localeCode: cart.localeCode,
        };
    }

    @action
    async getCart(modifiers) {
        this.loadingCart = true;
        try {
            this.pollingConfig = {};
            const cart = await getCart(modifiers);
            if (cart && cart.error) throw new Error(cart.error);
            this.cart = cart;
            this.pollingConfig = modifiers;
        } catch (e) {
            this.interfaceStore.openAlert(true, e && e.message);
        }
        this.loadingCart = false;
    }

    @action
    getPersonalCarts = async (uid = null) => {
        this.personalCarts = [];
        const modifiers = {
            uid,
        };

        const countryCalls = uniqBy(locales, 'countryId');
        const countries = await Promise.all([
            ...countryCalls.map(c =>
                getCart({
                    ...modifiers,
                    localeCode: c.code,
                }),
            ),
        ]);

        countries.forEach(country => {
            if (!isEmpty(country) && country.products && country.products.length)
                this.personalCarts.push(country);
        });
        this.personalCarts = [...this.personalCarts];
    };

    @action
    getClientCarts = async (uid = null) => {
        this.clientCarts = [];
        const modifiers = {
            uid,
        };

        const clientCarts = await getCarts('clientCarts', {
            ...modifiers,
            listAllCountries: true,
            localeCode: 'en-US', // used for translation defaults to display
        }).catch(e => console.error(e));

        this.clientCarts = clientCarts || [];
    };

    @action
    getPopupCarts = async (uid = null, popupId = null) => {
        this.popupCarts = [];
        const modifiers = {
            uid,
            socialId: popupId,
        };

        const popupCarts = await getCarts('socialCarts', {
            ...modifiers,
            localeCode: 'en-US',
        });

        this.popupCarts = popupCarts || [];
    };

    @action
    async mutateCart(action, modifiers, successMessage) {
        this.loadingCart = true;
        try {
            const cart = await mutateCart(action, modifiers);
            if (cart && cart.error) throw new Error(cart.error);
            this.cart = cart;
            if (successMessage) this.interfaceStore.openAlert(false, successMessage);
        } catch (e) {
            this.interfaceStore.openAlert(true, e && e.message);
        }
        this.loadingCart = false;
    }

    parseQuery(query) {
        const data = { ...query };
        Object.keys(data).forEach(q => {
            if (!data[q] || data[q] === 'null') delete data[q];
        });
        return data;
    }

    @action
    clearActiveCart = () => {
        this.cart = [];
        this.pollingConfig = {};
    };

    pollCart() {
        setTimeout(() => {
            if (!isEmpty(this.pollingConfig)) this.getCart(this.pollingConfig);
            this.pollCart();
        }, 4000);
    }

    @action
    setPromos() {
        // place-holder to resolve error when selecting promoToolCartApi flag
    }

    @action
    fetchPromoRules() {
        // place-holder to resolve error when selecting promoToolCartApi flag
    }
}

const cartApiStore = new CartApiStore();

export default CartApiStore;
export { CartApiStore };
