import { productHasFlag } from '@cs-admin/utils/productHasFlag';
import { COUNTERBASE_FLAG } from '@cs-admin/constants/flags';
/**
 * Filters products according to parent categories
 */

export function productHasCategoryVisibility(product) {
    const counterbaseFlag = productHasFlag(product, COUNTERBASE_FLAG);
    if (counterbaseFlag) return counterbaseFlag;

    return product.visibility === '4' || product.visibility === '2';
}

export function filterCategories(product, category) {
    if (!category) {
        return true;
    }
    if (category && product.categoryList) {
        for (let i = 0, len = product.categoryList.length; i < len; i++) {
            if (category == product.categoryList[i]) {
                return true;
            }
        }
    } else return true;

    return false;
}

export function filterItems(list, sections) {
    return (
        Object.keys(sections).filter(
            section =>
                Object.keys(sections[section]).filter(filter =>
                    list.includes(parseInt(sections[section][filter].id)),
                ).length,
        ).length >=
        Object.keys(sections).filter(section => Object.keys(sections[section]).length).length
    );
}

export function filterContainsItems({ items, filterKey = 'filters', filterId }) {
    for (let i = 0, len = items && items.length; i < len; i++) {
        if (items[i][filterKey].includes(parseInt(filterId))) return true;
    }

    return false;
}

export function filterWillContainItems({
    filter,
    filters,
    items,
    filterKey = 'filters',
    ifRemoved,
}) {
    try {
        const activeFilters = JSON.parse(JSON.stringify(filters));

        const prevTotal = items.filter(item => filterItems(item[filterKey], activeFilters)).length;

        const filtersTotal = Object.keys(activeFilters).filter(
            section =>
                Object.keys(activeFilters[section]).filter(filter => activeFilters[section][filter])
                    .length,
        ).length;

        if (typeof activeFilters[filter.parent] === 'undefined') activeFilters[filter.parent] = {};
        activeFilters[filter.parent][filter.id] = filter;

        // If ifRemoved is passed as an argument,
        // we'll anticipate the count
        // if this filter were to be removed from the selection
        if (ifRemoved) {
            delete activeFilters[filter.parent][filter.id];
        }

        const total = items.filter(item => filterItems(item[filterKey], activeFilters)).length;

        if (prevTotal === total && filtersTotal !== 1 && !ifRemoved) return false;
        if (total) return true;
        return false;
    } catch (e) {
        return false;
    }
}
