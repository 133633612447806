import { toJS, observable, action } from 'mobx';
import { getSafe } from '@cs-admin/utils/object';

function formatLabel(label = '', type, validations) {
    const fieldValidations = validations[type] || validations.optional;
    const isRequired = fieldValidations.some(validation =>
        validations.required.includes(validation),
    );
    return isRequired && !label.endsWith('*') ? `${label}*` : label;
}

/**
 * Field
 * Hanldes composition of individual form fields
 */
export default class Field {
    static hydrateValue(field, value, validate) {
        field.updateValue(value, validate);
    }

    @observable key;
    @observable type;
    @observable error;
    @observable value;
    @observable valid;
    @observable label;

    constructor({ key, value, locale, form, validations, label, type = null, error = null }) {
        this.form = form;
        this.key = key;
        this.type = type;
        this.error = error;
        this.value = value || ''; // nullsafe
        this.valid = true;
        this.validations = validations;
        this.locale = locale;
        this.label = formatLabel(label, type, validations);

        this.validate(false);
    }

    @action
    updateValue(value = '', validate = false) {
        this.value = value;
        if (validate) this.validate();
    }

    @action
    clearValue(validate = false) {
        this.value = '';
        if (validate) this.validate();
    }

    @action
    updateError(error = '') {
        this.error = error.replace('${label}', this.label.replace('*', '')) || null;

        if (this.error) {
            this.valid = false;
        }
    }

    @action
    clearError() {
        this.error = null;
        if (!this.valid) this.valid = true;
    }

    @action
    validate(addError = true) {
        const valid = true;
        const defaultShape = { isValid: true, value: '', errorMessages: [] };
        const validations = this.validations[this.type] || this.validations.optional;

        const validation = validations
            .map(validation => validation(toJS(this), this.form))
            .reduce(
                (prev, next) => ({
                    value: next.value,
                    isValid: prev.isValid && next.isValid,
                    errorMessages: prev.errorMessages.concat(
                        !next.isValid ? [next.errorMessage] : [],
                    ),
                }),
                defaultShape,
            );

        this.valid = !!validation.isValid;

        if (addError) {
            this.updateError(validation.errorMessages.reverse()[0]);
        }

        if (this.valid) {
            this.clearError();
        }
    }
}
