import { BCCOM, CB, userCollections } from '@cs-admin/constants/employeeAccountServices';
import firebase from '@cs-admin/services/firebase/init';

export function getFirestoreDocId(service, accountInfo) {
    if (!accountInfo) throw new Error('No account information provided');

    switch (service) {
        case BCCOM:
            if (typeof accountInfo === 'object' && accountInfo.hasOwnProperty('uid'))
                return accountInfo.uid;
            return accountInfo;
        case CB:
            if (accountInfo.hasOwnProperty('uid')) return accountInfo.uid;
            return accountInfo;
        default:
            return accountInfo;
    }
}

export async function getFirestoreUserRecord(service, docId) {
    const database = await firebase.firestore();
    return await database
        .collection(userCollections[service])
        .doc(docId)
        .get()
        .then(snapshot => {
            const data = snapshot.data();
            data.exists = snapshot.exists;
            return data;
        });
}
