import React, { Component } from 'react';
import Icon from '../../Icon.jsx';

const viewBox = {
    x: 0,
    y: 0,
    width: 450,
    height: 101,
};

const defaultSize = {
    width: '450px',
    height: '101px',
};

class FullLogo extends Component {
    render() {
        const size = this.props.size ? this.props.size : defaultSize;
        return (
            <Icon {...this.props} altText='Beautycounter' size={size} viewBox={viewBox}>
                <g id="Group" transform="translate(3.000000, 0.000000)">
                    <path d="M15.1325005,100 L0,100 L0,2 L15.1325005,2 C20.9774232,2 28.3181818,4.94232404 28.3181818,17.4482975 L28.3181818,38.9391024 C28.3181818,44.2361627 26.5187136,47.915164 22.4732591,50.1208108 C26.5187136,52.475547 28.3181818,56.1501633 28.3181818,61.2981341 L28.3181818,84.5560875 C28.3181818,97.057676 20.9774232,100 15.1325005,100 L15.1325005,100 Z M18.1286374,17.1545036 C18.1286374,13.7736811 16.7801525,12.4538011 14.8333333,12.4538011 L10.4887116,12.4538011 L10.4887116,45.5560428 L14.38235,45.5560428 C16.6328015,45.5560428 18.1286374,43.7932793 18.1286374,40.4080719 L18.1286374,17.1545036 Z M18.1286374,60.1229585 C18.1286374,56.8912256 16.6328015,54.9749877 14.38235,54.9749877 L10.4887116,54.9749877 L10.4887116,89.6996734 L14.8333333,89.6996734 C16.7801525,89.6996734 18.1286374,88.2263189 18.1286374,84.8498814 L18.1286374,60.1229585 L18.1286374,60.1229585 Z" id="Fill-1" fill="#0A0B09"></path>
                    <polyline id="Fill-2" fill="#0A0B09" points="34.3863636 2 34.3863636 100 58.6590909 100 58.6590909 89.8443778 44.7934527 89.8443778 44.7934527 54.6768088 54.5851894 54.6768088 54.5851894 44.5255716 44.7934527 44.5255716 44.7934527 12.1512372 58.6590909 12.1512372 58.6590909 2 34.3863636 2"></polyline>
                    <g id="Group-6" transform="translate(62.704545, 1.000000)">
                        <mask id="mask-2" fill="white">
                            <polygon id="path-1" points="0.801 0.681869565 64.2491 0.681869565 64.2491 99.7365217 0.801 99.7365217"></polygon>
                        </mask>
                        <g id="Clip-4"></g>
                        <path d="M10.8046,0.681869565 L0.801,98.8231304 L10.057,98.8231304 L11.7035,79.958087 L20.0695,79.958087 L21.1108,98.8231304 L31.85755,98.8231304 L22.00525,0.681869565 L10.8046,0.681869565 Z M16.47835,24.9965217 L19.313,69.3486957 L12.5935,69.3486957 L16.47835,24.9965217 L16.47835,24.9965217 Z" id="Fill-3" fill="#0A0B09" mask="url(#mask-2)"></path>
                        <path d="M49.9112,100 C41.8478,100 35.57775,93.2198261 35.57775,83.1988696 L35.57775,0.681869565 L45.8795,0.681869565 L45.8795,84.0815217 C45.8795,87.476 47.3747,89.2413043 50.0625,89.2413043 C52.89715,89.2413043 54.39235,87.476 54.39235,84.0815217 L54.39235,0.681869565 L64.2491,0.681869565 L64.2491,83.1988696 C64.2491,93.2198261 57.9746,100 49.9112,100" id="Fill-5" fill="#0A0B09" mask="url(#mask-2)"></path>
                    </g>
                    <polyline id="Fill-7" fill="#0A0B09" points="131.477273 2 131.477273 12.5941205 140.715325 12.5941205 140.715325 100 151.00154 100 151.00154 12.5941205 159.795455 12.5941205 159.795455 2 131.477273 2"></polyline>
                    <g id="Group-13" transform="translate(162.829545, 0.000000)">
                        <mask id="mask-4" fill="white">
                            <polygon id="path-3" points="0.524343109 0.465478261 132.869692 0.465478261 132.869692 100.736522 0.524343109 100.736522"></polygon>
                        </mask>
                        <g id="Clip-9"></g>
                        <polyline id="Fill-8" fill="#0A0B09" mask="url(#mask-4)" points="23.0063695 1.68186957 16.7591437 45.0064783 10.498607 1.68186957 0.524343109 1.68186957 10.951176 61.3596957 10.951176 99.8231304 21.2227155 99.8231304 21.2227155 61.3596957 32.838651 1.68186957 23.0063695 1.68186957"></polyline>
                        <path d="M49.4239707,101 C41.3797801,101 34.6799853,94.2198261 34.6799853,84.1944783 L34.6799853,17.271 C34.6799853,7.39495652 41.3797801,0.465478261 49.4239707,0.465478261 C57.765437,0.465478261 62.9788534,7.39495652 62.9788534,17.271 L62.9788534,35.9911304 L54.1892551,35.9911304 L54.1892551,16.2390435 C54.1892551,13.4373913 52.7028768,11.2241739 49.7212463,11.2241739 C46.5931965,11.2241739 45.1068182,13.4373913 45.1068182,16.2390435 L45.1068182,85.2264348 C45.1068182,88.028087 46.5931965,90.236913 49.7212463,90.236913 C52.7028768,90.236913 54.1892551,88.028087 54.1892551,85.2264348 L54.1892551,65.3206522 L62.9788534,65.3206522 L62.9788534,84.1944783 C62.9788534,94.2198261 57.765437,101 49.4239707,101" id="Fill-10" fill="#0A0B09" mask="url(#mask-4)"></path>
                        <path d="M83.1359179,101 C75.0917273,101 68.3919326,94.2198261 68.3919326,84.1944783 L68.3919326,17.271 C68.3919326,7.39495652 75.0917273,0.465478261 83.1359179,0.465478261 C91.1801085,0.465478261 97.8799032,7.39495652 97.8799032,17.271 L97.8799032,84.1944783 C97.8799032,94.2198261 91.1801085,101 83.1359179,101 M87.4575073,15.9448261 C87.4575073,13.288087 85.8158358,11.2241739 83.1359179,11.2241739 C80.3051437,11.2241739 78.8143284,13.288087 78.8143284,15.9448261 L78.8143284,85.5206522 C78.8143284,88.3223043 80.3051437,90.236913 83.1359179,90.236913 C85.8158358,90.236913 87.4575073,88.3223043 87.4575073,85.5206522 L87.4575073,15.9448261" id="Fill-11" fill="#0A0B09" mask="url(#mask-4)"></path>
                        <path d="M118.569402,101 C110.525211,101 104.269111,94.2198261 104.269111,84.1944783 L104.269111,1.64673913 L114.549525,1.64673913 L114.549525,85.0815217 C114.549525,88.4716087 116.035903,90.236913 118.720258,90.236913 C121.546595,90.236913 123.037411,88.4716087 123.037411,85.0815217 L123.037411,1.64673913 L132.869692,1.64673913 L132.869692,84.1944783 C132.869692,94.2198261 126.613592,101 118.569402,101" id="Fill-12" fill="#0A0B09" mask="url(#mask-4)"></path>
                    </g>
                    <polyline id="Fill-14" fill="#0A0B09" points="325.65364 2 325.65364 65.7129311 323.416733 51.4405332 313.859848 2 303.409091 2 303.409091 100 312.516815 100 312.516815 36.4317216 315.056127 50.5550834 326.55196 100 334.761364 100 334.761364 2 325.65364 2"></polyline>
                    <polyline id="Fill-15" fill="#0A0B09" points="339.818182 2 339.818182 12.5947131 349.057777 12.5947131 349.057777 100 359.340767 100 359.340767 12.5947131 368.136364 12.5947131 368.136364 2 339.818182 2"></polyline>
                    <polyline id="Fill-16" fill="#0A0B09" points="373.193182 2 373.193182 100 397.465909 100 397.465909 89.8436284 383.602197 89.8436284 383.602197 54.6798765 393.400239 54.6798765 393.400239 44.5235049 383.602197 44.5235049 383.602197 12.1519882 397.465909 12.1519882 397.465909 2 373.193182 2"></polyline>
                    <path d="M426.102817,100 C422.64858,100 420.997527,94.1347723 420.997527,87.9723498 L420.997527,62.1469919 C420.997527,59.2143781 419.494128,57.1646078 417.091375,57.1646078 L413.033094,57.1646078 L413.033094,99.7071757 L402.522727,99.7071757 L402.522727,2 L417.690945,2 C423.547935,2 430.908323,4.92824332 430.908323,17.4016858 L430.908323,41.3171297 C430.908323,46.4481113 428.957485,50.1193417 425.051333,52.4663069 C428.957485,54.8176426 431.208108,58.3359051 431.208108,63.6198546 L431.208108,87.2381037 C431.208108,91.6392097 431.660023,94.720421 432.863636,97.2159836 L432.863636,100 L426.102817,100 Z M420.545612,17.1088614 C420.545612,13.7348258 419.341998,12.4105606 417.39116,12.4105606 L413.033094,12.4105606 L413.033094,47.1823574 L416.939245,47.1823574 C419.194343,47.1823574 420.545612,45.5696383 420.545612,42.0513758 L420.545612,17.1088614 L420.545612,17.1088614 Z" id="Fill-17" fill="#0A0B09"></path>
                    <path d="M445,6.97089118 C445,9.78772951 442.809673,12 439.9587,12 C437.138765,12 434.886364,9.78772951 434.886364,6.97089118 C434.886364,4.21227049 437.138765,2 439.9587,2 C442.809673,2 445,4.21227049 445,6.97089118 L445,6.97089118 Z M436.150014,6.97089118 C436.150014,9.18316167 437.768373,10.9431258 439.989737,10.9431258 C442.149028,10.9431258 443.740784,9.18316167 443.740784,7.00223914 C443.740784,4.78996865 442.149028,2.99865652 439.9587,2.99865652 C437.768373,2.99865652 436.150014,4.78996865 436.150014,6.97089118 L436.150014,6.97089118 Z M439.178341,9.57725034 L438.034405,9.57725034 L438.034405,4.60635916 C438.482225,4.51679355 439.120701,4.45409763 439.927663,4.45409763 C440.858774,4.45409763 441.279991,4.60635916 441.639134,4.82131661 C441.905165,5.03179579 442.117991,5.42588446 442.117991,5.90953874 C442.117991,6.45588894 441.701208,6.88132557 441.098203,7.06045678 L441.098203,7.12315271 C441.581493,7.30228392 441.847525,7.66950291 441.993842,8.33676668 C442.149028,9.09359606 442.237705,9.39364084 442.357419,9.57725034 L441.12924,9.57725034 C440.978488,9.39364084 440.885377,8.94133453 440.73906,8.36363636 C440.650383,7.82176444 440.34888,7.57545902 439.719272,7.57545902 L439.178341,7.57545902 L439.178341,9.57725034 Z M439.209378,6.760412 L439.750309,6.760412 C440.379917,6.760412 440.885377,6.54545455 440.885377,6.03045231 C440.885377,5.57814599 440.557272,5.27362293 439.838986,5.27362293 C439.537484,5.27362293 439.324658,5.30497089 439.209378,5.33631885 L439.209378,6.760412 L439.209378,6.760412 Z" id="Fill-18" fill="#0A0B09"></path>
                </g>
            </Icon>
        );
    }
}

export default FullLogo;
