import queryString from 'query-string';
import { melissa } from '@cs-admin/config';
import fetch from '@beautycounter/utils/fetch';

export const getMelissaAddress = async params => {
    const {
        firstAddress = '',
        secondAddress = '',
        firstName = '',
        lastName = '',
        city = '',
        state = '',
        company = '',
        country = '',
        postalCode = '',
    } = params;
    try {
        const endpoint = `${melissa.verificationUrl}?${queryString.stringify({
            id: melissa.key,
            format: 'json',
            act: 'Check',
            cols: 'CountryCode,NameFirst,NameLast',
            a1: firstAddress,
            a2: secondAddress,
            first: firstName,
            last: lastName,
            postal: postalCode,
            ctry: country,
            comp: company,
            city,
            state,
        })}`;

        const request = await fetch(endpoint);
        const { Records = [] } = await request.json();
        const [addressRecord] = Records;

        return {
            melissaAddress: addressRecord || {},
            status: request?.status,
            statusText: request?.statusText,
        };
    } catch (error) {
        console.error(error);
        return {
            melissaAddress: {},
            error,
        };
    }
};
export default getMelissaAddress;
