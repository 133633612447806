import { routes } from '@cs-admin/config';

// nested configuration for all standardized routes.
const routeConfig = [
    /*
     * SCHEMA:
     *  to: URI path piece
     *  preLoadCallBack: custom processing at the beginnning of the route. Good place to set dynamic route template props.
     *  dependencies: any containers or stores needed to load.
     *  key: used to get the translated name of the path via contentful.
     *  component: the container for the path.
     *  redirectTo: instead of rendering a component, redirects to another route.
     *  preRenderCallback: optional route specific logic before rendering.
     *  children: additional nested routes.
     *  props: custom props for the rendered component.
     *  navLink: options for adding this link to the nav bar.
     *    {
     *       icon: reference the icon for this link
     *       position: Tools/ *or* 5
     *    }
     *  requireAuth: must be logged in.(bool)
     *  menuParent: the key for the parent item to nest within.
     *  icon: the icon to be displayed in menu.
     *  variables: named variables path pieces to extract after the current piece.
     */
    {
        to: routes.LOGIN,
        component: 'LoginPage',
    },
    {
        to: routes.FORGOT_PASSWORD,
        component: 'ForgotPasswordPage',
    },
    {
        to: routes.CUSTOMERS,
        requireAuth: true,
        component: 'CustomersContainer',
        children: [
            {
                to: '/(.*)/dashboard',
                key: 'dashboard',
                requireAuth: true,
                component: 'CustomerDetailContainer',
            },
            {
                to: '/(.*)/detail',
                key: 'detail',
                requireAuth: true,
                component: 'CustomerDetailContainer',
            },
            {
                to: '/(.*)/address',
                key: 'address',
                requireAuth: true,
                component: 'CustomerDetailContainer',
            },
            {
                to: '/(.*)/credit',
                key: 'credit',
                requireAuth: true,
                component: 'CustomerDetailContainer',
            },
            {
                to: '/(.*)/orders',
                key: 'orders',
                requireAuth: true,
                component: 'CustomerDetailContainer',
            },
            {
                to: '/(.*)/audit',
                key: 'audit',
                requireAuth: true,
                component: 'CustomerDetailContainer',
            },
            {
                to: '/(.*)/popups',
                key: 'popups',
                requireAuth: true,
                component: 'CustomerDetailContainer',
                children: [
                    {
                        to: '/(.*)',
                        key: 'popup-detail',
                        requireAuth: true,
                        component: 'PopupDetailContainer',
                    },
                ],
            },
            {
                to: '/(.*)/carts',
                key: 'carts',
                requireAuth: true,
                component: 'CustomerDetailContainer',
                children: [
                    {
                        to: '/(.*)',
                        key: 'cart-detail',
                        requireAuth: true,
                        component: 'CartDetailContainer',
                    },
                ],
            },
        ],
    },
    {
        to: routes.ORDERS,
        requireAuth: true,
        component: 'OrdersContainer',
        children: [
            {
                to: '/failed',
                key: 'failed',
                requireAuth: true,
                component: 'OrdersContainer',
            },
            {
                to: '/(.*)/info',
                key: 'info',
                requireAuth: true,
                component: 'OrderDetailContainer',
            },
            {
                to: '/(.*)/process',
                key: 'process',
                requireAuth: true,
                component: 'OrderDetailContainer',
            },
            {
                to: '/(.*)/refunds',
                key: 'refunds',
                requireAuth: true,
                component: 'OrderDetailContainer',
            },
            {
                to: '/(.*)/audit',
                key: 'audit',
                requireAuth: true,
                component: 'OrderDetailContainer',
            },
            {
                to: '/portal/(.*)',
                key: 'portal',
                requireAuth: true,
                component: 'PortalOrderContainer',
            },
        ],
    },
    {
        to: routes.BATCHORDERS,
        requireAuth: true,
        component: 'BatchOrdersContainer',
        children: [
            {
                to: '/(.*)/details',
                key: 'details',
                requireAuth: true,
                component: 'BatchOrderDetailsContainer',
            },
            {
                to: '/(.*)/failed',
                key: 'failed',
                requireAuth: true,
                component: 'BatchOrderDetailsContainer',
            },
        ],
    },
    {
        to: routes.BULKLEDGERS,
        requireAuth: true,
        component: 'BulkLedgersContainer',
    },
    {
        to: routes.RETURNS,
        requireAuth: true,
        component: 'ReturnsContainer',
    },
    {
        to: routes.ADMINISTRATORS,
        requireAuth: true,
        component: 'AdminContainer',
        children: [
            {
                to: '/create',
                requireAuth: true,
                component: 'AdminCreateContainer',
            },
        ],
    },
    {
        to: routes.PROMOS,
        requireAuth: true,
        component: 'PromoContainer',
        children: [
            {
                to: '/tool',
                requireAuth: true,
                component: 'PromoContainer',
            },
            {
                to: '/list',
                requireAuth: true,
                component: 'PromoContainer',
            },
            {
                to: '/editor/:promoName',
                key: 'editor',
                requireAuth: true,
                component: 'PromoContainer',
            },
            {
                to: '/editor',
                requireAuth: true,
                component: 'PromoContainer',
            },
            {
                to: '/create',
                requireAuth: true,
                component: 'PromoCreateContainer',
            },
        ],
    },
    {
        to: routes.INCENTIVES,
        requireAuth: true,
        component: 'IncentivesContainer',
        children: [
            {
                to: '/milestone1',
                requireAuth: true,
                component: 'IncentivesContainer',
            },
            {
                to: '/milestone2',
                requireAuth: true,
                component: 'IncentivesContainer',
            },
            {
                to: '/milestone3',
                requireAuth: true,
                component: 'IncentivesContainer',
            },
            {
                to: '/enrollers',
                requireAuth: true,
                component: 'IncentivesContainer',
            },
        ],
    },
    {
        to: routes.DASHBOARD,
        requireAuth: true,
        component: 'DashboardPage',
    },
];

/* ADD NEW PATHS BEFORE DASHBOARD OTHERWISE PATH WONT SHOW */

export { routeConfig };
