export const ADDRESSES = [
    {
        id: 0,
        address: '1733 Ocean Ave',
        address2: '',
        city: 'Santa Monica',
        state: 'CA',
        postalCode: '90401',
        country: 'US',
        countryId: 1,
    },
    {
        id: 1,
        address: '330 Hubbard St',
        address2: '',
        city: 'Glastonbury',
        state: 'CT',
        country: 'US',
        countryId: 1,
        postalCode: '06033',
    },
    {
        id: 2,
        address: '645 Howe Street',
        address2: '',
        city: 'Vancouver',
        state: 'British Columbia',
        country: 'CA',
        countryId: 2,
        postalCode: 'V6C 2Y9',
    },
    {
        id: 3,
        address: '123 Main Street',
        city: 'San Juan',
        state: 'PR',
        country: 'US',
        postalCode: '123345',
        countryId: 1,
    },
];

export default ADDRESSES;
