import { nogento } from '@cs-admin/config';
import { getSafe } from '@cs-admin/utils/object';
import fetch from '@beautycounter/utils/fetch';
import { getUserToken } from '@cs-admin/services/user';

export async function getUserAccountInfo(phoenixId, query) {
    if (!phoenixId || !query) return {};
    const payload = `
        {
            userAccount(phoenixId: ${phoenixId}) {
                ${query}
            }
        }
    `;
    return handleCall(payload);
}

export async function handleCall(payload) {
    try {
        const result = await query(payload);
        if (result && result.errors) {
            return {};
        }
        if (result && result.data) {
            return getSafe(() => result.data.userAccount) || {};
        }
        throw new Error('no data');
    } catch (error) {
        console.log('Error: ', error);
    }
}

export async function query(payload) {
    const token = await getUserToken();
    const result = await fetch(`${nogento.btc}/graphql/query`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ query: payload }),
    });
    return result.json();
}
