import { observable, computed, action } from 'mobx';
import { normalizeProducts, getVariantKey } from '@cs-admin/services/products';
import { keyBy } from 'lodash';
import { filterCategories, productHasCategoryVisibility } from '@cs-admin/services/filter';
import { searchForProducts } from '@cs-admin/services/search';
import { specialCategories, routes } from '@cs-admin/config';
import { getNativeProductUrl } from '@cs-admin/utils/url';
import { getSafe } from '@cs-admin/utils/object';
import { HostFreeItems } from '@beautycounter/constants/categories';

class ProductsStore {
    @observable total;
    @observable page;
    @observable products;
    @observable productsById;
    @observable productsByCategoryId;
    @observable activeProduct;
    @observable searchProducts;
    @observable searchResults;
    @observable searchTotal;

    constructor() {
        this.total = 0;
        this.page = 0;
        this.products = []; // parents only, variants nested
        this.productsById = {}; // parents only, variants nested
        this.productsByCategoryId = {}; // parents only, variants nested
        this.normalizedBySKU = {}; // includes variants, limited attributes
        this.normalizedById = {}; // includes variants, limited attributes
        this.activeProduct = null;
        this.searchProducts = ''; // keywords
        this.searchResults = [];
        this.searchTotal = 0;
    }

    @action
    load = data => {
        this.products = data;

        /* Increase max quatity for products who's max is 10 or greater. 
        Keep maxQuantity the same for products that have a maxQuatity less than 10 */

        this.products.forEach(product => {
            product.maxQuantity >= 10 ? (product.maxQuantity = Infinity) : null;
            product.variants &&
                product.variants.forEach(variant => {
                    variant.maxQuantity >= 10 ? (variant.maxQuantity = Infinity) : null;
                });
        });

        this.productsById = {
            ...keyBy(this.products, 'productId'),
        };
        this.categoryStore.categories.map(category => {
            this.productsByCategoryId[category.magentoId] = this.products.filter(
                product =>
                    productHasCategoryVisibility(product) &&
                    filterCategories(product, category.magentoId),
            );
        });
        this.normalizedById = normalizeProducts(this.products, 'id');
        this.normalizedBySKU = normalizeProducts(this.products, 'sku');
        //this.cartStore.initializeCartEngine && this.cartStore.initializeCartEngine(this.products);
    };

    @action
    variantProductsWithCategory = categoryId => {
        const productsWithVariants = this.products
            .filter(
                p =>
                    p.variants &&
                    p.variants.some(x => x.categories && x.categories.includes(categoryId)),
            )
            .map(product => ({
                ...product,
                variants: product.variants.filter(
                    x => x.categories && x.categories.includes(categoryId),
                ),
            }));

        return productsWithVariants || [];
    };

    @action
    productBySKU = SKU => {
        if (typeof this.normalizedBySKU[SKU] != 'undefined') {
            return {
                ...this.productsById[this.normalizedBySKU[SKU].id],
                selectedVariant: this.normalizedBySKU[SKU].selectedVariant,
            };
        }
        return false;
    };

    getFreeProductsBySKUs = (SKUs = []) => SKUs.filter(this.productBySKU).map(this.productBySKU);

    @action
    productsByCategory = categoryId =>
        this.products.filter(
            product =>
                productHasCategoryVisibility(product) && filterCategories(product, categoryId),
        );

    @action
    host = () =>
        this.products.filter(
            product =>
                productHasCategoryVisibility(product) && filterCategories(product, HostFreeItems),
        );

    @action
    productsBySKUS = SKUS => {
        if (SKUS && SKUS.length && Array.isArray(SKUS.slice())) {
            const lookups = [];
            return SKUS.filter(sku => {
                const product = this.productBySKU(sku);
                return product ? lookups.push(product) : false;
            }).map((sku, i) => lookups[i]);
        }
        return [];
    };

    @action
    productOrVariantById = id => {
        if (typeof this.normalizedById[id] != 'undefined') {
            return {
                ...this.productsById[this.normalizedById[id].id],
                selectedVariant: this.normalizedById[id].selectedVariant,
            };
        }
        return false;
    };

    @action
    variantById = id => {
        const product = getSafe(() => this.normalizedById[id]);
        const variant = getSafe(() =>
            product.selectedVariant
                ? getVariantKey(product.selectedVariant, this.productsById[product.id])
                : this.productsById[product.id],
        );
        return variant;
    };

    @computed
    get productsBySlug() {
        return keyBy(this.products, 'handle');
    }

    @computed
    get productsBySearch() {
        return this.searchTotal
            ? this.searchResults
            : this.productsByCategoryId[specialCategories.bestSellers];
    }

    @action
    setSearch = params => {
        this.searchProducts = params;
        this.searchResults = searchForProducts(this.products, params);
        this.searchTotal = this.searchResults.length;
    };

    @action
    setActiveProduct = params => {
        this.activeProduct = params.slug;
    };

    @action
    unsetActiveProduct = () => {
        this.activeProduct = null;
    };

    @action
    getPrimaryCategory = product => {
        if (
            this.categoryStore.lastCategory &&
            product.categoryList.includes(this.categoryStore.lastCategory)
        ) {
            return this.categoryStore.uniqueCategoryLinksById[this.categoryStore.lastCategory];
        }

        return (
            getSafe(
                () =>
                    this.categoryStore.uniqueCategoryLinksById[
                        product.parentCategoryIds.length > 1 && product.parentCategoryIds[1]
                    ],
            ) || null
        );
    };

    @action
    getSecondaryCategory = product => {
        if (
            this.categoryStore.lastCategory &&
            this.categoryStore.lastFilter &&
            product.categories.includes(this.categoryStore.lastFilter)
        ) {
            return this.categoryStore.categoryLinksById[
                this.categoryStore.lastCategory + this.categoryStore.lastFilter.toString()
            ];
        }

        if (product.categories) {
            for (let i = 0; i < product.categories.length; i++) {
                const category = product.categories[i];
                const deepId =
                    product.parentCategoryIds.length > 1 &&
                    product.parentCategoryIds[1] + category.toString();
                if (this.categoryStore.categoryLinksById[deepId])
                    return this.categoryStore.categoryLinksById[deepId];
            }
        }
        return null;
    };

    @action
    getProductLink(id) {
        return getNativeProductUrl(this.productsById[id].handle);
    }

    @computed
    get anyById() {
        const any = {};
        this.products.forEach(product => {
            product.variants &&
                product.variants.forEach(variant => {
                    any[variant.id] = variant;
                });
            any[product.id] = product;
        });
        return any;
    }

    @computed
    get segmentObject() {
        const segment = {};

        Object.keys(this.anyById).forEach(key => {
            const product = this.anyById[key];
            const parent = this.productOrVariantById(product.parentId) || product;

            /*
             * Note:
             * Calculating the discountPrice for every product
             * in the catalog on the client has heavy runtime overhead.
             *
             * discountPrice should ideally be precomputed
             * and cached serverside, rather than recalculated and
             * sent from the client constantly.
             *
             * Disabling this for now.
             *
             * const discountPrice = this.promoStore.getPromoPrice({
             *    price: product.price,
             *    sku: product.sku,
             * });
             * */
            const discountPrice = product.price;
            const promoPrice = discountPrice < product.price && discountPrice;
            /**/

            const category = getSafe(() => this.getPrimaryCategory(parent)) || {};
            const subcategory = getSafe(() => this.getSecondaryCategory(parent)) || {};

            segment[product.id] = {
                categoryPath: category.to,
                subcategoryPath: subcategory.to,
                categoryName: category.name,
                subcategoryName: subcategory.name,
                product_id: parent ? parent.sku : product.sku,
                magentoId: parent ? parent.id : product.id,
                sku: product.sku,
                name: product.title,
                price: product.price,
                priceInCents: product.priceInCents,
                value: product.price && product.price.toFixed(2),
                promoPrice,
                url: parent ? parent.handle : product.handle,
                fullPath: parent
                    ? `${routes.PRODUCT}/${parent.handle}`
                    : `${routes.PRODUCT}/${product.handle}`,
                tags: product.tags,
                product: product.swatches && product.swatches.swatchlabel,
                parentId: product.parentId,
                parentSku: parent ? parent.sku : product.sku,
                description: parent ? parent.description : product.description,
                image_url: getSafe(() => product.images[0].src) || null,
            };
        });
        return segment;
    }

    @action
    bundledProducts(SKU, bundled) {
        const product = this.productBySKU(SKU);
        const bundledProducts = [];
        Object.values(bundled).forEach(selection => {
            product.bundledOptions.some(option =>
                option.bundledOptionValues.some(({ selectionId, sku, productId, name }) => {
                    if (selectionId === selection) {
                        return bundledProducts.push({ sku, id: Number(productId), name });
                    }
                }),
            );
        });
        return bundledProducts;
    }
}

const productsStore = new ProductsStore();

export default productsStore;
export { ProductsStore };
