exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._11I1-{text-align:center}._3_j2p{margin:0 auto}._35cFH{margin:24px auto}.boGB4{background-color:#000;color:#fff}._15Imi{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex}._15Imi span{color:#000}", ""]);

// exports
exports.locals = {
	"label": "_11I1-",
	"content": "_3_j2p",
	"actions": "_35cFH",
	"update": "boGB4",
	"flag": "_15Imi"
};