import { observable, action, computed } from 'mobx';
import { isEmpty } from 'lodash';
import { getMelissaAddress } from '@cs-admin/services/melissa/verification';
import { SUGGESTED } from './constants';
import {
    hasAddressChangedStatus,
    hasAddressErrorStatus,
} from '@cs-admin/stores/melissaStore/utils';

class MelissaStore {
    @observable melissaAddress;
    @observable enteredAddress;
    @observable hasSuggestions;
    @observable selectedAddressVariant;
    @observable hasAddressError;

    constructor() {
        this.enteredAddress = {};
        this.melissaAddress = {};
        this.hasSuggestions = false;
        this.selectedAddressVariant = '';
        this.hasAddressError = false;
    }

    @action
    setSelectedAddressVariant = (addressVariant = '') => {
        this.selectedAddressVariant = addressVariant;
    };

    @computed
    get isAddressSuggestedByMelissa() {
        return this.selectedAddressVariant === SUGGESTED;
    }

    @action
    checkForSuggestions = async enteredAddress => {
        const { melissaAddress } = await getMelissaAddress(enteredAddress);
        const isMelissaAddressExist = !isEmpty(melissaAddress);

        if (!isMelissaAddressExist) return false;

        const {
            Results,
            NameFirst,
            NameLast,
            CompanyName,
            AddressLine1,
            AddressLine2,
            City,
            State,
            CountryCode,
            PostalCode,
        } = melissaAddress;

        const resultCodes = Results?.split(',');
        const hasSuggestions = resultCodes?.some(hasAddressChangedStatus);
        const hasAddressError = resultCodes?.some(hasAddressErrorStatus);

        this.hasSuggestions = hasSuggestions;
        this.hasAddressError = hasAddressError;

        this.melissaAddress = {
            firstName: NameFirst,
            lastName: NameLast,
            company: CompanyName,
            firstAddress: AddressLine1,
            secondAddress: AddressLine2,
            city: City,
            state: State,
            country: CountryCode,
            postalCode: PostalCode,
        };
        this.enteredAddress = enteredAddress;

        return { hasSuggestions, hasAddressError };
    };

    @action
    resetSuggestions = () => {
        this.enteredAddress = {};
        this.melissaAddress = {};
        this.hasSuggestions = false;
        this.hasAddressError = false;
    };

    @action
    setErrorStatus = error => {
        this.hasAddressError = error;
    };

    @computed
    get selectedAddress() {
        return this.isAddressSuggestedByMelissa ? this.melissaAddress : this.enteredAddress;
    }
}

const melissaStore = new MelissaStore();

export default melissaStore;
export { MelissaStore };
