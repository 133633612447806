import { observable, observe, computed, action, autorun } from 'mobx';
import { cloneDeep } from 'lodash';
import { getPopupByID, getOrdersForPopup } from '@cs-admin/services/popups';
import { getAccountInfo } from '@cs-admin/services/portalOrder';

class PopupStore {
    @observable orders;
    @observable activePopup;
    @observable consultant;
    @observable loadingOrders;

    constructor() {
        this.orders = [];
        this.activePopup = null;
        this.consultant = {};
        this.loadingOrders = false;
    }

    @action
    async setActivePopup(id) {
        this.loadingOrders = true;
        this.activePopup = await getPopupByID(id);
        this.consultant = await getAccountInfo(this.activePopup.uid);
        this.orders = await getOrdersForPopup(id);
        this.orders =
            this.orders &&
            this.orders.sort((a, b) => new Date(b.dateCreatedUTC) - new Date(a.dateCreatedUTC));
        this.loadingOrders = false;
    }

    @action
    clearActivePopup = () => {
        this.activePopup = null;
        this.consultant = {};
        this.orders = [];
    };
}

const popupStore = new PopupStore();

export default PopupStore;
export { PopupStore };
