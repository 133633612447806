import { toTwoDecimal } from './utils/numbers';
import { PROMO_ID } from './utils/constants/promoIDs';
import { isEqual } from 'lodash';

function dropShipping(context, promo) {
    _checkForSelections(context, promo);
    if (!(context.cart.shipping.rates && context.cart.shipping.rates.length)) return context;
    const rates = context.cart.shipping.rates.filter(rate => !promo.methods.includes(rate.method));
    context.cart.shipping.rates = rates;
    return Object.assign({}, context);
}

function freeShipping(context, promo) {
    _checkForSelections(context, promo);
    if (!(context.cart.shipping.rates && context.cart.shipping.rates.length)) return context;
    const rates = context.cart.shipping.rates.map(rate => {
        if (promo.methods.includes(rate.method)) rate.amount = 0;
        return rate;
    });
    context.cart.shipping.rates = rates;
    return Object.assign({}, context);
}

function discountShipping(context, promo) {
    _checkForSelections(context, promo);
    if (!(context.cart.shipping.rates && context.cart.shipping.rates.length)) return context;
    const rates = context.cart.shipping.rates.map(rate => {
        const method = promo.methods.find(p => p[0] == rate.method);
        if (method) rate.amount = method[1];
        return rate;
    });
    context.cart.shipping.rates = rates;
    return Object.assign({}, context);
}

function cartDiscount(context, promo) {
    _checkForSelections(context, promo);
    _calculateSubtotal(context, promo.discount);
    return Object.assign({}, context);
}

function skuDiscount(context, promo) {
    _checkForSelections(context, promo);
    let discounted;
    discounted = context.cart.promoItems.some(
        product =>
            matchItemByProperties(product, promo, ['sku', 'redemptionKey']) &&
            _applyDiscount(product, promo),
    );
    if (!discounted)
        discounted = context.cart.products.some(
            product =>
                matchItemByProperties(product, promo, ['sku', 'redemptionKey']) &&
                _applyDiscount(product, promo),
        );
    _calculateSubtotal(context, discounted ? promo.discount : 0);
    return Object.assign({}, context);
}

function subscriptionItemDiscount(context, promo) {
    _checkForSelections(context, promo);
    let discounted;
    discounted = context.cart.promoItems.some(
        product =>
            matchItemByProperties(product, promo, ['sku', 'redemptionKey']) &&
            product.subscription &&
            _applyDiscount(product, promo),
    );
    if (!discounted)
        discounted = context.cart.products.some(
            product =>
                matchItemByProperties(product, promo, ['sku', 'redemptionKey']) &&
                product.subscription &&
                _applyDiscount(product, promo),
        );
    _calculateSubtotal(context, discounted ? promo.discount : 0);
    return Object.assign({}, context);
}

function skuDiscountNoCondition(context, promo) {
    _checkForSelections(context, promo);
    let discounted;
    discounted = context.cart.promoItems.some(
        product =>
            matchItemByProperties(product, promo, ['sku', 'redemptionKey', 'subscription']) &&
            _applyDiscount(product, promo),
    );
    if (!discounted)
        discounted = context.cart.products.some(
            product =>
                matchItemByProperties(product, promo, ['sku', 'redemptionKey', 'subscription']) &&
                _applyDiscount(product, promo),
        );
    _calculateSubtotal(context, discounted ? promo.discount : 0);
    return Object.assign({}, context);
}

function matchItemByProperties(product, promo, properties) {
    const skuMatches = properties.includes('sku') ? skuMatch(product, promo) : true;
    const redemptionKeyMatches = properties.includes('redemptionKey')
        ? redemptionKeyMatch(product, promo)
        : true;
    const subscriptionMatches = properties.includes('subscription')
        ? subscriptionMatch(product, promo)
        : true;

    return skuMatches && redemptionKeyMatches && subscriptionMatches;
}

function skuMatch(product, promo) {
    return product.sku === promo.sku;
}

function redemptionKeyMatch(product, promo) {
    return (
        product.redemptionKey === promo.redemptionKey ||
        (!product.redemptionKey && !promo.redemptionKey)
    );
}

function subscriptionMatch(product, promo) {
    return (
        isEqual(product.subscription, promo.subscription) ||
        (!product.subscription && !promo.subscription)
    );
}

function dropPromoItem(context, promo, noDrop) {
    if (noDrop) return context;
    _checkForSelections(context, promo);
    context.cart.promoItems = context.cart.promoItems
        .map(product => {
            if (product.sku === promo.sku) product.quantity -= promo.quantity;
            return product;
        })
        .filter(({ quantity }) => quantity > 0);
    _calculateSubtotal(context, promo.amount);
    return Object.assign({}, context);
}

function dropAllProducts(context, promo, noDrop) {
    if (noDrop) return context;
    _checkForSelections(context, promo);
    let price = 0;
    const product = context.cart.products.find(product => product.sku === promo.sku);
    if (product) price += product.totalPrice;
    const promoItem = context.cart.promoItems.find(product => product.sku === promo.sku);
    if (promoItem) price += promoItem.totalPrice;
    const filteredProducts = context.cart.products.filter(product => product.sku !== promo.sku);
    context.cart.products = filteredProducts;
    const filteredPromoItems = context.cart.promoItems.filter(product => product.sku !== promo.sku);
    context.cart.promoItems = filteredPromoItems;
    _calculateSubtotal(context, price);
    return Object.assign({}, context);
}

function flagPromoTag(context, promo) {
    if (!promo.code) return;
    const tag = context.cart.promoTags.find(tag => tag.code === promo.code);
    if (tag) tag.success = true;
    return Object.assign({}, context);
}

function _checkForSelections(context, promo) {
    if (!(promo.selection && promo.selection.length)) return;
    context.cart.selections.push(promo);
}

function _applyDiscount(product, promo) {
    product.doNotShipAlone = promo.do_not_ship_alone || false;
    if (product.totalPrice == 0) return false;
    product.isFinalSaleConfig = promo.final_sale || false;
    const totalPrice = toTwoDecimal(product.totalPrice - promo.discount);
    if (totalPrice >= 0) product.totalPrice = totalPrice;
    else {
        promo.discount = toTwoDecimal(promo.discount + totalPrice);
        product.totalPrice = 0;
    }
    if (promo.discount === product.price) promo.free = true;
    if (promo.name && promo.name.includes('HostRewardFree')) {
        promo.rewardType === 'free';
    } else if (promo.name && promo.name.includes('HostRewardDiscount')) {
        promo.rewardType === 'discount';
    }
    if (promo.discount) {
        if (product.discounts) return product.discounts.push(promo);
        return (product.discounts = [promo]);
    }
}

function _calculateSubtotal(context, discount) {
    context.cart.subtotal = toTwoDecimal(context.cart.subtotal - discount);
    if (context.cart.subtotal < 0) context.cart.subtotal = 0;
}

export default {
    [PROMO_ID.RESTRICT_SHIPPING_WHEN_ITEM_IN_CART]: dropShipping,
    [PROMO_ID.FREE_SHIPPING_WHEN_PARTICULAR_PURCHASE]: freeShipping,
    [PROMO_ID.FREE_SHIPPING_WHEN_REACH_SUBTOTAL]: freeShipping,
    [PROMO_ID.DISCOUNTED_SHIPPING_WHEN_PARTICULAR_ITEM]: discountShipping,
    [PROMO_ID.DISCOUNTER_SHIPPING_WHEN_REACH_SUBTOTAL]: discountShipping,
    [PROMO_ID.NO_CONDITION_PERCENT_OFF]: skuDiscountNoCondition,
    [PROMO_ID.PERCENT_OFF_ENTIRE_CART_WHEN_REACH_SUBTOTAL]: skuDiscount,
    8: cartDiscount,
    4: skuDiscount,
    3: skuDiscount,
    5: skuDiscount,
    [PROMO_ID.PERCENT_OFF_WHEN_REACH_SUBTOTAL]: skuDiscount,
    [PROMO_ID.$_OFF_ITEM_WHEN_REACH_SUBTOTAL]: skuDiscount,
    12: skuDiscount,
    [PROMO_ID.PERCENT_OFF_WHEN_PURCHASE_ITEM]: skuDiscount,
    [PROMO_ID.AMOUNT_OFF_WHEN_PURCHASE_ITEM]: skuDiscount,
    6: skuDiscount,
    7: skuDiscount,
    13: skuDiscount,
    [PROMO_ID.REMOVE_ITEM_FROM_CART_WHEN_ADDRESS_IN_ZIP_OR_STATE]: dropAllProducts,
    [PROMO_ID.RESTRICT_SHIPPING_WHEN_ADDRESS_IN_CITY_OR_STATE]: dropShipping,
    [PROMO_ID.DROP_PROMO_ITEM]: dropPromoItem,
    20: flagPromoTag,
    [PROMO_ID.REMOVE_ITEM_FROM_CART_WHEN_NO_PARTICULAR_ITEM_IN_CART]: dropAllProducts,
    [PROMO_ID.DISCOUNT_AMOUNT_ON_SUBSCRIPTION_ITEM]: subscriptionItemDiscount,
    [PROMO_ID.DISCOUNT_PERCENTAGE_ON_SUBSCRIPTION_ITEM]: subscriptionItemDiscount,
};
