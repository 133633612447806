import { firebase as config } from '@cs-admin/config';
import { firestore } from './settings';

export async function firebaseInit() {
    const [module] = await Promise.all([
        import(/* webpackChunkName: "FirebaseApp" */ 'firebase/app'),
    ]);
    const firebase = module.default;
    if (firebase.apps.length === 0) firebase.initializeApp(config);

    return firebase;
}

/**
 * Wrap Firebase into a singleton so that we only load one instance
 * of its services throughout the duration of the app. We will also
 * only load the module that is required for the service, and nothing more.
 */

class FirebaseApp {
    init = async () => {
        if (!this.app) this.app = await firebaseInit();
    };

    firestore = async () => {
        await Promise.all([
            this.init(),
            import(/* webpackChunkName: "FirebaseFirestore" */ 'firebase/firestore'),
        ]);

        this.db = this.app.firestore();
        this.db.settings(firestore);

        return this.db;
    };

    auth = async () => {
        await Promise.all([
            this.init(),
            import(/* webpackChunkName: "FirebaseAuth" */ 'firebase/auth'),
        ]);

        this.authentication = this.app.auth();
        return this.authentication;
    };
}

const instance = new FirebaseApp();
export default instance;
