import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import theme from '@cs-admin/theme/theme';

/**
 * Interface for consistent text styles
 * Custom Typography using MUI components.
 * Be mindful of the global overwrites from theme.js
 */

// TODO: Update to use counter-ui text instead

export const D4 = props => <Typography {...props} variant="h1" />;

export const D3 = props => <Typography {...props} variant="h2" />;

export const D2 = props => <Typography {...props} variant="h3" />;

export const D1 = props => <Typography {...props} variant="h4" />;

export const Headline = props => <Typography {...props} variant="h5" />;

export const Title = props => <Typography {...props} variant="h6" />;

export const SubHeadline = props => <Typography {...props} variant="subtitle1" />;

export const Subtitle2 = props => <Typography {...props} variant="subtitle2" />;

export const B2 = props => <Typography {...props} variant="body2" />;

export const B1 = props => <Typography {...props} variant="body1" />;

export const Caption = props => <Typography {...props} variant="caption" />;

export default class Text extends Component {
    static D4 = D4;
    static D3 = D3;
    static D2 = D2;
    static D1 = D1;
    static Headline = Headline;
    static Title = Title;
    static SubHeadline = SubHeadline;
    static Subtitle2 = Subtitle2;
    static B2 = B2;
    static B1 = B1;
    static Caption = Caption;

    // Default is B1
    render = props => <Typography {...props} />;
}

// export default withStyles(style)(Text);
