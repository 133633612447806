import firebase from '@cs-admin/services/firebase/init';
import { isEmpty, mergeWith, groupBy } from 'lodash';
import { loadCartsByUser } from './cart';
import { CUSTOMER_ORDER, PERSONAL_ORDER } from '@beautycounter/constants/orderTypes';

export async function loadCartsBySocial(socialId) {
    try {
        if (!socialId) return false;

        const database = await firebase.firestore();

        const snapshot = await database
            .collection('carts')
            .where('socialId', '==', socialId)
            .get();

        if (snapshot.empty) return [];

        return snapshot.docs.map(doc => doc.data()).filter(x => !x.canceled);
    } catch (err) {
        console.info(err);
        return err;
    }
}

export async function loadPersonalCart(uid, email) {
    try {
        if (!uid) return false;
        const database = await firebase.firestore();

        const snapshot = await database
            .collection('carts')
            .where('uid', '==', uid)
            .where('canceled', '==', false)
            .where('customer.email', '==', email)
            .get();
        if (snapshot.empty) return null;
        const carts = snapshot.docs
            .map(doc => doc.data())
            .filter(x => !x.socialId)
            .sort((a, b) => (a.lastSave < b.lastSave ? 1 : a.lastSave > b.lastSave ? -1 : 0));

        console.log(carts);
        const [first] = carts;
        return first;
    } catch (err) {
        console.info(err);
        return err;
    }
}

export async function loadUserAllOpenCarts({ email, userId, defaultLocaleCode }) {
    const carts = await loadCartsByUser(userId);
    const mappedCarts = carts.map(c => {
        const { socialId, canceled, cartId, uid, cid, cartInstances: carts } = c;
        const activeLocale = c.activeLocale || defaultLocaleCode;
        const [[locale, cartInstance]] = Object.entries(carts).filter(
            ([l, cart]) => l === activeLocale,
        );

        if (socialId || canceled || !cartInstance) return null;
        const {
            customer,
            promoItems,
            currency,
            lastSave,
            unpromofiedItems,
            promoTags,
        } = cartInstance;
        const orderType =
            !customer ||
            Object.keys(customer).length === 0 ||
            email === customer.email ||
            userId === customer.uid
                ? PERSONAL_ORDER
                : CUSTOMER_ORDER;
        return {
            cartId,
            uid,
            cid,
            activeLocale: locale,
            lastSave,
            currency,
            customer,
            unpromofiedItems,
            promoItems,
            orderType,
            promoTags,
        };
    });

    const clientOrders = mappedCarts.filter(x => x && x.orderType === CUSTOMER_ORDER);
    function sortByLastSaved(a, b) {
        return a.lastSave < b.lastSave ? 1 : a.lastSave > b.lastSave ? -1 : 0;
    }
    const clientOrderFiltered = Object.values(groupBy(clientOrders, x => x.customer.email)).map(
        x => x.sort(sortByLastSaved)[0],
    );

    const personalOrders = mappedCarts
        .filter(x => x && x.orderType === PERSONAL_ORDER)
        .sort(sortByLastSaved);

    console.log(personalOrders);
    const [personalOrder] = personalOrders;

    return { personalOrder, clientOrders: clientOrderFiltered };
}
