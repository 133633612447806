/**
 * Default developer environment
 * Values defined here will be overwritten by those declared in specific environments
 * such as "qa" or prod.
 */

const environment = {
    // Leave blank for local defaults
    liveUrl: '',
    bccomUrl: 'drafts.beautycountertest.com',
    firebaseProject: '',
    environmentName: 'localhost',

    cssShortening: false,
    codeOptimization: false,
    verbose: true,
    developerTools: true,

    flags: {
        contentDrafts: true,
        incentives: true,
        membershipManualUpgrade: true,
        cartApi: true,
        batchOrders: true,
        promoToolCartApi: false,
        businessPromoTool: true,
        restoreClientMemberDownline: false,
        ibisRestoreConsultant: false,
        ibisConsultantPortalOrders: false,
        useV2: true,
        compressConsultant: true,
        compressDownline: true,
        cleanLogin: true,
        bulkLedgers: true,
        enablePortalOrderButton: false,
    },

    firebase: {
        key: 'Drafts',
        apiKey: 'AIzaSyCTrd9CdKtrknuTRW5JakRagRhJ2Vj4Ct8',
        projectId: 'beautycounter-drafts',
        apiBase: 'https://us-central1-beautycounter-drafts.cloudfunctions.net',
        assetBucket: 'beautycounter-draft-assets',
        assetURL: '',
        useChunkCDN: false,
        image: [
            'https://www.googleapis.com/storage/v1/b/beautycounter-drafts.appspot.com/o',
            'https://firebasestorage.googleapis.com/v0/b/beautycounter-drafts.appspot.com/o',
        ],
    },

    square: {
        US: {
            url: 'https://js.squareupsandbox.com/v2/paymentform',
            appId: 'sandbox-sq0idb-qVIqJGwZKMrL16f7_kzZKg',
            token: 'EAAAEOwBxDsmfbSuurxo3J3GMTjuEsKB8HZ4xZja9C2YFGDMyn--zbdBGTGd3n-i',
            locationId: 'CJY833967SKSF',
            sandboxAppId: 'sandbox-sq0idb-qVIqJGwZKMrL16f7_kzZKg',
            sandboxLocationId: 'CJY833967SKSF',
            SDKUrl: 'https://sandbox.web.squarecdn.com/v1/square.js',
        },
        CA: {
            url: 'https://js.squareupsandbox.com/v2/paymentform',
            appId: 'sandbox-sq0idb-mOTJnRgdwW3fw7eOFu3OaA',
            token: 'EAAAEJ0cbC3fhNxUmGVj41AUaRonvCJPhyRejpfa8ESj_PVIEOJ4RbVMgxq-8Dra',
            locationId: 'YQBMZ1KQCXVY6',
            sandboxAppId: 'sandbox-sq0idb-mOTJnRgdwW3fw7eOFu3OaA',
            sandboxLocationId: 'YQBMZ1KQCXVY6',
            SDKUrl: 'https://sandbox.web.squarecdn.com/v1/square.js',
        },
    },

    contentful: {
        accessToken: '241923aebd05f59685c26adcd08490d8945c9b983f9ae90348f4a6d09953ba40',
        space: 'ylsk4zpp53wa',
        previewAccessToken: '5f091a0b95046c4053ca35d8a32a3dfcd8168685037a5af8e1b0527631abdde3',
    },

    phoenix: {
        pws: 'https://v2phoenix.api.beautycountertest.com/pws',
        search: 'https://v1phoenix.api.beautycountertest.com',
        prospect:
            'https://nxiy7lbifa.execute-api.us-east-1.amazonaws.com/stg/account/addprospectaccount',
        leads: 'https://nxiy7lbifa.execute-api.us-east-1.amazonaws.com/stg/leads',
        geoSearch: 'https://nxiy7lbifa.execute-api.us-east-1.amazonaws.com/stg/account/geosearch',
        btcLink: 'https://behindthecounter.beautycounter.com.staging.beautycounter.com',
    },

    sailthru: {
        // Customer ID key for BeautyCounter - Dev
        customerId: '3b5ad8b4e0e0534c8e3d44a0261a0127',
        env: 'dev',
    },

    googleMaps: {
        key: 'AIzaSyAuqVGoDI8XkUWZyiwhMtBNwLh9qv6xFJU',
    },

    nogento: {
        base: 'https://nogento.beautycountertest.com',
        checkout: 'https://api.checkout.beautycountertest.com',
        ecomm: 'https://api.ecomm.beautycountertest.com',
        btc: 'https://api.btc.beautycountertest.com',
        counterbase: 'https://api.counterbase.beautycountertest.com',
        get geoSearch() {
            return `${this.base}/accounts/geosearch`;
        },
    },

    paypal: {
        env: 'sandbox',
        script: 'https://www.paypalobjects.com/api/checkout.js',
        US: {
            client: {
                sandbox:
                    'AewD-eXgTPM9LSV5j8HvVTmJFcBhgk3hogvdSwGYhghr2rdAGFOJkrTldedwcXmk8FzcOmLGuxm2x87b',
            },
            sandboxClient: {
                sandbox:
                    'AewD-eXgTPM9LSV5j8HvVTmJFcBhgk3hogvdSwGYhghr2rdAGFOJkrTldedwcXmk8FzcOmLGuxm2x87b',
                production:
                    'AewD-eXgTPM9LSV5j8HvVTmJFcBhgk3hogvdSwGYhghr2rdAGFOJkrTldedwcXmk8FzcOmLGuxm2x87b',
            },
        },
        CA: {
            client: {
                sandbox:
                    'AfQVQk9TzEmLeaE4zVbOHvEYpUvRDME0hN13jxLTkJFmR90-EYjIxGwp2U0scynd1OH9qcCLg2Wz31iW',
            },
            sandboxClient: {
                sandbox:
                    'AfQVQk9TzEmLeaE4zVbOHvEYpUvRDME0hN13jxLTkJFmR90-EYjIxGwp2U0scynd1OH9qcCLg2Wz31iW',
                production:
                    'AfQVQk9TzEmLeaE4zVbOHvEYpUvRDME0hN13jxLTkJFmR90-EYjIxGwp2U0scynd1OH9qcCLg2Wz31iW',
            },
        },
    },
    narvarUrl: 'https://beautycounter.narvar.com/beautycounter/tracking/',

    bobTCs: 'https://drafts.beautycountertest.com/member-program/',
    bccomForgotPassword: 'https://drafts.beautycountertest.com/customer/account/forgotPassword',
    bccomBase: 'https://drafts.beautycountertest.com',
    newBase: 'https://drafts.beautycountertest.com',

    melissa: {
        key: 'Ny4yANaXXeZIF_FV3Fvz1V**',
        verificationEntry: 'https://personator.melissadata.net/v3/WEB',
        autocompleteUrl: 'https://expressentry.melissadata.net/web',

        get verificationUrl() {
            return `${this.verificationEntry}/ContactVerify/doContactVerify`;
        },

        get globalSearchUrl() {
            return `${this.autocompleteUrl}/GlobalExpressFreeForm`;
        },
    },

    klarna: {
        klarnaCanCutoverDateUTC: '2022-03-23T16:00:00.000Z',
    },
};

export { environment };
