import Validation from './validators';

/**
 * Convenience method to get validation status of all promos.
 * @param {object} context
 * @param {array} rules
 */
export function getRulesetStatus(context, ruleset) {
    if (!(context && typeof context === 'object'))
        throw new Error('Could not get validated ruleset. No context provided.');
    if (!(ruleset && Array.isArray(ruleset)))
        throw new Error('Could not get validated ruleset. No ruleset provided.');

    return ruleset.map(rule => new Validation(rule, context));
}
