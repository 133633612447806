import { toJS, observable, computed, action, autorun } from 'mobx';
import { getSafe } from '@cs-admin/utils/object';
import {
    validEmail,
    validUsZipcode,
    validCaZipcode,
    validPhoneNumber,
} from '@cs-admin/utils/validators';
import errorMessages from '@cs-admin/constants/errorMessages';
import { keyBy } from 'lodash';
import { locales } from '@cs-admin/config';
import { isValid, isBefore, isAfter } from 'date-fns';
import { majorityMaxDate } from '@cs-admin/utils/date';
import { DISTRIBUTOR_DATE_OF_BIRTH } from '@cs-admin/constants/validation';

/**
 *  TODO: Remove localStore and sessionStore out of formStore.
 */

const isDistributorFieldType = fieldType => fieldType === DISTRIBUTOR_DATE_OF_BIRTH;

class FormStore {
    @observable formsByLocale;
    @observable validations;

    constructor() {
        this.formsByLocale = keyBy(locales, 'code');

        // The validations will run in order, so the last validation should be the
        // priority error message as it will run last and attach the final error message
        this.validations = {
            email: [this.email, this.required],
            zip: [this.zipcode, this.required],
            address: [this.lengthLimit, this.required],
            addressOptional: [this.addressLength],
            required: [this.required],
            lengthExceeded: [this.lengthLimit],
            lengthExceededRequired: [this.lengthLimit, this.required],

            optional: [],
            phone: [this.required],
            phoneOptional: [this.phone],
            creditCardNumber: [this.required],
            creditCardNumberOptional: [], // my account hack type.
            creditCardVerification: [this.required],
            float: [this.float, this.required],
            date: [this.validateDate],
            dateOfBirth: [this.validateDateOfBirth, this.validateFutureDates],
            dateOfBirthDistributor: [
                this.dateRequired,
                this.validateDateOfBirth,
                this.validateMajority,
                this.validateFutureDates,
            ],
        };
    }

    ready() {
        this.applySessionForms();
        __BROWSER__ &&
            autorun(() => {
                // this.localeStore.activeLocale;
                this.applySessionForms();
            });
    }

    @computed
    get forms() {
        return this.formsByLocale[locales[0].code];
    }

    @action
    form(key) {
        return this.forms[key] || {};
    }

    @action
    create(options) {
        const key = typeof options === 'string' ? options : options.key;
        if (!key) throw new Error('Error in Formstore: Must provide a key when creating a form');
        if (this.forms[key]) return this.forms[key];
        const persist = typeof options === 'object' ? options.persist : false;
        this.forms[key] = new Form(key, persist, null, this);
        return this.forms[key];
    }

    @action
    createReactiveForm(name, modelLocation, action) {
        const reaction = () => {
            this.clearForm(name);
            action();
        };
        action();
    }

    @action
    clearForm(key) {
        if (this.forms[key]) delete this.forms[key];
    }

    @computed
    get sessionString() {
        return `formStore-${this.localeStore.activeLocale.code}`;
    }

    @computed
    get sessionForms() {
        return getSafe(() =>
            this.sessionStore.getKey(this.sessionString, { localized: false }, true),
        );
    }

    @action
    saveSessionForms(forms) {
        try {
            this.sessionStore.saveKey(this.sessionString, toJS(forms), { localized: false }, true);
        } catch (error) {
            console.log('Error saving forms to session storage: ', error);
        }
    }

    @action
    applySessionForms() {
        const storedForms = this.sessionForms;
        if (storedForms) {
            Object.keys(storedForms).forEach(key => {
                this.forms[key] = new Form(key, true, storedForms[key], this);
            });
        }
    }

    @action
    save(key) {
        const form = this.forms[key];
        if (form && form.persist) {
            const storedForms = this.sessionForms;
            this.saveSessionForms({
                ...storedForms,
                [key]: {
                    key: form.key,
                    fields: form.saveFields(),
                },
            });
        }
    }

    @action
    clear(...keys) {
        if (keys.length > 0) {
            const storedForms = this.sessionForms;
            keys.forEach(key => {
                if (storedForms && storedForms[key]) delete storedForms[key];
                if (this.forms[key]) this.forms[key] = null;
            });
            if (storedForms) this.saveSessionForms(storedForms);
        } else {
            this.formsByLocale[this.localeStore.activeLocale.code] = {};
            __BROWSER__ && window.sessionStorage.removeItem(this.sessionString);
        }
    }

    // Validators
    // Either true or an error message can be returned
    @action
    required(field) {
        let label = field.label;
        if (label[label.length - 1] === '*') label = label.substring(0, label.length - 1);
        if (typeof field.value === 'number' || field.value.length > 0) return true;
        return errorMessages.required && errorMessages.required.replace('${label}', label);
    }

    @action
    email(field) {
        return validEmail(field.value) ? true : errorMessages.validEmail;
    }

    @action
    zipcode(field) {
        // instead of POS.countryId, it was this.customerStore.activeCustomer.CountryId, but that did not exist anywhere.
        const isCA = getSafe(() => this.portalOrderStore.countryId === 2);
        if (isCA) return validCaZipcode(field.value) ? true : errorMessages.validCaZipcode;
        return validUsZipcode(field.value) ? true : errorMessages.validUsZipcode;
    }

    @action
    phone(field) {
        if (field.value.length) {
            return validPhoneNumber(field.value) ? true : errorMessages.telephone;
        }
        return true;
    }

    @action
    addressLength(field) {
        return field.value.length <= 30 ? true : errorMessages.addressLength;
    }

    @action
    float(field) {
        return Number.isNaN(parseFloat(field.value)) ? errorMessages.number : true;
    }

    @action
    lengthLimit(field) {
        const { label } = field;
        const { lengthLimit } = errorMessages;
        const limit = 35;
        const hasAsterisk = label.endsWith('*');
        const newLabel = hasAsterisk ? label.slice(0, -1) : label;
        const message = lengthLimit.replace('${label}', newLabel).replace('${limit}', limit);
        return field.value.length <= limit ? true : message;
    }

    @action
    dateRequired(field) {
        return Boolean(field.value) || errorMessages.distributorValidDateOfBirth;
    }

    validateDate(field) {
        if (!field.value || isValid(new Date(field.value))) return true;
        return errorMessages.dateInvalid;
    }

    @action
    validateDateOfBirth(field) {
        if (!field.value || isValid(new Date(field.value))) return true;
        const { distributorValidDateOfBirth, validDateOfBirth } = errorMessages;
        return isDistributorFieldType(field.type) ? distributorValidDateOfBirth : validDateOfBirth;
    }

    @action
    validateFutureDates(field) {
        if (!field.value || isBefore(new Date(field.value), new Date())) return true;
        const { distributorValidDateOfBirth, validDateOfBirth } = errorMessages;
        return isDistributorFieldType(field.type) ? distributorValidDateOfBirth : validDateOfBirth;
    }

    @action
    validateMajority(field) {
        if (!field.value || isAfter(new Date(majorityMaxDate), new Date(field.value))) return true;
        return errorMessages.majorityDateOfBirth;
    }
}

class Form {
    @observable key;
    @observable persist;
    @observable fields;

    constructor(key, persist = false, storedForm = null, formStore) {
        this.key = key;
        this.persist = persist;
        this.fields = {};
        this.formStore = formStore;

        if (storedForm && storedForm.fields) {
            this.add(Object.values(storedForm.fields));
        }
    }

    @action
    add(fields) {
        if (!Array.isArray(fields)) fields = [fields];
        fields.forEach(options => {
            if (!this.fields[options.key]) {
                if (options.key)
                    this.fields[options.key] = new Field({
                        ...options,
                        formKey: this.key,
                        formStore: this.formStore,
                    });
                else
                    throw new Error(
                        'Error in Formstore: Must provide a key when adding a field to a form',
                    );
            }
        });
    }

    @action
    removeFields(fields) {
        fields &&
            fields.length &&
            fields.forEach(key => {
                if (this.fields[key]) {
                    delete this.fields[key];
                }
            });
    }

    @action
    clearField(key) {
        if (this.fields[key]) this.fields[key].value = '';
    }

    @action
    save() {
        this.formStore && this.formStore.save(this.key);
    }

    @action
    value(key) {
        return this.fields[key].value;
    }

    @computed
    get values() {
        const obj = {};
        Object.keys(this.fields).forEach(key => (obj[key] = this.fields[key].value));
        return obj;
    }

    @action
    field(key) {
        return this.fields[key] || {};
    }

    @action
    clearFields() {
        Object.keys(this.fields).forEach(key => this.fields[key].clearValue());
    }

    @action
    validate() {
        Object.keys(this.fields).forEach(key => this.fields[key].validate());
    }

    @computed
    get valid() {
        return Object.keys(this.fields).every(key => this.fields[key].valid);
    }

    @action
    saveFields() {
        const copy = toJS(this.fields);
        Object.keys(copy).map(key => {
            const field = copy[key];
            delete field.formStore;
            return field;
        });
        return copy;
    }
}

class Field {
    @observable key;
    @observable value;
    @observable label;
    @observable type;
    @observable error;
    @observable formKey;
    @observable valid;

    constructor(options) {
        this.formStore = options.formStore;
        this.key = options.key;
        this.value = options.value || '';
        this.type = options.type || null;
        this.error = options.error || null;
        this.formKey = options.formKey;
        this.valid = true;

        let label = options.label || '';
        if (
            getSafe(() =>
                this.formStore.validations[options.type].includes(this.formStore.required),
            ) &&
            !label.endsWith('*')
        )
            label += '*';
        this.label = label;

        this.validate(false);
    }

    @action
    updateValue(value = '', validate = false) {
        this.value = value;
        if (validate) this.validate();
    }

    @action
    clearValue(validate = false) {
        this.value = '';
        if (validate) this.validate();
    }

    @action
    updateError(error = null) {
        this.error = error;
        if (this.error) this.valid = false;
    }

    @action
    clearError() {
        this.error = null;
        if (!this.valid) this.valid = true;
    }

    @action
    validate(addError = true) {
        let valid = true;
        const validations = getSafe(() => this.formStore.validations[this.type]);
        if (validations) {
            validations.forEach(validation => {
                const result = validation.call(this.formStore, toJS(this), this.formKey);
                if (result !== true) {
                    valid = false;
                    if (addError) this.updateError(result);
                }
            });
        }
        this.valid = valid;
        if (this.valid) this.clearError();
    }
}

const formStore = new FormStore();

export default formStore;
export { FormStore, Form, Field };
