import { observable, computed, action } from 'mobx';
import { findLast, last } from 'lodash';
import QueryString from 'query-string';
import { isBackPointPath, parseNavigation } from '@cs-admin/services/navigation';
import { setURLQuery, removeURLQueries } from '@cs-admin/utils/url';
import { routes, liveUrl } from '@cs-admin/config';
import Location from '@cs-admin/utils/Location';

const DEFAULT_BACK_PATH = '/';

class NavigationStore {
    @observable history;
    @observable navigation;
    @observable hash;

    constructor() {
        this.history = [
            {
                path: DEFAULT_BACK_PATH,
                isBackPoint: true,
            },
        ];
        this.navigation = [];
        this.hash = this.updatedHash;
    }

    @action
    load = data => {
        this.navigation = parseNavigation(data);
    };

    /*
     * Abstracting window.location.replace and window.location.assign
     * JSDOM treats these as read-only values that can't be reassigned
     * Jest 25+ aligns with that behavior so the abstraction allows us to mock in tests
     * https://github.com/facebook/jest/issues/9471
     */

    locationReplace = url => {
        window.location.replace(url);
    };

    locationAssign = url => {
        window.location.assign(url);
    };

    @action
    to = ({ url, reload = false, assign = false, newWindow = false } = {}) => {
        if (reload && __BROWSER__) {
            this.locationReplace(url);
            return true;
        }
        if (assign && newWindow && __BROWSER__) {
            window.open(url);
            return true;
        }

        if (assign && __BROWSER__) {
            this.locationAssign(url);
            return true;
        }

        Location.push(url);

        const path = typeof url === 'string' ? url : url.pathname;

        this.history.push({
            path,
            isBackPoint: isBackPointPath(path),
        });
    };

    @action
    goBack = data => {
        const backPath = this.previous.path !== this.path ? this.previous.path : null;

        this.to({ url: backPath || routes.DASHBOARD });
    };

    @action
    addQuery = ({ queries = [], isBackPoint = false }) => {
        if (__BROWSER__) {
            window.history.replaceState('', '', setURLQuery(queries));

            this.history.push({
                path: window.location.pathname + window.location.search,
                isBackPoint,
            });
        }
    };

    @action
    changePath = ({ path = '', isBackPoint = false }) => {
        this.history.push({
            path,
            isBackPoint,
        });
    };

    @action
    cleanPath = ({ route = '' }) => {
        const query = window.location.search;
        const url = getLocaleURL(this.localeStore.activeLocale.code, route);
        window.history.replaceState('', '', url + query);
    };

    @action
    addPathArgument = ({ route = '', arg = '' }) => {
        const query = window.location.search;
        const url = getLocaleURL(this.localeStore.activeLocale.code, route);
        window.history.replaceState('', '', `${url}/${arg}${query}`);
    };

    @action localizeURL = url => getLocaleURL(this.localeStore.activeLocale.code, url);

    @action
    setStartPage = (path = '') => {
        this.history.push({
            path,
            isBackPoint: isBackPointPath(path),
        });
    };

    @action
    newPath = (path = '') => {
        this.history.push({
            path,
            isBackPoint: isBackPointPath(path),
        });
        this.postNavigationEvents();
    };

    @action
    postNavigationEvents = () => {
        if (typeof newrelic !== 'undefined' && typeof newrelic.interaction !== 'undefined') {
            try {
                newrelic.interaction().end();
            } catch (e) {
                console.info(e);
            }
        }
        __BROWSER__ &&
            requestAnimationFrame(() => {
                if (analytics.optimizely.enabled) {
                    window.optimizely = window.optimizely || [];
                    window.optimizely.push({ type: 'activate' });
                }
            });
    };

    @computed
    get path() {
        return last(this.history).path;
    }

    @computed
    get backPath() {
        return findLast(this.history, { isBackPoint: true }).path;
    }

    @computed
    get previous() {
        return this.history[this.history.length - 3] || { isBackpoint: false };
    }

    @computed
    get queryString() {
        return QueryString.extract(this.path);
    }

    @computed
    get absolutePath() {
        return liveUrl + last(this.history).path;
    }

    @action unlocalizedPath = (path = this.path) => unlocalizeURL(path);

    @action
    unlocalizedBasePath = (path = this.path) => removeURLQueries(false, unlocalizeURL(path));

    @action
    parseNavigation = links =>
        parseNavigation(links.filter(link => link && this.isLinkVisible(link) && link));

    @action
    isLinkVisible = link => {
        // (only update on appIsMounted render pass to avoid server side hydration issues)
        if (link.fields.hideIf && this.interfaceStore.appIsMounted) {
            let valid = true;
            link.fields.hideIf.map(condition => {
                switch (condition) {
                    case 'isConsultant':
                        if (this.userStore.isConsultant) valid = false;
                        break;
                    case 'hasConsultant':
                        if (this.attributionStore.consultantShopping) valid = false;
                        break;
                    case 'isEmployee':
                        if (this.userStore.isEmployee) valid = false;
                        break;
                    case 'isEnrollmentKitInCart':
                        if (this.cartStore.isEnrollmentKitInCart) valid = false;
                        break;
                    default:
                        valid = true;
                        break;
                }
            });
            if (!valid) return false;
        }
        return true;
    };

    // Update subscriber components when link visibility changes
    @computed
    get observeLinkChanges() {
        return [
            this.interfaceStore.appIsMounted,
            this.userStore.isConsultant,
            this.attributionStore.consultantShopping,
        ];
    }

    get updatedHash() {
        if (__BROWSER__) {
            return '' || window.location.hash.substr(1);
        }
        return '';
    }

    @action
    updateHash = newHash => {
        // you would never want a space in your hash.
        newHash = newHash.replace(new RegExp('\\s', 'g'), '-');

        window.history.replaceState(null, null, newHash ? `#${newHash}` : '');
        this.hash = this.updatedHash;
    };
}

const navigationStore = new NavigationStore();

export default navigationStore;
export { NavigationStore };
