import {
    PROMO_RULES,
    US,
    TAB_NAMES,
    MILESTONE_1_US,
} from '@cs-admin/constants/incentivesPromoRuleNames';
import { get } from 'lodash';

export const getPromoRuleName = (activeTabName = TAB_NAMES.milestone1, selectedCountry = US) => {
    return get(PROMO_RULES, `${activeTabName}.${selectedCountry}`, MILESTONE_1_US);
};
