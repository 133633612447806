import fetchCatalog from './fetch';
import getProducts from '@cs-admin/services/products';
import { processCategories } from '@cs-admin/services/categories';

/**
 * Handles processing of products from the database.
 * Should not be loaded in production to avoid additional
 * bandwidth and processing penalities that would affect
 * performance.
 */

export default async function productParser(locale, drafts, userType) {
    const { Categories, Filters, Products } = await fetchCatalog(locale.code);

    const { data } = await getProducts(Products, drafts, userType);

    return {
        data: {
            Categories: processCategories(Categories, drafts, userType),
            Filters: processCategories(Filters, drafts, userType),
            Products: data,
        },
    };
}
