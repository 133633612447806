import { isSubscriptionItem, isExclusionItem } from '../helpers.js';
export const subscriptionItemsOnlyPercentPromo = (context, rule) => {
    const {
        exclusionItems = [],
        id,
        name,
        autoAdd = false,
        do_not_ship_alone = false,
        final_sale = false,
        discountValue,
        limitOnePerEmail = false,
        doNotStack = false,
    } = rule;
    const { cart, user } = context;
    const { products, promoItems } = cart;
    const subscriptionItems = products
        .concat(promoItems)
        .filter(
            item => !!item && isSubscriptionItem(item) && !isExclusionItem(item, exclusionItems),
        );
    let discounts = [];

    subscriptionItems.forEach(item => {
        const temp = new Array(item.quantity).fill(
            JSON.stringify({
                final_sale,
                do_not_ship_alone,
                sku: item.sku,
                id,
                name,
                autoAdd,
                discount: item.price * discountValue[0] || 0,
                limitOnePerEmail,
                doNotStack,
            }),
        );
        discounts = [...discounts, ...temp];
    });

    return discounts;
};
