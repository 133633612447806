import { nogento } from '@cs-admin/config';
import fetch from '@beautycounter/utils/fetch';

const param = (r, p) => (p ? `${r}: "${p}"` : '');

export async function checkPWSPersistence(uid, sid) {
    try {
        if (!uid && !sid) return;
        const payload = `
                query {attribution(${param('uid', uid)} ${param('sid', sid)}) {
                    pws {
                        pwsId
                        timeRemaining
                    }
                }}
            `;

        const response = await query(payload);
        const id = response.data.attribution.pws.pwsId;
        return id;
    } catch (e) {
        console.error(e);
        return null;
    }
}

export async function query(payload) {
    const result = await fetch(`${nogento.ecomm}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: payload }),
    });
    return result.json();
}
