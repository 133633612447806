export const INITIATED = 1;
export const RECEIVED = 2;
export const COMPLETED = 3;
export const PROCESSING = 4;

export const returnStatusTypes = {
    [INITIATED]: {
        id: INITIATED,
        name: 'Initiated',
        description: 'Return started in Narvar',
    },
    [RECEIVED]: {
        id: RECEIVED,
        name: 'Received',
        description: 'When item is scanned but refund hasn’t been processed',
    },
    [COMPLETED]: {
        id: COMPLETED,
        name: 'Completed',
        description: 'When items have been scanned and customer was refunded',
    },
    [PROCESSING]: {
        id: PROCESSING,
        name: 'Processing',
        description:
            'DHL is currently scanning this package and determining if an automated refund can be completed',
    },
};
