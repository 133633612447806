function getFirstObjectProperty(object) {
    return object[Object.keys(object)[0]];
}

function getFirstObjectPropertyName(object) {
    return Object.keys(object)[0];
}

function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    function isObject(item) {
        return item && typeof item === 'object' && !Array.isArray(item);
    }

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

function getSafe(fn) {
    try {
        return fn();
    } catch (e) {
        return undefined;
    }
}

function validImage(imageURL) {
    return imageURL ? `https:${getSafe(() => imageURL)}` : '';
}

function setSEOMeta(context, seoConfig = {}) {
    const seo = typeof seoConfig === 'object' ? seoConfig : {};

    return [
        context.onSetMeta('canonical', seo.canonicalPath || ''),
        context.onSetMeta('description', seo.description || seo.seoDescriptionFacebook || ''),
        context.onSetMeta('og:title', seo.seoTitle || '', 'property'),
        context.onSetMeta(
            'og:description',
            seo.seoDescriptionFacebook || seo.description || '',
            'property',
        ),
        context.onSetMeta(
            'og:image',
            validImage(getSafe(() => seo.seoImageFacebook.fields.file.url)),
            'property',
        ),
        context.onSetMeta(
            'og:image:secure_url',
            validImage(getSafe(() => seo.seoImageFacebook.fields.file.url)),
            'property',
        ),
        context.onSetMeta('og:type', 'website', 'property'),
        context.onSetMeta('twitter:name', 'summary_large_image'),
        context.onSetMeta('twitter:site', '@beautycounterhq'),
        context.onSetMeta('twitter:title', seo.seoTitleTwitter || ''),
        context.onSetMeta(
            'twitter:description',
            seo.seoDescriptionTwitter || seo.description || '',
        ),
        context.onSetMeta(
            'twitter:image',
            validImage(getSafe(() => seo.seoImageTwitter.fields.file.url)) || '',
        ),
    ];
}

const insertIf = (condition, object) => (condition ? object : {});

export {
    getFirstObjectProperty,
    getFirstObjectPropertyName,
    mergeDeep,
    getSafe,
    setSEOMeta,
    insertIf,
};
