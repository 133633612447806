const errorMessages = {
    maxLength: (field, length) => `Max length of the field "${field}" is ${length} symbols.`,
    minLength: (field, length) => `Min length of the field "${field}" is ${length} symbols`,
    validEmail: 'Must be a valid email address.',
    validUsZipcode: `Must be a valid US zip code.`,
    validCaZipcode: `Must be a valid CA zip code.`,
    required: '${label} is required.',
    telephone: 'Must be a valid telephone number',
    addressLength: 'Address must not be over 30 characters.',
    passwordLength: 'Please enter 6 or more characters.',
    passwordConfirm: 'Please make sure your passwords match.',
    number: 'Please enter a valid number.',
    validReason: "The Reason field shouldn't contain the 'expire' word.",
    lengthLimit: '${label} can not exceed ${limit} characters.',
    dateInvalid: 'Must be a valid date',
    distributorValidDateOfBirth: 'Must provide valid date',
    majorityDateOfBirth: 'Must provide valid date: Must be over 18',
    validDateOfBirth: 'Invalid Date: date not required',
};

export const enrollmentErrorMessages = {
    tandcMissing: `Please have the customer accept the T&Cs and enter their account details in the enrollment flow in order to process this order.`,
    countryMismatch: `Please make sure the customer's country flag that was selected in the enrollment flow matches the country flag on this order.`,
    sponsorMismatch: `Please make sure the customer's mentor that was selected in the enrollment flow matches the attributed consultant on this order.`,
    accountDetailsMissing: `Please have the customer enter their account details in the enrollment flow in order to process this order.`,
};

export const employeeAccountErrorMessages = {
    serviceNotSupported: (operation, service) => `${operation} in ${service} is not supported.`,
    accountNotFound: (docId, service) =>
        `User with document id ${docId} does not exist in records for ${service}`,
    notAnEmployee: id => `Account with uid ${id} is not an employee account`,
    authCouldNotBeDisabled: (id, message) => `Auth could not be disabled: ${id}.  Error ${message}`,
    userCouldNotBeModified: (id, message) => `User could not be modified: ${id}.  Error ${message}`,
    csUserCouldNotBeDeleted: (id, message) =>
        `Could not delete from cs-users user with uid ${id}.  Error ${message}`,
};

export default errorMessages;
