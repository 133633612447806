import {
    parse,
    startOfMonth,
    endOfMonth,
    addMonths,
    subMonths,
    isWithinRange,
    getMonth,
    differenceInCalendarMonths,
} from 'date-fns';

// NOTE: kept consequent and subsequent as an object to support days if needed in the future
const defaultRange = {
    months: 0,
};

// must be a valid timestamp from context – treat this as a safe interface.
const allowedComparators = context => ({
    purchaseTime: new Date(context.timestamp), // time at purchase (for testing)
    consultantEnrollment: context && context.user && context.user.enrollmentDateUTC,
});

/**
 * Runs if there is a defined range. Looks at anniversary for that range.
 */
const isAnniversaryRange = (purchaseDate, anniversaryDate, anniversary) => {
    const { subsequent = defaultRange, consequent = defaultRange } = anniversary;

    const start = subMonths(startOfMonth(purchaseDate), subsequent.months);
    const end = addMonths(endOfMonth(purchaseDate), consequent.months);

    const startMonthIndex = getMonth(start);
    const anniversaryMonthIndex = getMonth(anniversaryDate);
    const range = [
        startMonthIndex,
        Math.abs(startMonthIndex + differenceInCalendarMonths(start, end)) % 11,
    ].sort((a, b) => a - b);

    return (
        !isWithinRange(anniversaryDate, start, end) &&
        (range[0] <= anniversaryMonthIndex && anniversaryMonthIndex <= range[1])
    );
};

/**
 * Checks if a purchase is made within an anniversary window.
 * Should be invalid if anniversary year is same year.
 * subsequent and consequent days or months can be added to the root anniversary window.
 */
export const validateAnniversary = (anniversaryRules, context, flag) => {
    const anniversaryDate = allowedComparators(context)[anniversaryRules.comparitor];

    if (!!anniversaryDate) {
        const isValidRange = isAnniversaryRange(
            parse(context.timestamp),
            parse(anniversaryDate),
            anniversaryRules,
            flag,
        );
        return anniversaryRules.invert ? !isValidRange : isValidRange;
    }

    return anniversaryRules.invert;
};
