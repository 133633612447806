import { observable, action, computed, autorun, toJS } from 'mobx';
import { searchForProducts } from '@cs-admin/services/search';
import throttle from '@cs-admin/utils/throttle';
import { locales } from '@cs-admin/config';
import { WEB } from '@cs-admin/constants/channels';
import { GUEST } from '@cs-admin/constants/userStates';
import { getSafe } from '@cs-admin/utils/object';
import ADDRESSES from '@cs-admin/constants/promoToolAddresses';
import {
    fetchPendingPromos,
    fetchActivePromos,
    fetchShippingRates,
    savePromoRule,
    deployPromoRule,
    fetchPromoTags,
    bulkUpdatePromos,
} from '@cs-admin/services/promos';
import { PROMO_RULES_PROD, PENDING_PROMO_RULES } from '@cs-admin/constants/promoCollections';
import { NONE, CREATE_PROMO, EDIT_PROMO, BULK_EDIT } from '@cs-admin/constants/promoEditorModes';
import { MILESTONE_1_US, US, TAB_NAMES } from '@cs-admin/constants/incentivesPromoRuleNames';
import { getPromoRuleName } from '@cs-admin/containers/IncentivesContainer/MilestoneContainer/FreeProductSKUs/utils';
import { sorting } from '@cs-admin/utils/sorting';

class PromoToolStore {
    @observable activeCarts;
    @observable countryId;
    @observable searchText;
    @observable pendingPromoRules;
    @observable activePromoRules;
    @observable usertype;
    @observable selectedPromos;
    @observable selectedPromoJSON;
    @observable selectedPromoJSONName;
    @observable promoTimestamps;
    @observable channel;
    @observable isLoadingProducts;
    @observable address;
    @observable isLoadingRates;
    @observable shippingRates;
    @observable promoCollections;
    @observable selectedPromoCollection;
    @observable createPromo;
    @observable confirmDeployToProd;
    @observable confirmDelayedDeployToProd;
    @observable publishToProd;
    @observable promoEditorModes;
    @observable selectedPromoEditorMode;
    @observable editModeSelected;
    @observable noModeSelected;
    @observable validationErrors;
    @observable populatedPromoName;
    @observable selectedUserTypes;
    @observable selectedPromoTags;
    @observable promoTagsList;
    @observable bulkAction;
    @observable bulkActionField;
    @observable bulkSkusToUpdate;

    constructor() {
        this.activeCarts = [];
        this.countryId = 1;
        this.usertype = GUEST;
        this.searchText = '';
        this.pendingPromoRules = [];
        this.activePromoRules = [];
        this.selectedPromos = ['None'];
        this.selectedPromoJSONName = ['None'];
        this.selectedPromoJSON = null;
        this.promoTimestamps = { timestamps: {} };
        this.editedPromoJSON = null;
        this.isLoadingProducts = false;
        this.channel = WEB;
        this.address = null;
        this.isLoadingRates = false;
        this.shippingRates = [];
        this.promoCollections = ['None', PENDING_PROMO_RULES, PROMO_RULES_PROD];
        this.selectedPromoCollection = ['None'];
        this.createPromo = null;
        this.confirmDeployToProd = false;
        this.confirmDelayedDeployToProd = false;
        this.promoEditorModes = [NONE, CREATE_PROMO, EDIT_PROMO, BULK_EDIT];
        this.selectedPromoEditorMode = NONE;
        this.editModeSelected = false;
        this.noModeSelected = true;
        this.validationErrors = [];
        this.populatedPromoName = '';
        this.originalPublishToProd = null;
        this.selectedUserTypes = [];
        this.selectedPromoTags = [];
        this.promoTagsList = [];
        this.bulkAction = null;
        this.bulkActionField = null;
        this.bulkSkusToUpdate = '';
    }

    ready() {
        autorun(async () => {
            if (this.userStore.isLogged) {
                await Promise.all([
                    this.fetchActivePromoRules(),
                    this.fetchPendingPromoRules(),
                    this.initializePromoTagsList(),
                ]);
                if (this.populatedPromoName) {
                    this.setPopulatedPromo();
                }
            }
        });
    }

    @action
    setup() {
        this.updatePromos([]);
        this[this.selectedCartStore].promoTool = true;
    }

    @action
    resetPromoRules() {
        this[this.selectedCartStore].fetchPromoRules();
        this[this.selectedCartStore].promoTool = false;
    }

    @computed
    get locale() {
        const locale = locales.find(locale => locale.countryId === this.countryId);
        return locale;
    }

    @computed
    get promoRules() {
        return [
            { id: 'none', name: 'None' },
            { id: 'active', name: 'Active Promos' },
            ...sorting.byName(this.pendingPromoRules, 'asc', 'name'),
            ...sorting.byName(this.activePromoRules, 'asc', 'name'),
        ];
    }

    @computed
    get selectedCartStore() {
        const cartApiStore = this.flagStore.isFeatureEnabled('promoToolCartApi');
        return cartApiStore ? 'cartApiStore' : 'cartStore';
    }

    promosByPriority(rules, min, max) {
        return rules
            .filter(rule => rule.priority >= min && rule.priority <= max)
            .sort((a, b) => a.priority - b.priority);
    }

    getPromoByName(promoName = MILESTONE_1_US) {
        return this.activePromoRules
            ? this.activePromoRules.find(promoRule => promoRule.name === promoName)
            : {};
    }

    @action
    setIncentivesPromoRule(activeTabName = TAB_NAMES.milestone1, selectedCountry = US) {
        const promoRuleName = getPromoRuleName(activeTabName, selectedCountry);
        const promoRule = this.getPromoByName(promoRuleName);
        this.startCountingStore.setPromoRule(promoRule);
    }

    /**
     * Filter promos by date and usertypes
     * TODO: Expand to include enrolling member and enrolling consultant
     */
    filterPromos({ rules, date, userTypes }) {
        return rules.filter(rule => {
            if (userTypes.length && rule.userTypes) {
                let ruleHasSelectedUserType = false;
                const userTypesInRule = toJS(rule.userTypes);

                userTypes.forEach(userType => {
                    if (userTypesInRule.find(type => type == userType)) {
                        ruleHasSelectedUserType = true;
                    }
                });

                if (!ruleHasSelectedUserType) return false;
            }

            if (rule.timestamps) {
                const { timestamps } = rule;

                if (timestamps.start && timestamps.end) {
                    const startDate = new Date(timestamps.start._seconds * 1000);
                    const endDate = new Date(timestamps.end._seconds * 1000);

                    return date >= startDate && date <= endDate;
                } else if (timestamps.start) {
                    const startDate = new Date(timestamps.start._seconds * 1000);

                    return date >= startDate;
                } else if (timestamps.end) {
                    const endDate = new Date(timestamps.end._seconds * 1000);

                    return date <= endDate;
                }
            }

            return rule;
        });
    }

    @computed
    get addresses() {
        return ADDRESSES.filter(address => address.countryId === this.countryId);
    }

    @action
    async fetchPendingPromoRules() {
        this.pendingPromoRules = await fetchPendingPromos();
    }

    @action
    async fetchActivePromoRules() {
        this.activePromoRules = await fetchActivePromos();
        this.setIncentivesPromoRule();
    }

    @action
    async setUserType(usertype) {
        this.usertype = usertype;
        await this.updateCatalog();
    }

    @action
    async setChannel(channel) {
        this.channel = channel;
        await this.updateCatalog();
    }

    @action
    async setCountry(countryId) {
        this.countryId = countryId;
        this.address = null;
        this.shippingRates = [];
        await this.updateCatalog();
    }

    @action
    setSearchText = text => {
        this.setCurrentCategory(1);
        this.searchText = text;
        this.updateSearchResult(text);
    };

    @action
    setCurrentCategory = category => {
        this.searchText = '';
        this.currentCategory = category;
        this.categoryStore.setCurrentCategory(category);
    };

    @action
    updatePromos(promos) {
        if (promos.length === 0) {
            this.selectedPromos = ['None'];
        } else if (promos.includes('None')) {
            this.selectedPromos = promos.filter(promo => promo !== 'None');
        } else {
            this.selectedPromos = promos;
        }

        const rules = [];
        promos.map(async promo => {
            if (promo === 'None') {
                return;
            } else if (promo === 'Active Promos') {
                this.activePromoRules.map(rule => rules.push(rule));
            } else {
                const rule = this.promoRules.find(rule => rule.name === promo);
                rules.push(rule);
            }
        });

        this[this.selectedCartStore].setPromos(rules);

        if (this.address !== null) {
            this.getShippingRates();
        }
    }

    @action
    initializePromo() {
        if (this.selectedPromoJSON) this.clearSelectedPromo();
        this.selectedPromoJSON = {
            name: null,
            id: null,
            active: false,
            priority: null,
            stop: false,
            exclusionItems: [],
        };
        this.createPromo = true;
    }

    @action
    selectPromoJSON(value) {
        this.clearSelectedPromo();
        this.clearEditedPromo();

        this.selectedPromoEditorMode = EDIT_PROMO;

        if (value === 'None') return;

        this.promoRules.forEach(rule => {
            if (rule.name === value) {
                this.selectedPromoJSON = { ...rule };
                this.editedPromoJSON = { ...rule };
                this.selectedPromoJSONName = [rule.name];
                this.promoTimestamps = { timestamps: rule.timestamps || {} };
                this.populatedPromoName = rule.name;
                this.originalPublishToProd = rule.publishToProd;
                this.publishToProd = rule.publishToProd;
                this.confirmDelayedDeployToProd = !!rule.publishToProd;
            }
        });
    }

    @action
    editPromoJSON(value) {
        this.editedPromoJSON = value.jsObject;
    }

    @action
    clearSelectedPromo() {
        this.selectedPromoJSON = null;
        this.selectedPromoJSONName = ['None'];
        this.editedPromoJSON = null;
        this.createPromo = null;
        this.promoCollections = [NONE, PROMO_RULES_PROD, PENDING_PROMO_RULES];
        this.selectedPromoEditorMode = NONE;
        this.promoTimestamps = { timestamps: {} };
        this.populatedPromoName = '';
        this.validationErrors = [];
        this.originalPublishToProd = null;
        this.confirmDelayedDeployToProd = false;
        this.selectedPromoTags = [];
        this.selectedPromos = [];
        this.bulkAction = null;
        this.bulkActionField = null;
        this.bulkSkusToUpdate = '';
    }

    @action
    clearEditedPromo() {
        this.editedPromoJSON = null;
        this.validationErrors = [];
    }

    @action
    selectPromoCollection(value) {
        this.selectedPromoCollection = [value];
    }

    @action toggleConfirmDeployToProd() {
        this.confirmDeployToProd = !this.confirmDeployToProd;
    }

    @action toggleConfirmDelayedDeployToProd() {
        this.confirmDelayedDeployToProd = !this.confirmDelayedDeployToProd;
        if (!this.confirmDelayedDeployToProd) this.publishToProd = null;
    }

    @action setPromoEditorMode(value) {
        if (value === NONE) {
            this.clearSelectedPromo();
            this.editModeSelected = false;
            this.noModeSelected = true;
            this.selectedPromoJSON = null;
            this.promoTimestamps = { timestamps: {} };
        }
        if (value === CREATE_PROMO) {
            this.clearSelectedPromo();
            this.initializePromo();
            this.editModeSelected = false;
            this.noModeSelected = false;
        }
        if (value === EDIT_PROMO) {
            this.clearSelectedPromo();
            this.editModeSelected = true;
            this.noModeSelected = false;
        }
        if (value === BULK_EDIT) {
            this.clearSelectedPromo();
            this.editModeSelected = false;
            this.noModeSelected = false;
        }
        this.selectedPromoEditorMode = value;
    }

    async savePromoRule() {
        const collection = this.selectedPromoCollection[0];
        const rule = this.editedPromoJSON;
        rule.tags = this.selectedPromoTags;
        const publishToProdChanges = rule.publishToProd != this.publishToProd;

        if (this.promoTimestamps.timestamps) {
            rule.timestamps = this.promoTimestamps.timestamps;
        }

        // changes can only be made by juno, but maintain publishToProd if no changes
        if (!publishToProdChanges || (publishToProdChanges && this.userStore.junoAccess)) {
            rule.publishToProd = this.publishToProd || {};
        }

        const body = {
            collection,
            rule,
        };

        this.validationErrors = [];

        const { success, message, errors } = await savePromoRule(body);
        if (!success) {
            if (errors && errors.length) {
                this.validationErrors = errors;
            }

            this.interfaceStore.openAlert(true, message);
            return;
        }

        this.interfaceStore.openAlert(false, 'Promo successfully updated');
    }

    async deployPromoRule({ deployRuleAsActive = false }) {
        this.toggleConfirmDelayedDeployToProd();
        if (!this.userStore.junoAccess) {
            this.interfaceStore.openAlert(true, 'Insufficient Permissions');
            return;
        }

        const collection = PROMO_RULES_PROD;
        const rule = this.editedPromoJSON;
        rule.active = deployRuleAsActive;
        const body = {
            collection,
            rule,
            active: deployRuleAsActive,
        };

        const { success, message } = await deployPromoRule(body);
        if (!success) {
            this.interfaceStore.openAlert(true, message);
            return;
        }

        this.interfaceStore.openAlert(
            false,
            'Promo successfully Deployed! Please remember to manually set active to true in Firestore.',
        );
    }

    @throttle(300)
    updateSearchResult() {
        this.searchResults = this.searchText
            ? searchForProducts(this.productsStore.products, this.searchText, true)
            : [];
    }

    @action
    async updateCatalog() {
        this.isLoadingProducts = true;
        await this.contentStore.get({ locale: this.locale, userType: this.usertype });
        this.isLoadingProducts = false;
    }

    @action
    setAddress(id) {
        this.address = id;
        this.getShippingRates();
    }

    @action
    async getShippingRates() {
        this.isLoadingRates = true;
        const address = this.addresses.find(address => address.id === this.address);
        const products = Object.values(toJS(this[this.selectedCartStore].unpromofiedItems));
        const promoItems = Object.values(toJS(this[this.selectedCartStore].promoItems));
        const promoTags = Object.values(toJS(this[this.selectedCartStore].promoTags));
        const hostRewardItems = Object.values(
            toJS(this[this.selectedCartStore].hostRewardItems || []),
        );
        const context = {
            localcode: this.locale.code,
            timestamp: Date.now(),
            channel: Number(this.channel),
            user: {
                userType: Number(this.usertype),
                regionId: this.countryId,
            },
            customer: { email: 'test@email.com' },
            newShippingRates: true,
            cart: {
                products,
                promoItems,
                promoTags,
                hostRewardItems,
                shipping: {
                    saveAddress: false,
                    address,
                },
            },
        };

        const rates = await fetchShippingRates(context);
        if (rates) {
            context.cart.shipping.rates = rates.sort((a, b) => a.method - b.method);
            const newContext = this[this.selectedCartStore].cartEngine.applyPromos(context);
            this.shippingRates = newContext.cart.shipping.rates;
        } else {
            this.shippingRates = [];
        }

        this.isLoadingRates = false;
    }

    @action
    addPromoTimestamp(field) {
        const timestamps = this.promoTimestamps.timestamps || {};
        const timestamp = { _seconds: new Date().setHours(0, 0, 0, 0) / 1000 };
        timestamps[field] = timestamp;

        this.promoTimestamps = {
            timestamps: {
                ...timestamps,
            },
        };
    }

    @action
    updatePromoTimestamp(field, date) {
        this.promoTimestamps.timestamps[field]._seconds = date.getTime() / 1000;
    }

    @action
    removePromoTimestamp(field) {
        let { timestamps } = this.promoTimestamps;
        if (timestamps[field]) {
            delete timestamps[field];
            if (!Object.keys(timestamps).length) {
                timestamps = {};
            }
        }

        this.promoTimestamps = {
            timestamps: {
                ...timestamps,
            },
        };
    }

    @action
    setPopulatedPromoName(ruleName) {
        this.populatedPromoName = ruleName;
    }

    @action
    setPopulatedPromo() {
        if (!this.populatedPromoName) return;
        const activeRule = this.activePromoRules.find(
            ({ name }) => name.toLowerCase() === this.populatedPromoName.toLowerCase(),
        );
        const pendingRule = this.pendingPromoRules.find(
            ({ name }) => name.toLowerCase() === this.populatedPromoName.toLowerCase(),
        );

        if (!activeRule && !pendingRule) return;

        const rule = activeRule || pendingRule;

        this.selectedPromoEditorMode = EDIT_PROMO;
        this.editModeSelected = true;
        this.noModeSelected = false;
        this.selectedPromoJSON = { ...rule };
        this.editedPromoJSON = { ...rule };
        this.selectedPromoJSONName = [rule.name];
    }

    @action
    updateDelayedDeployTimestamp(date) {
        this.publishToProd._seconds = date.getTime() / 1000;
    }

    @action
    addDelayDeployTimestamp() {
        this.publishToProd = { _seconds: new Date().setHours(0, 0, 0, 0) / 1000 };
    }

    @action
    removeDelayDeployTimestamp() {
        this.publishToProd = null;
    }

    @action
    updateUserTypes(userTypes) {
        if (userTypes.length !== 0) {
            this.selectedUserTypes = userTypes;
        } else {
            this.selectedUserTypes = [];
        }
    }

    @action
    selectPromoTag(promoTags) {
        if (promoTags.length == 0) {
            this.selectedPromoTags = [];
        } else {
            this.selectedPromoTags = promoTags;
        }
    }

    @action
    async initializePromoTagsList() {
        this.promoTagsList = await fetchPromoTags();
    }

    @action
    updateBulkAction(action) {
        if (!action) this.bulkAction = null;
        else this.bulkAction = action;
    }

    @action
    updateBulkActionField(field) {
        if (!action) this.bulkActionField = null;
        else this.bulkActionField = field;
    }

    @action
    updateBulkSkuList(updatedList) {
        this.bulkSkusToUpdate = updatedList;
    }

    @computed
    get validBulkUpdate() {
        return this.bulkActionField && this.bulkAction && this.bulkSkusToUpdate.length;
    }

    @action
    async bulkUpdate() {
        //clean up sku list
        var skusToUpdate = this.bulkSkusToUpdate.split(',');
        skusToUpdate = skusToUpdate.map(sku => sku.trim());

        const data = {
            action: this.bulkAction,
            field: this.bulkActionField,
            rules: toJS(this.selectedPromos),
            skus: skusToUpdate,
        };

        const { success, message } = await bulkUpdatePromos(data);

        if (!success) {
            this.interfaceStore.openAlert(true, message);
            return;
        }

        this.interfaceStore.openAlert(false, `Promos successfully update: ${this.selectedPromos}`);
    }
}

const promoToolStore = new PromoToolStore();

export default PromoToolStore;
export { PromoToolStore };
