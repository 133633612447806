export default function exclusionItemsAddressFormMatch(exclusionItems, cart, field) {
    const addressField = cart.shipping.address[field];

    if (field === 'address') {
        return exclusionItems.some(
            v => v.length > 9 && addressField.search(new RegExp(v, 'i')) != -1,
        );
    }

    return exclusionItems.some(v => v.toUpperCase() == addressField.toUpperCase());
}
