import { observable, action, computed } from 'mobx';
import { getAddresses } from '@cs-admin/services/melissa/autocomplete';

class AddressAutocompleteStore {
    @observable addresses;

    constructor() {
        this.addresses = [];
    }

    @action
    searchAddresses = async options => {
        const { countryShort } = this.localeStore.activeLocale;
        const { value, country = countryShort } = options;

        const { Results: melissaAddresses } = await getAddresses({ country, value });
        this.addresses = melissaAddresses;
    };

    @computed
    get suggestedAddresses() {
        return this.addresses.map(({ Address }) => ({
            label: Address.Address,
            address: Address.DeliveryAddress,
            city: Address.Locality,
            state: Address.AdministrativeArea,
            zip: Address.PostalCode,
            country: Address.ISO3166_2,
        }));
    }
}

const addressAutocompleteStore = new AddressAutocompleteStore();

export default addressAutocompleteStore;
export { AddressAutocompleteStore };
