import { computed, action, toJS, observable } from 'mobx';
import { getSessionItem, saveSessionItem } from '@cs-admin/services/session';
import { saveUserCart } from '@cs-admin/services/cart';
import { locales } from '@cs-admin/config';
import { generateSiftSessionId } from '@beautycounter/utils/id';

class SessionStore {
    @observable siftSessionId;

    constructor() {
        this.siftSessionId = getSessionItem('siftSessionId', true) || generateSiftSessionId();
        saveSessionItem('siftSessionId', this.siftSessionId, true);
    }

    @action
    restoreSessions = () => {
        const cart = this.getKey('cart', { localized: true });
        if (cart && typeof cart.cartId != 'undefined') {
            this.cartStore.load(cart);
        } else {
            this.cartStore.create();
        }

        const user = this.getKey('user');
        if (user) {
            this.userStore.verifyLoggedIn();
        }
    };

    @action
    getKey = (key, options = {}, sessionStorage = false) => {
        if (!__BROWSER__) return false;

        // we need to remove the check on the portal order store as
        // as soon as we find out why it errors on load
        const locale =
            options.localized && this.portalOrderStore
                ? `-${this.portalOrderStore.locale.code}`
                : '';
        return getSessionItem(`${key}${locale}`, sessionStorage);
    };

    @action
    saveKey = (key, data, options = {}, sessionStorage = false) => {
        if (!__BROWSER__) return false;

        // we need to remove the check on the portal order store as
        // as soon as we find out why it errors on load
        const locale =
            options.localized && this.portalOrderStore
                ? `-${this.portalOrderStore.locale.code}`
                : '';
        saveSessionItem(`${key}${locale}`, toJS(data), sessionStorage);
    };

    @action
    getAllKeyInstances = key => {
        const keys = {};
        locales.map(locale => {
            keys[locale.code] = this.getKey(`${key}-${locale.code}`);
        });

        return keys;
    };

    @action
    clearAllKeyInstances = (key, sessionStorage) => {
        locales.map(locale => saveSessionItem(`${key}-${locale.code}`, '', sessionStorage));
    };

    @action
    clearAllCartInstances = sessionStorage => {
        locales.map(locale => saveSessionItem(`cart-${locale.code}`, '', sessionStorage));
    };

    @action
    restoreCart = () => {
        const cart = this.getCart();
        this.cartStore.switchLocalizedCart(cart || {});
    };

    @action
    getCart = () => this.getKey('cart', { localized: true });

    @action
    saveCart = async params => {
        this.saveKey('cart', params, { localized: true });

        const cart = this.getAllKeyInstances('cart');
        const { cartId, activeLocale, socialId = null } = params;
        const customer =
            this.portalOrderStore && this.portalOrderStore.account
                ? this.portalOrderStore.account
                : null;
        const uid =
            this.portalOrderStore && this.portalOrderStore.account
                ? this.portalOrderStore.account.uid
                : null;
        const userEmail =
            this.portalOrderStore && this.portalOrderStore.account
                ? this.portalOrderStore.account.email
                : null;
        await saveUserCart({ cart, cartId, uid, userEmail, customer, activeLocale, socialId });
    };

    getScopedField = ({ name, settings = {}, key, timestamp }) => {
        const stored = this.getKey(name, { localized: false, ...settings });
        const current = stored[key];
        const timestampValid = !timestamp || (current && current.timestamp > timestamp);
        if (current && timestampValid) return current;
        return false;
    };

    saveScopedField = ({ name, object, settings = {}, key }) => {
        const storage = this.getKey(name, { localized: false, ...settings }) || {};
        storage[key] = {
            ...object,
            timestamp: Date.now(),
        };
        this.saveKey(name, storage, { localized: false, ...settings });
    };

    clearScopedField = ({ name, settings = {}, key }) => {
        const storage = this.getKey(name, { localized: false, ...settings }) || {};
        if (storage[key]) delete storage[key];
        this.saveKey(name, storage, { localized: false, ...settings });
    };

    @computed
    get anonymousId() {
        return (
            __BROWSER__ &&
            window.analytics &&
            window.analytics.user() &&
            window.analytics.user().anonymousId()
        );
    }
}

const sessionStore = new SessionStore();

export default sessionStore;
export { SessionStore };
