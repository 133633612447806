import { calculatePromos } from './promos';
import actions from './actions';
import applyErrors from './applyErrors';
import { toTwoDecimal } from './utils/numbers';
import { calculateVolumes } from './volumes/calculateVolumes';
import { generateHostRewardProducts } from './hostRewards';

/**
 * A wrapper for the promo engine that creates
 * a complete cart composition for use in a flyout
 * cart or checkout scenario client side and server-side.
 * @param {ruleset} array
 */

export default class CartEngine {
    constructor(ruleset = []) {
        this.ruleset = ruleset;
        this.noDrop = false;
    }
    // test
    applyPromos(context) {
        let { cart } = context;
        if (!cart.promoTags) cart.promoTags = [];
        if (!cart.promoItems) cart.promoItems = [];
        if (!cart.selections) cart.selections = [];
        if (!cart.appliedPromos) cart.appliedPromos = [];
        if (!cart.cartCounterPromos) cart.cartCounterPromos = [];
        if (!context.clientErrors) context.clientErrors = [];
        if (!cart.shipping)
            cart.shipping = {
                address: {
                    address: '',
                    city: '',
                    state: '',
                    postalCode: '',
                },
            };

        // remove hostReward products before applying promo rules
        if (cart.products && cart.products.some(x => x.isHostReward)) {
            cart.products = cart.products.filter(x => !x.isHostReward);
        }

        const { hostRewardItems } = cart;
        let rewardProducts;
        if (hostRewardItems && hostRewardItems.length) {
            rewardProducts = generateHostRewardProducts(hostRewardItems);
        }

        // Populate appliedPromos from promo engine
        let promoEligable = this.ruleset && this.ruleset.length;
        if (promoEligable) {
            context = calculatePromos(context, this.ruleset, rewardProducts);
            cart = context.cart; // context modified in calculatePromos, so need to reassign cart
        }

        promoEligable = promoEligable && cart.appliedPromos && cart.appliedPromos.length;

        // Calculate subtotal before applying actions that may discount subtotal
        cart.subtotal = cart.products
            .concat(cart.promoItems)
            .reduce((prev, curr) => prev + curr.totalPrice, 0);

        cart.prePromoSubtotal = cart.subtotal;

        // Apply actions
        if (promoEligable) {
            cart.appliedPromos.forEach(promo => {
                if (actions[promo.id]) context = actions[promo.id](context, promo, this.noDrop);
            });
            context = applyErrors(context);
            cart.products = cart.products.filter(product => product.sku !== 'CartDiscount');
        }

        if (context.payment && context.payment.appliedCredit) {
            cart.subtotal = toTwoDecimal(cart.subtotal - context.payment.appliedCredit);
            if (cart.subtotal < 0) {
                context.payment.appliedCredit = toTwoDecimal(
                    context.payment.appliedCredit + cart.subtotal,
                );
                cart.subtotal = 0;
            }
        }

        // Merge products and promoItems arrays
        cart.promoItems.forEach(promoItem => {
            const existing = cart.products.find(product => product.sku === promoItem.sku);
            if (existing) {
                existing.quantity += promoItem.quantity;
                existing.totalPrice += promoItem.totalPrice;
                existing.isPromo = true;
                if (existing.discounts && promoItem.discounts)
                    existing.discounts = existing.discounts.concat(promoItem.discounts);
                else if (!existing.discounts && promoItem.discounts)
                    existing.discounts = promoItem.discounts;
            } else {
                cart.products.push(promoItem);
            }
        });

        // Create products from host reward items

        if (hostRewardItems && hostRewardItems.length) {
            cart.products = [...cart.products, ...rewardProducts];
            cart.subtotal += rewardProducts.reduce((prev, curr) => prev + curr.totalPrice, 0);
            cart.prePromoSubtotal += rewardProducts.reduce(
                (prev, curr) => prev + toTwoDecimal(curr.price * curr.quantity),
                0,
            );
        }

        // If there is a difference in the subtotal and the products totalPrices sum,
        // there was a cart discount applied
        const cartDiscount = toTwoDecimal(
            cart.products.reduce((prev, curr) => prev + curr.totalPrice, 0) -
                cart.subtotal -
                ((context.payment && context.payment.appliedCredit) || 0),
        );
        if (cartDiscount) cart.cartDiscount = cartDiscount;

        context = calculateVolumes(context, context.cart.isWholesale);

        return context;
    }

    updateRules(ruleset = [], options = {}) {
        this.ruleset = ruleset;
        this.noDrop = !!options.noDrop;
    }

    updateHostRewardRules(rules = []) {
        this.hostRewardRules = rules;
    }
}
