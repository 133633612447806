export const CREDIT_CARD = 1,
    PAYPAL = 2,
    APPLE_PAY = 3,
    SPLIT_PAYMENT = 4,
    KLARNA = 6;

export const paymentMethods = {
    1: 'Credit Card',
    2: 'Paypal',
    3: 'Apple Pay',
    4: 'Split Pay',
    6: { CA: 'Klarna', US: 'Klarna' },
};
