export const PROMO_ID = {
    NO_CONDITION_PERCENT_OFF: 0,
    AMOUNT_OFF_WHEN_PURCHASE_ITEM: 1,
    PERCENT_OFF_WHEN_PURCHASE_ITEM: 2,
    PERCENT_OFF_ENTIRE_CART_WHEN_REACH_SUBTOTAL: 9,
    $_OFF_ITEM_WHEN_REACH_SUBTOTAL: 10,
    PERCENT_OFF_WHEN_REACH_SUBTOTAL: 11,
    FREE_SHIPPING_WHEN_PARTICULAR_PURCHASE: 14,
    FREE_SHIPPING_WHEN_REACH_SUBTOTAL: 15,
    RESTRICT_SHIPPING_WHEN_ITEM_IN_CART: 16,
    REMOVE_ITEM_FROM_CART_WHEN_ADDRESS_IN_ZIP_OR_STATE: 17,
    RESTRICT_SHIPPING_WHEN_ADDRESS_IN_CITY_OR_STATE: 18,
    DROP_PROMO_ITEM: 19,
    REMOVE_ITEM_FROM_CART_WHEN_NO_PARTICULAR_ITEM_IN_CART: 21,
    DISCOUNTED_SHIPPING_WHEN_PARTICULAR_ITEM: 22,
    DISCOUNTER_SHIPPING_WHEN_REACH_SUBTOTAL: 23,
    DISCOUNT_AMOUNT_ON_SUBSCRIPTION_ITEM: 24,
    DISCOUNT_PERCENTAGE_ON_SUBSCRIPTION_ITEM: 25,
};

export const DROP_IDS = [
    PROMO_ID.RESTRICT_SHIPPING_WHEN_ITEM_IN_CART,
    PROMO_ID.REMOVE_ITEM_FROM_CART_WHEN_ADDRESS_IN_ZIP_OR_STATE,
    PROMO_ID.RESTRICT_SHIPPING_WHEN_ADDRESS_IN_CITY_OR_STATE,
    PROMO_ID.DROP_PROMO_ITEM,
    PROMO_ID.REMOVE_ITEM_FROM_CART_WHEN_NO_PARTICULAR_ITEM_IN_CART,
];
