/**
 * When defining environment specific rules such as API URLS,
 * it is recommended to put that in the environment configs
 * and to then reference the environment middleware here
 */

import { getEnvironmentConfig } from '@cs-admin/utils/env';

export const PORT = process.env.PORT || 3000,
    devUrl = `http://localhost:${PORT}`,
    production = process.env.NODE_ENV === 'production',
    environment = getEnvironmentConfig(process.env.CONFIG),
    {
        ayncModels,
        contentful,
        liveUrl,
        firebaseProject,
        firebaseApiBase = environment.firebase.apiBase,
        serverRendering,
        cdnUrl,
        env,
        prerender,
        verbose,
        info,
        developerTools,
        firebase,
        narvarUrl,
        googleMaps,
        nogento,
        bobTCs,
        bccomForgotPassword,
        bccomBase,
        newBase,
        flags,
        environmentName,
        phoenix,
        square,
        melissa,
        klarna,
    } = environment,
    coreModels = [
        'configGeneral',
        'cart',
        'category',
        'footer',
        'hamburgerNavigation',
        'header',
        'shop',
        'configProductInfo',
        'links',
        'configSharingPage',
        'configShareWithSocial',
        'configEmailSharing',
        'promoBanner',
    ],
    HOST = production ? liveUrl : devUrl,
    baseUrl = HOST,
    routes = {
        DASHBOARD: '/',
        LOGIN: '/login',
        FORGOT_PASSWORD: '/forgotpassword',
        CUSTOMERS: '/customers',
        ORDERS: '/orders',
        BATCHORDERS: '/batchOrders',
        BULKLEDGERS: '/bulkLedgers',
        RETURNS: '/returns',
        ADMINISTRATORS: '/administrators',
        ADMIN_CREATE: '/administrators/create',
        FAILED_ORDERS: '/orders/failed',
        PROMOS: '/promos',
        PROMO_TOOL: '/promos/tool',
        PROMO_EDITOR: '/promos/editor',
        PROMO_LIST: '/promos/list',
        PROMO_CREATE: '/promos/create',
        INCENTIVES: '/incentives',
        INCENTIVES_MILESTONE_1: '/incentives/milestone1',
        INCENTIVES_MILESTONE_2: '/incentives/milestone2',
        INCENTIVES_MILESTONE_3: '/incentives/milestone3',
        INCENTIVES_ENROLLERS: '/incentives/enrollers',
        CARTS: '/carts',
    },
    emptyLayoutPages = ['error', 'login', 'forgotpassword'],
    assetPath = environment.firebase.assetURL || cdnUrl || '',
    /**
     * Locales
     * Used in localeStore to determine
     * localization throughout the site.
     * */
    locales = [
        {
            code: 'en-US',
            longName: 'US',
            lang: 'en',
            languageId: 1,
            name: 'US',
            country: 'United States',
            countryShort: 'US',
            countryId: 1,
            image: 'images/us-flag.svg',
            default: true,
            currency: {
                name: 'USD',
                format: '$0',
                rounding: true,
            },
            magentoURL: '',
            magentoStoreView: 1,
            phoenixCountry: 1,
            preRender: true,
        },
        {
            code: 'en-CA',
            longName: 'CA',
            lang: 'en',
            languageId: 1,
            name: 'CA',
            country: 'Canada',
            countryShort: 'CA',
            countryId: 2,
            image: 'images/ca-flag.svg',
            currency: {
                name: 'CAD',
                format: '$0',
                rounding: true,
            },
            magentoURL: '/en-ca',
            magentoStoreView: 2,
            phoenixCountry: 2,
        },
        {
            code: 'fr-CA',
            longName: 'FR',
            lang: 'fr',
            languageId: 2,
            name: 'FR',
            country: 'Canada',
            countryShort: 'CA',
            countryId: 2,
            image: 'images/ca-flag.svg',
            currency: {
                name: 'CAD',
                format: '0 $',
                rounding: true,
            },
            magentoURL: '/fr-ca',
            magentoStoreView: 3,
            phoenixLanguageId: 3,
            phoenixCountry: 2,
        },
    ],
    mobilePagesWithBackIcon = [],
    sortTypes = [
        { id: 'sortRecommended', method: 'byParentPosition' },
        { id: 'sortByPrice', method: 'byPrice', order: 'asc' },
        { id: 'sortByPriceDescending', method: 'byPrice', order: 'desc' },
    ],
    specialCategories = {
        bestSellers: 24,
        newProducts: 10999,
    },
    specialProductSKUs = {
        flawlessInFivePrevious: '15999',
        flawlessInFive: '700000330',
        memberProgram: '9000',
        memberProgramRenew: '9916',
        enrollmentKit: '9078',
    },
    specialProductIds = {
        enrollmentKit: '905',
    },
    flawlessInFiveSKUs = ['15999', '700000330'],
    memberOnlySKUs = ['9000', '9916'],
    siteName = 'Beautycounter',
    klarnaCanCutoverDateUTC = klarna.klarnaCanCutoverDateUTC,
    useCDN = true,
    cacheAPIData = environment.cache,
    pixel = production;
