export const downloadCsvFile = ({ data, fileName = 'data.csv' }) => {
    try {
        const csvBlob = new Blob([data], { type: 'text/csv' });
        const a = document.createElement('a');
        a.setAttribute('href', URL.createObjectURL(csvBlob));
        a.setAttribute('download', fileName);
        a.click();
    } catch (error) {
        console.error('Error exporting table', error);
    }
};
