/* export const
    CLIENT = '7',
    MEMBER = '8',
    CONSULTANT = '9',
    EMPLOYEE = '10';
 */

export const CLIENT = '3',
    CONSULTANT = '1',
    MEMBER = '2',
    PROSPECT = '5',
    GUEST = '7',
    EMPLOYEE = '4';

export const USER_STATE_LABELS = {
    [CLIENT]: 'client',
    [CONSULTANT]: 'consultant',
    [MEMBER]: 'member',
    [PROSPECT]: 'prospect',
    [GUEST]: 'guest',
    [EMPLOYEE]: 'employee',
};
