import React, { Component } from 'react';
import { PropTypes as pt } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { observer, inject } from 'mobx-react';
import s from './DefaultLayout.scss';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from '@cs-admin/theme/theme';
import Header from '@cs-admin/components/Header';
import Menu from '@cs-admin/components/Menu';
import { 
    CircularProgress, 
    SnackbarContent,
    Snackbar,
    IconButton,
    Fab,
 } from '@beautycounter/counter-ui/src/Material';
import CloseIcon from '@material-ui/icons/Close';
import { developerTools } from '@cs-admin/config';
import FlagIcon from '@material-ui/icons/Flag';
import FlagController from '@cs-admin/components/FlagController';

@withStyles(s)
@inject('navigationStore', 'userStore', 'interfaceStore', 'flagStore')
@observer
class DefaultLayout extends Component {
    static propTypes = {
        children: pt.node,
    };
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            flags: false,
        };

        this.handleMenu = this.handleMenu.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpenFlags = this.handleOpenFlags.bind(this);
        this.handleCloseFlags = this.handleCloseFlags.bind(this);
    }

    handleMenu() {
        this.setState(prevState => ({
            open: !prevState.open,
        }));
    }

    handleClose(event, reason) {
        this.props.interfaceStore.closeAlert();
    };

    handleOpenFlags() {
        this.setState({
            flags: true,
        });
    }

    handleCloseFlags() {
        this.setState({
            flags: false,
        });
    }

    render() {
        const { open, flags } = this.state;

        if (__BROWSER__)
            return (
                <div className={s.root}>
                    <MuiThemeProvider theme={theme}>
                        <Header handleMenu={this.handleMenu} {...this.props} />
                        <Menu open={open} {...this.props}/>
                        { this.props.interfaceStore.inProgress ?
                            <CircularProgress className={s.progress} />
                            : null
                        }
                        <div className={s.content}>{this.props.children}</div>
                        { developerTools && (
                            <div className={s.fab}>
                                <Fab onClick={this.handleOpenFlags}>
                                    <FlagIcon />
                                </Fab>
                                <FlagController 
                                    open={flags} 
                                    closeFlags={this.handleCloseFlags} 
                                    {...this.props}
                                />
                            </div> 
                        )}
                        <Snackbar 
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}          
                            autoHideDuration={5000}
                            open={this.props.interfaceStore.alertOpen}
                            onClose={this.handleClose}
                        >
                            <SnackbarContent
                                className={this.props.interfaceStore.alertError ? s.error : s.success}
                                action={[
                                    <IconButton
                                        key="close"
                                        aria-label="Close"
                                        color="inherit"
                                        onClick={this.handleClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>,
                                ]}
                                message={this.props.interfaceStore.alertMessage}
                            />
                        </Snackbar>
                    </MuiThemeProvider>
                </div>
            );
        return null;
    }
}

export default DefaultLayout;

// TODO: Add prop-types to all components.
