export const notificationConfig = {
    notificationText:
        '__Action Required:__\n<br><br>\nThis order contains a membership. The customer will receive an email after checkout with a link to accept the terms before. Please make sure they accept the terms so can ship this order.',
    cartNotificationFlag: {
        title: 'Icon - Box',
        description: '',
        file: {
            url:
                '//images.ctfassets.net/ylsk4zpp53wa/2QH8ifmDLyU2sy06UQw2Ie/5ef7e99ff9f647232c64ead951117d53/flag-150x150.png',
            fileName: 'flag-150x150.png',
            contentType: 'image/png',
            details: {
                size: 3662,
                image: {
                    width: 150,
                    height: 150,
                },
            },
            fileName: 'flag-150x150.png',
            contentType: 'image/png',
        },
    },
};
