const customerTypes = {
    1: { name: 'Distributor' },
    2: { name: 'Preferred Customer' },
    3: { name: 'Retail Customer' },
    4: { name: 'Employee' },
    5: { name: 'Prospect' },
    6: { name: 'Partner' },
    7: { name: 'Guest' },
};

export const DISTRIBUTOR = 1;

export const EMPLOYEE = 4;

export default customerTypes;
