/**
 * Product fields not specified below are filtered out
 * from the CMS.
 *
 * This is done to prevent payload size from becoming larger
 * than it should in the case of other non-relevent fields
 * for other applications.
 */

export const productFields = [
    'productId',
    'title',
    'titleAlias', // override in the product grid.
    'handle',
    'categories',
    'parentCategoryIds',
    'positions',
    'description',
    'howToUse',
    'ingredients',
    'metaDescription',
    'inStock',
    'isVirtual',
    'isFinalSaleConfig',
    'stockNotify',
    'sku',
    'image',
    'urlPath',
    'status',
    'productVolume',
    'bestSeller',
    'newTo',
    'newFrom',
    'isGWP',
    'upSellIds',
    'bundleIds',
    'bundledOptions',
    'altVideo',
    'video',
    'swatches',
    'price',
    'specialPrice',
    'maxQuantity',
    'preOrder',
    'preOrderConsultant',
    'flags',
    'information',
    'label',
    'shortDescription',
    'notCrawlable',
    'disableProductDetailPage',
    'brandName',
    'brandPage',
    'whatsInside',
    'value',
    'productVariety',
    'bestFor',
    'QV',
    'PV',
    'CV',
    'hostValue',
    'priceInCents',
    'doNotShipAlone',
];

/**
 * Additonal fields allowed only on parent
 * products (containing variants)
 */

export const parentFields = [
    ...productFields,
    'variants',
    'altPromotion',
    'promoName',
    'widgets',
    'visibility',
];
