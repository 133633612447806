import { CLIENT } from '@cs-admin/constants/userStates';
import { employeeAccountErrorMessages } from '@cs-admin/constants/errorMessages';
import { nogento } from '@cs-admin/config';
import offboardAuth from './offboardAuth';

export default async function offboardBCCOM(uid) {
    const actions = [];
    try {
        const authResponse = await offboardAuth(
            `${nogento.base}/auth/offboardBCCOMEmployeeAuth`,
            uid,
        );
        actions.push({
            success: authResponse.success,
            message: authResponse.success
                ? `Auth disabled: ${uid}`
                : employeeAccountErrorMessages.authCouldNotBeDisabled(uid, authResponse.error),
        });

        const userResponse = await offboardAuth(
            `${nogento.base}/auth/offboardBCCOMEmployeeAccount`,
            uid,
        );

        actions.push({
            success: userResponse.success,
            message: userResponse.success
                ? `User modified: ${uid}`
                : employeeAccountErrorMessages.userCouldNotBeModified(uid, userResponse.error),
        });

        return {
            success: true,
            actions,
            accountInfo: uid,
        };
    } catch (error) {
        console.error(error);
        return {
            success: false,
            error,
            actions,
            accountInfo: uid,
        };
    }
}
