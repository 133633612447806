import { POPUP, SHIPPING } from './utils/constants/clientErrorTypes';

export default function applyErrors(context) {
    if (!(context.cart.appliedPromos && context.cart.appliedPromos.length)) return;

    context.cart.appliedPromos.forEach(promo => {
        if (
            promo.name === 'CounterSunShippingDrop' &&
            !context.clientErrors.some(error => error.code === 'cart/counter-sun')
        ) {
            context.clientErrors.push({ code: 'cart/counter-sun', type: POPUP });
        }

        if (
            promo.name === 'CounterSunGroundShipOnly' &&
            !context.clientErrors.some(error => error.code === 'cart/counter-sun-ground')
        ) {
            context.clientErrors.push({ code: 'cart/counter-sun-ground', type: SHIPPING });
        }
    });

    return Object.assign({}, context);
}
