export const WEB = 1,
    CONSULTANT_APP = 2,
    POS_APP = 3,
    COUNTERBASE = 4;

export const CHANNEL_NAMES = {
    1: 'Web',
    2: 'Consultant App',
    3: 'POS',
    4: 'Counterbase',
};
