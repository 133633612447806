import accountSearch from './accounts/accountSearch';
import offboardAccount from './offboard/offboardAccount';
import { EMPLOYEE } from '@cs-admin/constants/userStates';
import { BCCOM, CB } from '@cs-admin/constants/employeeAccountServices';
const SERVICES = [BCCOM, CB]; // should eventually include phoenix, etc

/*
  getEmployeeAccounts
  intake id
  return array of employee accounts exist for the user
*/
export async function getEmployeeAccounts(accountInfo) {
    const employeeAccounts = {};

    try {
        await Promise.all(
            SERVICES.map(async service => {
                const result = await accountSearch(service, accountInfo);
                employeeAccounts[service] = result;
            }),
        );

        return {
            success: true,
            accountInfo,
            employeeAccounts,
        };
    } catch (error) {
        console.error(error);
        return {
            success: false,
            accountInfo,
            employeeAccounts,
            error,
        };
    }
}

/*
  offboardAccount
  intake id, account type
  returns result of offboarding fn
*/

export async function offboardEmployeeAccount(service, accountInfo) {
    return await offboardAccount(service, accountInfo);
}
