import queryString from 'query-string';
import { melissa } from '@cs-admin/config';
import fetch from '@beautycounter/utils/fetch';
import { CountryNamesShort } from '@beautycounter/constants/locales';

export const getAddresses = async params => {
    try {
        const { US } = CountryNamesShort;
        const { country = US, maxrecords = 10 } = params;
        const endpoint = `${melissa.globalSearchUrl}?${queryString.stringify({
            id: melissa.key,
            format: 'json',
            ff: params.value,
            country,
            maxrecords,
        })}`;

        const melissaAddresses = await fetch(endpoint);
        return melissaAddresses.json();
    } catch (error) {
        return {
            success: false,
            data: [],
            error,
        };
    }
};
