import React from 'react';
import { observer, inject } from 'mobx-react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Header.scss';
 import Link from '@cs-admin/components/Link';
// import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Text from '@cs-admin/components/Common/Typography';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import { routes } from '@cs-admin/config';
import FullLogo from '@counter-ui/Icon/Icons/Logo/FullLogo.jsx';
import Checkbox from '@counter-ui/Inputs/Checkbox';

@withStyles(s)
@inject('userStore', 'interfaceStore')
@observer
class Header extends React.Component {
    handleMenuChange = () => {
        this.props.handleMenu();
    };

    handleLogout = async () => {
        await this.props.userStore.logout()
        this.props.navigationStore.to({ url: routes.LOGIN });
    }

    render() {
        const { classes, userStore } = this.props;
        const { isDeveloper, devMode } = userStore;
         const { isPhone } = this.props.interfaceStore;

        return (
            <div className={s.root}>
                <AppBar position="fixed">
                    <Toolbar disableGutters>
                        <IconButton className={s.menuButton} onClick={this.handleMenuChange}>
                            <MenuIcon />
                        </IconButton>
                        <Link to={routes.DASHBOARD}>
                            <FullLogo className={s.logo} />
                        </Link>
                        {isDeveloper && <Checkbox
                            className={s.devMode}
                            onChange={() => userStore.setDevMode()}
                            checked={devMode}
                            title='Dev Mode'
                        />}
                        <Button className={s.logout} onClick={this.handleLogout}>
                            <PowerSettingsNew className={s.leftIcon} />
                            {isPhone ? '': 'Logout'}
                        </Button>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

export default Header;
