export const ENROLLMENT = '9078';
export const STARTER_SETS = ['8200', '8201', '8202', '7213', '7214', '7215', '8203', '8204'];
export const ANNIVERSARY_SETS = [
    '700000080',
    '700000081',
    '700000082',
    '700000083',
    '700000084',
    '700000086',
    '700000087',
    '700000088',
];
