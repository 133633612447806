export const MILESTONE_1_US = 'StartCountingMilestone1-US';
export const MILESTONE_2_US = 'StartCountingMilestone2-US';
export const MILESTONE_3_US = 'StartCountingMilestone3-US';

export const MILESTONE_1_CA = 'StartCountingMilestone1-CA';
export const MILESTONE_2_CA = 'StartCountingMilestone2-CA';
export const MILESTONE_3_CA = 'StartCountingMilestone3-CA';

export const US = 'us';
export const CA = 'ca';

export const TAB_NAMES = {
    milestone1: 'Milestone 1',
    milestone2: 'Milestone 2',
    milestone3: 'Milestone 3',
};

export const PROMO_RULES = {
    [TAB_NAMES.milestone1]: {
        [US]: MILESTONE_1_US,
        [CA]: MILESTONE_1_CA,
    },
    [TAB_NAMES.milestone2]: {
        [US]: MILESTONE_2_US,
        [CA]: MILESTONE_2_CA,
    },
    [TAB_NAMES.milestone3]: {
        [US]: MILESTONE_3_US,
        [CA]: MILESTONE_3_CA,
    },
};
