export const minimumMonths = 6;
export const MEMBERSHIP_ERRORS = {
    INELIGIBLE_USER_TYPE: 'accountTypeMembershipError',
    INELIGIBLE_USER_TYPE_REMOVAL: 'accountTypeError',
    INELIGIBLE_USER_TYPE_RENEWAL: 'accountTypeRenewalError',
    ELIGIBLE_USER_TYPE_RENEWAL: 'accountTypeEligibleRenewalError',
    USER_OUTSIDE_WINDOW_EARLY: 'accountTypeRenewalWindowEarlyError',
    USER_OUTSIDE_WINDOW_LATE: 'accountTypeRenewalWindowLateError',
    GUEST_MUST_LOG_IN: 'guestIneligibleRenewalError',
    HOST_CART: 'hostCartMembershipError',
    PENDING_MEMBER: 'membershipPendingMemberError',
    ENROLLING_CONSULTANT: 'membershipEnrollingConsultantError',
};
