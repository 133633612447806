/**
 * Production environment
 * This configuration defines the final LIVE website environment
 * Run 'yarn start -- -environment=prod' to use this config
 */

const environment = {
    liveUrl: 'https://counterbase.beautycounter.com',
    firebaseProject: 'beautycounter-prod',
    environmentName: 'production',

    cssShortening: true,
    codeOptimization: true,
    verbose: false,
    developerTools: false,

    prerender: true,

    flags: {
        contentDrafts: false,
        incentives: true,
        membershipManualUpgrade: false,
        cartApi: true,
        batchOrders: true,
        promoToolCartApi: false,
        businessPromoTool: false,
        restoreClientMemberDownline: false,
        ibisRestoreConsultant: false,
        ibisConsultantPortalOrders: false,
        compressConsultant: false,
        compressDownline: true,
        useV2: true,
        cleanLogin: false,
        bulkLedgers: true,
        enablePortalOrderButton: false,
    },

    firebase: {
        key: 'Prod',
        apiKey: 'AIzaSyA5Tr1PBGtBHFyDaMwXOTH0gjV6ZioSjOU',
        projectId: 'beautycounter-prod',
        apiBase: 'https://us-central1-beautycounter-prod.cloudfunctions.net',
        assetBucket: 'beautycounter-prod-assets',
        assetURL: 'https://assets.beautycounter.com',
        useChunkCDN: false,
        // images should point only to the drafts bucket, even in prod
        image: [
            'https://www.googleapis.com/storage/v1/b/beautycounter-drafts.appspot.com/o',
            'https://firebasestorage.googleapis.com/v0/b/beautycounter-drafts.appspot.com/o',
        ],
    },

    nogento: {
        base: 'https://nogento.beautycounter.com',
        checkout: 'https://api.checkout.beautycounter.com',
        ecomm: 'https://api.ecomm.beautycounter.com',
        // Feature flag to point prod to nogento while we test CB in drafts pointing to new container
        counterbase: 'https://nogento.beautycounter.com',
        // This will eventually be new service prod points to
        // counterbase: 'https://api.counterbase.beautycounter.com',
        btc: 'https://api.btc.beautycounter.com',
    },

    phoenix: {
        pws: 'https://api2.beautycounter.com/pws',
        search: 'https://api.beautycounter.com',
        prospect:
            'https://7gabwec15f.execute-api.us-east-1.amazonaws.com/v1/account/addprospectaccount',
        leads: 'https://7gabwec15f.execute-api.us-east-1.amazonaws.com/v1/leads',
        geoSearch: 'https://7gabwec15f.execute-api.us-east-1.amazonaws.com/v1/account/geosearch',
        btcLink: 'https://behindthecounter.beautycounter.com',
    },

    square: {
        US: {
            url: 'https://js.squareup.com/v2/paymentform',
            appId: 'sq0idp-7s151C3XAsL-cehx07bvtw',
            locationId: 'WSBJ45H0VYF32',
            SDKUrl: 'https://web.squarecdn.com/v1/square.js',
        },
        CA: {
            url: 'https://js.squareup.com/v2/paymentform',
            appId: 'sq0idp-ct3CmwkwWp6lu-iki6bVig',
            locationId: 'D5GP0DNQ9Q6JK',
            SDKUrl: 'https://web.squarecdn.com/v1/square.js',
        },
    },

    contentful: {
        preview: false,
    },

    paypal: {
        env: 'production',
        script: 'https://www.paypalobjects.com/api/checkout.js',
        US: {
            client: {
                production: 'AZv5KhCyr-ufiSAA4_OJLskWLjAYwRwTY-ZyDFIrLZtlgq7FRnbWvhhMg6Rf',
            },
        },
        CA: {
            client: {
                production:
                    'AV8yZA-_GbUdx-HX1NzXSjv-5fw9syyrexoqvuj7Ua1-HAWWe58hleJMNNJodH29Gy5y1Utp4lkmS9qQ',
            },
        },
    },
    bobTCs: 'https://www.beautycounter.com/member-program/',
    bccomForgotPassword: 'https://www.beautycounter.com/customer/account/forgotPassword',
    bccomBase: 'https://www.beautycounter.com',
    newBase: 'https://my.beautycounter.com',
    narvarUrl: 'https://beautycounter.narvar.com/beautycounter/tracking/',

    melissa: {
        key: 'KvPFG6_4jLpY6cyJ4Ch0ri**',
    },

    klarna: {
        klarnaCanCutoverDateUTC: '2022-03-29T16:00:00.000Z',
    },
};

export { environment };
