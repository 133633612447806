import { throttle } from 'lodash';

export default function throttleMe(delay, options = {}) {
    return function throttleDecorator(target, name, descriptor) {
        if (typeof descriptor === 'undefined') {
            throw new Error('@throttle decorator can only be applied to class methods');
        }

        const method = descriptor.value;
        if (typeof method !== 'function') {
            throw new Error('@throttle decorator can only be applied to class methods');
        }

        let definingProperty = false;

        return {
            configurable: true,
            get() {
                if (definingProperty || this === target.prototype || this.hasOwnProperty(name)) {
                    return method;
                }

                const throttledMethod = throttle(method.bind(this), delay, options);

                definingProperty = true;
                Object.defineProperty(this, name, {
                    value: throttledMethod,
                    configurable: true,
                    writable: true,
                });
                definingProperty = false;

                return throttledMethod;
            },
        };
    };
}
