const drawerWidth = 240;

export const styles = theme => ({
    root: {
        display: 'flex',
        flexGrow: '1',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        zIndex: 0,
        borderRight: 'none',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing.unit * 7 + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 7 - 1,
        },
    },
    list: {
        marginTop: '5.5em',
        padding: '0',
    },
    card: {
        display: 'flex',
        padding: '24px',
        flexGrow: '1',
        flexDirection: 'column',
        marginTop: '4em',
        paddingLeft: '5px',
    },
});
