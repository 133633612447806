import { keyBy } from 'lodash';
import { getNavigationItemUrl } from '@cs-admin/utils/url';
import { routes } from '@cs-admin/config';
import { validByUser } from '@cs-admin/services/catalog/filters';
import { isEmpty } from 'lodash';

/**
 * Rearranges the Magento subcategory schema into
 * "filters" that can be used in conjunction with eachother
 * @param {Object} categories
 * @param {Object} content
 */

export function sortFilters({ categories, content, definedFilters }) {
    const filters = {};
    const specialCategories = [];

    content = keyBy(content, 'magentoId');

    // Inject subcategories into the filter object
    categories.map(category => {
        if (typeof content[category.categoryId] !== 'undefined') {
            if (
                !content[category.categoryId].special &&
                typeof (category.children !== 'undefined')
            ) {
                // Subcategories
                category.children &&
                    category.children.map(subcategory => {
                        if (subcategory.isActive != '0') {
                            if (typeof filters[category.categoryId] != 'undefined') {
                                filters[category.categoryId].Category.push({
                                    ...subcategory,
                                    parent: 'Category',
                                });
                            } else {
                                filters[category.categoryId] = {};
                                filters[category.categoryId].Category = [
                                    {
                                        ...subcategory,
                                        parent: 'Category',
                                    },
                                ];
                            }
                        }
                    });

                // Filters (repeated across each category)
                definedFilters &&
                    definedFilters.length &&
                    definedFilters.map(
                        section =>
                            section.children &&
                            section.children.length &&
                            (!section.showOnlyOnParent ||
                                section.parentId === category.categoryId) &&
                            section.children.map(filter => {
                                try {
                                    !filters[category.categoryId][section.name] &&
                                        (filters[category.categoryId][section.name] = []);
                                    filters[category.categoryId][section.name].push({
                                        ...filter,
                                        parent: section.name,
                                    });
                                } catch (e) {
                                    return false;
                                }
                            }),
                    );
            } else {
                specialCategories.push(category.categoryId);
            }
        }
    });

    // Inject a virtual category for "new" products
    // which do not have an actual category defined in the CMS
    specialCategories.push(10999);

    // 1 for all
    specialCategories.push(1);

    // Inject all filters into the special categories that don't have their own filters but instead pick up from other categories
    specialCategories.forEach(special => {
        filters[special] = {};
        categories.forEach(category => {
            if (filters[category.categoryId] && !content[category.categoryId].special) {
                Object.keys(filters[category.categoryId]).forEach(section => {
                    if (typeof filters[special][section] == 'undefined')
                        filters[special][section] = [];
                    filters[special][section] = [
                        ...filters[category.categoryId][section],
                        ...filters[special][section],
                    ];
                });
            }
        });
        filters[special] = removeDuplicateSubcategories(filters[special]);
    });

    return filters;
}

/**
 * Gets the hard URL for subcategories that are
 * actually "filters"
 * @param {Object} categoryFilters
 * @param {Object} categoriesById
 */

export function getCategoryLinks(categoryFilters, categoriesById) {
    const categoryLinks = [];

    Object.keys(categoryFilters).forEach((category, key) => {
        if (categoriesById[category]) {
            categoryLinks.push({
                id: category,
                deepId: category,
                name: categoriesById[category].name,
                type: 'parent',
                to: createCategoryUrl({
                    slug: categoriesById[category].slug,
                    id: category,
                }),
            });

            Object.keys(categoryFilters[category]).forEach(filterContainer => {
                categoryFilters[category][filterContainer].length &&
                    categoryFilters[category][filterContainer].forEach(filter => {
                        categoryLinks.push({
                            id: filter.categoryId,
                            deepId: category + (filter.categoryId && filter.categoryId.toString()),
                            name: filter.name,
                            slug: filter.slug,
                            type: 'filter',
                            parent: category,
                            to: createCategoryUrl({
                                slug: categoriesById[category].slug,
                                isFilter: true,
                                id: filter.categoryId,
                                query: filter.query,
                            }),
                        });
                    });
            });
        }
    });
    return categoryLinks;
}

/**
 * Pre-processes categories
 * @param {Object} categories
 */

export function processCategories(categories, drafts, userType) {
    const processed = [];

    try {
        const canSeeDraft = category => (category.draft ? drafts : true);

        const validCategory = category =>
            typeof category === 'object' &&
            category.categoryId &&
            category.isActive !== 0 &&
            canSeeDraft(category) &&
            validByUser(category, userType);

        const filterCategory = category => {
            if (!validCategory(category)) return;

            category.children =
                (Array.isArray(category.children) &&
                    category.children.length &&
                    category.children.filter(child => filterCategory(child))) ||
                null;

            category.query = category.slug || String(category.categoryId);

            return category;
        };

        return categories.filter(category => filterCategory(category));
    } catch (e) {
        console.info(e);
    }

    return processed;
}

/**
 * Figures out what the filters are for categories
 * in the url params.
 * @param {Object} query
 * @param {Object} category - the selected category from the router
 * @param {Object} categoryStore
 */

export function getCategoryFiltersFromQuery(query, category, categoryStore) {
    const applyFilters = [];
    let queryFilters = false;
    let filter;

    try {
        if (query) {
            queryFilters = [];
            for (let i = 0; i < Object.keys(query).length; i++) {
                filter = query[Object.keys(query)[i]];
                if (/filter(s?)/.test(Object.keys(query)[i])) {
                    if (typeof filter == 'string') {
                        queryFilters.push(filter);
                    } else if (Array.isArray(filter) && filter.length) {
                        for (let i = 0; i < filter.length; i++) {
                            filter[i] = filter[i];
                            queryFilters.push(filter[i]);
                        }
                    }
                }
            }
        }
        if (query && queryFilters) {
            queryFilters.map(queryFilter =>
                Object.keys(
                    categoryStore.categoryFilters[category ? category.magentoId : 1],
                ).forEach(section =>
                    categoryStore.categoryFilters[category ? category.magentoId : 1][
                        section
                    ].forEach(item => {
                        queryFilter = String(queryFilter);
                        if (item.query === queryFilter || String(item.categoryId) === queryFilter) {
                            applyFilters.push({
                                ...item,
                                id: item.categoryId,
                                value: true,
                            });
                        }
                    }),
                ),
            );
        }
    } catch (e) {
        console.error(e);
    }
    return applyFilters;
}

export function createCategoryUrl(categoryLink) {
    if (categoryLink.isFilter) {
        return `${getNavigationItemUrl(categoryLink.slug)}?${routes.FILTER}=${categoryLink.query}`;
    }

    return getNavigationItemUrl(categoryLink.slug);
}

export function removeDuplicateSubcategories(item) {
    if (!item) return {};
    const result = {};
    Object.keys(item).forEach(key => {
        if (!Array.isArray(item[key])) return;

        const map = new Map();
        result[key] = item[key].filter(category => {
            if (isEmpty(category)) return false;

            const duplicate = category ? map.get(category.name) : false;

            if (duplicate) return false;

            map.set(category.name, true);
            return true;
        });
    });

    return result;
}
