export async function getCurrentPosition() {
    return new Promise((resolve, reject) => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(position => {
                resolve(position);
            });
        } else {
            reject();
        }
    });
}
