export const orderStates = {
    1: { name: 'Processing' },
    2: { name: 'Reviewed' },
    3: { name: 'Captured' },
    4: { name: 'Printed' },
    5: { name: 'Shipped' },
    6: { name: 'Pending Review' },
    7: { name: 'Cancelled' },
    8: { name: 'Holding' },
    13: { name: 'Partially Shipped' },
    14: { name: 'Cancelled Paid' },
};

export const PROCESSING = 1,
    REVIEWED = 2,
    CAPTURED = 3,
    PRINTED = 4,
    SHIPPED = 5,
    PENDING_REVIEW = 6,
    CANCELLED = 7,
    HOLDING = 8,
    PARTIALLY_SHIPPED = 13,
    CANCELLED_PAID = 14;

export default orderStates;
