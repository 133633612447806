import firebase from '@cs-admin/services/firebase/init';
import { getUserToken } from '@cs-admin/services/user';
import fetch from '@cs-admin/services/fetch';
import queryString from 'query-string';
import { nogento, narvarUrl } from '@cs-admin/config';
import { getUserByPhoenixId } from '@cs-admin/services/customers';
import { CONSULTANT } from '@cs-admin/constants/userStates';
import { orderStates, CANCELLED } from '@cs-admin/constants/orderStates';
import { getPopupByID, getOpenPopups } from '@cs-admin/services/popups';
import { COUNTERBASE } from '@cs-admin/constants/channels';
import { orderBy, isBoolean } from 'lodash';
import { carrierCodeMappings } from '@cs-admin/constants/carrierCodeMappings';
import { ORDER_TYPES } from '@cs-admin/constants/orderTypes';
import { NOT_AVAILABLE } from './constants';
import { ORDERS_CHUNK_START_PAGE, ORDERS_CHUNK_LIMIT } from '@cs-admin/services/orders/constants';

let flagStore = null; /* TODO: Remove flagstore logic when full move to orders */
const fireBaseOrderIdRegex = /\d{9}/;

export function setFlagStore(store) {
    flagStore = store;
}

export async function getProcessedOrders({
    searchValues = {},
    page = ORDERS_CHUNK_START_PAGE,
    limit = ORDERS_CHUNK_LIMIT,
}) {
    try {
        const token = await getUserToken();

        const response = await fetch(
            `${nogento.counterbase}/cs/orders/?${queryString.stringify({
                ...searchValues,
                page,
                limit,
            })}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        if (response.status !== 200) return [];

        const {
            data: { processedOrders },
        } = await response.json();

        return processedOrders || [];
    } catch (error) {
        console.error(error);
        return [];
    }
}

export async function getFailedOrders() {
    try {
        const orders = [];
        const database = await firebase.firestore();
        await database
            .collection('phoenix-order-failures')
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    const data = doc.data();
                    orders.push({
                        docId: doc.id,
                        ...data,
                    });
                });
            });

        return orders;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function failedOrderSearch(orderId) {
    try {
        const results = [];
        const database = await firebase.firestore();
        const orders = database.collection('phoenix-order-failures');

        if (orderId && fireBaseOrderIdRegex.test(orderId))
            orders = orders.where(
                'context.orderId',
                '==',
                parseInt(orderId.replace(/\s+/g, ''), 10),
            );

        await orders.get().then(snapshot => {
            snapshot.forEach(doc => {
                results.push({
                    ...doc.data(),
                    docId: doc.id,
                });
            });
        });

        return results;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Temporary function to use to generate accurate status
 * Will use state in order once state is accurate
 */
export function getOrderStatus(order) {
    if (order.shipments) return 'Shipped';

    if (order.printed) return 'Printed';

    if (order.state) return orderStates[order.state].name;

    // Default to processing if status is in phoenix
    return 'Processing';
}

export const getOrderTypeLabel = order => {
    const orderType = ORDER_TYPES[order.orderType];
    if (!order.orderType || !orderType) return NOT_AVAILABLE;
    return orderType;
};

export function phoenixOrderNumber({ byConsultant, customer, phoenixOrderId }) {
    if (!phoenixOrderId || phoenixOrderId === NOT_AVAILABLE) return NOT_AVAILABLE;
    if (byConsultant || (customer && customer.userType === CONSULTANT)) return `X${phoenixOrderId}`;
    return `F${phoenixOrderId}`;
}

export async function getOrderInfo(id, type) {
    let result = false;

    try {
        if (type == 'Completed') {
            result = await getCompletedOrderInfo(id);
        } else {
            result = await getPendingOrderInfo('ship-to-socials', id);

            if (!result) result = await getPendingOrderInfo('orders', id);
        }

        if (result) {
            const response = await getDHLStaus(result.orderId);
            result.dhlHoldStatus = response.success ? response.inHolding : false;
        }

        return result;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

export async function getCompletedOrderInfo(id) {
    const collection = 'invoices';
    const database = await firebase.firestore();
    const order = await database
        .collection(collection)
        .doc(id)
        .get()
        .then(doc => doc.data());
    if (order) {
        const data = [];
        await database
            .collection('phoenix-insert')
            .where('Order.MagentoOrderNumber', '==', order.orderId)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    data.push(doc.data());
                });
            });
        const ref = data[0];

        order.status = getOrderStatus(order);
        order.trackingNumber = null;
        order.shippingCarrierId = null;
        order.shippingMethodId = null;
        const phoenixOrder = order.phoenixOrderId
            ? await getPhoenixOrder(order.phoenixOrderId)
            : null;
        if (phoenixOrder) {
            const {
                orderResponse: { orderShipment },
            } = phoenixOrder;
            const packages =
                orderShipment.orderShipmentPackages && orderShipment.orderShipmentPackages.length
                    ? orderShipment.orderShipmentPackages
                    : [];
            if (packages.length) {
                const { shippingCarrierID, trackingNumber } = packages[0];
                const { shippingMethodID } = orderShipment;
                order.trackingNumber = trackingNumber;
                order.shippingMethodId = shippingMethodID;
                order.shippingCarrierId = shippingCarrierID;
            }
        }

        order.phoenixOrderId = phoenixOrderNumber(order);
        order.cart.products =
            ref && ref.Order && ref.Order.OrderCustomers[0]
                ? ref.Order.OrderCustomers[0].OrderItems
                : order.cart.products;
        order.cart.products.shippingTax =
            ref && ref.Order && ref.Order.OrderCustomers[0]
                ? ref.Order.OrderCustomers[0].TaxAmountShipping
                : null;
        order.trackingInfoMessage = 'Tracking Info Unavailable';
        order.shippingInfoMessage = 'Shipping Info Unavailable';
        order.shipments = order.shipments?.map(shipment => ({
            ...(shipment || {}),
            trackingUrl: getTrackingUrl(shipment?.carrier, shipment?.trackingNumber),
        }));
        const popup = order.socialId && (await getPopupByID(order.socialId.toString()));
        const consultantInfo = order.sponsorId !== 1 && (await getUserByPhoenixId(order.sponsorId));
        const consultantName = consultantInfo.fullName;
        const consultantPopups = await getOpenPopups(consultantInfo.uid);

        return {
            ...order,
            id,
            consultantName,
            consultantPopups,
            collection,
            popup,
        };
    }

    return false;
}

export function getCarrier(code) {
    const shippingCarrier = carrierCodeMappings[code];
    return { shippingCarrier };
}

export function getTrackingUrl(narvarCode, trackingNumber) {
    if (!(narvarCode && trackingNumber)) return null;
    return `${narvarUrl}${narvarCode}?tracking_numbers=${trackingNumber}`;
}

export async function getPendingOrderInfo(collection, id) {
    const database = await firebase.firestore();
    const order = await database
        .collection(collection)
        .doc(id)
        .get()
        .then(doc => doc.data());
    if (order) {
        const data = [];
        const ref = data[0];

        order.narvarTrackingUrl = null;
        if (order.state === CANCELLED) order.status = orderStates[order.state].name;
        else order.status = collection === 'ship-to-socials' ? 'Pending Pop-up' : 'Pending Terms';
        order.trackingNumber = null;
        order.shippingCarrierId = null;
        order.shippingMethodId = null;

        order.cart.products =
            ref && ref.Order && ref.Order.OrderCustomers[0]
                ? ref.Order.OrderCustomers[0].OrderItems
                : order.cart.products;
        order.cart.products.shippingTax =
            ref && ref.Order && ref.Order.OrderCustomers[0]
                ? ref.Order.OrderCustomers[0].TaxAmountShipping
                : null;
        order.trackingInfoMessage = 'Tracking Info Unavailable';
        order.shippingInfoMessage = 'Shipping Info Unavailable';
        const popup = order.socialId !== 0 && (await getPopupByID(order.socialId));
        const consultantInfo = order.sponsorId !== 1 && (await getUserByPhoenixId(order.sponsorId));
        const consultantName = consultantInfo.fullName;
        const consultantPopups = await getOpenPopups(consultantInfo.uid);

        order.narvarTrackingUrl = 'NA';
        order.trackingNumber = 'NA';
        order.phoenixOrderId = 'N/A';

        return {
            ...order,
            id,
            consultantName,
            consultantPopups,
            collection,
            popup,
        };
    }
}

// TODO: Remove this function after full transition to new refund flow
export async function getRefunds(orderId) {
    try {
        const refunds = [];
        const database = await firebase.firestore();
        await database
            .collection('refunds')
            .where('orderId', '==', parseInt(orderId))
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    const refund = doc.data();
                    const hasProperties = propertyCheck(refund);

                    if (hasProperties) {
                        let refundInfo = {
                            refundId: doc.id,
                            orderId: refund.orderId.toString(),
                            reason: refund.reason || '',
                            timestamp: new Date(refund.timestamp.seconds * 1000),
                            tax: refund.order.tax.taxAmount,
                            phoenixReturnItems: refund.phoenixResponse.body
                                ? refund.phoenixResponse.body[0].returnOrderItems
                                : [],
                            returnedItems: refund.returnItems,
                            shippingTotal: refund.shippingTotal,
                            refundedAmount: refund.amount,
                            pcAmount: refund.pcAmount,
                            shippingTotal: refund.shippingTotal,
                            adminUid: refund.adminUid,
                            clawback: refund.clawback,
                        };

                        if (refund.refunds || refund.cards) {
                            const cardRefunds = refund.refunds || refund.cards;
                            refundInfo = {
                                ...refundInfo,
                                cardRefunds,
                            };
                        } else {
                            refundInfo = {
                                ...refundInfo,
                                cardBrand: refund.order.newOrderFlow
                                    ? refund.order.payment.cardData.card_brand
                                    : refund.order.cardData.card_brand,
                            };
                        }
                        refunds.push({
                            ...refundInfo,
                        });
                    }
                });
            });
        return refunds;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

// TODO: Remove this function after full transition to new refund flow
/**
 * Summary: Check to see if the refund document has
 * refund properties to list in view.
 * @param {Object} refund - Refund object from firebase query
 * @param {Array} refundProperties - Refund Properties to check against refund object
 */
const propertyCheck = refund => {
    const refundProperties = ['amount', 'shippingTotal', 'order', 'returnItems'];

    for (const property of refundProperties) {
        if (!refund.hasOwnProperty(property)) {
            return false;
        }
    }
    return true;
};

export async function getPhoenixOrder(phoenixOrderId) {
    const token = await getUserToken();

    const response = await fetch(`${nogento.base}/customer/orderDetails/${phoenixOrderId}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (response.status !== 200) return;
    const json = await response.json();
    return json.data;
}

/**
 * Returns orders associated with id
 * @param {string} id
 */
export async function getOrdersById(id) {
    const collection = 'invoices';

    try {
        const orders = [];
        const database = await firebase.firestore();

        await getOrderVariations(database, collection, orders, id);
        await getOrderVariations(database, 'ship-to-socials', orders, id);

        if (collection !== 'orders') await getOrderVariations(database, 'orders', orders, id);

        return orders;
    } catch (error) {
        console.log(error);
        return {
            success: false,
            error,
        };
    }
}

/**
 * Returns orders only for customer.
 * If customer is consultant, it returns only personal orders.
 * Does not return pending orders.
 * @param {string} id
 */
export async function getPersonalOrders(id) {
    const collection = 'invoices';

    try {
        const orders = [];
        const database = await firebase.firestore();

        if (collection === 'invoices') await addOrders(database, collection, 'uid', id, orders);

        await addOrders(database, collection, 'customer.id', id, orders);
        await addOrders(database, collection, 'customer.uid', id, orders);

        return orders;
    } catch (error) {
        console.log(error);
        return {
            success: false,
            error,
        };
    }
}

async function getOrderVariations(database, collection, orders, id) {
    if (collection === 'invoices') await addOrders(database, collection, 'uid', id, orders);

    await addOrders(database, collection, 'user.id', id, orders);
    await addOrders(database, collection, 'customer.id', id, orders);
    await addOrders(database, collection, 'customer.uid', id, orders);

    return orders;
}

async function addOrders(database, collection, query, id, list) {
    try {
        await database
            .collection(collection)
            .where(query, '==', id)
            .get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    const order = {
                        ...doc.data(),
                        docId: doc.id,
                    };

                    // This is done because the user.id and uid for each order document is referencing the person who made the order on behalf of a customer
                    // the customer.id is different from the user.id and uid so the order history is incorrect because it displays orders that do not belong to
                    // the user. this just skips adding orders that do not belong to the original customer in counterbase
                    if (
                        (order.channel === COUNTERBASE && query === 'user.id') ||
                        (order.channel === COUNTERBASE && query === 'uid')
                    )
                        return;

                    // Only add orders from order collection if it is a membership order
                    if (collection === 'orders' && !order.isMembership) return;

                    let status;
                    if (order.state === CANCELLED) status = orderStates[order.state].name;
                    else if (collection === 'ship-to-socials') status = 'Pending Pop-up';
                    else if (collection === 'orders') status = 'Pending Terms';

                    if (!list.find(o => o.docId === order.docId))
                        list.push({
                            ...order,
                            status,
                        });
                });
            });

        list = await Promise.all(
            list.map(async order => {
                if (!order.status) order.status = getOrderStatus(order);

                return order;
            }),
        );
    } catch (error) {
        console.log(error);
    }

    return list;
}

export async function submitComment(id, comment, collection) {
    try {
        const database = await firebase.firestore();
        return await database
            .collection(collection)
            .doc(id)
            .get()
            .then(async order => {
                const data = order.data();
                const comments = data.comments ? data.comments : [];
                comments.unshift(comment);

                const response = await order.ref
                    .update({
                        comments,
                    })
                    .then(() => ({
                        success: true,
                    }));

                if (response.success) {
                    return {
                        ...response,
                        comments,
                    };
                }
            });
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

// TODO: Remove once on new refund flow
export async function processRefund(refund) {
    const response = await fetch(`${nogento.base}/refund/refund`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(refund),
    });

    return await response.json();
}

export async function resendOrderConfirmationEmail(orderId) {
    const token = await getUserToken();

    const response = await fetch(`${nogento.counterbase}/cs/resendOrderConfirmation/${orderId}`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
    });

    const json = await response.json();

    return { ...json };
}

export async function resendShippingConfirmationEmail(orderId) {
    const token = await getUserToken();

    const response = await fetch(
        `${nogento.counterbase}/cs/resendShippingConfirmation/${orderId}`,
        {
            method: 'POST',
            headers: { Authorization: `Bearer ${token}` },
        },
    );

    const json = await response.json();

    return {
        ...json,
    };
}

// TODO: Remove once on new refund flow
export async function retryRefund(refund) {
    const token = await getUserToken();
    const response = await fetch(`${nogento.counterbase}/cs/retryRefund`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(refund),
    });

    return await response.json();
}

export async function cancelOrder(orderId, uid, { ticket, comment }) {
    const token = await getUserToken();
    const response = await fetch(`${nogento.counterbase}/cs/cancelOrder/${orderId}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ticket,
            reason: comment,
        }),
    });

    return await response.json();
}
export async function getUsernameByUserId(userId) {
    const database = await firebase.firestore();
    const userSnapshot = await database
        .collection('users')
        .doc(userId)
        .get();
    const { firstName, lastName } = await userSnapshot.data();
    const fullUserName = `${firstName}\u00A0${lastName}`;
    return fullUserName;
}

export async function getOrderLogs(orderId) {
    try {
        const orderLogs = [];
        const database = await firebase.firestore();
        const test = await database
            .collection('orders-log')
            .doc(orderId)
            .collection('events')
            .get()
            .then(snapshot => {
                snapshot.forEach(logs => {
                    orderLogs.push({
                        id: logs.id,
                        ...logs.data(),
                    });
                });
            });
        const sortedLogs = orderBy(orderLogs, ['dateChangedUTC'], ['desc']);
        const formattedLogs = [];
        // this loops through the differences array property and makes each change a seperate
        // object consisting of the change and the root elements (changedBy, Collection, date)
        // so for each difference, it will create and object of { changedby, collection, date, difference }
        // and push into formattedLogs array
        for (const entry of sortedLogs) {
            const { id, changedBy, auth, authType, differences, timestamp } = entry;
            // kind is action, lhs is old, rhs is new, path column name
            if (entry.differences) {
                for (const difference of differences) {
                    // my temp object used as a template to be pushed into [formattedLogs]
                    const objectTemplate = {
                        id,
                        changedBy,
                        timestamp,
                        auth,
                        authType,
                    };

                    const { kind, lhs, rhs, path } = difference;
                    objectTemplate.action = kind;
                    objectTemplate.oldValue = lhs;
                    objectTemplate.newValue = rhs;
                    objectTemplate.columnName = path;

                    formattedLogs.push(objectTemplate);
                }
            }
        }
        return formattedLogs;
    } catch (error) {
        console.error('errored', error);
        return [];
    }
}

export async function getDHLStaus(orderId) {
    try {
        const token = await getUserToken();
        const response = await fetch(`${nogento.counterbase}/cs/order/${orderId}/dhlStatus`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        return await response.json();
    } catch (error) {
        console.error(error);
    }
}

const handleError = error => ({
    success: false,
    error,
});

export async function updateFraudStatus(orderId, status, collection) {
    if (!isBoolean(status)) {
        return {
            success: false,
        };
    }

    try {
        const database = await firebase.firestore();
        return await database
            .collection(collection)
            .doc(orderId)
            .get()
            .then(
                async order =>
                    await order.ref
                        .update({ isFraud: status })
                        .then(() => ({ success: true }))
                        .catch(handleError),
            )
            .catch(handleError);
    } catch (error) {
        handleError(error);
    }
}

export async function updateFraudStatusAtSiftDecision({
    isFraud,
    uid: userId,
    analystEmail,
    orderId,
}) {
    const token = await getUserToken();

    const applyOrderDecisionResponse = await fetch(`${nogento.base}/sift/apply-order-decision`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            isFraud,
            userId,
            analystEmail,
            orderId,
        }),
    }).then(response => response.json());

    return applyOrderDecisionResponse;
}
