export const sorting = {
    byName: (items, type, key = 'title') => {
        function sortByName(a, b) {
            const nameA = a[key].toUpperCase();
            const nameB = b[key].toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        }

        return (
            (type == 'asc' && items.sort(sortByName)) ||
            (type == 'desc' && items.sort(sortByName).reverse())
        );
    },

    byPrice: (items, type) => {
        function sortByPrice(a, b) {
            const nameA = parseFloat(a.price);
            const nameB = parseFloat(b.price);
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        }

        return (
            (type == 'asc' && items.sort(sortByPrice)) ||
            (type == 'desc' && items.sort(sortByPrice).reverse())
        );
    },

    byPosition: (items, type, categories) => {
        const products = {};

        for (let p = 0, len = items.length; p < len; p++) {
            const item = items[p];

            for (let i = 0, len = categories.length; i < len; i++) {
                const cat = categories[i];
                const id = parseInt(cat.categoryId);

                // Category position takes priority
                // Product position defined under that category takes second priority
                if (item.categoryList.includes(id)) {
                    const newProduct = {
                        ...item,
                        position:
                            i * 1000000 +
                            (typeof item.positions[id] != 'undefined'
                                ? item.positions[id] * 1000 + 1
                                : 100000) +
                            p,
                        mainCategory: id,
                    };
                    products[item.id] = newProduct;
                    break;
                }
            }
        }

        function sortPosition(a, b) {
            if (!products[a.id]) return 1;
            if (!products[b.id]) return -1;
            if (products[a.id].position < products[b.id].position) return -1;
            if (products[b.id].position < products[a.id].position) return 1;
            return 0;
        }

        return items.sort(sortPosition);
    },

    byParentPosition: (items, type, categories, categoryId = 0) => {
        const products = {};

        for (let p = 0, len = items.length; p < len; p++) {
            const item = items[p];
            const newProduct = {
                ...item,
                position: item.positions[categoryId] || 1000000 + p,
                mainCategory: categoryId,
            };
            products[item.id] = newProduct;
        }

        function sortPosition(a, b) {
            if (!products[a.id]) return 1;
            if (!products[b.id]) return -1;
            if (products[a.id].position < products[b.id].position) return -1;
            if (products[b.id].position < products[a.id].position) return 1;
            return 0;
        }

        return items.sort(sortPosition);
    },

    byTagType: type => (
        a,
        b => {
            for (let i = 0; i < a.tags.length; i++) {
                for (let x = 0; x < b.tags.length; x++) {
                    if (a.tags[i] == type || b.tags[x] == type) {
                        const tagA = a.tags[i] == type;
                        const tagB = b.tags[x] == type;
                        if (tagA && !tagB) {
                            return -1;
                        }
                        if (!tagA && tagB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    }
                }
            }
        }
    ),
};
