import Router from 'universal-router';
import React from 'react';

import { routeConfig } from '@cs-admin/router/routes';
import { routeListGenerator } from './routeListGenerator';

const routeList = routeListGenerator(routeConfig);

/**
 * Initialize the router instance
 */

export default new Router(routeList, {
    resolveRoute(context, params) {
        if (typeof context.route.page === 'function') {
            return context.route.page(context, params);
        }
        if (typeof context.route.action === 'function') {
            return context.route.action(context, params);
        }
        return undefined;
    },
});
