import firebase from '@cs-admin/services/firebase/init';
import { COMPLETED, RECEIVED } from '@cs-admin/constants/returnStatusTypes';

// used for removing any white spaces or letters from "phoenixOrderId"
// when searching a specific order, orderId comes as a string with letter "F" at the beginning
// to get a specific order with "getPhoenixOrder" function, "phoenixOrderId" should be an integer
const formatPhoenixOrderId = /(\s+|F)/g;

/**
 * Returns a list of 10 returns
 */
export async function getReturns() {
    try {
        const returns = [];
        const database = await firebase.firestore();

        const snapshot = await database
            .collection('returns')
            .where('returnStatus', '==', RECEIVED)
            .get();
        snapshot.forEach(doc => returns.push({ docId: doc.id, ...doc.data() }));

        const processedAndInitiated = returns
            .filter(r => r.returnStatus !== COMPLETED)
            // processed/initiated (oldest -> newest)
            .sort((a, b) => new Date(a.dateCreatedUTC) - new Date(b.dateCreatedUTC))
            // processed returns on top
            .sort((a, b) => b.returnStatus - a.returnStatus);
        return processedAndInitiated;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

/**
 * Updates return info with new data
 * @param {string} uid
 * @param {object} data
 */
export async function updateReturnInfo(id, data) {
    try {
        const database = await firebase.firestore();
        await database
            .collection('returns')
            .doc(id)
            .update(data);

        return { success: true };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}

// returnsSearch
export async function returnSearch(search = {}) {
    try {
        const database = await firebase.firestore();
        const returns = database.collection('returns');
        let query = returns;

        const fireBaseOrderIdRegex = /\d{9}/;
        const phoenixOrderIdRegex = /F?\d{7}/;

        if (search.orderId && fireBaseOrderIdRegex.test(search.orderId)) {
            query = query.where('orderId', '==', parseInt(search.orderId.replace(/\s+/g, ''), 10));
        }

        if (search.phoenixOrderId && phoenixOrderIdRegex.test(search.phoenixOrderId)) {
            const phoenixOrderId = parseInt(
                search.phoenixOrderId.replace(formatPhoenixOrderId, ''),
                10,
            );
            query = query.where('phoenixOrderId', '==', phoenixOrderId);
        }

        const returnDocs = [];
        const querySnapshot = await query.get();
        querySnapshot.forEach(doc => {
            const data = doc.data();
            data.phoenixOrderId = data.phoenixOrderId ? `F${data.phoenixOrderId}` : 'N/A';
            returnDocs.push({ ...data, docId: doc.id });
        });

        return returnDocs;
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
}
