import { observable, action, computed, toJS } from 'mobx';
import {
    fetchEstimate,
    fetchProcessedRefunds,
    processRefund,
    retryRefund,
} from '@cs-admin/services/refunds';
import { SPLIT_PAYMENT, STORR, KLARNA } from '@cs-admin/constants/paymentMethods';
import { formatFloat } from '@cs-admin/utils/floatNumber';
import { checkCardRefundAmount } from './utils';
import { isEmpty } from 'lodash';
import { getSafe } from '@beautycounter/utils/object';

class RefundStore {
    @observable orderId;
    @observable refunds;
    @observable selectedProducts;
    @observable returnShipping;
    @observable refund;
    @observable reason;
    @observable fetchingEstimate;
    @observable processingRefund;
    @observable cards;
    @observable payments;
    @observable overriddenCards;
    @observable useOverrideFlow;
    @observable useSplitAutoRefundFlow;
    @observable useSelectionSplitFlow;
    @observable selectedCardsTransactionIds;
    @observable delay;

    constructor() {
        this.orderId = null;
        this.refunds = [];
        this.selectedProducts = [];
        this.returnShipping = false;
        this.refund = {};
        this.fetchingEstimate = false;
        this.reason = '';
        this.processingRefund = false;
        this.cards = [];
        this.payments = [];
        this.overriddenCards = [];
        this.useOverrideFlow = false;
        this.useSplitAutoRefundFlow = false;
        this.useSelectionSplitFlow = false;
        this.selectedCardsTransactionIds = [];
        this.delay = null;
    }

    get estimate() {
        return {
            ...(this.isSplitPayment && this.autoRefund),
            orderId: this.orderId,
            returnShipping: this.returnShipping,
            returnItems: this.selectedProducts,
        };
    }

    get clawbackAmount() {
        return this.refund.clawback || 0;
    }

    get canReturnShipping() {
        const { order } = this.refund;

        if (order) {
            const {
                cart: { shipping },
            } = order;

            if (this.refunds.length) {
                const refundedShipping = this.refunds
                    .map(refund => refund.shippingTotal)
                    .reduce((a, b) => a + b, 0);
                return shipping.total - refundedShipping;
            }

            return shipping.total;
        }

        return false;
    }

    @computed
    get purchasedProducts() {
        return this.refund.order ? this.refund.order.cart.adjustedProducts : [];
    }

    @computed
    get canRefundProducts() {
        const canRefundProducts = [];

        this.purchasedProducts.forEach(product => {
            const quantityAvailable = this.quantityAvailable(product);

            if (quantityAvailable) canRefundProducts.push(product);
        });

        return canRefundProducts;
    }

    @computed
    get isSplitPayment() {
        const isRefundSet = !isEmpty(this.refund);
        const isSplitPaymentMethod =
            isRefundSet && getSafe(() => this.refund.order.payment.method) === SPLIT_PAYMENT;
        const noPaymentsFound = Boolean(
            isRefundSet && (!this.refund.order.payments || !this.refund.order.payments.length),
        );
        return isSplitPaymentMethod && noPaymentsFound;
    }

    @computed
    get isAllSelected() {
        return !this.purchasedProducts.some(product => {
            const quantityAvailable = this.quantityAvailable(product);
            const isSelected = this.productSelected(product);

            return quantityAvailable && !isSelected;
        });
    }

    @computed
    get allCardsSelected() {
        const cardsWithAvailableRefund = this.cards.filter(
            card => this.cardAmountAvailable(card) > 0,
        );
        return cardsWithAvailableRefund.length
            ? !cardsWithAvailableRefund.some(checkCardRefundAmount)
            : !this.cards.some(checkCardRefundAmount);
    }

    @computed
    get allPaymentsSelected() {
        return !this.payments.some(payment => payment.refundAmount === 0);
    }

    get refundCards() {
        const { isFeatureEnabled } = this.flagStore;
        return this.cards.map(card => {
            const { refundAmount, transactionId, tenderId, accountNumber } = card;

            return {
                refundAmount,
                transactionId,
                tenderId,
                accountNumber,
            };
        });
    }

    get buildRefundContext() {
        const { squareV2 } = this.refund.order;

        return {
            ...this.refund,
            reason: this.reason,
            adminUid: this.userStore.userInfo.uid,
            cards: this.refundCards,
            payments: toJS(this.payments),
            squareV2,
        };
    }

    get currentPaymentSource() {
        if (this.cards.length) return 'cards';
        if (this.payments.length) return 'payments';
        return null;
    }

    get autoRefund() {
        return {
            autoRefund: {
                overriddenCards: this.overriddenCards.length ? this.overriddenCards : [],
                selectedCardsTransactionIds: this.selectedCardsTransactionIds,
                flags: {
                    useOverrideFlow: this.useOverrideFlow,
                    useSplitAutoRefundFlow: this.useSplitAutoRefundFlow,
                    useSelectionSplitFlow: this.useSelectionSplitFlow,
                },
            },
        };
    }

    @computed
    get isRefundEstimating() {
        return Boolean(
            this.useOverrideFlow || this.useSplitAutoRefundFlow || this.useSelectionSplitFlow,
        );
    }

    @action
    delayedEstimate(ms = 3000) {
        clearTimeout(this.delay);
        this.delay = setTimeout(() => {
            this.getEstimate();
        }, ms);
    }

    @action
    overrideFlow() {
        this.useOverrideFlow = true;
        this.updateOverriddenCards();
        this.delayedEstimate(5000);
    }

    @action
    splitAutoRefundFlow() {
        this.useSplitAutoRefundFlow = true;
    }

    @action
    selectionSplitFlow(cardTransactionId) {
        this.delayedEstimate();
        if (!cardTransactionId) return;
        const isCardSelected = this.selectedCardsTransactionIds.find(
            id => id === cardTransactionId,
        );
        if (isCardSelected) return;
        this.selectedCardsTransactionIds = [...this.selectedCardsTransactionIds, cardTransactionId];
        this.useSelectionSplitFlow = true;
    }

    @action
    resetAutoRefundFlow() {
        this.useOverrideFlow = false;
        this.useSplitAutoRefundFlow = false;
        this.useSelectionSplitFlow = false;
        this.selectedCardsTransactionIds = [];
        this.overriddenCards = [];
    }

    findProduct(list, product) {
        return list.find(item => {
            const sku = item.sku || item.SKU || item.Sku;
            const adjustedPrice =
                item.adjustedPrice !== undefined ? item.adjustedPrice : item.AdjustedPrice;

            return product.sku === sku && product.adjustedPrice === adjustedPrice;
        });
    }

    purchasedProduct(product) {
        return this.findProduct(this.purchasedProducts, product);
    }

    refundedQuantity(product) {
        let refundedQuantity = 0;
        this.refunds.forEach(refund => {
            refund.returnItems.forEach(item => {
                const sku = item.sku || item.SKU || item.Sku;
                const adjustedPrice = item.adjustedPrice || item.AdjustedPrice;

                if (product.sku === sku && product.adjustedPrice === adjustedPrice) {
                    const quantity = item.quantity || item.Quantity;
                    refundedQuantity += Number(quantity);
                }
            });
        });

        return refundedQuantity;
    }

    quantityAvailable(product) {
        const purchasedQuantity = this.purchasedProduct(product).quantity;
        const refundedQuantity = this.refundedQuantity(product);

        return purchasedQuantity - refundedQuantity;
    }

    buildProduct(product) {
        return {
            sku: product.sku || product.SKU || product.Sku,
            quantity: product.quantity || product.Quantity,
            adjustedPrice: product.adjustedPrice || product.AdjustedPrice || 0,
        };
    }

    productSelected(product) {
        return this.findProduct(this.selectedProducts, product);
    }

    cardRefundedAmount(card) {
        let prevRefundAmount = 0;
        this.refunds.forEach(refund => {
            const { cards, cardRefunds, newRefundFlow } = refund;

            if (newRefundFlow) {
                cards.forEach(item => {
                    if (
                        card.accountNumber === item.accountNumber &&
                        card.transactionId === item.transactionId
                    ) {
                        prevRefundAmount += item.refundAmount;
                    }
                });
            } else {
                cardRefunds.forEach(item => {
                    if (
                        card.accountNumber === item.accountNumber &&
                        card.transactionId === item.transactionId
                    ) {
                        prevRefundAmount += item.refundAmount;
                    }
                });
            }
        });
        return formatFloat(prevRefundAmount);
    }

    paymentRefundedAmount(payment) {
        const prevRefundAmount = 0;
        this.refunds.forEach(refund => {
            const { payments } = refund;

            payments.forEach(item => {
                if (payment.idempotencyKey === item.idempotencyKey) {
                    prevRefundAmount != item.refundAmount;
                }
            });
        });

        return prevRefundAmount;
    }

    cardAmountAvailable(card) {
        return formatFloat(card.amount - this.cardRefundedAmount(card));
    }

    paymentAmountAvailable(payment) {
        return payment.amount - this.paymentRefundedAmount(payment);
    }

    // TODO: Error messaging should be coming from backend refund service instead
    splitPaymentFormError() {
        const { useSelectionSplitFlow, useSplitAutoRefundFlow, useOverrideFlow } = this;
        if (useSelectionSplitFlow || useSplitAutoRefundFlow || useOverrideFlow) return;

        let errorMessage = null;
        let totalRefundAmount = 0;

        if (this.payments.length) {
            this.payments.map(payment => {
                if (
                    payment.refundAmount &&
                    payment.refundAmount > this.paymentAmountAvailable(payment)
                ) {
                    errorMessage =
                        'Please make sure the refund amount for each card is equal to or less than the refund amount available.';
                }

                totalRefundAmount += payment.refundAmount || 0;
            });
        } else {
            this.cards.map(card => {
                if (card.refundAmount && card.refundAmount > this.cardAmountAvailable(card)) {
                    errorMessage =
                        'Please make sure the refund amount for each card is equal to or less than the refund amount available.';
                }

                totalRefundAmount += card.refundAmount ? card.refundAmount : 0;
            });
        }

        if (!errorMessage && formatFloat(totalRefundAmount) !== this.refund.amount) {
            errorMessage =
                'Please make sure the refund sum for multiple cards matches the refund amount.';
        }

        return errorMessage;
    }

    @action
    resetForm() {
        this.reason = '';
        this.selectedProducts = [];
        this.returnShipping = false;
        this.getEstimate();
    }

    @action
    setOrderId(orderId) {
        this.orderId = parseInt(orderId);
        this.fetchRefunds();
        this.resetForm();
    }

    @action
    updateShipping() {
        this.returnShipping = !this.returnShipping;
        this.getEstimate();
    }

    @action
    updateReason(reason) {
        this.reason = reason;
    }

    @action
    addProduct(product, quantity) {
        const newProduct = this.buildProduct(product);
        newProduct.quantity = quantity;

        if (!this.productSelected(newProduct)) {
            this.selectedProducts.push({ ...newProduct });
            this.getEstimate();
        }
    }

    @action
    removeProduct(product) {
        const productToRemove = this.buildProduct(product);
        if (this.productSelected(productToRemove)) {
            const productIndex = this.selectedProducts.findIndex(
                selected =>
                    selected.sku === productToRemove.sku &&
                    selected.adjustedPrice === productToRemove.adjustedPrice,
            );
            this.selectedProducts.splice(productIndex, 1);
            this.getEstimate();
        }
    }

    @action
    updateProduct(product, quantity) {
        const productToUpdate = this.buildProduct(product);

        if (quantity == 0) this.removeProduct(product);
        else if (this.productSelected(productToUpdate)) {
            this.selectedProducts.find(selected => {
                if (
                    selected.sku === productToUpdate.sku &&
                    selected.adjustedPrice === productToUpdate.adjustedPrice
                )
                    selected.quantity = quantity;
            });
            this.getEstimate();
        } else this.addProduct(product, quantity);
    }

    @action
    selectAllProducts(products = this.canRefundProducts) {
        products.map(product => {
            const quantity = product.quantity || product.Quantity;
            this.updateProduct(product, quantity);
        });
    }

    @action
    removeAllProducts() {
        this.selectedProducts = [];
        this.getEstimate();
    }

    @action
    updateOverriddenCards() {
        this.overriddenCards = this.cards
            .filter(card => card.overridden)
            .map(({ transactionId, refundAmount }) => ({
                transactionId,
                refundAmount,
            }));
    }

    @action
    checkRefundAmountValue() {
        const paymentSource = this.currentPaymentSource;
        if (!paymentSource) return;
        this[paymentSource] = this[paymentSource].map(source => {
            if (source.refundAmount === '') {
                return {
                    ...source,
                    refundAmount: 0,
                };
            }
            return source;
        });
    }

    @action
    updateCardRefundAmount(cardToUpdate, amount, override = false) {
        this.cards = this.cards.map(card => {
            if (
                card.accountNumber === cardToUpdate.accountNumber &&
                card.transactionId === cardToUpdate.transactionId
            ) {
                return {
                    ...card,
                    refundAmount: amount,
                    ...(override && { overridden: true }),
                };
            }

            return card;
        });
    }

    @action
    updatePaymentRefundAmount(paymentToUpdate, amount) {
        this.payments = this.payments.map(payment => {
            if (payment.idempotencyKey === paymentToUpdate.idempotencyKey) {
                return {
                    ...payment,
                    refundAmount: amount,
                };
            }

            return payment;
        });
    }

    @action
    async getEstimate() {
        this.fetchingEstimate = true;
        const { success, message, data } = await fetchEstimate(this.estimate);
        if (success) {
            this.refund = data;

            if (this.isSplitPayment) {
                this.cards = this.refund.order.payment.splitPayments.map(card => {
                    const { refundAmount = 0 } = card;
                    return {
                        ...card,
                        refundAmount,
                    };
                });
                this.resetAutoRefundFlow();
            }

            if (
                this.refund.order.payments &&
                this.refund.order.payments.every(({ method }) => method !== KLARNA)
            ) {
                this.payments = this.refund.order.payments.map(payment => ({
                    ...payment,
                    refundAmount: 0,
                }));
            }

            if (
                this.refund.order.payments &&
                this.refund.order.payments.every(({ method }) => method === KLARNA) &&
                this.refund.order.payments.length === 1
            ) {
                this.payments = this.refund.order.payments.map(payment => ({
                    ...payment,
                    refundAmount: data.amount,
                }));
            }
        } else {
            this.interfaceStore.openAlert(true, `Error: ${message}`);
        }
        this.fetchingEstimate = false;
    }

    @action
    async fetchRefunds() {
        const { success, refunds, error } = await fetchProcessedRefunds(this.orderId);

        if (success) {
            this.refunds = refunds;
        } else {
            this.interfaceStore.openAlert(true, `Error fetching processed refunds: ${error}`);
        }
    }

    @action
    async retryRefund(refund) {
        this.interfaceStore.showProgress();
        const { success } = await retryRefund(refund);
        this.interfaceStore.hideProgress();

        if (success) {
            this.interfaceStore.openAlert(false, 'Processed refund successfully.');
            this.fetchRefunds();
        }

        this.interfaceStore.openAlert(true, 'Retry failed.');
    }

    @action
    async submitRefund() {
        this.checkRefundAmountValue();
        if (parseFloat(this.refund.amount) === 0 && parseFloat(this.refund.pcAmount) === 0) {
            this.interfaceStore.openAlert(
                true,
                'Please select something to refund before processing',
            );
            return;
        }

        if (this.refund.order.payment.method === SPLIT_PAYMENT && this.splitPaymentFormError()) {
            this.interfaceStore.openAlert(
                true,
                'Please fix errors on page first before processing.',
            );
            return;
        }

        this.interfaceStore.showProgress();
        this.processingRefund = true;
        const { success, message } = await processRefund(this.buildRefundContext);
        this.interfaceStore.hideProgress();

        const isStorrOrder = this.refund.order.payment.method === STORR;
        if (success) {
            if (isStorrOrder) {
                this.interfaceStore.openAlert(
                    false,
                    'Refund request has been made to Storr. Please allow up to 2 business days for the refund to be initiated by Storr',
                );
            } else {
                this.interfaceStore.openAlert(false, 'Successfully processed refund.');
            }
            this.fetchRefunds();
            this.resetForm();
            this.navigationStore.to({ url: `/orders/${this.orderId}/refunds` });
        } else {
            this.interfaceStore.openAlert(true, `Error: ${message}`);
        }

        this.processingRefund = false;
    }
}

const refundStore = new RefundStore();

export default RefundStore;
export { RefundStore };
