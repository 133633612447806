import { observable, computed, action } from 'mobx';
import {
    login as loginFirebase,
    logout as logoutFirebase,
    subscribeIsLogged,
    resetPassword as resetPasswordFirebase,
    forgotPassword,
    userLogin,
} from '@cs-admin/services/user';
import { cloneDeep } from 'lodash';
import {
    LEADER,
    AGENT,
    DEVELOPER,
    FRAUD_AGENT,
    SALES_OPERATOR_MANAGER,
} from '@cs-admin/constants/roleTypes';

class UserStore {
    @observable userInfo;
    @observable isLogged;
    @observable role;
    @observable devMode;
    @observable junoAccess;
    @observable promoAccess;

    constructor() {
        this.userInfo = {};
        this.isLogged = false;
        this.role = '';
        this.devMode = false;
        this.junoAccess = false;
        this.promoAccess = false;
    }

    @computed
    get isAgent() {
        return this.role === AGENT;
    }

    @computed
    get isLeader() {
        return this.role === LEADER || this.role === SALES_OPERATOR_MANAGER;
    }

    @computed
    get isDeveloper() {
        return this.role === DEVELOPER;
    }

    @computed
    get isFraudAgent() {
        return this.role === FRAUD_AGENT;
    }

    @action
    verifyLoggedIn = async () =>
        new Promise(resolve =>
            subscribeIsLogged(async auth => {
                if (!auth) resolve(false);
                const login = await auth();

                if (login && typeof login === 'object' && login.email) {
                    await this.addLoginState(login);
                    resolve(true);
                } else {
                    this.addLogoutState();
                    resolve(false);
                }
            }),
        );

    @action
    login = async form => {
        this.verifyLoggedIn();
        const response = await loginFirebase(form.email, form.password);

        if (response.email) {
            return {
                ...response,
                success: true,
            };
        }

        logoutFirebase();
        return {
            ...response,
            success: false,
        };
    };

    @action
    addLoginState = async account => {
        const userInfo = {
            ...account,
        };

        this.isLogged = true;
        this.userInfo = cloneDeep(userInfo);
        this.role = cloneDeep(userInfo.roleName);

        if (this.isDeveloper) this.devMode = true;
        if (cloneDeep(userInfo.junoAccess) && this.isDeveloper) this.junoAccess = true;
        if (cloneDeep(userInfo.promoAccess)) this.promoAccess = true;
    };

    @action
    logout = async (load = true) => {
        await logoutFirebase(() => {
            this.addLogoutState();
        });
    };

    @action
    addLogoutState = async () => {
        this.isLogged = false;
        this.userInfo = {};
        this.role = '';
        this.devMode = false;
        this.junoAccess = false;
    };

    @action requestPasswordReset = async params => resetPasswordFirebase(params);

    @action
    requestForgotPasswordEmail = async userInfo => {
        try {
            const response = await forgotPassword(userInfo);

            if (response.success) return { success: true };

            const message = response.message
                ? response.message
                : 'Unable to send forgot password email.';

            return { success: false, message };
        } catch (error) {
            return { success: false, message: 'Unable to send forgot password email.' };
        }
    };

    @action
    setDevMode() {
        this.devMode = !this.devMode;
    }
}

const userStore = new UserStore();

export default userStore;
export { UserStore };
