import { createMuiTheme } from '@material-ui/core/styles';
import { table } from './overwrites/table';
import palette from '@beautycounter/counter-ui/src/theme/palette';

const breakpoints = {};

/**
 * TODO: sync fonts with CDN
 */
const typography = {
    fontFamily: ['Mark', 'Helvetica Neue', 'Helvetica', 'sans-serif'].join(','),

    headline: {
        textTransform: 'uppercase',
        fontWeight: 900,
    },
};

/**
 * Overrides for MUI classes only
 */
const overrides = {
    table,
    MuiAppBar: {
        colorPrimary: {
            backgroundColor: palette.secondary.main,
            color: palette.secondary.contrastText,
        },
        root: {
            zIndex: 1000,
        },
    },
    MuiCard: {
        root: {
            padding: '2rem 4rem',
        },
    },
    MuiDrawer: {
        paperAnchorDockedLeft: {
            background: '#F4F7F9',
        },
    },
};

const theme = createMuiTheme({
    breakpoints,
    palette,
    typography,
    overrides,
});

export default theme;
