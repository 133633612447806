import { maxAmount } from '@cs-admin/services/productCredit';

export const returnTypes = {
    1: {
        title: 'Band of Beauty',
    },
    2: {
        title: 'Defective / Damaged Item',
        amount: 10.0,
        isStatic: true,
    },
    3: {
        title: 'No Order Adjustments / Product Availability',
        amount: 5.95,
        isStatic: true,
    },
    4: {
        title: 'Other (CX Related)',
    },
    5: {
        title: 'Out of Stock',
    },
    6: {
        title: 'Partial Collection',
    },
    7: {
        title: 'Product Recall',
    },
    8: {
        title: 'Promo or Tech Error',
        amount: 10.0,
        isStatic: true,
    },
    9: {
        title: 'Replace Expired Credit',
    },
    10: {
        title: 'Return Policy Exception',
    },
    11: {
        title: 'Shipping or Order Error',
        amount: 10.0,
        isStatic: true,
    },
    12: {
        title: 'Product Credit Error',
    },
    13: {
        title: 'Returns',
    },
    14: {
        title: 'Consultant Credit',
    },
};

export const CONSULTANT_CREDIT = 14;

export default returnTypes;
