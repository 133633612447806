import React from 'react';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { routes } from '@cs-admin/config';
import { DEV_MENU_ITEMS, PROD_MENU_ITEMS } from './constants';
import { styles } from './style.js';

@inject('userStore', 'flagStore')
@observer
class Menu extends React.Component {
    handleClick = data => {
        if (this.props.navigationStore.path !== data.currentTarget.id) {
            this.props.navigationStore.to({ url: data.currentTarget.id });
        }
    };

    selected(route) {
        if (window.location.pathname === routes.DASHBOARD) {
            return route === window.location.pathname;
        }

        const path = window.location.pathname.split('/');
        return route.includes(path[1]);
    }

    getMenuItems = () => {
        const { userStore, flagStore } = this.props;
        const menuItems = userStore.devMode ? DEV_MENU_ITEMS : PROD_MENU_ITEMS;

        if (
            flagStore.isFeatureEnabled('incentives') &&
            (userStore.isDeveloper || userStore.isLeader)
        ) {
            return menuItems;
        }

        return menuItems.filter(({ resource }) => resource !== 'Incentives');
    };

    render() {
        const { classes, open } = this.props;
        const menuItems = this.getMenuItems();

        return (
            <div className={classes.root}>
                <Drawer
                    variant="permanent"
                    className={classNames(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    open={open}
                    classes={{
                        paper: classNames({
                            [classes.drawer]: open || !open,
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <List className={classes.list}>
                        {menuItems.map(({ route, resource, icon }) => (
                            <ListItem
                                button
                                key={resource}
                                id={route}
                                selected={this.selected(route)}
                                onClick={this.handleClick}
                            >
                                <ListItemIcon>{icon}</ListItemIcon>
                                {resource}
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            </div>
        );
    }
}

export default withStyles(styles)(Menu);
