import { getUserToken } from '@cs-admin/services/user';
import fetch from '@cs-admin/services/fetch';

export default async function offboardAuth(endpoint, uid) {
    const token = await getUserToken();

    const response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify({
            uid,
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    return await response.json();
}
