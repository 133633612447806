import theme from '@beautycounter/counter-ui/src/theme';
const { palette } = theme || {};

export const table = {
    MuiTable: {
        root: {
            border: `2px solid ${palette.black}`,
        },
    },
    MuiTableCell: {
        root: {
            borderBottom: `2px solid ${palette.black}`,
            '&$paddingNone': {
                padding: '0 3rem',
            },
        },
        head: {
            color: `${palette.white}`,
            fontWeight: 900,
        },
    },
    MuiTableHead: {
        root: {
            background: `${palette.black}`,
            textTransform: 'uppercase',
        },
    },
    MuiTableSortLabel: {
        root: {
            // TODO: extend typography
            color: `${palette.white}`,
            '&:hover': {
                color: `${palette.gray60}`,
            },
            '&:focus': {
                color: `${palette.white}`,
            },
        },
        active: {
            color: `${palette.white}`,
        },
    },
    MuiTableRow: {
        root: {
            height: '5rem',
        },
    },
};
