import { getUserToken } from '@cs-admin/services/user';
import { nogento } from '@cs-admin/config';
import fetch from '@cs-admin/services/fetch';

export const getCustomerTaxNumber = async ({ phoenixId }) => {
    try {
        const token = await getUserToken();
        const createPwsUrl = `${nogento.counterbase}/cs/taxNumber?accountId=${phoenixId}`;
        const response = await fetch(createPwsUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        return response.json();
    } catch (e) {
        console.error(e);
        return {
            status: false,
        };
    }
};

export const updateCustomerTaxNumber = async ({ phoenixId, taxNumber, entityName, isEntity }) => {
    try {
        const token = await getUserToken();
        const createPwsUrl = `${nogento.counterbase}/cs/taxNumber?accountId=${phoenixId}`;
        const response = await fetch(createPwsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                taxNumber,
                isEntity,
                entityName,
            }),
        });

        return response.json();
    } catch (e) {
        console.error(e);
        return {
            status: false,
        };
    }
};
