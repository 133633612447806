import { mergeDeep } from '@cs-admin/utils/object';

const canUseDOM = !!(
        typeof window !== 'undefined' &&
        window.document &&
        window.document.createElement
    ),
    canUseWorkers = typeof Worker !== 'undefined',
    canUseEventListeners = canUseDOM && !!(window.addEventListener || window.attachEvent),
    canUseViewport = canUseDOM && !!window.screen;

/**
 * Gets the environment name passed in via command line
 * @param {Object} args
 */

function getEnvironmentName(args) {
    let customEnvironment = null;

    if (typeof process.env.NODE_ENV_SPECIAL == 'undefined' || args.test) {
        if (args.release && !args.environment) {
            customEnvironment = 'production';
        } else if (args.environment) {
            customEnvironment = args.environment;
        }
    }

    return customEnvironment;
}

/**
 * Finds the environment configuration passed in via command line
 * @param {String} env
 */

function getEnvironmentConfig(env) {
    const defaultEnvironment = require(`@beautycounter/cs-admin/src/environment`);
    let overrideEnvironment = {};

    /*
     * To not expose other environments when compiling for the client
     * we will use only the webpack defined name because a conditional require
     * would force a webpack dynamic require which would bundle all the
     * files in /environments.
     */
    if (typeof __WEBPACK_ENV__ !== 'undefined' && __WEBPACK_ENV__)
        overrideEnvironment = require(__WEBPACK_ENV__);
    /*
     * In direct mode (where only Babel is used) we will instead do a
     * conditional require as webpack is not available and we are
     * not concerned with exposing them.
     */ else if (typeof env === 'string')
        overrideEnvironment = require(`@beautycounter/cs-admin/environments/environment.${env}`);
    /*
     * When Jest tests run, we need to "trick" Jest into reading
     * the specified environment target config as there is not
     * currently a way to do so direct (although this may change in future).
     */ else if (typeof __TEST__ !== 'undefined' && __TEST__) {
        try {
            const fs = require('fs');
            const path = require('path');
            const name = fs.readFileSync(path.resolve(__dirname, '../../build/.env'), 'utf8');
            if (name.length > 2)
                overrideEnvironment = require(`@beautycounter/cs-admin/environments/environment.${name}`);
        } catch (e) {}
    }

    const environment = mergeDeep(defaultEnvironment.environment, overrideEnvironment.environment, {
        env,
    });

    return environment;
}

export {
    canUseDOM,
    canUseWorkers,
    canUseEventListeners,
    canUseViewport,
    getEnvironmentConfig,
    getEnvironmentName,
};
