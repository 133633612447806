const volumeFields = `
    NV
    PV
    QV
    CV
`;

const productFields = `
    name
    totalPrice
    price
    compareAtPrice
    swatchLabel
    id
    handle
    isSample
    thumbnailUrl
    bundled {
        sku
        name
    }
    quantity
    sku
    volumes {
        ${volumeFields}
    }
    isHostReward
    rewardType
`;

export const cartFields = `
    active
    lastModified
    clientUid
    typeId
    countryId
    uid
    active
    createDate
    cartId
    totalProducts
    socialId
    localeCode
    totalUniqueProducts
    subtotal
    prePromoSubtotal
    customer {
        firstName
        lastName
        userType
        email
        phoenixId
        memberExpirationDateUTC
    }
    products {
        ${productFields}
    }
    promoItems {
        sku
        id
    }
    hostRewardItems {
        ${productFields}
        isFree
        discounts {
            discount
            name
            id
            code
        }    
    }
    appliedPromos {
        id
        discount
        selection
        name
    }
    volumes {
        ${volumeFields}
    }
`;
