const roleTypes = [
    { id: 'leader', name: 'Leader' },
    { id: 'agent', name: 'Agent' },
    { id: 'developer', name: 'Developer' },
    { id: 'fraudAgent', name: 'Fraud Agent' },
    { id: 'salesOperatorManager', name: 'Sales Operator Manager' },
];

export const LEADER = 'leader',
    AGENT = 'agent',
    DEVELOPER = 'developer',
    FRAUD_AGENT = 'fraudAgent',
    SALES_OPERATOR_MANAGER = 'salesOperatorManager';

export default roleTypes;
