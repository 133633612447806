import { subYears } from 'date-fns';
import { MAJORITY_AGE } from '@cs-admin/constants/validation';

export const majorityMaxDate = subYears(new Date(), MAJORITY_AGE);

export function dateInMs(date = new Date()) {
    return date.getTime();
}

export const getISODateString = () => new Date().toISOString();
