export const replaceCircular = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export function noCircular(obj) {
    if (typeof obj !== 'object') return obj;
    return JSON.parse(JSON.stringify(obj, replaceCircular()) || {});
}
