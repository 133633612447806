import { nogento, phoenix } from '@cs-admin/config';
import { getUserToken } from '@cs-admin/services/user';
import fetch from '@cs-admin/services/fetch';

export const getUserPwsInfo = async accountId => requestPWS(accountId);

export const isPwsAvailable = async pws => {
    const pwsRequestParam = `?key=${encodeURIComponent(pws)}`;
    const pwsInfo = await requestPWS(pwsRequestParam);
    return pwsInfo && pwsInfo.success === false;
};

export async function requestPWS(params) {
    try {
        const endpoint = `${phoenix.pws}/${params}`;
        const response = await fetch(endpoint);
        return response.json();
    } catch (e) {
        console.error(e);
        return false;
    }
}

export async function createPWS({ phoenixId, firstName, lastName, email, title }) {
    try {
        const token = await getUserToken();
        const createPwsUrl = `${nogento.counterbase}/cs/accounts/phoenix/pws/`;
        const response = await fetch(createPwsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phoenixId, firstName, lastName, email, title }),
        });

        return response.json();
    } catch (e) {
        console.error(e);
        return {
            success: false,
        };
    }
}

export async function updatePws({ phoenixId, firstName, lastName, email, title, pwsKey }) {
    try {
        const token = await getUserToken();
        const createPwsUrl = `${nogento.counterbase}/cs/accounts/phoenix/pws/${phoenixId}`;
        const response = await fetch(createPwsUrl, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pwsKey, firstName, lastName, email, title }),
        });

        return response.json();
    } catch (e) {
        console.error(e);
        return {
            success: false,
        };
    }
}
