import { addMonths, subMonths, isBefore, isAfter } from 'date-fns';
import { MEMBER, CLIENT } from '@beautycounter/constants/userStates';
import { COUNTERBASE } from '@beautycounter/constants/channels';
import { formatToTimeZone } from 'date-fns-timezone';

/*
Promo rule id is needed to determine the validation result since the result for a drop rule and a GWP rule are opposites.
If a user should be able to buy BOB/Renewal these methods should return false for a drop rule so that the rule is not applied/sku is not removed.
If a user should be able to receive a GWP or free shipping with a BOB/Renewal purchase, these methods should return true so that the rule is applied.
*/

export const bobValidation = (validateBoB, context) => {
    let result;

    if (validateBoB && validateBoB.months && validateBoB.id) {
        // using months field on promo rule rather than hardcoding the number of months
        const { months, id } = validateBoB;
        const { timestamp, user, customer, channel } = context;
        const userInfo = Number(channel) === COUNTERBASE ? customer : user;

        if (
            userInfo &&
            Number(userInfo.userType) === Number(CLIENT) &&
            userInfo.memberExpirationDateUTC
        ) {
            const { memberExpirationDateUTC, userType } = userInfo;
            const minimumDate = addMonths(new Date(memberExpirationDateUTC), months);
            const isAfterMinimumDate = isAfter(new Date(timestamp), minimumDate);

            result = id === 21 ? !isAfterMinimumDate : isAfterMinimumDate;
            return result;
        }

        /*
        do not need to do date validation for customers without a memberExpirationDateUTC since they have not purchased BOB before
        */
        result = id === 21 ? false : true;
        return result;
    }
};

export const renewalValidation = (validateRenewal, context) => {
    /*
    Should only be eligilbe to purchase the Renewal SKU if:
    User is currently a member whose account will expire in 6 months or less.
    User has been downgraded to Client, but their memberExpirationDateUTC is within 6 months or less of renewal SKU purchase date.
    */

    let result;

    if (validateRenewal && validateRenewal.months && validateRenewal.id) {
        const { months, id } = validateRenewal;
        const { timestamp, user, customer, channel } = context;
        const userInfo = Number(channel) === COUNTERBASE ? customer : user;

        if (
            userInfo &&
            (Number(userInfo.userType) === Number(MEMBER) ||
                Number(userInfo.userType) === Number(CLIENT)) &&
            userInfo.memberExpirationDateUTC
        ) {
            const { memberExpirationDateUTC, userType } = userInfo;
            const orderTimestamp = new Date(timestamp);
            const memberExpirationDate = new Date(memberExpirationDateUTC);

            if (Number(userType) === Number(MEMBER)) {
                const expirationCompareDate = renewalEndOfDayDate(memberExpirationDateUTC);
                const minimumDate = subMonths(memberExpirationDate, months);
                const isAfterMinDate = isAfter(orderTimestamp, minimumDate);
                const isBeforeExpiration = isBefore(orderTimestamp, expirationCompareDate);

                const isWithinDateRange = !(isAfterMinDate && isBeforeExpiration);
                result = id === 21 ? isWithinDateRange : !isWithinDateRange;
                return result;
            } else {
                const maxLapsedDate = addMonths(memberExpirationDate, months);
                const isAfterLapsedDate = isAfter(orderTimestamp, maxLapsedDate);

                result = id === 21 ? isAfterLapsedDate : !isAfterLapsedDate;
                return result;
            }
        }

        // not eligilbe for Renewal if not currently a member or not a downgraded member within 6 months of their downgrade date
        result = id === 21 ? true : false;
        return result;
    }
};

function renewalEndOfDayDate(memberExpirationDateUTC) {
    // convert memberExpirationDateUTC from UTC to PST
    let dateUTC = new Date(memberExpirationDateUTC);
    let formattedDate = formatToTimeZone(dateUTC, 'YYYY-MM-DDTHH:mm:ss.SSS', {
        timeZone: 'America/Los_Angeles',
    });
    const datePSTString = formattedDate + 'Z';

    // set memberExpirationDateUTC to start of the day (member exp date at 12 am PST)
    const datePST = new Date(datePSTString);
    const utcInt = Date.parse(memberExpirationDateUTC);
    const pstInt = Date.parse(datePSTString);
    const offset = Number(((utcInt - pstInt) / (1000 * 60 * 60)).toFixed(0));
    const midnight = datePST.setUTCHours(offset, 0, 0, 0);
    const midnightDate = new Date(midnight);
    const startOfDay = midnightDate.toISOString();

    /*
    Use startOfDay date to calculate the end of day (member exp date at 11:59 pm PST).
    The endOfDay value will be used to compare against user's memberExpirationDateUTC
    since members are not expired until the end of the day at midnight
    */
    let utcIntEnd = Date.parse(startOfDay);
    let nextDay = utcIntEnd + 86399999;
    let newDate = new Date(nextDay);
    const expirationCompareDate = newDate.toISOString();

    return expirationCompareDate;
}
