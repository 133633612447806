const CountryNames = {
    US: 'United States',
    CA: 'Canada',
};
const CountryNamesShort = {
    US: 'US',
    CA: 'CA',
};

const IsoCodes = {
    enUS: 'en-US',
    enCA: 'en-CA',
    frCA: 'fr-CA',
};

const CountryIds = {
    US: 1,
    CA: 2,
};

const LanguageIds = {
    EN: 1,
    FR: 2,
    /* UNCOMMENT WHEN ADDING SPANISH LANGUAGE FUNCTIONALITY TO THE SITE
    ES: 3,
    */
};

const LanguageNames = {
    EN: 'English',
    FR: 'Français',
    /* UNCOMMENT WHEN ADDING SPANISH LANGUAGE FUNCTIONALITY TO THE SITE
    ES: 'Español',
    */
};

const LanguageNamesShort = {
    EN: 'EN',
    FR: 'FR',
    /* UNCOMMENT WHEN ADDING SPANISH LANGUAGE FUNCTIONALITY TO THE SITE
    ES: 'ES',
    */
};

// The below was added for the flyout functionality
// we should be able to remove in the future and redirect to the new
const countryOptions = [
    {
        name: 'United States ($USD)',
        selectable: true,
        key: 'US',
        short: 'US',
        value: 'US',
    },
    {
        name: 'Canada ($CAD)',
        selectable: true,
        key: 'CA',
        short: 'CA',
    },
];

const language = [
    { key: 'en', parentCountry: 'US', name: 'English (EN)', short: 'en', selectable: true },
    /* UNCOMMENT WHEN ADDING SPANISH LANGUAGE FUNCTIONALITY TO THE SITE
    { key: 'es', parentCountry: 'US', name: 'Spanish (ES)', short: 'es', selectable: true },
    */
    { key: 'en', parentCountry: 'CA', name: 'English (EN)', short: 'en', selectable: true },
    { key: 'fr', parentCountry: 'CA', name: 'French (FR)', short: 'fr', selectable: true },
];
const Enums = {
    EnUS: 'EnUS',
    /* UNCOMMENT WHEN ADDING SPANISH LANGUAGE FUNCTIONALITY TO THE SITE
    EsUS: 'EsUS',
    */
    EnCA: 'EnCA',
    FrCA: 'FrCA',
};

module.exports = {
    CountryNames,
    CountryNamesShort,
    IsoCodes,
    CountryIds,
    LanguageIds,
    LanguageNames,
    LanguageNamesShort,
    countryOptions,
    language,
    Enums,
};
