import { toTwoDecimal } from './utils/numbers';

export function createHostRewardPromoRules(context) {
    // get to total, is there any non host reward items
    // evaludate customer count and total
    // determin number of free and halfof items
    // calcuate product and price and flag them
    // remove products if nessesary
    const { hostRewardItems } = context.cart;
    const promoRules = hostRewardItems.map(
        ({ sku, price, quantity, rewardType, rewardDiscount }, i) => ({
            active: true,
            id: 7,
            name: rewardType === 'free' ? `HostRewardFree-${i}` : `HostRewardDiscount-${i}`,
            actionItems: [sku],
            actionQuantity: { perConditionItem: 1 },
            conditionItems: [sku],
            discountValue: [rewardType === 'free' ? 1 : rewardDiscount],
            stop: false,
            priority: 200,
            final_sale: true,
        }),
    );

    // hostRewardItems.forEach(item => {
    //     const previous = context.cart.promoItems.find(({ sku }) => sku === item.sku);
    //     if (previous) previous.quantity += item.quantity;
    //     else context.cart.promoItems.push(item);
    // });
    return promoRules;
}

export function generateHostRewardProducts(hostRewardItems) {
    // combine non free items
    const halfOffs = {};
    hostRewardItems
        .filter(x => !x.isFree)
        .map(x => {
            if (halfOffs[x.sku]) {
                halfOffs[x.sku].quantity += 1;
            } else {
                halfOffs[x.sku] = x;
            }
        });

    return hostRewardItems
        .filter(x => x.isFree)
        .concat(Object.values(halfOffs))
        .map(reward => {
            const { price, isFree, quantity } = reward;
            const discountPercent = isFree ? 1 : 0.5; // hard coded for now
            const discount = price * quantity * discountPercent;

            reward.totalPrice = toTwoDecimal(price * quantity - discount);
            const rewardName = `host-reward-${isFree ? 'free' : 'halfoff'}`;
            reward.discounts = [
                {
                    id: 0,
                    code: rewardName,
                    name: rewardName,
                    discount,
                    final_sale: true,
                },
            ];

            return { ...reward, isHostReward: true, quantity };
        });
}
