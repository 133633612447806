import React, { Component } from 'react';
import PropTypes from 'prop-types';

const title = 'Error';

const styles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
}

class ErrorPage extends Component {
    static pageName = 'error';

    static propTypes = {
        error: PropTypes.shape({
        name: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        stack: PropTypes.string.isRequired,
        }),
    };

    static defaultProps = {
        error: null,
    };

    render() {
        if (this.props.error) {
            return (
                <div style={{
                        alignItems: 'center', 
                        display: 'flex', 
                        justifyContent:'center', 
                        flexDirection:'column',
                        padding: "20px"
                    }}>
                <h2>{this.props.error.name}</h2>
                <span>{this.props.error.stack}</span>
                </div>
            );
        }
        return (
        <div>
            <h1>Error</h1>
            <p>Sorry, a critical error occurred on this page.</p>
        </div>
        );
    }
}

export default ErrorPage;